import TablePagination from '@mui/material/TablePagination'
import { ChangeEvent, MouseEvent, memo } from 'react'

const LeadsTablePagination = ({
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  totalRecords,
}: {
  page: number
  rowsPerPage: number
  totalRecords: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>
}) => {
  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <TablePagination
      component="div"
      sx={{ overflow: 'hidden' }}
      count={totalRecords}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}

export default memo(LeadsTablePagination)
