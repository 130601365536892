import { Autocomplete, Chip, TextField } from '@mui/material'
import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { debounce, isEqual } from 'lodash'

import { GetJobTitlesAutocompleteOptionsDocument } from '../../../graphql/generated'
import styles from '../../../pages/UniverseController/AddObjectDialog/styles'
import { useCampaignAndUser } from '../../NewCampaignLayout'
import { WidgetMessage } from '..'
import Text from '../../Text'

const style = {
  autoComplete: {
    '& .MuiChip-root': {
      background: '#5F69C1',
      color: 'white',
    },
    '& .MuiChip-deleteIcon ': { color: 'white !important' },
    '& .MuiOutlinedInput-root ': { padding: '6px', paddingRight: '0px !important' },
    '@media (max-width: 344px)': {
      '& .MuiOutlinedInput-root ': { padding: '0px 0px' },
    },
    '.MuiAutocomplete-input': { minWidth: '0px !important', padding: '0px !important' },
  },
  textField: {
    ':hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '2px solid #CED3F1 !important',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
    },
    '.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#CED3F1 !important',
    },
  },
}

const JobTitles = ({
  label,
  textStyles,
  showError,
  padding,
  handleChange,
  exampleTitles,
  handleTranslate,
}: {
  label: string
  textStyles?: object
  showError?: boolean
  padding?: string
  handleChange?: (value: string[]) => void
  exampleTitles?: string[]
  handleTranslate?: (widgetMessage?: WidgetMessage) => Promise<void>
}) => {
  const { campaign } = useCampaignAndUser()
  const selectedPersona = campaign?.personas?.[0]

  const [getAutocompleteOptions, { loading, data }] = useLazyQuery(GetJobTitlesAutocompleteOptionsDocument)

  const [jobTitles, setJobTitles] = useState<string[]>([])
  const [isOpen, setIsOpen] = useState(false)

  const [limitTags, setLimitTags] = useState(3)

  // Check the screen width and update the limitTags value accordingly
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 385) {
        setLimitTags(1)
      } else if (window.innerWidth <= 600) {
        setLimitTags(2) // Set the limit to 2 on mobile screens
      } else {
        setLimitTags(3) // Set the limit to 3 on larger screens
      }
    }

    // Initial check on component mount
    handleResize()

    // Add a window resize listener
    window.addEventListener('resize', handleResize)

    // Remove the resize listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (selectedPersona?.exampleTitles) {
      setJobTitles((selectedPersona?.exampleTitles as string[]) ?? '')
    }
  }, [setJobTitles, selectedPersona?.exampleTitles])

  const handleInputChange = debounce((value: string) => {
    if (value.length >= 2) {
      getAutocompleteOptions({ variables: { input: value } })
    }
  }, 500)

  return (
    <div
      style={{
        width: '100%',
        padding: padding,
        borderRadius: '16px',
        background: '#FFF',
        marginBottom: '12px',
      }}
    >
      <Text extraStyles={[textStyles || styles.dialogFormContentSubTitle]}>{label}</Text>
      <Autocomplete
        multiple
        id="job-titles-select"
        // limitTags={3}
        limitTags={limitTags}
        value={exampleTitles ? exampleTitles : jobTitles}
        disableCloseOnSelect
        filterOptions={(options, params) => {
          const filtered = options.filter((option) => {
            return option.toLowerCase().includes(params.inputValue.toLowerCase())
          })

          if (params.inputValue !== '' && !options.includes(params.inputValue)) {
            filtered.push(params.inputValue)
          }

          return filtered
        }}
        options={(data?.getJobTitlesAutocompleteOptions as string[]) || []}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => <Chip label={option} {...getTagProps({ index })} />)
        }
        sx={style.autoComplete}
        onChange={(event, newValue) => {
          if (handleChange) {
            handleChange(newValue as string[])
            return
          }
          setJobTitles(newValue as string[])
        }}
        onInputChange={(_, value) => {
          handleInputChange(value)
        }}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={(event, reason) => {
          if (reason === 'blur') {
            setIsOpen(false)
            const selectedValues = jobTitles?.join(', ')
            if (selectedValues && handleTranslate && !isEqual(jobTitles, selectedPersona?.exampleTitles)) {
              handleTranslate({
                message: `Update Job Titles: ${selectedValues}`,
                type: 'not_to_show',
                widget: 'job_titles',
              })
            }
          }
        }}
        getOptionLabel={(option) => option || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={jobTitles?.length > 0 || exampleTitles ? '' : 'Example titles'}
            error={showError && exampleTitles?.length === 0}
            helperText={!exampleTitles?.length && showError ? 'Please enter example titles' : ''}
            inputProps={{
              ...params.inputProps,
            }}
            sx={style.textField}
          />
        )}
      />
    </div>
  )
}

export default JobTitles
