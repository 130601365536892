import { memo, useState } from 'react'
import { Button, TextareaAutosize } from '@mui/material'
import { css } from 'aphrodite'

import styles from './style'
import { ReactComponent as SuccessSvgIcon } from '../../../../assets/svg/blue_success.svg'
import { useCampaignAndUser } from '../../../NewCampaignLayout'
import { useGraphQL } from '../../../../context/GraphQLContext'
import Text from '../../../Text'
import client from '../../../../apolloClient'
import {
  GetCampaignByIdDocument,
  GetCompaniesForLabeling,
  UpdateTargetingInfoDocument,
} from '../../../../graphql/generated'
import { useMutation } from '@apollo/client'

const EditedTargetDescr = ({
  targetingDescr: descr,
  handleCreateTargetingInfo,
  showError,
  messageType,
  messageId,
}: {
  targetingDescr?: string
  messageType?: string
  messageId?: string
  handleCreateTargetingInfo: () => void
  showError?: boolean
}) => {
  const [updateTargetingInfo] = useMutation(UpdateTargetingInfoDocument)

  const { campaign } = useCampaignAndUser()
  const [targetingDescr, setTargetingDescr] = useState(descr || '')
  const { acceptOrRejectTargetDescr, handleGetLabelingCompanies, getCompaniesForLabelingResult } = useGraphQL()
  // const { showError } = useChatContext()
  const [isClicked, setIsClicked] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  const handleClick = async (type: string) => {
    if (campaign?.id && messageId) {
      await acceptOrRejectTargetDescr({
        variables: {
          data: {
            edited_target_descr: targetingDescr,
            campaignId: campaign.id,
            type: type,
            messageId: messageId,
          },
        },
      }).then((res: any) => {
        client.cache.writeQuery({
          query: GetCampaignByIdDocument,
          variables: {
            data: {
              id: campaign.id,
              isDemo: false,
            },
          },
          data: {
            getCampaignById: {
              ...res.data.acceptOrRejectTargetDescr,
            },
          },
        })
        if (type === 'accepted_edited_target_descr') {
          const companiesLabeledAsMachineLabel =
            getCompaniesForLabelingResult?.data?.getCompaniesForLabeling.companies
              ?.slice(0, 5)
              ?.filter((company: GetCompaniesForLabeling) => company?.user_label === company?.machine_label) || []

          updateTargetingInfo({
            variables: {
              data: {
                campaignId: campaign.id,
                should_keep_unlabeled_companies: companiesLabeledAsMachineLabel?.length >= 2 ? true : false,
              },
            },
          })
          handleGetLabelingCompanies({
            campaign,
            getMoreCompanies: companiesLabeledAsMachineLabel?.length >= 2 ? false : true,
            order: 'asc',
          })
        }
      })
    }
    setIsSaved(true)

    // Reset button state after 2 seconds
    setTimeout(() => {
      setIsSaved(false)
    }, 2000)
  }

  const isDismissed =
    messageType === 'dismissed_edited_target_descr' || messageType === 'dismissed_edited_target_descr_show_companies'
  const isAccepted =
    messageType === 'accepted_edited_target_descr' || messageType === 'accepted_edited_target_descr_show_companies'

  return (
    <>
      <div
        className={css(styles.editedTargetDescriptionContainer)}
        onClick={() => setIsClicked(!isClicked)}
        style={{
          border: isClicked ? '1px solid rgba(55, 141, 250, 0.40))' : '1px solid #F0F3F5',
          boxShadow: isClicked ? '0px 0px 15px 0px rgba(55, 141, 250, 0.19)' : 'none',
        }}
      >
        <Text extraStyles={[styles.editTargetDescrHeading]}>New target company description </Text>
        <TextareaAutosize
          maxRows={16}
          name="targetingDescr"
          placeholder="The company provides smartwatches with advanced health and fitness features"
          className={css(styles.editedTargetDescriptionArea)}
          value={targetingDescr}
          disabled={isAccepted || isDismissed}
          onChange={(e) => {
            setTargetingDescr(e.target.value)
          }}
        />
        <div className={css(styles.buttonContainer)}>
          {!isDismissed && !isAccepted && (
            <>
              <Button
                onClick={() => handleClick('accepted_edited_target_descr')}
                className={css([styles.saveButton, isSaved ? styles.savedBtn : ''])}
              >
                {isSaved ? (
                  <>
                    <SuccessSvgIcon /> Accepted
                  </>
                ) : (
                  'Accept'
                )}
              </Button>
              <Button
                onClick={() => handleClick('dismissed_edited_target_descr')}
                className={css([styles.dismissedButton, isSaved ? styles.savedBtn : ''])}
              >
                Dismiss
              </Button>
            </>
          )}
          {isAccepted && (
            <Button disabled={isAccepted} className={css([styles.savedBtn])}>
              <SuccessSvgIcon /> Accepted
            </Button>
          )}
          {isDismissed && (
            <Button disabled={isDismissed} className={css([styles.dismissedButton])}>
              Dismissed
            </Button>
          )}
        </div>
      </div>{' '}
    </>
  )
}

export default memo(EditedTargetDescr)
