import { css } from 'aphrodite'
import React from 'react'

import styles from './styles'

interface Args {
  bold?: boolean
  children: any
  extraStyles?: any[]
  inlineStyle?: React.CSSProperties
  light?: boolean
  openSans?: boolean
  semiBold?: boolean
  smallSectionTitle?: boolean
  small?: boolean
  onClick?: () => void
}

const Text = ({
  bold,
  children,
  extraStyles,
  inlineStyle,
  light,
  openSans,
  semiBold,
  smallSectionTitle,
  small,
  onClick,
}: Args) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <p
    onKeyPress={() => {}}
    className={css(
      styles.text,
      light && styles.light,
      bold && styles.bold,
      openSans && styles.openSans,
      semiBold && styles.semiBold,
      smallSectionTitle && styles.smallSectionTitle,
      small && styles.small,
      ...(extraStyles || []),
    )}
    style={inlineStyle}
    onClick={onClick}
  >
    {children}
  </p>
)

export default Text
