import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Button, Grid } from '@mui/material'
import { css } from 'aphrodite'

import Text from '../../../components/Text'
import UserModal from './UserModal/UserModal'
import AdminTable from './AdminTable'
import styles from './style'
import { useAuth } from '../../../hooks/useAuth'
import {
  GetAllUsersDocument,
  GetAllPendingUserInvitesDocument,
  User,
  GetStripeCustomerPortalDocument,
} from '../../../graphql/generated'
import WarningModal from '../../../components/WarningModal/WarningModal'

const CompanyAdminSetting = () => {
  const { user } = useAuth()
  const [showWarnModal, setShowWarnModal] = useState(false)
  const [showAddUserModal, setShowAddUserModal] = useState(false)

  const [getAllUsers, { data }] = useLazyQuery(GetAllUsersDocument, {
    fetchPolicy: 'network-only',
    pollInterval: 10000,
  })

  const [getAllPendingUserInvites, { data: pendingUser }] = useLazyQuery(GetAllPendingUserInvitesDocument, {
    fetchPolicy: 'network-only',
    pollInterval: 10000,
  })

  const [getCustomerPortal] = useLazyQuery(GetStripeCustomerPortalDocument, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (user) {
      getAllUsers({
        variables: {
          companyId: user?.companyId,
        },
      })
      getAllPendingUserInvites({
        variables: {
          companyId: user?.companyId,
        },
      })
    }
  }, [getAllUsers, getAllPendingUserInvites, user])

  const handleAccept = async () => {
    try {
      if (user && user.company) {
        getCustomerPortal({
          variables: {
            stripeCustomerId: user.company.stripeCustomerId as string,
          },
        }).then(({ data }) => {
          if (data?.getStripeCustomerPortal) {
            handleWarnModal()
            window.location.href = data?.getStripeCustomerPortal
          }
        })
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleShowAddUserModal = useCallback(() => {
    setShowAddUserModal(!showAddUserModal)
  }, [showAddUserModal])

  const getUsersCount = useMemo(() => {
    if (data?.getAllUsers?.length && pendingUser?.getAllPendingUserInvites?.length) {
      return data?.getAllUsers?.length + pendingUser?.getAllPendingUserInvites?.length
    } else if (data?.getAllUsers?.length && !pendingUser?.getAllPendingUserInvites?.length) {
      return data?.getAllUsers?.length
    } else if (!data?.getAllUsers?.length && pendingUser?.getAllPendingUserInvites?.length) {
      return pendingUser?.getAllPendingUserInvites?.length
    }
    return 0
  }, [data?.getAllUsers.length, pendingUser?.getAllPendingUserInvites.length])

  const handleWarnModal = () => {
    setShowWarnModal(!showWarnModal)
  }

  const handleAddMemberClick = () => {
    if (getUsersCount === user?.company?.seatsAvailable) {
      handleWarnModal()
    } else {
      handleShowAddUserModal()
    }
  }

  return (
    <Grid container>
      <WarningModal
        loading={false}
        open={showWarnModal}
        handleAccept={handleAccept}
        title="Not enough seats available"
        handleReject={handleWarnModal}
        acceptLabel="Upgrade your subscription"
        rejectLabel="Never mind"
        message={`Your subscription has an allocation of ${user?.company?.seatsAvailable} seats available. To get more seats, please upgrade your subscription.`}
      />
      <Grid item xs={12}>
        <Text extraStyles={[styles.header]}>Admin Setting</Text>
      </Grid>
      <Grid item xs={12}>
        <div className={css(styles.subHeader)}>
          <Text extraStyles={[styles.subHeading]}>
            Account Members{' '}
            <small className={css(styles.seatUsed)}>
              {getUsersCount}/{user?.company?.seatsAvailable} seats used
            </small>
          </Text>
          <Button variant="outlined" className={css(styles.addMemberButton)} onClick={handleAddMemberClick}>
            Add Member
          </Button>
        </div>
      </Grid>
      <br />
      <br />
      <Grid
        item
        xs={12}
        sx={{
          '& ::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <AdminTable
          pendingUsers={pendingUser?.getAllPendingUserInvites as User[]}
          verifiedUsers={data?.getAllUsers as User[]}
        />
      </Grid>
      <UserModal
        header="Add a new member"
        modalButton="Add"
        showUserModal={showAddUserModal}
        companyId={user?.companyId}
        handleShowUserModal={handleShowAddUserModal}
      />
    </Grid>
  )
}

export default CompanyAdminSetting
