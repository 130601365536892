import { FC, memo } from 'react'
import { Grid, Typography } from '@mui/material'
import { css } from 'aphrodite'
import HelpInfoPanelSvg from '../../../assets/svg/help _info_panel.svg'
import styles from './styles'
import { GetCampaignStatusRes } from '../../../graphql/generated'
import { useCampaignAndUser } from '../../../components/NewCampaignLayout'

interface LeadsDataIndicatorProps {
  campaignStatus?: GetCampaignStatusRes
  leadsGenerated: number
}

const LeadsDataIndicator: FC<LeadsDataIndicatorProps> = ({ campaignStatus, leadsGenerated }) => {
  const { campaign } = useCampaignAndUser()
  return (
    <Grid item xs={12} className={css(styles.dataIndicator)}>
      <div className={css(styles.dataIndicatorCard)}>
        <Typography variant="body1" className={css(styles.dataIndicatorCardTitle)}>
          Leads generated
          <img src={HelpInfoPanelSvg} alt="help-info" />
        </Typography>
        <Typography variant="body1" className={css(styles.dataIndicatorCardValue)}>
          {leadsGenerated}
        </Typography>
      </div>
      <div className={css(styles.dataIndicatorCard)}>
        <Typography variant="body1" className={css(styles.dataIndicatorCardTitle)}>
          Leads Requested
          <img src={HelpInfoPanelSvg} alt="help-info" />
        </Typography>
        <Typography variant="body1" className={css(styles.dataIndicatorCardValue)}>
          {campaign?.desiredNumberOfLeads}
        </Typography>
      </div>
      <div className={css(styles.dataIndicatorCard)}>
        <Typography variant="body1" className={css(styles.dataIndicatorCardTitle)}>
          Total Accurate Leads
          <img src={HelpInfoPanelSvg} alt="help-info" />
        </Typography>
        <Typography variant="body1" className={css(styles.dataIndicatorCardValue)}>
          {campaignStatus?.accurate_leads}
        </Typography>
      </div>
      <div className={css(styles.dataIndicatorCard)}>
        <Typography variant="body1" className={css(styles.dataIndicatorCardTitle)}>
          Potential Leads
          <img src={HelpInfoPanelSvg} alt="help-info" />
        </Typography>
        <Typography variant="body1" className={css(styles.dataIndicatorCardValue)}>
          {campaignStatus?.db_leads_estimate}
        </Typography>
      </div>
    </Grid>
  )
}

export default memo(LeadsDataIndicator)
