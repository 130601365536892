import { useNavigate, useOutlet } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import { css } from 'aphrodite'
import { useEffect, useState } from 'react'
import useNavigateToLogin from '../../hooks/useNavigateToLogin'
import { useIntercom } from 'react-use-intercom'
import { H } from 'highlight.run'

import { ReactComponent as addNewLeadsSvgIcon } from '../../assets/svg/plus_icon.svg'
import { ReactComponent as selectedAddNewLeadsSvgIcon } from '../../assets/svg/🚀.svg'
import { ReactComponent as selectedSettingsSvgIcon } from '../../assets/icons/settings.svg'
import { ReactComponent as SettingsSvgIcon } from '../../assets/svg/setting_icon.svg'
import { ReactComponent as SelectedDashboardIcon } from '../../assets/svg/🗂.svg'
import DashboardIcon from '../../assets/icons/DashboardIcon'
import UniverseIcon from '../../assets/icons/UniverseIcon'
import { AppBar } from '../NavBar'
import styles from './styles'

const ProtectedLayout = () => {
  const { user, firebaseUser, isUserLoading, pages, setPages, isMobile } = useAuth()
  const { update } = useIntercom()
  const { navigateToLogin } = useNavigateToLogin()
  const outlet = useOutlet()
  const navigate = useNavigate()

  useEffect(() => {
    setPages([
      { label: 'Leads lists', path: 'campaigns', icon: DashboardIcon, selectedIcon: SelectedDashboardIcon },
      // { label: "Campaigns", path: "campaigns", icon: CampaignsIcon },
      // { label: "Verifications", path: "verifications" },
      // { label: "Profile", path: "profile" },
      {
        label: 'Generate new leads',
        path: 'new-campaign/targeting-input',
        icon: addNewLeadsSvgIcon,
        selectedIcon: selectedAddNewLeadsSvgIcon,
      },
      { label: 'Settings', path: 'settings', icon: SettingsSvgIcon, selectedIcon: selectedSettingsSvgIcon },
    ])
  }, [setPages])

  useEffect(() => {
    console.log('firebaseUser from ProtectedLayout', firebaseUser)
    console.log('user from ProtectedLayout', user)
    if (!firebaseUser) {
      navigateToLogin()
    } else if (user && user.company?.hasCompletedOnboarding) {
      update({
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        userId: user.id,
        createdAt: user.createdAt,
      })
      H.identify(user.email, {
        ID: user.id,
        Email: user.email,
        ...(!!user.firstName && { 'First Name': user.firstName }),
        ...(!!user.lastName && { 'Last Name': user.lastName }),
        ...(!!user.company?.name && { Company: user.company?.name }),
        ...(!!user.title && { Title: user.title }),
      })
      if (user.company.websiteDomain === 'salezilla.io') {
        const isExist = pages.find((page) => page.path === 'universe-controller')

        if (!isExist) {
          setPages([
            ...pages,
            {
              label: 'Universe Controller',
              path: 'universe-controller',
              icon: UniverseIcon,
              selectedIcon: UniverseIcon,
            },
          ])
        }
      }

      if (
        !user?.company?.stripeMainPlanSubscriptionId &&
        !isUserLoading &&
        !(user.company.websiteDomain === 'salezilla.io')
      ) {
        console.log('user from ProtectedLayout if (!user?.company?.stripeMainPlanSubscriptionId)', user)
        navigate('/pricing', { replace: true })
      } else if (
        user?.company?.stripeMainPlanSubscriptionId &&
        !user?.company?.hasCompletedInitialPaymentStep &&
        user.company.websiteDomain !== 'salezilla.io'
      ) {
        navigate('/plan-selection-complete', {
          replace: true,
        })
      }
    }
  }, [user, navigate, pages, update, navigateToLogin, firebaseUser, isUserLoading])

  return (
    <div className={css(styles.container)}>
      {!isMobile && <AppBar pages={pages} />}
      {/* {user?.company?.hasCompletedInitialPaymentStep && (
        <>{isMobile ? <MobileNavBar pages={pages} /> : <AppBar pages={pages} />}</>
      )} */}
      <div className={css(styles.pagesContainer)}>{outlet}</div>
    </div>
  )
}

export default ProtectedLayout
