import { StyleSheet } from 'aphrodite'

const targetStyles = StyleSheet.create({
  targetDescriptionContainer: {
    position: 'relative',
    padding: '10px',
    width: '100%',
    borderRadius: '14px',
    border: '1px solid #F0F3F5',
    background: '#FFF',
    ':hover': {
      border: '1px solid rgba(55, 141, 250, 0.40))',
      boxShadow: '0px 0px 15px 0px rgba(55, 141, 250, 0.19)',
    },
  },
  defaultHover: {
    ':hover': {
      border: '1px solid rgba(55, 141, 250, 0.40))',
      boxShadow: '0px 0px 15px 0px rgba(55, 141, 250, 0.19)',
    },
  },
  rexHover: {
    ':hover': {
      border: '2px solid #CED3F1 !important',
      boxShadow: 'none',
    },
  },
  default: {
    border: '1px solid #F0F3F5',
    boxShadow: 'none',
  },
  defaultRex: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxShadow: 'none',
  },
  Click: {
    border: '1px solid rgba(55, 141, 250, 0.40))',
    boxShadow: '0px 0px 15px 0px rgba(55, 141, 250, 0.19)',
  },
  rexClick: {
    border: '2px solid #CED3F1 !important',
    boxShadow: 'none',
  },
  targetDescriptionArea: {
    textAlign: 'justify',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '0 10px 0 10px',
    overflow: 'auto',
    // padding: '13px 10px',
    fontSize: '14px',
    gap: '10px',
    width: '100%',
    border: 'none',
    resize: 'none',
    outline: 'none',
    '-webkit-appearance': 'none',
  },
  // expandedTargetDescription: {
  //   height: '180px',
  // },
  // collapseTargetDescription: {
  //   height: '100px',
  // },
  buttonContainer: {
    // position: 'absolute',
    // right: '20px',
    right: '19px',
    bottom: '5px',
    width: '97.5%',
    display: 'flex',
    justifyContent: 'flex-end',
    // justifyContent: 'center',
    gap: 5,
    background: 'white',
  },
  viewMoreButton: {
    textTransform: 'none',
    color: '#727578',
    height: '25px',
  },
  saveButton: {
    border: '1px solid #378DFA',
    background: '#378DFA',
    borderRadius: '4px',
    color: 'white',
    textTransform: 'none',
    padding: '2px 6px',
    height: '25px',
    ':hover': {
      color: 'white',
      background: '#0E5ADE',
      border: '1px solid #0E5ADE',
    },
    ':disabled': {
      border: '1px solid  #E5E5E5',
      background: 'linear-gradient(0deg, rgba(105, 111, 134, 0.04) 0%, rgba(105, 111, 134, 0.04) 100%), #FFF',
      transition: 'background 0.5s',
      color: '#C1C9D2',
    },
  },
  savedBtn: {
    ':disabled': {
      background: 'rgba(106, 224, 110, 0.30)',
      transition: 'background 0.7s',
      transitionDelay: '0.2s',
      borderRadius: '16px',
      padding: '2px 8px',
      color: '#4CAF50',
      border: 'none',
    },
  },
})

export default targetStyles
