import { CircularProgress, useMediaQuery, Autocomplete, Typography, TextField, Grid, Box } from '@mui/material'
import { SearchLocationDocument } from '../../graphql/generated'
import React, { useEffect, useCallback, useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useLazyQuery } from '@apollo/client'
import { throttle } from 'lodash'

import { Location } from '../../graphql/generated'

const style = {
  autoComplete: {
    marginBottom: '10px',
    '& .MuiChip-root': { background: '#5F69C1', color: 'white' },
    '& .MuiChip-deleteIcon': { color: 'white !important' },
    '& .MuiOutlinedInput-root ': { padding: '6px 30px 6px 6px !important' },
    // '.MuiAutocomplete-input': { minWidth: '0px !important', padding: '0px !important' },
  },
  textField: {
    background: '#FFFFFF',
    ':hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '2px solid #CED3F1 !important',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px !important',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#CED3F1',
    },
  },
}

interface Args {
  placeholder?: string
  label?: string
  limitTags?: number
  customStyle?: any
  createPersonaLocationStr: string
  setCreatePersonaLocationStr: React.Dispatch<React.SetStateAction<string>>
  createPersonaLocationOptions: Location[]
  setCreatePersonaLocationOptions: React.Dispatch<React.SetStateAction<Location[]>>
  selectedLocations: Location[]
  setSelectedLocations: (value: Location[]) => void
  isCreatePersonaAutocompleteOpen: boolean
  showError?: boolean
  handleUpdateLocation?: (location: Location[]) => void
  setIsCreatePersonaAutocompleteOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClose?: (newValue: Location[]) => void
}

const LocationAutocomplete = ({
  placeholder,
  label = 'Company HQ Location(s)',
  createPersonaLocationStr,
  setCreatePersonaLocationStr,
  createPersonaLocationOptions,
  setCreatePersonaLocationOptions,
  selectedLocations,
  setSelectedLocations,
  isCreatePersonaAutocompleteOpen,
  setIsCreatePersonaAutocompleteOpen,
  customStyle,
  showError,
  limitTags,
  handleUpdateLocation,
  onClose,
}: Args) => {
  const isMobile = useMediaQuery('@media (max-width: 1023px)')

  const [isOpen, setIsOpen] = useState(false)

  const [limitTag, setLimitTags] = useState(3)

  // Check the screen width and update the limitTags value accordingly
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 385) {
        setLimitTags(1)
      } else if (window.innerWidth <= 600) {
        setLimitTags(2) // Set the limit to 2 on mobile screens
      } else {
        setLimitTags(3) // Set the limit to 3 on larger screens
      }
    }

    // Initial check on component mount
    handleResize()

    // Add a window resize listener
    window.addEventListener('resize', handleResize)

    // Remove the resize listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const fetchLocations = useCallback(
    throttle(async (input: string) => {
      searchLocation()
    }, 500),
    [],
  )
  const [searchLocation, { loading: searchLocationLoading, error: searchLocationError, data: searchLocationRes }] =
    useLazyQuery(SearchLocationDocument, {
      variables: {
        locationStr: createPersonaLocationStr,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data && data.searchLocation) {
          console.log('data.searchLocation: ', data.searchLocation)
          setCreatePersonaLocationOptions(data.searchLocation as Location[])
        }
      },
    })

  // Call throttledFetchLocations whenever the input value changes
  useEffect(() => {
    if (createPersonaLocationStr.length > 0) {
      // If the input is empty or one or more locations are selected, don't perform any fetches
      fetchLocations(createPersonaLocationStr)
    }
  }, [createPersonaLocationStr])

  // This function is called by the Autocomplete component to get the options to display
  const getOptions = (value: string) => {
    return createPersonaLocationOptions.filter((location) => {
      if (
        selectedLocations.some(
          (sel) =>
            sel.country === location.country && sel.locality === location.locality && sel.region === location.region,
        )
      )
        return false

      // Only show locations that match the input value
      return (
        location.locality?.toLowerCase().includes(value.toLowerCase()) ||
        location.region?.toLowerCase().includes(value.toLowerCase()) ||
        location.country.toLowerCase().includes(value.toLowerCase())
      )
    })
  }

  // This function is called by the Autocomplete component to format the display value of each option
  const getOptionLabel = (option: Location) => {
    // Display the locality, region, and country if available, otherwise just the country
    return option.locality && option.region
      ? `${option.locality}, ${option.region}, ${option.country}`
      : option.region
      ? `${option.region}, ${option.country}`
      : option.country
  }

  // This function is called by the Autocomplete component to highlight the matching characters in the option labels
  const highlightOption = (option: string, inputValue: string) => {
    // Split the option string and value string on word boundaries
    const optionWords = option.split(/\b/)
    const valueWords = inputValue.split(/\b/)

    // Find the starting index of the first matching value word in the option string
    let index = optionWords.findIndex((optionWord) => {
      return valueWords.some((valueWord) => optionWord.toLowerCase().startsWith(valueWord.toLowerCase()))
    })

    // If a match was found, create a new array of React elements by mapping over the option words
    if (index >= 0) {
      return optionWords.map((optionWord, i) => {
        // Check if the current option word is a match and should be highlighted
        const isMatch = valueWords.some((valueWord) => optionWord.toLowerCase().startsWith(valueWord.toLowerCase()))
        return isMatch ? (
          <strong key={i}>{optionWord}</strong> // If it's a match, wrap it in a strong element
        ) : (
          optionWord
        ) // If it's not a match, return it as is
      })
    }
    // If no match was found, return the option string as is
    return option
  }

  const mergedStyles = { ...style.autoComplete, ...customStyle }

  return (
    <Autocomplete
      sx={mergedStyles}
      value={selectedLocations}
      disableCloseOnSelect
      onChange={(event, newValue) => {
        setSelectedLocations(newValue)
        handleUpdateLocation && handleUpdateLocation(newValue)
      }}
      multiple
      // limitTags={limitTags || 3}
      limitTags={limitTag}
      inputValue={createPersonaLocationStr}
      onInputChange={(event, newInputValue) => {
        setCreatePersonaLocationStr(newInputValue)
        setIsCreatePersonaAutocompleteOpen(true)
      }}
      getOptionLabel={getOptionLabel}
      options={getOptions(createPersonaLocationStr)}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={(event, reason) => {
        if (reason === 'blur') {
          setIsOpen(false)
          onClose && onClose(selectedLocations)
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          // label={label}
          placeholder={placeholder}
          variant="outlined"
          size="small"
          helperText={showError ? `${label} is requried` : null}
          error={showError}
          InputProps={{
            ...params.InputProps,
            ...(!isMobile && { shrink: true }),
            // startAdornment: (
            //   <InputAdornment position="start">
            //     <SearchIcon fontSize="small" />
            //   </InputAdornment>
            // ),
            endAdornment: (
              <React.Fragment>
                {searchLocationLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          sx={style.textField}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
              </Grid>
              <Grid item xs>
                {option.locality && (
                  <Typography variant="body2" color="text.secondary">
                    {highlightOption(option.locality, createPersonaLocationStr)}
                  </Typography>
                )}
                {option.region && (
                  <Typography variant="body2" color="text.secondary">
                    {highlightOption(option.region, createPersonaLocationStr)}
                  </Typography>
                )}
                <Typography variant="body2" color="text.secondary">
                  {highlightOption(option.country, createPersonaLocationStr)}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}

export default LocationAutocomplete
