import { Grid } from '@mui/material'
import SettingTabs from './SettingTabs'

interface SettingsProps {}

const Settings: React.FC<SettingsProps> = () => {
  // const [activeTab,setActiveTab]=useState()
  return (
    <Grid
      container
      spacing={2}
      sx={{
        '@media (max-width: 1380px)': {
          width: 'calc(100% + 40%)',
        },
        '@media (max-width: 990px)': {
          width: 'calc(100% + 55%)',
        },
      }}
    >
      <Grid item xs={8}>
        <SettingTabs />
      </Grid>
    </Grid>
  )
}

export default Settings
