import { useEffect, useState } from 'react'
import { Location } from '../../../../graphql/generated'
import LocationAutocomplete from '../../../LocationAutocomplete'
import Text from '../../../Text'
import styles from './style'
import { WidgetMessage } from '../..'
import { useCampaignAndUser } from '../../../NewCampaignLayout'
import { isEqual } from 'lodash'

const LocationSelector = (props: { handleTranslate: (widgetMessage?: WidgetMessage) => Promise<void> }) => {
  const { campaign } = useCampaignAndUser()
  const selectedPersona = campaign?.personas?.[0]
  const { handleTranslate } = props
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([])
  const [createContactLocationStr, setContactLocationStr] = useState('')
  const [createContactLocationOptions, setCreateContactLocationOptions] = useState([] as Location[])
  const [isCreateContactAutocompleteOpen, setIsCreateContactAutocompleteOpen] = useState(false)

  useEffect(() => {
    if (selectedPersona?.hqLocations) {
      setSelectedLocations(selectedPersona.hqLocations as Location[])
    }
  }, [setSelectedLocations, selectedPersona?.hqLocations])

  return (
    <div
      style={{
        width: '100%',
        padding: '12px 8px',
        borderRadius: '16px',
        background: '#FFF',
      }}
    >
      <Text extraStyles={[styles.textStyle]}>HQ Locations</Text>
      <LocationAutocomplete
        placeholder={selectedLocations && selectedLocations?.length > 0 ? '' : 'Enter a City/ State/ Country'}
        label="HQ Locations"
        showError={false}
        createPersonaLocationStr={createContactLocationStr}
        setCreatePersonaLocationStr={setContactLocationStr}
        createPersonaLocationOptions={createContactLocationOptions}
        setCreatePersonaLocationOptions={setCreateContactLocationOptions}
        selectedLocations={selectedLocations}
        setSelectedLocations={setSelectedLocations}
        isCreatePersonaAutocompleteOpen={isCreateContactAutocompleteOpen}
        setIsCreatePersonaAutocompleteOpen={setIsCreateContactAutocompleteOpen}
        onClose={(location) => {
          const selectedValues = location?.map((loc) => ({
            country: loc.country,
            ...(loc.region && { region: loc.region }),
            ...(loc.locality && { locality: loc.locality }),
          }))

          if (selectedValues && !isEqual(selectedPersona?.hqLocations, location)) {
            handleTranslate({
              message: `update company location to ${selectedValues?.map((loc) => loc.country).join(', ')}`,
              type: 'not_to_show',
              widget: 'company_location',
              value: selectedValues,
            })
          }
        }}
      />
    </div>
  )
}

export default LocationSelector
