import { useAuth } from '../../../../hooks/useAuth'
import { css } from 'aphrodite'

import { ReactComponent as RexMblAvatar } from '../../../../assets/svg/Rex-I.svg'
import RexAvatar from '../../../../assets/icons/RexAvatar'
import styles from '../styles'

const ChatRexLoader = () => {
  const { user, isMobile } = useAuth()
  return (
    <>
      <div className={css([styles.container, styles.containerSystem])}>
        <div className={css([styles.avatarContainer, styles.avatarContainerSystem])}>
          {isMobile ? <RexMblAvatar /> : <RexAvatar />}
        </div>
        <div className={css(styles.messageContainer)}>
          <div className="loading">...</div>
        </div>
      </div>
    </>
  )
}

export default ChatRexLoader
