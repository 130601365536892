import { useLocation, useNavigate } from 'react-router-dom'
import { Avatar, Divider } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { useState } from 'react'
import { css } from 'aphrodite'

import { ReactComponent as SalezillaLogoIcon } from '../../assets/svg/salezillaLogo_icon.svg'
import { ReactComponent as LogoutSvgIcon } from '../../assets/svg/logout.svg'
import SalezillaLogo from '../../assets/images/salezilla_logo.png'
import WarningModal from '../WarningModal/WarningModal'
import styles from './styles'

const pathnameMap = {
  '/campaigns': 'campaigns',
  '/new-campaign/targeting-input': 'new-campaign/targeting-input',
  '/new-campaign/teaching-and-grading': 'new-campaign/targeting-input',
  '/new-campaign/review': 'new-campaign',
  '/universe-controller': 'universe-controller',
  '/settings': 'settings',
} as any

interface Args {
  pages: any[]
}

export const AppBar = ({ pages }: Args) => {
  const navigate = useNavigate()
  const { user, firebaseUser, logout } = useAuth()
  let timeout: ReturnType<typeof setTimeout>

  const location = useLocation()
  const [showWarnModal, setShowWarnModal] = useState(false)

  const [selectedPage, setSelectedPage] = useState('campaigns')

  const [isExpanded, setIsExpanded] = useState(true)

  const handleSidebar = useCallback(
    (open: boolean) => {
      if (location.pathname.includes('new-campaign')) setIsExpanded(open)
    },
    [location.pathname],
  )

  const handleMouseEnter = () => {
    timeout = setTimeout(() => {
      handleSidebar(true)
    }, 300)
  }

  const handleMouseLeave = () => {
    clearTimeout(timeout)
    handleSidebar(false)
  }

  useEffect(() => {
    handleSidebar(false)
  }, [handleSidebar])

  useEffect(() => {
    setSelectedPage(pathnameMap[location.pathname as string] as string)
  }, [setSelectedPage, location.pathname])

  const handlePageClick = async (path: string) => {
    if (path === 'home') {
      window.open('https://www.salezilla.io/', '_blank')
    }
    if (path.includes('new-campaign') && location.pathname.includes('new-campaign')) {
      setSelectedPage(path)
      handleWarnModal()
    } else {
      setSelectedPage(path)
      navigate(path)
    }
  }

  const handleLogout = useCallback(() => {
    logout()
  }, [logout])

  const onUserInfoClick = () => {
    navigate('/settings')
  }

  const handleWarnModal = () => {
    setShowWarnModal(!showWarnModal)
  }

  const handleAccept = () => {
    navigate(selectedPage)
    handleWarnModal()
  }

  return (
    <div className={css(styles.container)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <WarningModal
        loading={false}
        width="500px"
        open={showWarnModal}
        handleAccept={handleAccept}
        title="Create new lead list?"
        handleReject={handleWarnModal}
        acceptLabel="Create new lead list"
        rejectLabel="Stay on current list building"
        message="You’re already working on a lead list. You progress will be saved. Are you sure you want to move on to creating another lead list?"
      />

      <div className={css([isExpanded ? styles.expandedNavbar : styles.collapsedNavbar])}>
        {isExpanded ? (
          <img height={49} alt="Salezilla Logo" src={SalezillaLogo} className={css(styles.salezillaLogo)} />
        ) : (
          <SalezillaLogoIcon style={{ marginLeft: 16, height: 49 }} />
        )}
        <div className={css(styles.navBarContainer)}>
          <ul className={css(styles.navList)}>
            {pages?.map((page) => (
              <li
                className={css([
                  styles.navItemContainer,
                  isExpanded ? styles.expandedNavItemWidth : styles.collapseNavItemWidth,
                  selectedPage === page.path && [isExpanded ? styles.selectedNavItem : ''],
                ])}
                key={page.label}
                onClick={async () => {
                  await handlePageClick(page.path)
                }}
              >
                {page.icon && (
                  <>
                    {selectedPage !== page.path ? (
                      <page.icon
                        className={css([selectedPage === page.path && [isExpanded ? '' : styles.selectedNavIcons]])}
                        isSelected={selectedPage === page.path}
                      />
                    ) : (
                      <page.selectedIcon
                        className={css([selectedPage === page.path && [isExpanded ? '' : styles.selectedNavIcons]])}
                        isSelected={selectedPage === page.path}
                      />
                    )}
                  </>
                )}
                {page.path === 'new-campaign' && <p className={css(styles.campaignRocket)}>🚀</p>}
                {isExpanded && (
                  <p className={css([styles.pageLabel, selectedPage === page.path && styles.selectedNavText])}>
                    {page.label}
                  </p>
                )}
                {/* <div className={css(styles.navItem)}>
                </div> */}
              </li>
            ))}
            {!!user && firebaseUser && (
              <li
                className={css([
                  styles.navItemContainer,
                  isExpanded ? styles.expandedNavItemWidth : styles.collapseNavItemWidth,
                ])}
                key="logout"
                onClick={handleLogout}
              >
                <LogoutSvgIcon className={css(styles.moreIcon)} />
                {isExpanded && <p>Logout</p>}
              </li>
            )}
          </ul>
        </div>
        <div className={css(styles.userInfoContainer)}>
          <div className={css(styles.userInfo)}>
            <div className={css(styles.navItemContainer, styles.userAvatar)} onClick={onUserInfoClick}>
              {/* {user?.photoUrl && <Avatar alt="Remy Sharp" src={user?.photoUrl} />} */}
              <Avatar alt={user?.firstName as string} src={user?.photoUrl ? user.photoUrl : '/broken-image.jpg'} />
              {isExpanded && (
                <p>
                  {user?.firstName} {user?.lastName}
                </p>
              )}
            </div>
            {/* <IconButton className={css(styles.moreIcon)} aria-label="Example">
            <MoreHorizIcon />
          </IconButton> */}
          </div>
        </div>
      </div>
    </div>
  )
}
