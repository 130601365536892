import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  noticeContainer: {
    display: 'flex',
    padding: '6px 16px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: '#E5E5E5',
    background: '#FFF',
  },
  noticeText: {
    color: '#3C4257',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
  },
})

export default styles
