import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  profileContainer: {
    width: '100%',
    margin: '3rem 1rem',
    '@media (max-width: 768px)': {
      margin: 0,
    },
  },
  userAvatar: {
    height: '115px',
    borderRadius: '50%',
    marginTop: '2rem',
  },
  title: {
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
})

export default styles
