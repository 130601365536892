import { css } from 'aphrodite'
import { Box, Grid } from '@mui/material'
import Description from './Description'
import styles from './style'
import { GetCompaniesForLabeling } from '../../../graphql/generated'
import StatusRenderer, { BUTTONS_CLASSES } from './ButtonStatusRenderer'
import { memo } from 'react'
import LabelListSkeleton from './LabelListSkeleton'
import TableCellWithReadMore from '../Review/TableCellWithReadMore'
import { TooltipProvider } from './TooltipContext/TooltipContext'

export const PICSUM_IMAGE_URL = 'https://picsum.photos/800/400?img='

type RecommendedOptionInput = { _class: string; toolTip: string }

const CompanyListItem: React.FC<{
  recommendedOptionInput: RecommendedOptionInput
  company: GetCompaniesForLabeling
  handleAccurateTooltip: () => void
  companyIndex: number
  totalLabled: number
}> = ({ recommendedOptionInput, company, handleAccurateTooltip, companyIndex, totalLabled }) => {
  return (
    <Grid container className={css(styles.ListItemContainer)}>
      <Grid item sm={3} className={css(styles.PicFrame)}>
        {/* <img
          src={`${PICSUM_IMAGE_URL}${company?.company_info?.name}`}
          alt={PICSUM_IMAGE_URL}
          style={{ width: '48px', borderRadius: '4px' }}
        /> */}
        <span style={{ overflow: 'hidden' }}>
          {company?.company_info.name?.length > 30
            ? `${company.company_info.name.substring(0, 30)}...`
            : company?.company_info.name}
        </span>
      </Grid>
      <Grid item sm={6}>
        <TableCellWithReadMore text={company?.company_info.description} />
      </Grid>
      <Grid display="flex" justifyContent="center" item sm={2}>
        <StatusRenderer
          recommendedOption={recommendedOptionInput}
          company={company?.company_info}
          machineLabel={company?.machine_label as string}
          userLabel={company?.user_label as string}
          handleAccurateTooltip={handleAccurateTooltip}
          companyIndex={companyIndex}
          totalLabled={totalLabled}
        />
      </Grid>
    </Grid>
  )
}

const CompanyList = ({
  companiesForLabeling,
  is_completed,
  handleAccurateTooltip,
}: {
  companiesForLabeling: GetCompaniesForLabeling[]
  is_completed: boolean
  handleAccurateTooltip: () => void
}) => (
  <TooltipProvider>
    {companiesForLabeling
      ?.sort((a: GetCompaniesForLabeling, b: GetCompaniesForLabeling) => (b?.id as number) - (a?.id as number))
      ?.map((company, index) => (
        <CompanyListItem
          key={company.id}
          company={company}
          companyIndex={index}
          totalLabled={
            companiesForLabeling?.filter((company: GetCompaniesForLabeling) => company.user_label !== '')?.length
          }
          recommendedOptionInput={{
            _class: company?.machine_label === 'y' ? BUTTONS_CLASSES.TICK : BUTTONS_CLASSES.CROSS,
            toolTip: `Oops, I thought this company was ${
              company?.machine_label === 'y' ? '' : 'not'
            } a good fit. If you disagree, you could adjust the target description or label more companies to update my understanding.`,
          }}
          handleAccurateTooltip={handleAccurateTooltip}
        />
      ))}
    {!is_completed && <LabelListSkeleton />}
  </TooltipProvider>
)

export default memo(CompanyList)
