import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Grid } from '@mui/material'
import {
  GetCompaniesForLabelingDocument,
  GetCampaignStatusDocument,
  Persona,
  UpdateNotEnoughCompaniesModalShownDocument,
  UpdateBadPromptModalShownDocument,
} from '../../../graphql/generated'
import { css } from 'aphrodite'

import styles from './styles'
import { useCampaignAndUser } from '../../../components/NewCampaignLayout'
import LabellingListView from '../LabellingListView/LabellingListView'
import TargetDescription from '../TargetDescription/TargetDescription'
import LeadsWarningModal from './LeadsWarningModal/LeadsWarningModal'
import { LeadsCompanyFilter } from '../LeadsFilter/LeadsFilter'
import { useGraphQL } from '../../../context/GraphQLContext'
import Toast from '../../../components/Toast/Toast'
import CampaignName from './CampaignName'
import WarningIcon from '../../../assets/icons/WarningIcon'
import Text from '../../../components/Text'

const TeachingAndGrading = () => {
  const { handleCreateTargetingInfo, createTargetingInfoResult, handlePersonaCreateOrUpdate } = useGraphQL()
  const { campaign, user } = useCampaignAndUser()

  const [updateNotEnoughCompaniesModalShown] = useMutation(UpdateNotEnoughCompaniesModalShownDocument)

  const [updateBadPromptModalShown] = useMutation(UpdateBadPromptModalShownDocument)

  const [selectedPersona, setSelectedPersona] = useState<Persona | null>(
    campaign?.personas ? campaign?.personas?.[0] : null,
  )

  const [showNotEnoughCompaniesWarningModal, setShowNotEnoughCompaniesWarningModal] = useState(false)
  const [showBadPromptWarningModal, setShowBadPromptWarningModal] = useState(false)

  const [showToast, setShowToast] = useState(false)

  const [loadingMessage, setLoadingMessage] = useState('')
  const parentRef = useRef<HTMLDivElement>(null)
  const childRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (campaign?.personas) {
      setSelectedPersona(campaign?.personas?.[0])
    }
  }, [])

  const onCompleted = () => {
    if (campaign) {
      getCompaniesForLabeling({
        variables: {
          data: {
            campaignId: campaign?.id,
            creatorId: campaign?.creatorId,
            getMoreCompanies: true,
          },
        },
      })
      getCampaignStatus({
        variables: {
          data: {
            campaignId: campaign?.id,
            creatorId: campaign?.creatorId,
          },
        },
      })
    }
  }

  const handleTargetingInfo = () => {
    if (campaign && selectedPersona) {
      handleCreateTargetingInfo(campaign, selectedPersona, onCompleted)
    }
  }

  const [
    getCompaniesForLabeling,
    {
      loading: getCompaniesForLabelingLoading,
      error: _getCompaniesForLabelingError,
      data: getCompaniesForLabelingRes,
      stopPolling: getCompaniesForLabelingStopPolling,
    },
  ] = useLazyQuery(GetCompaniesForLabelingDocument, {
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getCompaniesForLabeling) {
        const { companies, is_completed } = data.getCompaniesForLabeling
        if (is_completed && campaign) {
          getCompaniesForLabelingStopPolling()
        }
      }
    },
  })

  const [getCampaignStatus, { loading: _loading, error: _error, data: campaignStatus }] = useLazyQuery(
    GetCampaignStatusDocument,
    {
      pollInterval: 5000,
      fetchPolicy: 'network-only',
    },
  )

  useEffect(() => {
    if (campaign && campaign.dbSize !== null && campaign.dbSize !== undefined && campaign.dbSize >= 1000) {
      // TODO: This is firing each time the user refreshes the page, which we don't want when the previous is_completed is true
      // prob need to set a flag in the database that the first time is already completed
      getCompaniesForLabeling({
        variables: {
          data: {
            campaignId: campaign?.id,
            creatorId: campaign?.creatorId,
          },
        },
      })
      getCampaignStatus({
        variables: {
          data: {
            campaignId: campaign?.id,
            creatorId: campaign?.creatorId,
          },
        },
      })
    }
  }, [campaign, campaign?.dbSize, getCampaignStatus, getCompaniesForLabeling])

  const handleWheel = (event: WheelEvent) => {
    const target = event.target as HTMLInputElement
    if (childRef.current && target?.name !== 'targetingDescr') {
      childRef.current.scrollTop += event.deltaY * 0.2
    }
  }

  useEffect(() => {
    if (parentRef.current) {
      parentRef.current.addEventListener('wheel', handleWheel)
    }

    return () => {
      if (parentRef.current) {
        parentRef.current.removeEventListener('wheel', handleWheel)
      }
    }
  }, [])

  const handleMoreCompanies = () => {
    if (campaign) {
      getCompaniesForLabeling({
        variables: {
          data: {
            campaignId: campaign?.id,
            creatorId: campaign?.creatorId,
            getMoreCompanies: true,
          },
        },
      })
    }
  }

  const handleUpdatePersona = () => {
    if (user && selectedPersona) {
      handlePersonaCreateOrUpdate(user, selectedPersona, () => {
        handleTargetingInfo()
      })
    }
  }

  const handleNotEnoughCompaniesWarningModal = useCallback(() => {
    if (campaign) {
      setShowNotEnoughCompaniesWarningModal(false)
      updateNotEnoughCompaniesModalShown({
        variables: {
          data: {
            campaignId: campaign.id,
            notEnoughCompaniesModalShown: true,
          },
        },
      })
    }
  }, [campaign, updateNotEnoughCompaniesModalShown])

  const handleBadPromptWarningModal = useCallback(() => {
    if (campaign) {
      setShowBadPromptWarningModal(false)
      updateBadPromptModalShown({
        variables: {
          data: {
            campaignId: campaign.id,
            badPromptModalShown: true,
          },
        },
      })
    }
  }, [campaign, updateBadPromptModalShown])

  useEffect(() => {
    if (
      campaign &&
      campaign.dbSize !== null &&
      campaign.dbSize !== undefined &&
      campaign.dbSize < 1000 &&
      !campaign.notEnoughCompaniesModalShown
    ) {
      setShowNotEnoughCompaniesWarningModal(true)
    }
  }, [campaign, campaign?.dbSize])

  useEffect(() => {
    if (
      getCompaniesForLabelingRes?.getCompaniesForLabeling?.is_completed &&
      campaignStatus?.getCampaignStatus.is_prompt_bad &&
      !campaign?.badPromptModalShown
    ) {
      setShowBadPromptWarningModal(true)
    }
  }, [
    campaign?.badPromptModalShown,
    campaignStatus?.getCampaignStatus.is_prompt_bad,
    getCompaniesForLabelingRes?.getCompaniesForLabeling?.is_completed,
  ])

  // if db_size < 1000 && is_prompt_bad === true, only show db size warning modal
  let warningModalToShow = null
  let warningNotice = null

  if ((campaign?.dbSize as number) < 1000) {
    warningModalToShow = (
      <LeadsWarningModal
        title="Not enough companies"
        message="There are limited companies that match your current filters. Please adjust company filters or the target description to enhance the number of potential matches."
        acceptLabel="Got it"
        open={showNotEnoughCompaniesWarningModal}
        handleReject={handleNotEnoughCompaniesWarningModal}
      />
    )
  } else if (campaignStatus?.getCampaignStatus.is_prompt_bad) {
    warningNotice = (
      <div className={css(styles.warningMsgContainer)}>
        <WarningIcon />
        <Text extraStyles={[styles.warningMsgTxt]}>
          <span className={css(styles.warningMsgTitleTxt)}>Not Enough Companies</span> There are limited companies that
          match your current filters. Please adjust company filters to enhance the number of potential matches.
        </Text>
      </div>
    )
  }

  return (
    <div className={css(styles.container)}>
      <Toast open={showToast} setOpen={setShowToast} message={loadingMessage} severity="error" />
      {!!warningModalToShow && warningModalToShow}
      <CampaignName name={campaign?.name as string} campaignId={campaign?.id as string} />
      <Grid ref={parentRef} mx={2} xs={12} className={css(styles.filterContainer)}>
        <Grid xs={12} md={12} lg={12} xl={8} container mt={2} gap={2}>
          <Grid xs={12} item>
            <LeadsCompanyFilter
              persona={selectedPersona as Persona}
              setSelectedPersona={setSelectedPersona}
              handleUpdatePersona={handleUpdatePersona}
              handleCreateTargetingInfo={handleTargetingInfo}
            />
          </Grid>
          <Grid xs={12} item>
            <TargetDescription
              targetingDescr={campaign?.targetingDescr as string}
              handleCreateTargetingInfo={handleTargetingInfo}
              loading={createTargetingInfoResult?.loading || getCompaniesForLabelingLoading}
            />
          </Grid>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
            xs={12}
            item
          >
            <LabellingListView
              warningNotice={warningNotice}
              handleMoreCompanies={handleMoreCompanies}
              loading={getCompaniesForLabelingLoading}
              campaignStatus={campaignStatus?.getCampaignStatus}
              time={getCompaniesForLabelingRes?.getCompaniesForLabeling?.time || 0}
              percentage={getCompaniesForLabelingRes?.getCompaniesForLabeling?.percentage || 0}
              companiesForLabeling={getCompaniesForLabelingRes?.getCompaniesForLabeling?.companies || []}
              is_completed={
                (getCompaniesForLabelingRes?.getCompaniesForLabeling?.is_completed as boolean) === true &&
                (campaign?.dbSize as number) >= 1000 &&
                !getCompaniesForLabelingLoading
              }
              tableRef={childRef}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default memo(TeachingAndGrading)
