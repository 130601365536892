import { AppBar, Toolbar, Typography } from '@mui/material'
import MobileNavBar from '../../../../components/MobileNavBar'
import { useAuth } from '../../../../hooks/useAuth'

const MainBar = ({ title = 'Leads lists' }) => {
  const { pages, isMobile } = useAuth()
  return (
    <AppBar position="static" style={{ background: 'transparent', color: 'black', boxShadow: 'none' }}>
      <Toolbar style={{ padding: 0 }}>
        {isMobile && <MobileNavBar pages={pages} />}
        <Typography
          fontWeight={'bold'}
          variant="h4"
          sx={{
            flexGrow: 1,
            marginLeft: '30px',
            '@media (max-width:1280px)': {
              marginLeft: '10px',
            },
          }}
        >
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default MainBar
