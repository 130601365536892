import { FC, memo } from 'react'
import { Campaign, GetAllCampaignsByTeamIdDocument, SalezillaDemoDuplicateCampaignDocument } from '../../../../../graphql/generated'
import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import ForwardIcon from '@mui/icons-material/Forward'
import { useAuth } from '../../../../../hooks/useAuth'
import { Link } from 'react-router-dom'

interface AccessDemoProps {
  campaign: Campaign
  setNewCampaigns: React.Dispatch<React.SetStateAction<Campaign[]>>
}

const AccessDemo: FC<AccessDemoProps> = ({ campaign, setNewCampaigns }) => {
  const { user } = useAuth()

  const [salezillaDemoDuplicateCampaign, { loading, error }] = useMutation(SalezillaDemoDuplicateCampaignDocument, {
    fetchPolicy: 'network-only',
    update: (cache, { data }) => {
      if (user && data && data.salezillaDemoDuplicateCampaign) {
        const getAllCampaignsData = cache.readQuery({
          query: GetAllCampaignsByTeamIdDocument,
          variables: {
            teamId: user.teamId as string,
          },
        })

        if (getAllCampaignsData && getAllCampaignsData.getAllCampaignsByTeamId) {
          setNewCampaigns(data.salezillaDemoDuplicateCampaign as Campaign[])
        }
      }
    },
  })

  return (
    <Link
      to={{
        pathname: '/new-campaign',
        search: `?campaignId=${campaign.id}&d=true`,
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button
        // disabled={loading}
        startIcon={<ForwardIcon fontSize="medium" />}
        sx={{
          textTransform: 'capitalize',
        }}
      >
        Access Stage
      </Button>
    </Link>
  )
}

export default memo(AccessDemo)
