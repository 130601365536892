import React, { memo, useState } from 'react'
import { Popover, TextField, FormControlLabel, Checkbox, Button, Typography, Badge } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { css } from 'aphrodite'

import styles from './style'
import { NumEmployeeRange, Persona } from '../../../graphql/generated'
import { numEmployeeRangesMap } from '../../../shared/constants'

const theme = createTheme({
  palette: {
    primary: {
      main: '#378DFA',
    },
  },
})

interface DropdownProps {
  numEmployee: NumEmployeeRange[]
  handleUpdatePersona: () => void
  setSelectedPersona: React.Dispatch<React.SetStateAction<Persona | null>>
}

const sortEmployeeValues = (selectedOptions: NumEmployeeRange[]) => {
  const sortedSelections = selectedOptions.sort((a, b) => {
    const rangeA = numEmployeeRangesMap[a]
    const rangeB = numEmployeeRangesMap[b]
    const [startA, endA] = rangeA.split('-').map(Number)
    const [startB, endB] = rangeB.split('-').map(Number)

    if (startA < startB) {
      return -1
    } else if (startA > startB) {
      return 1
    } else {
      return endA - endB
    }
  })
  return sortedSelections
}

function isContinuousSelection(selections: string[]) {
  const rangeKeys = Object.keys(numEmployeeRangesMap)
  const originalIndices = rangeKeys.slice(0, selections.length).map((ov, i) => i)
  const selectedRangeIndices = selections.map((selection) => rangeKeys.indexOf(selection))
  const sumOfSeletedIndices = selectedRangeIndices.reduce((acc, curr) => acc + curr, 0)
  const sumOfOriginalIndices = originalIndices.reduce((acc, curr) => acc + curr, 0)
  return sumOfOriginalIndices === sumOfSeletedIndices && sumOfOriginalIndices !== 0
}

const getStartNumber = (range: string) => {
  return parseInt(range.split('-')[0])
}

function getRangeFromSelections(selections: string[]) {
  if (isContinuousSelection(selections)) {
    const selectedRanges = selections.map((selection) => numEmployeeRangesMap[selection])
    const sortedSelectedRanges = selectedRanges.sort((a, b) => {
      return getStartNumber(a) - getStartNumber(b)
    })
    const firstSelection = sortedSelectedRanges[0]
    const lastSelection = sortedSelectedRanges[sortedSelectedRanges.length - 1]
    if (lastSelection.includes('+')) {
      return `${firstSelection.split('-')[0]}-${lastSelection}`
    }
    return `${firstSelection?.split('-')[0]}-${lastSelection?.split('-')[1]}`
  }
  return selections.length
}
const CompanyEmployeesDropdown: React.FC<DropdownProps> = ({
  numEmployee = [],
  setSelectedPersona,
  handleUpdatePersona,
}) => {
  const numEmployeeArray = numEmployee?.map((range) => {
    return `${range[0].toUpperCase()}${range.slice(1)}`
  }) as NumEmployeeRange[]

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [selectedOptions, setSelectedOptions] = useState<NumEmployeeRange[]>(numEmployeeArray)

  const handleOptionClick = (option: NumEmployeeRange) => {
    if (selectedOptions.includes(option)) {
      const filterValues = selectedOptions.filter((item) => item !== option)
      setSelectedOptions([...filterValues])
      setSelectedPersona((prevPersona: any) => ({
        ...prevPersona,
        numEmployeeRanges: [...filterValues.map((item) => item.toLowerCase())],
      }))
    } else {
      setSelectedOptions([...selectedOptions, option])
      setSelectedPersona((prevPersona: any) => ({
        ...prevPersona,
        numEmployeeRanges: [...prevPersona.numEmployeeRanges, option.toLowerCase()],
      }))
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const [isClicked, setIsClicked] = useState(false)

  const handleApply = () => {
    handleUpdatePersona()
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setIsClicked(!isClicked)
  }

  const range =
    selectedOptions.length === 1
      ? numEmployeeRangesMap[selectedOptions?.[0]]
      : getRangeFromSelections(sortEmployeeValues(selectedOptions))

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClick}
        aria-describedby={id}
        className={css(styles.filterBtn)}
        sx={{
          background: isClicked ? '#FFF' : '#F4F5F6',
          border: isClicked ? '1px solid #378DFA' : '1px solid  #E5E5E5',
          boxShadow: isClicked ? '0px 0px 4px 0px rgba(55, 141, 250, 0.40)' : 'none',
        }}
      >
        {isNaN(range as number) ? (
          <span className={css(styles.allFilterBtnText)}>{range}</span>
        ) : (
          <>
            <span className={css(styles.allFilterBtnText)}>Company Employees</span>
            {selectedOptions.length > 1 && (
              <Badge
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#E5E5E5',
                    color: '#3C4257',
                  },
                }}
                badgeContent={selectedOptions?.length}
                color="primary"
              />
            )}
          </>
        )}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          top: '4px',
          '& .MuiPopover-paper ': {
            borderRadius: '12px',
            background: '#FFF',
            boxShadow: '0px 4px 20px 0px rgba(61, 61, 61, 0.38)',
          },
        }}
        onFocus={() => setIsClicked(true)}
        onBlur={() => setIsClicked(false)}
      >
        <CloseIcon
          style={{
            margin: '16px',
            float: 'right',
            cursor: 'pointer',
          }}
          onClick={handleClose}
        />
        <div
          style={{
            padding: '16px',
            width: 250,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {Object.keys(NumEmployeeRange).map((option) => (
              <ThemeProvider theme={theme}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOptions.includes(option as NumEmployeeRange)}
                      onChange={() => handleOptionClick(option as NumEmployeeRange)}
                    />
                  }
                  label={numEmployeeRangesMap[option]}
                />
              </ThemeProvider>
            ))}
          </div>
          <Button fullWidth variant="contained" onClick={handleApply} className={css(styles.filterApplyBtn)}>
            Apply
          </Button>
        </div>
      </Popover>
    </div>
  )
}

export default memo(CompanyEmployeesDropdown)
