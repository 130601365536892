import CloseIcon from '@mui/icons-material/Close'
import { css } from 'aphrodite'
import * as Yup from 'yup'
import {
  DialogContentText,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  TextField,
  MenuItem,
  Dialog,
  Button,
} from '@mui/material'

import styles from './styles'
import { useFormik } from 'formik'
import {
  AddPendingUserDocument,
  GetAllPendingUserInvitesDocument,
  GetAllUsersDocument,
  UpdatePendingUserDocument,
  UpdateUserDocument,
  User,
} from '../../../../graphql/generated'
import { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import client from '../../../../apolloClient'

const team_Role = [
  {
    id: '1',
    value: 'Company Admin',
  },
  {
    id: '2',
    value: 'Member',
  },
]

interface UserModalProps {
  user?: User
  companyId?: string
  header: string
  modalButton: string
  showUserModal: boolean
  handleShowUserModal: () => void
  isPendingUser?: boolean
}

const UserModal = ({
  header,
  modalButton,
  showUserModal,
  handleShowUserModal,
  user,
  companyId,
  isPendingUser,
}: UserModalProps) => {
  const initialValues = {
    id: !!user?.id ? user.id : '',
    firstName: !!user?.firstName ? user.firstName : '',
    lastName: !!user?.lastName ? user.lastName : '',
    email: !!user?.email ? user.email : '',
    companyId: companyId as string,
    teamRole: 'Company Admin',
    isTeamAdmin: false,
    isSuperAdmin: false,
  }

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (data) => {
      if (!!user) {
        if (isPendingUser) {
          handleEditPendingUser()
        } else {
          handleEditUser()
        }
        handleClose()
        return
      }
      handleAddUser()
      handleClose()
    },
  })

  const { values, handleChange, setValues, setFieldValue, handleSubmit } = formik

  useEffect(() => {
    if (user) {
      setValues({
        id: !!user?.id ? user.id : '',
        firstName: !!user?.firstName ? user.firstName : '',
        lastName: !!user?.lastName ? user.lastName : '',
        email: !!user?.email ? user.email : '',
        companyId: companyId as string,
        teamRole: user.isTeamAdmin && user.isSuperAdmin ? 'Company Admin' : 'Member',
        isTeamAdmin: user.isTeamAdmin as boolean,
        isSuperAdmin: user.isSuperAdmin as boolean,
      })
    }
  }, [user])

  const handleClose = () => {
    setValues({
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      companyId: '',
      teamRole: 'Company Admin',
      isTeamAdmin: false,
      isSuperAdmin: false,
    })
    handleShowUserModal()
  }

  const [addPendingUser] = useMutation(AddPendingUserDocument, {
    onError: (error) => {
      console.log('%cError in AddPendingUserDocument', 'color:red;font-size:50px', error)
    },
    onCompleted: (data) => {
      const addPendingUser = data.addPendingUser
      if (addPendingUser) {
        const getAllPendingUsers = client.cache.readQuery({
          query: GetAllPendingUserInvitesDocument,
          variables: {
            companyId: companyId as string,
          },
        })
        const pendingUser = [...(getAllPendingUsers?.getAllPendingUserInvites as User[]), addPendingUser]
        client.cache.writeQuery({
          query: GetAllPendingUserInvitesDocument,
          variables: {
            companyId: companyId as string,
          },
          data: {
            getAllPendingUserInvites: [...pendingUser] as User[],
          },
        })
      }
    },
  })

  const handleAddUser = () => {
    addPendingUser({
      variables: {
        data: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          companyId: companyId as string,
          isTeamAdmin: values.teamRole === 'Company Admin' ? true : false,
          isSuperAdmin: values.teamRole === 'Company Admin' ? true : false,
        },
      },
    })
  }

  const [updateUser] = useMutation(UpdateUserDocument, {
    onError: (error) => {
      console.log('%cError in UpdateUserDocument', 'color:red;font-size:50px', error)
    },
    onCompleted: (data) => {
      const updateUser = data.updateUser

      if (updateUser) {
        const getAllUsers = client.cache.readQuery({
          query: GetAllUsersDocument,
          variables: {
            companyId: companyId as string,
          },
        })

        if (getAllUsers) {
          const updatedUser = [...(getAllUsers?.getAllUsers as User[]), updateUser]
          client.cache.writeQuery({
            query: GetAllUsersDocument,
            variables: {
              companyId: companyId as string,
            },
            data: {
              getAllUsers: [...updatedUser] as User[],
            },
          })
        }
      }
    },
  })

  const [updatePendingUser] = useMutation(UpdatePendingUserDocument, {
    onError: (error) => {
      console.log('%cError in UpdatePendingUserDocument', 'color:red;font-size:50px', error)
    },
    onCompleted: (data) => {
      const updatePendingUser = data.updatePendingUser

      if (updatePendingUser) {
        const getAllPendingUsers = client.cache.readQuery({
          query: GetAllPendingUserInvitesDocument,
          variables: {
            companyId: companyId as string,
          },
        })

        if (getAllPendingUsers) {
          const pendingUser = [...(getAllPendingUsers?.getAllPendingUserInvites as User[]), updatePendingUser]
          client.cache.writeQuery({
            query: GetAllPendingUserInvitesDocument,
            variables: {
              companyId: companyId as string,
            },
            data: {
              getAllPendingUserInvites: [...pendingUser] as User[],
            },
          })
        }
      }
    },
  })

  const handleEditUser = () => {
    updateUser({
      variables: {
        data: {
          userId: values.id,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          isTeamAdmin: values.teamRole === 'Company Admin' ? true : false,
          isSuperAdmin: values.teamRole === 'Company Admin' ? true : false,
        },
      },
    })
  }

  const handleEditPendingUser = () => {
    updatePendingUser({
      variables: {
        data: {
          userId: values.id,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          isTeamAdmin: values.teamRole === 'Company Admin' ? true : false,
          isSuperAdmin: values.teamRole === 'Company Admin' ? true : false,
        },
      },
    })
  }

  const sxStyle = {
    '& .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      borderRadius: '12px',
      background:
        'var(--background-page-background, linear-gradient(0deg, rgba(105, 111, 134, 0.04) 0%, rgba(105, 111, 134, 0.04) 100%), #FFF)',
      boxShadow: '0px 4px 30px 0px rgba(99, 99, 99, 0.35)',
    },
  }

  return (
    <div>
      <Dialog maxWidth="lg" open={showUserModal} onClose={handleClose} sx={sxStyle}>
        <DialogTitle className={css(styles.dialogHeader)}>
          {header}{' '}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={css(styles.dialogContentWrapper)}>
          <div className={css(styles.dialogFieldWrapper)}>
            <TextField
              fullWidth
              required
              id="firstName"
              placeholder="First Name"
              variant="outlined"
              size="small"
              sx={{
                background: '#fff',
              }}
              value={values.firstName}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              required
              id="lastName"
              placeholder="Last Name"
              variant="outlined"
              size="small"
              sx={{
                background: '#fff',
              }}
              value={values.lastName}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              required
              id="email"
              placeholder="Email"
              variant="outlined"
              size="small"
              sx={{
                background: '#fff',
              }}
              value={values.email}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              select
              value={values.teamRole}
              required
              id="teamRole"
              label="Team Role"
              placeholder="Team Role"
              variant="outlined"
              size="small"
              sx={{
                background: '#fff',
              }}
              onChange={(e) => {
                setFieldValue('teamRole', e.target.value)
              }}
            >
              {team_Role.map((role) => (
                <MenuItem key={role.id} value={role.value}>
                  {role.value}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <DialogContentText color={'var(--text-light-title, #A3AAB7)'} fontSize={14}>
            An email invitation will be sent to your new member.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={css(styles.dialogBtnWrapper)}>
          <Button variant="contained" className={css(styles.dialogCancelBtn)} onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()} variant="contained" className={css(styles.dialogAddBtn)}>
            {modalButton}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UserModal
