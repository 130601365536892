import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button, Grid, Typography } from '@mui/material'
import { css } from 'aphrodite'
import { FC, useEffect } from 'react'

import LeadsDataIndicator from './cards'
import styles from './styles'
import { useAuth } from '../../../hooks/useAuth'
import moment from 'moment'
import { GetPricingPlanDocument, GetStripeCustomerPortalDocument } from '../../../graphql/generated'
import { useLazyQuery } from '@apollo/client'

interface SubscriptionProps {}

const Subscription: FC<SubscriptionProps> = () => {
  const { user } = useAuth()
  const [getCustomerPortal] = useLazyQuery(GetStripeCustomerPortalDocument, {
    fetchPolicy: 'network-only',
  })

  const [getPricingPlan, { data: getPricingPlanData }] = useLazyQuery(GetPricingPlanDocument)

  useEffect(() => {
    if (user && user.company) {
      getPricingPlan({
        variables: {
          stripeMainPlanSubscriptionId: user.company.stripeMainPlanSubscriptionId as string,
        },
      })
    }
  }, [getPricingPlan, user])

  const handleOnClick = async () => {
    try {
      if (user && user.company) {
        getCustomerPortal({
          variables: {
            stripeCustomerId: user.company.stripeCustomerId as string,
          },
        }).then(({ data }) => {
          if (data?.getStripeCustomerPortal) {
            window.location.href = data?.getStripeCustomerPortal
          }
        })
      }
    } catch (err) {
      console.error(err)
    }
  }
  const plan = user?.company?.subscriptionRecurringInterval
  const addPlan = plan === 'month' ? 'M' : 'y'

  return (
    <div>
      <Grid container xs={12} className={css(styles.container)}>
        <Grid item xs={12}>
          <Typography variant="body1" className={css(styles.mainTitle)}>
            Subscription
          </Typography>
        </Grid>
        <Grid item xs={12} className={css(styles.cardContainer)}>
          <Typography variant="body1" className={css(styles.subTitle)}>
            Email credits
          </Typography>
          <Typography variant="body1" className={css(styles.description)}>
            Each lead generated is 1 credit used from your account. Unused credits will expire at the credits reset
            date.
          </Typography>
          {user && user.company && (
            <div className={css(styles.dataIndicator)}>
              <LeadsDataIndicator title="Leads generated this month" value={user.company.creditsUsed} font="24px" />
              <LeadsDataIndicator title="Credits remaining" value={user.company.creditsAvailable} font="24px" />
              {getPricingPlanData?.getPricingPlan?.productId !== process.env.REACT_APP_STRIPE_FREE_PLAN_PRODUCT_ID && (
                <LeadsDataIndicator
                  title="Credits reset at"
                  font="19px"
                  value={moment(user.company.subscriptionLastResetTime).add(1, addPlan).format('MMMM DD, yyyy')}
                />
              )}
            </div>
          )}
        </Grid>
        <Grid item xs={12} className={css(styles.planOverviewContainer)}>
          <Typography variant="body1" className={css(styles.subTitle)}>
            Plan overview
          </Typography>
          <div className={css(styles.growthPlanMainContainer)}>
            <div className={css(styles.growthPlanSubContainer)}>
              <Typography variant="body1" sx={{ color: '#4CAF50' }} className={css(styles.mainTitle)}>
                {getPricingPlanData?.getPricingPlan?.productName}
              </Typography>
              <div className={css(styles.growthPlanDataIndicator)}>
                <Typography variant="body1" sx={{ color: '#697386' }} className={css(styles.mainTitle)}>
                  ${getPricingPlanData?.getPricingPlan?.price}
                </Typography>
                <Typography variant="body1" sx={{ color: '#A3AAB7', fontSize: '14px', fontWeight: 400 }}>
                  /{getPricingPlanData?.getPricingPlan?.recurringInterval}
                </Typography>
              </div>
            </div>
            <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 400, color: '#697386' }}>
              {getPricingPlanData?.getPricingPlan?.seatsIncluded} seat
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 400, color: 'red' }}>
              {getPricingPlanData?.getPricingPlan?.cancelAt
                ? `Your subscription will end on ${moment
                    .unix(getPricingPlanData?.getPricingPlan?.cancelAt)
                    .format('MMM DD, yyyy')}`
                : ''}
            </Typography>
            <Button onClick={handleOnClick} variant="text" className={css(styles.planManagmentBtn)}>
              Plan management portal
              <ChevronRightIcon />
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Subscription
