import { StyleSheet } from 'aphrodite'
import { ghostWhite } from '../../shared/colors'

const styles = StyleSheet.create({
  container: {
    // background: ghostWhite,
    background: 'linear-gradient(0deg, rgba(105, 111, 134, 0.04) 0%, rgba(105, 111, 134, 0.04) 100%), #FFF',
    display: 'flex',
    maxHeight: '100vh',
    height: '100vh',
    overflow: 'hidden',
  },
  pagesContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    height: '100%',
  },
})

export default styles
