import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import { useMutation } from '@apollo/client'
import { Theme, useTheme } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import { css } from 'aphrodite'
import {
  useMediaQuery,
  FormControl,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  Toolbar,
  Select,
  AppBar,
  Dialog,
  Button,
  Chip,
  Box,
  Slide,
} from '@mui/material'

import LocationAutocomplete from '../../../components/LocationAutocomplete'
import WarningModal from '../../../components/WarningModal/WarningModal'
import getErrorMessage from '../../../shared/utils/getErrorMessage'
import { numEmployeeRangesMap } from '../../../shared/constants'
import styles from '../AddObjectDialog/styles'
import { white } from '../../../shared/colors'
import Text from '../../../components/Text'
import {
  DeletePersonaDocument,
  NumEmployeeRange,
  ManagementLevel,
  Location,
  Persona,
  User,
} from '../../../graphql/generated'
import { useGraphQL } from '../../../context/GraphQLContext'
import ChipTextField from './ChipTextField'
import JobTitles from '../../../components/Chat/MessageBox/JobTitles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(option: string, selections: readonly string[], theme: Theme) {
  return {
    fontWeight:
      selections.indexOf(option) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  }
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export enum DialogEnum {
  CLIENT = 'CLIENT',
  TEAM = 'TEAM',
  USER = 'USER',
  PERSONA = 'filter group',
}

export type DialogType = DialogEnum.CLIENT | DialogEnum.TEAM | DialogEnum.USER | DialogEnum.PERSONA | ''
interface Args {
  user: User
  dialogType?: DialogType
  openModal?: boolean
  onClose: () => void
  selectedPersona?: Persona
  getPersonasByTeamId?: () => void
  handleCreateTargetingInfo?: () => void
  setSelectedPersona?: React.Dispatch<React.SetStateAction<Persona | null>>
}

interface State {
  name: string
  descr: string
  exampleTitles: string[]
  titleExcludeKeywords: string[]
  foundedYearAfter: string | null
  foundedYearBefore: string | null
  numEmployeeRanges: NumEmployeeRange[]
  managementLevels: ManagementLevel[]
  hqLocations: Location[]
  contactLocations: Location[]
}

const initialState: State = {
  name: '',
  descr: '',
  exampleTitles: [],
  titleExcludeKeywords: [],
  foundedYearAfter: null,
  foundedYearBefore: null,
  numEmployeeRanges: [],
  managementLevels: [],
  hqLocations: [],
  contactLocations: [],
}

const CreationModal = ({
  selectedPersona,
  onClose,
  user,
  dialogType = '',
  openModal = false,
  getPersonasByTeamId,
  setSelectedPersona,
  handleCreateTargetingInfo,
}: Args) => {
  const theme = useTheme()
  const { handlePersonaCreateOrUpdate, loading: queryLoading } = useGraphQL()
  const [state, setState] = useState<State>(initialState)

  const {
    name,
    descr,
    exampleTitles,
    titleExcludeKeywords,
    foundedYearAfter,
    foundedYearBefore,
    numEmployeeRanges,
    managementLevels,
    hqLocations,
    contactLocations,
  } = state

  const [addDialogType, setAddDialogType] = useState<DialogType>(dialogType)
  const [isCreatePersonaAutocompleteOpen, setIsCreatePersonaAutocompleteOpen] = useState(false)
  const [createPersonaLocationStr, setCreatePersonaLocationStr] = useState('')
  const [createPersonaLocationOptions, setCreatePersonaLocationOptions] = useState([] as Location[])
  const [addDialogErrorMessage, setAddDialogErrorMessage] = useState('')
  const [showWarnModal, setShowWarnModal] = useState(false)
  const [showError, setShowError] = useState(false)

  const [createContactLocationStr, setContactLocationStr] = useState('')
  const [createContactLocationOptions, setCreateContactLocationOptions] = useState([] as Location[])
  const [isCreateContactAutocompleteOpen, setIsCreateContactAutocompleteOpen] = useState(false)

  const isMobile = useMediaQuery('@media (max-width: 1023px)')

  const setPersonaName = useCallback((value: string) => setState((prevState) => ({ ...prevState, name: value })), [])
  const setDescription = useCallback((value: string) => setState((prevState) => ({ ...prevState, descr: value })), [])
  const setPersonaTitle = useCallback(
    (value: string[]) => setState((prevState) => ({ ...prevState, exampleTitles: value })),
    [],
  )
  const setExcludeTitles = useCallback(
    (value: string[]) => setState((prevState) => ({ ...prevState, titleExcludeKeywords: value })),
    [],
  )
  const setAddDialogFoundedYearAfter = useCallback(
    (value: string | null) => setState((prevState) => ({ ...prevState, foundedYearAfter: value })),
    [],
  )
  const setAddDialogFoundedYearBefore = useCallback(
    (value: string | null) => setState((prevState) => ({ ...prevState, foundedYearBefore: value })),
    [],
  )
  const setNumEmployeeRanges = useCallback(
    (value: NumEmployeeRange[]) => setState((prevState) => ({ ...prevState, numEmployeeRanges: value })),
    [],
  )
  const setManagementLevels = useCallback(
    (value: ManagementLevel[]) => setState((prevState) => ({ ...prevState, managementLevels: value })),
    [],
  )
  const setSelectedLocations = useCallback(
    (value: Location[]) => setState((prevState) => ({ ...prevState, hqLocations: value })),
    [],
  )
  const setSelectedContactLocations = useCallback(
    (value: Location[]) => setState((prevState) => ({ ...prevState, contactLocations: value })),
    [],
  )

  // const [getCampaignsLinkedPersona, { loading, error, data }] = useLazyQuery(GetCampaignsLinkedPersonaDocument, {
  //   fetchPolicy: 'network-only',
  //   ...(selectedPersona && {
  //     variables: {
  //       personaId: selectedPersona.id,
  //     },
  //   }),
  // })

  useEffect(() => {
    if (selectedPersona) {
      // getCampaignsLinkedPersona()
      setNumEmployeeRanges(selectedPersona.numEmployeeRanges as NumEmployeeRange[])
      setDescription(selectedPersona.descr ?? '')
      setPersonaTitle((selectedPersona.exampleTitles as string[]) ?? '')
      setPersonaName(selectedPersona.name as string)
      setSelectedLocations(selectedPersona.hqLocations as Location[])
      setSelectedContactLocations((selectedPersona.contactLocations as Location[]) ?? [])
      setManagementLevels((selectedPersona.managementLevels as ManagementLevel[]) ?? [])
      setAddDialogFoundedYearAfter(selectedPersona.foundedYearAfter as string | null)
      setAddDialogFoundedYearBefore(selectedPersona.foundedYearBefore as string | null)
      setExcludeTitles((selectedPersona.titleExcludeKeywords as string[]) ?? [])
    }
  }, [selectedPersona])

  const resetDefaultInputs = useCallback(() => {
    setPersonaName('')
    setAddDialogFoundedYearAfter(null)
    setAddDialogFoundedYearBefore(null)
    setNumEmployeeRanges([])
    setIsCreatePersonaAutocompleteOpen(false)
    setCreatePersonaLocationStr('')
    setCreatePersonaLocationOptions([])
    setSelectedLocations([])
    setAddDialogErrorMessage('')
    setPersonaTitle([])
    setDescription('')
    setManagementLevels([])
    setIsCreateContactAutocompleteOpen(false)
    setCreatePersonaLocationStr('')
    setCreateContactLocationOptions([])
    setSelectedContactLocations([])
    setExcludeTitles([])
  }, [])

  const handleClose = () => {
    resetDefaultInputs()
    setShowError(false)
    setAddDialogType(dialogType)
    onClose()
  }

  useEffect(() => {
    setAddDialogType(dialogType)
  }, [dialogType])

  // update: (cache, { data }) => {
  //   if (data && data?.createNewPersona) {
  //     const cachedData = cache.readQuery({
  //       query: GetPersonasByTeamIdDocument,
  //       variables: {
  //         teamId: user.teamId as string,
  //       },
  //     })
  //     if (!cachedData || !cachedData.getPersonasByTeamId) {
  //       return
  //     }
  //     const newData = [...(cachedData?.getPersonasByTeamId as Persona[]), data.createNewPersona]
  //     cache.writeQuery({
  //       query: GetPersonasByTeamIdDocument,
  //       variables: {
  //         teamId: user.teamId as string,
  //       },
  //       data: {
  //         getPersonasByTeamId: [...newData] as Persona[],
  //       },
  //     })
  //   }
  // },

  const validateForm = useCallback(() => {
    if (!descr || exampleTitles.length === 0 || hqLocations.length === 0 || contactLocations.length === 0) {
      setShowError(true)
      return false
    }
    // Add validation checks for other required fields as needed
    setShowError(false)
    return true
  }, [descr, exampleTitles.length, hqLocations.length, contactLocations.length])

  const onAddObjClick = () => {
    if (addDialogType === DialogEnum.PERSONA && validateForm()) {
      if (selectedPersona) {
        handlePersonaCreateOrUpdate(
          user,
          {
            id: selectedPersona?.id,
            ...state,
            numEmployeeRanges: state?.numEmployeeRanges.map((item) => item.toLowerCase()) as NumEmployeeRange[],
          } as Persona,
          () => {
            getPersonasByTeamId && getPersonasByTeamId()
            handleCreateTargetingInfo && handleCreateTargetingInfo()
            handleClose()
          },
        )
      } else {
        handlePersonaCreateOrUpdate(user, state as Persona, () => {
          getPersonasByTeamId && getPersonasByTeamId()
          handleClose()
        })
      }
    }
  }

  const [deletePersona, { loading: deletePersonaLoading, error: deletePersonaError, data: deletePersonaRes }] =
    useMutation(DeletePersonaDocument, {
      ...(selectedPersona && {
        variables: {
          personaId: selectedPersona.id,
        },
      }),
      onError: (error) => {
        setAddDialogErrorMessage(getErrorMessage(error))
      },
      update: (cache, { data }) => {
        if (selectedPersona) {
          const normalizedId = cache.identify({ id: selectedPersona?.id, __typename: 'Persona' })
          cache.evict({ id: normalizedId })
          cache.gc()
        }
      },
      onCompleted: (data) => {
        handleClose()
      },
    })

  const handleWarnModal = useCallback(() => {
    setShowWarnModal((show) => !show)
  }, [])

  const handleAccept = useCallback(() => {
    deletePersona()
    handleWarnModal()
  }, [deletePersona, handleWarnModal])

  const numEmployeeRangesString = useMemo(
    () => numEmployeeRanges.map((r) => r.charAt(0).toUpperCase() + r.slice(1)),
    [numEmployeeRanges],
  )

  return (
    <>
      <WarningModal
        open={showWarnModal}
        loading={deletePersonaLoading}
        handleAccept={handleAccept}
        handleReject={handleWarnModal}
      />

      <Dialog fullScreen open={openModal} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={css(styles.header)}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, textTransform: 'none' }} variant="h6" component="div">
              {selectedPersona ? 'Edit ' : 'Add a '}
              {addDialogType}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={css(styles.dialogFormContainer)}>
          <div className={css(styles.dialogFormOutterContainer)}>
            <div className={css(styles.dialogFormInnerContainer)}>
              <div className={css(styles.dialogFormContentContainer)}>
                <Text smallSectionTitle>Company filters</Text>
                <div className={css(styles.dialogFormContentSubContainer)}>
                  <div>
                    <Text extraStyles={[styles.dialogFormContentSubTitle]}>Company HQ Location(s)*</Text>
                    <LocationAutocomplete
                      placeholder="Enter a City/ State/ Country"
                      showError={showError && hqLocations.length === 0}
                      createPersonaLocationStr={createPersonaLocationStr}
                      setCreatePersonaLocationStr={setCreatePersonaLocationStr}
                      createPersonaLocationOptions={createPersonaLocationOptions}
                      setCreatePersonaLocationOptions={setCreatePersonaLocationOptions}
                      selectedLocations={hqLocations}
                      setSelectedLocations={setSelectedLocations}
                      isCreatePersonaAutocompleteOpen={isCreatePersonaAutocompleteOpen}
                      setIsCreatePersonaAutocompleteOpen={setIsCreatePersonaAutocompleteOpen}
                      handleUpdateLocation={(newValue) => {
                        setSelectedPersona &&
                          setSelectedPersona((prevState: any) => ({ ...prevState, hqLocations: [...newValue] }))
                      }}
                    />
                  </div>
                  <div>
                    <Text extraStyles={[styles.dialogFormContentSubTitle]}>Number of Employees*</Text>
                    <FormControl required fullWidth size="small" sx={{ background: white }}>
                      <Select
                        labelId="multiple-numEmployeeRanges-label"
                        id="multiple-numEmployeeRanges"
                        multiple
                        placeholder="Select target company employee range"
                        value={numEmployeeRangesString}
                        error={showError && numEmployeeRanges.length === 0}
                        // helperText={!numEmployeeRanges.length && showError ? 'Please select # of employees' : ''}
                        onChange={(event: any) => {
                          const {
                            target: { value },
                          } = event

                          setNumEmployeeRanges(value)
                          setSelectedPersona &&
                            setSelectedPersona((prevState: any) => ({ ...prevState, numEmployeeRanges: value }))
                        }}
                        // input={
                        //   <OutlinedInput
                        //     placeholder="Select target company employee range"
                        //     id="select-multiple-numEmployeeRanges"
                        //     label="# Employees"
                        //   />
                        // }
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={numEmployeeRangesMap[`${value[0].toUpperCase()}${value.slice(1)}`]}
                              />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                        sx={{
                          // marginBottom: '16px',
                          width: '100%',
                        }}
                      >
                        {Object.keys(NumEmployeeRange).map((numEmployeeRangesOption) => (
                          <MenuItem
                            key={numEmployeeRangesOption}
                            value={numEmployeeRangesOption}
                            style={getStyles(numEmployeeRangesOption, numEmployeeRanges, theme)}
                          >
                            {numEmployeeRangesMap[numEmployeeRangesOption]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <small
                      style={{
                        color: '#d32f2f',
                      }}
                    >
                      {!numEmployeeRanges.length && showError ? 'Please select # of employees' : ''}
                    </small>
                  </div>
                  <div>
                    <Text extraStyles={[styles.dialogFormContentSubTitle]}>Founded Year</Text>
                    <div className={css(styles.yearDialogFormContainer)}>
                      <div className={css(styles.yearDialogFormSubContainer)}>
                        <Text>After</Text>
                        <TextField
                          fullWidth
                          required
                          id="after-year"
                          placeholder="Enter a year"
                          variant="outlined"
                          size="small"
                          sx={{
                            marginTop: '8px',
                            background: white,
                          }}
                          value={foundedYearAfter}
                          onChange={(e: React.ChangeEvent<{ value: string }>) => {
                            setAddDialogFoundedYearAfter(e.target.value)
                            setSelectedPersona &&
                              setSelectedPersona((prevState: any) => ({
                                ...prevState,
                                foundedYearAfter: e.target.value,
                              }))
                          }}
                        />
                      </div>
                      <div className={css(styles.yearDialogFormSubContainer)}>
                        <Text>Before</Text>
                        <TextField
                          fullWidth
                          required
                          id="before-year"
                          placeholder="Enter a year"
                          variant="outlined"
                          size="small"
                          value={foundedYearBefore}
                          onChange={(e: React.ChangeEvent<{ value: string }>) => {
                            setAddDialogFoundedYearBefore(e.target.value)
                            setSelectedPersona &&
                              setSelectedPersona((prevState: any) => ({
                                ...prevState,
                                foundedYearBefore: e.target.value,
                              }))
                          }}
                          sx={{
                            marginTop: '8px',
                            background: white,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={css(styles.dialogFormContentContainer)}>
                <Text smallSectionTitle extraStyles={[styles.dialogFormContentSubTitle]}>
                  People filters
                </Text>
                <div className={css(styles.dialogFormContentSubContainer)}>
                  <JobTitles
                    label="Example titles*"
                    exampleTitles={exampleTitles}
                    showError={showError}
                    handleChange={(titles) => {
                      setPersonaTitle(titles)
                      setSelectedPersona &&
                        setSelectedPersona((prevState: any) => ({ ...prevState, exampleTitles: titles }))
                    }}
                  />
                  {/* <ChipTextField
                    label="Example titles*"
                    value={exampleTitles}
                    placeholder={
                      exampleTitles.length
                        ? 'Please type and hit enter.'
                        : 'Please click "enter" after typing a title (ex. Marketing Director)'
                    }
                    handleChange={(titles) => {
                      setPersonaTitle(titles)
                      setSelectedPersona &&
                        setSelectedPersona((prevState: any) => ({ ...prevState, exampleTitles: titles }))
                    }}
                    error={showError && exampleTitles.length === 0}
                    helperText={!exampleTitles.length && showError ? 'Please enter example titles' : ''}
                  /> */}
                  <div>
                    <Text extraStyles={[styles.dialogFormContentSubTitle]}>Title description*</Text>
                    <TextField
                      required
                      fullWidth
                      sx={{ background: white }}
                      size="small"
                      value={descr}
                      error={showError && descr.length === 0}
                      helperText={!descr.length && showError ? 'Please enter title description' : ''}
                      placeholder={
                        'I am looking for sales and marketing people who would be decision makers of a potential lead generation software product at an enterprise company'
                      }
                      onChange={(e) => {
                        setDescription(e.target.value)
                        setSelectedPersona &&
                          setSelectedPersona((prevState: any) => ({ ...prevState, descr: e.target.value }))
                      }}
                    />
                  </div>
                  <div>
                    <Text extraStyles={[styles.dialogFormContentSubTitle]}>Management Levels*</Text>
                    <FormControl required fullWidth size="small" sx={{ background: white }}>
                      {/* <InputLabel id="multiple-management-level-label">Management Levels</InputLabel> */}
                      <Select
                        labelId="multiple-management-level-label"
                        id="multiple-management-level"
                        required
                        multiple
                        error={showError && managementLevels.length === 0}
                        value={managementLevels as any}
                        onChange={(event: any) => {
                          const {
                            target: { value },
                          } = event

                          setManagementLevels(value)
                          setSelectedPersona &&
                            setSelectedPersona((prevState: any) => ({ ...prevState, managementLevels: value }))
                        }}
                        // input={<OutlinedInput id="select-multiple-management-level" label="# Management Level" />}
                        renderValue={(selected: [keyof typeof ManagementLevel]) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value: keyof typeof ManagementLevel, index) => (
                              <Chip key={value + index} label={ManagementLevel[value]} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                        sx={{
                          // marginBottom: '16px',
                          width: '100%',
                        }}
                      >
                        {Object.keys(ManagementLevel).map((managementL) => (
                          <MenuItem
                            key={managementL}
                            value={managementL}
                            style={getStyles(managementL, Object.keys(ManagementLevel), theme)}
                          >
                            {ManagementLevel[managementL as unknown as keyof typeof ManagementLevel]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <small
                      style={{
                        color: '#d32f2f',
                      }}
                    >
                      {!managementLevels.length && showError ? 'Please select management levels' : ''}
                    </small>
                  </div>
                  <ChipTextField
                    label="Exclude title keywords"
                    placeholder={
                      titleExcludeKeywords?.length
                        ? 'Please type and enter.'
                        : 'Please click "enter" after typing a title (ex. Marketing Director)'
                    }
                    value={titleExcludeKeywords}
                    handleChange={(titles) => {
                      setExcludeTitles(titles)
                      setSelectedPersona &&
                        setSelectedPersona((prevState: any) => ({ ...prevState, titleExcludeKeywords: [...titles] }))
                    }}
                  />
                  <div>
                    <Text extraStyles={[styles.dialogFormContentSubTitle]}>Contact Location(s)*</Text>
                    <LocationAutocomplete
                      placeholder="Enter a City/ State/ Country"
                      label="Contact Hq Locations"
                      showError={showError && contactLocations.length === 0}
                      createPersonaLocationStr={createContactLocationStr}
                      setCreatePersonaLocationStr={setContactLocationStr}
                      createPersonaLocationOptions={createContactLocationOptions}
                      setCreatePersonaLocationOptions={setCreateContactLocationOptions}
                      selectedLocations={contactLocations}
                      setSelectedLocations={setSelectedContactLocations}
                      isCreatePersonaAutocompleteOpen={isCreateContactAutocompleteOpen}
                      setIsCreatePersonaAutocompleteOpen={setIsCreateContactAutocompleteOpen}
                      handleUpdateLocation={(newValue) => {
                        setSelectedPersona &&
                          setSelectedPersona((prevState: any) => ({ ...prevState, contactLocations: [...newValue] }))
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* {data?.getCampaignsLinkedPersona?.length && (
              <Text extraStyles={[styles.dialogErrorMessage]}>Used by draft campaign(s): {campaignNames}</Text>
            )} */}
            <div className={css(styles.dialogButtonsContainer)}>
              {/* <Button sx={{ textTransform: 'none' }} autoFocus color="inherit" onClick={handleClose}>
                Cancel
              </Button> */}
              <LoadingButton
                loading={queryLoading}
                autoFocus
                variant="contained"
                className={css(styles.dialogConfirmBotton)}
                // disabled={data?.getCampaignsLinkedPersona?.length ? true : false}
                onClick={onAddObjClick}
                // sx={{
                //   marginLeft: '10px',
                //   background: '#378DFA',
                //   boxShadow: '0px 4px 12px rgba(99, 99, 99, 0.13)',
                //   borderRadius: '8px',
                //   textTransform: 'none',
                // }}
              >
                Confirm
                {/* {selectedPersona ? 'Edit' : 'Add'} {addDialogType} */}
              </LoadingButton>
              {/* {selectedPersona && (
                <LoadingButton
                  loading={deletePersonaLoading}
                  autoFocus
                  color="error"
                  variant="contained"
                  onClick={handleWarnModal}
                  // disabled={data?.getCampaignsLinkedPersona?.length ? true : false}
                  sx={{
                    marginLeft: '10px',
                  }}
                >
                  Delete {addDialogType}
                </LoadingButton>
              )} */}
            </div>
            {addDialogErrorMessage && (
              <Text extraStyles={[styles.dialogErrorMessage]}>Error: {addDialogErrorMessage}</Text>
            )}
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default CreationModal
