import { Button } from '@mui/material'
import { FC, memo, useCallback } from 'react'
import { Campaign } from '../../../../../graphql/generated'
import { css } from 'aphrodite'
import styles from '../styles'
import { useNavigate } from 'react-router'

interface ViewCampaignButtonProps {
  campaign: Campaign
}

const ViewCampaignButton: FC<ViewCampaignButtonProps> = ({ campaign }) => {
  const navigate = useNavigate()

  const handleShowModal = useCallback(() => {
    navigate(`/new-campaign?campaignId=${campaign.id}`)
  }, [campaign.id, navigate])

  return (
    <>
      <Button
        onClick={handleShowModal}
        variant="outlined"
        size="small"
        className={css(campaign?.isInDraft ? styles.editBtn : styles.reviewBtn)}
      >
        {campaign?.isInDraft ? 'Edit' : 'Review'}
      </Button>
    </>
  )
}

export default memo(ViewCampaignButton)
