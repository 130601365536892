import React, { useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import { useLazyQuery } from '@apollo/client'
import Text from '../../components/Text'
import { Company, GetAllCompaniesDocument } from '../../graphql/generated'
import { useAuth } from '../../hooks/useAuth'
import styles from './styles'
import { BasicPage } from '../../components/BasicPage'
import CompanyTable from './CompanyTable'
import AddObjectDialog from './AddObjectDialog'
import MobileNavBar from '../../components/MobileNavBar'

const UniverseControllerPage = () => {
  const { pages, isMobile, user } = useAuth()

  const [getAllCompanies, { loading: getAllCompaniesLoading, error: getAllCompaniesError, data: getAllCompaniesRes }] =
    useLazyQuery(GetAllCompaniesDocument, {
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    if (user) {
      getAllCompanies({
        variables: {
          userId: user.id,
        },
      })
    }
  }, [user, getAllCompanies])

  if (getAllCompaniesLoading) {
    return (
      <div>
        <Text>Loading the universe</Text>
        <CircularProgress />
      </div>
    )
  } else {
    return (
      <BasicPage>
        <Grid width={'100%'} style={{ padding: isMobile ? 10 : 30, paddingTop: 10 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {isMobile && <MobileNavBar pages={pages} />}
            <Text extraStyles={[styles.pageTitle]}>Universe Controller</Text>
          </div>
          {user &&
            getAllCompaniesRes &&
            getAllCompaniesRes.getAllCompanies &&
            getAllCompaniesRes.getAllCompanies.length && (
              <AddObjectDialog
                companies={getAllCompaniesRes.getAllCompanies as Company[]}
                user={user}
                getAllCompanies={getAllCompanies}
              />
            )}
          <br />
          <br />
          {getAllCompaniesRes && getAllCompaniesRes.getAllCompanies && getAllCompaniesRes.getAllCompanies.length ? (
            <CompanyTable companies={getAllCompaniesRes.getAllCompanies as Company[]} />
          ) : (
            <></>
          )}
        </Grid>
      </BasicPage>
    )
  }
}

export default UniverseControllerPage
