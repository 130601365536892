import { StyleSheet } from 'aphrodite'
import { placeholder } from '../../../shared/colors'

const styles = StyleSheet.create({
  LabelCompanyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '11px',
    borderRadius: '8px',
    flex: '1 1',
    overflow: 'hidden',
    position: 'relative',
  },
  CompanyLabelHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    color: '#3C4257',
    fontSize: '20px',
    fontWeight: 600,
  },
  loadingBarTime: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    color: '#697386',
    display: 'flex',
    alignItems: 'center',
  },
  toolTipPosition: {
    position: 'absolute',
    top: '180px',
    left: '800px',
    transform: 'none',
    '@media (min-width: 1280px)': {
      left: '50%',
      transform: 'translateX(65%)',
    },
  },
  ViewInstructionButton: {
    height: '20px',
    fontFamily: "'Open Sans'",
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '165%',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    padding: 0,
  },
  Notice: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '48px',
    height: '32px',
    flex: 'none',
    order: 2,
    alignSelf: 'stretch',
    flexGrow: 0,
    color: '#697386',
  },
  CompanySummary: {
    marginTop: 10,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '3px 10px',
    gap: '8px',
    width: '1122px',
    height: '36px',
    background: '#FCFCFD',
    border: '1px solid #E5E5E6',
    borderRadius: '8px',
    flex: 'none',
    order: 3,
    alignSelf: 'stretch',
    flexGrow: 0,
  },
  SummaryFrame1: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '24px',
    width: '496px',
    height: '28px',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },
  AddMoreCompanyButton: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4px 16px',
    gap: '17px',
    width: '112px',
    height: '28px',
    background: '#FFFFFF',
    border: '0.5px solid #E5E5E6',
    borderRadius: '8px',
    flex: 'none',
    order: 2,
    flexGrow: 0,
  },
  AddMoreCompanyLabel: {
    width: '82px',
    height: '20px',
    fontFamily: "'Open Sans'",
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '165%',
    textDecorationLine: 'underline',
    color: '#0E5ADE',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },
  ViewDetailButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    width: '80px',
    flex: 'none',
    border: 'none',
    background: 'transparent',
    order: 1,
    flexGrow: 0,
    fontSize: 12,
    color: '#0E5ADE',
  },
  ViewDetailButtonLabel: {
    height: '20px',
    fontFamily: "'Open Sans'",
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '165%',
    textDecorationLine: 'underline',
    color: '#0E5ADE',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    textTransform: 'capitalize',
  },
  CompanyAddLabel: {
    width: '169px',
    height: '23px',
    fontFamily: "'Open Sans'",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '165%',
    color: '#697386',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },
  CompanyAddedLabelNames: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '3px',
    width: '167px',
    height: '23px',
    flex: 'none',
    order: 1,
    flexGrow: 0,
  },
  LabelCompanyHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px 4px',
    gap: 10,
    height: '36px',
    flex: 'none',
    // alignSelf: 'stretch',
    color: '#697386',
  },
  DescriptionHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '8px 10px',
    // width: '651px',
    height: '36px',
    fontSize: '12px',
  },
  CompayFrame: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '8px 10px',
    gap: '10px',
    width: '100%',
    height: '36px',
    fontSize: '12px',
  },
  ButtonHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '8px 10px',
    gap: '19px',
    width: '248px',
    height: '36px',
    fontSize: '12px',
  },
  ListGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    // width: '1124px',
    height: '971px',
    flex: 'none',
    order: 5,
    flexGrow: 0,
  },
  ListContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '6px 0px 6px 8px',
    gap: '2px',
    // height: '79px',
    background: '#FFFFFF',
    borderRadius: '8px',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },
  ListItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    // padding: '6px 0px 6px 8px',
    padding: '6px 16px',
    gap: 16,
    margin: '5px 0px',
    width: '100%',
    minHeight: '79px',
    // background: '#FFFFFF',
    borderRadius: '8px',
    background: '#FAFAFA',
    ':hover': {
      background: '#F1F2F5',
    },
  },
  PicFrame: {
    display: 'flex',
    gap: '8px',
    width: '185px',
    height: '48px',
    fontSize: '14px',
    fontWeight: 600,
  },
  DescriptionContainer: {
    position: 'relative',
  },
  ExpandBtn: {
    position: 'absolute',
    right: 0,
    bottom: '-13px',
    color: '#C1C9D2',
    fontSize: 30,
    ':hover': {
      background: 'transparent',
    },
  },
  DescriptionBox: {
    maxWidth: '637px',
    left: '245px',
    top: '6px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '122%',
    color: '#3C4257',
    overflow: 'hidden',
    paddingBottom: 10,
    textAlign: 'justify',
  },
  toolTipSpan: {
    width: '8px',
    height: '8px',
    position: 'absolute',
    top: 4,
    right: 5,
    borderRadius: '50%',
    orderRadius: '50%',
    background: 'red',
  },
  customTooltip: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '16px 8px',
    gap: '16px',
    position: 'absolute',
    width: '396px',
    right: '0px',
    bottom: '10px',
    background: '#F2EDF9',
    borderRadius: '8px',
  },
  AiAlertContainer: {
    display: 'flex',
    height: '23px',
    fontFamily: "'Open Sans'",
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '165%',
    color: '#9B66D0',
    order: 1,
    flexGrow: 0,
    textTransform: 'initial',
  },
  active: {
    background: '#378DFA',
  },
  AiImage: {
    fontSize: 20,
  },
  AlertContent: {
    fontSize: 11,
  },
  AiContent: {
    width: '310px',
    fontFamily: "'Open Sans'",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    textAlign: 'initial',
    textTransform: 'initial',
    lineHeight: '150%',
    color: '#9B66D0',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    paddingLeft: 5,
  },
  disabledButton: {
    background: '#EFF0F2',
    border: `0.5px solid ${placeholder}`,
    boxShadow: '0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
    color: placeholder,
  },
  outlinedButton: {
    borderRadius: '16px',
    border: '1px solid rgba(55, 141, 250, 0.30)',
    background: '#FDFDFD',
    boxShadow: '0px 0px 4px 0px rgba(55, 141, 250, 0.40)',
    padding: '12px 36px',
    fontSize: '18px',
    fontWeight: 600,
    color: '#378DFA',
    cursor: 'pointer',
    height: '49px',
    textTransform: 'initial',
    ':disabled': {
      border: '0.5px solid #C1C9D2',
      background: '#F0F3F5',
      color: '#C1C9D2',
    },
  },
  activateLeadsButton: {
    color: '#FDFDFD !important',
    background: '#378DFA !important',
  },
  getMoreLabelsButton: {
    boxShadow: '0px 0px 4px 0px rgba(55, 141, 250, 0.40)',
    padding: '4px 36px',
    fontSize: '18px',
    fontWeight: 600,
    color: '#378DFA',
    cursor: 'pointer',
    height: '33px',
    textTransform: 'initial',
    borderRadius: '16px',
    border: '1.5px solid rgba(55, 141, 250, 0.40)',
    background: '#F5FDFF',
  },

  leadsCardItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '2px 10px',
    cursor: 'pointer',
    borderRadius: '12px',
    border: '1px solid #F0F3F5',
    background: '#FDFDFD',

    // width: '155px',
  },
  leadsCardItemTitle: {
    fontWeight: 400,
    fontSize: '12px',
    /* identical to box height, or 21px */

    /* Text/Note */

    color: '#697386',
  },
  leadsCardItemValue: {
    padding: '2px',
    fontSize: '20px',
    fontWeight: 600,

    gap: '16px',

    width: '129px',
    color: '#7865ED',
  },
  buttonComponentContanier: {
    display: 'flex',
    padding: '4px 0px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '10px',
    alignSelf: 'stretch',
  },
  getLeadsBtn: {
    display: 'flex',
    width: '174px',
    fontWeight: 600,
    color: '#378DFA',
    padding: '6px 16px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '4px',
    borderRadius: '8px',
    border: '1px solid rgba(55, 141, 250, 0.40)',
    background: '#FFF',
    boxShadow: '0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
  },
  tableHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableWrapper: {
    borderRadius: '12px 12px 0px 0px',
    padding: '0px 10px',
    border: '1px solid #E5E5E5',
    background: '#F4F5F6',
    // height: '450px',
    width: '100%',
    flex: '1 1',
    height: 0,
    overflow: 'auto',
  },
})

export default styles
