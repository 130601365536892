import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { useState } from 'react'
import { css } from 'aphrodite'

import { useGraphQL } from '../../../../context/GraphQLContext'
import styles from './styles'

interface RenameCampaignModalProps {
  name: string
  campaignId: string
  showUserModal: boolean
  handleShowUserModal: () => void
}

const RenameCampaignModal = ({ name, campaignId, showUserModal, handleShowUserModal }: RenameCampaignModalProps) => {
  const { updateCampaign } = useGraphQL()

  const [campaignName, setCampaignName] = useState(name || '')

  const handleConfirmCampaignName = () => {
    handleUpdateCampaignName()
    handleClose()
  }

  const handleUpdateCampaignName = () => {
    if (campaignId) {
      updateCampaign({
        variables: {
          data: {
            campaignId: campaignId,
            campaignName: campaignName,
          },
        },
      })
    }
  }

  const handleClose = () => {
    handleShowUserModal()
  }

  const sxStyle = {
    '& .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      borderRadius: '16px',
      background: ' #FFF',
      boxShadow: '0px 4px 30px 0px rgba(99, 99, 99, 0.35)',
      width: '467px',
      maxHeight: '650px',
      padding: '36px',
    },
  }

  return (
    <div>
      <Dialog open={showUserModal} onClose={handleClose} sx={sxStyle}>
        <DialogTitle className={css(styles.dialogTitle)}>Rename leads list</DialogTitle>
        <DialogContent sx={{ width: '100%', maxWidth: 500, display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <DialogContentText>Leads list name</DialogContentText>
          <TextField
            fullWidth
            required
            value={campaignName}
            id="name"
            size="small"
            variant="outlined"
            placeholder="US Toy Sales Director "
            onChange={(e) => {
              setCampaignName(e.target.value)
            }}
            sx={{
              background: '#fff',
            }}
          />
        </DialogContent>
        <DialogActions className={css(styles.dialogBtnContainer)}>
          <Button variant="contained" onClick={handleClose} className={css(styles.cancelBtn)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmCampaignName}
            disabled={!campaignName || campaignName === name}
            sx={{
              borderRadius: '10px',
              border: '1px solid rgba(55, 141, 250, 0.10)',
              background: '#378DFA',
              boxShadow: '0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
              textTransform: 'none',
              padding: '10px 24px',
              ':hover': {
                background: '#378DFA',
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default RenameCampaignModal
