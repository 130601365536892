import { FC } from 'react'
import styles from './styles'
import { css } from 'aphrodite'
import SuccessSvg from '../../assets/svg/success.svg'
import TipSvg from '../../assets/svg/tip.svg'

interface ShowNoticeProps {
  notice: string
  showInfoIcon: boolean
}

const ShowNotice: FC<ShowNoticeProps> = ({ notice, showInfoIcon }) => {
  return (
    <div className={css(styles.noticeContainer)}>
      <img src={showInfoIcon ? TipSvg : SuccessSvg} alt="success" />
      <span className={css(styles.noticeText)}>{notice}</span>
    </div>
  )
}

export default ShowNotice
