import { Box, Checkbox, Chip, FormControl, FormControlLabel, MenuItem, Select } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import { white } from '../../../../shared/colors'
import Text from '../../../Text'
import styles from './style'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    sx: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      padding: '8px 6px 8px 8px',
      border: '1.5px solid #CED3F1',
      borderTop: 'none',
      '& .MuiMenu-list ': {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      },
      '::-webkit-scrollbar': {
        // width: '2px',
      },
    },
  },
}

interface SelectFormControlProps {
  title: string
  labelId: string
  selectId: string
  values: string[]
  errorCondition: boolean
  errorMessage: string
  onChange: (event: any) => void
  valueMap: Record<string, string>
  items: string[]
  placeholder: string
  bg: string
  color: string
  onClose?: () => void
}

const SelectFormControl: FC<SelectFormControlProps> = ({
  title,
  placeholder,
  onChange,
  values = [],
  valueMap,
  items,
  labelId,
  selectId,
  errorCondition,
  errorMessage,
  bg,
  color,
  onClose,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(values)

  useEffect(() => {
    setSelectedValues(values)
  }, [values])

  const handleRowClick = (item: string) => {
    const newValue = selectedValues.includes(item)
      ? selectedValues.filter((val) => val !== item)
      : [...selectedValues, item]
    setSelectedValues(newValue)
    onChange(newValue)
  }

  return (
    <>
      <FormControl required fullWidth size="small" sx={{ background: white, marginBottom: '12px' }}>
        <Text extraStyles={[styles.textStyle]}>{title}</Text>
        <Select
          labelId={labelId}
          id={selectId}
          required
          multiple
          value={values}
          error={errorCondition}
          placeholder={placeholder}
          // onChange={onChange}
          onClose={() => {
            onClose && onClose()
          }}
          renderValue={(selected: string[]) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value, index) => (
                <Chip
                  key={value + index}
                  label={valueMap[value]}
                  sx={{ background: bg, color: color, fontSize: '14px', fontWeight: 600 }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          sx={{
            width: '100%',
            ':hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: '2px solid #CED3F1',
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '8px',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#CED3F1',
            },
          }}
        >
          {items.map((item) => (
            <MenuItem
              key={item}
              value={item}
              onClick={() => handleRowClick(item)}
              sx={{
                padding: '4px 12px',
                borderRadius: '6px',
                '&.Mui-selected': {
                  backgroundColor: '#E7E9F6',
                  color: '#5F69C1',
                },
                ':hover': {
                  '&.MuiMenuItem-root.Mui-selected': {
                    backgroundColor: '#E7E9F6',
                    color: '#5F69C1',
                  },
                  '&.MuiMenuItem-root': {
                    backgroundColor: '#F5F6F9',
                  },
                },
                ':focus': {
                  '&.MuiMenuItem-root.Mui-selected': {
                    backgroundColor: '#E7E9F6',
                  },
                },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedValues.includes(item)}
                    // onChange={(e) => {
                    //   const newValue = e.target.checked ? [...values, item] : values.filter((val) => val !== item)
                    //   onChange(newValue)
                    // }}
                    sx={{
                      padding: '5px 4px 5px 12px',
                      '&.Mui-checked': {
                        color: '#5F69C1 !important',
                      },
                    }}
                  />
                }
                label={valueMap[item]}
              />
              {/* {valueMap[item]} */}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <small style={{ color: '#d32f2f' }}>{errorCondition ? errorMessage : ''}</small>
    </>
  )
}

export default SelectFormControl
