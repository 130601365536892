import { createContext, useContext, FC, ReactNode } from 'react'
import { useMutation, useLazyQuery, MutationResult, LazyQueryExecFunction, QueryResult } from '@apollo/client'
import {
  AcceptOrRejectTargetDescrDocument,
  AcceptOrRejectTargetDescrMutation,
  Campaign,
  ChatInitDocument,
  ChatInitInput,
  ChatInitQuery,
  ChatReplyDocument,
  ChatReplyInput,
  ChatReplyQuery,
  ChatRewriteDescrDocument,
  ChatRewriteDescrQuery,
  ChatRewriteDescriptionInput,
  CreateNewPersonaDocument,
  CreateNewPersonaMutation,
  CreateTargetingInfoDocument,
  CreateTargetingInfoMutation,
  Exact,
  GetCampaignByIdDocument,
  GetCompaniesForLabelingDocument,
  GetCompaniesForLabelingInput,
  GetCompaniesForLabelingQuery,
  LocationInput,
  ManagementLevel,
  NumEmployeeRange,
  Persona,
  UpdateCampaignDocument,
  UpdateCampaignMutation,
  UpdatePersonaDocument,
  UpdatePersonaMutation,
  User,
} from '../graphql/generated'
import { managementLevelsMap, numEmployeeRangesMap } from '../shared/constants'
import client from '../apolloClient'

type GetLabelingCompaniesType = {
  campaign: Campaign
  getMoreCompanies?: boolean
  updateShowInitialFiveCompanies?: boolean
  order?: string
}
interface GraphQLContextType {
  updateCampaign: any
  updateCampaignResult: MutationResult<UpdateCampaignMutation>
  createTargetingInfo: any
  createTargetingInfoResult: MutationResult<CreateTargetingInfoMutation>
  getCompaniesForLabeling: any
  getCompaniesForLabelingResult: QueryResult<
    GetCompaniesForLabelingQuery,
    Exact<{
      data: GetCompaniesForLabelingInput
    }>
  >
  updatePersona: any
  updatePersonaResult: MutationResult<UpdatePersonaMutation>
  createPersonaResult: MutationResult<CreateNewPersonaMutation>
  handlePersonaCreateOrUpdate: (user: User, selectedPersona: Persona, onCompleted: () => void) => Promise<void>
  handleCreateTargetingInfo: (campaign: Campaign, selectedPersona: Persona, onCompleted: () => void) => void
  handleGetLabelingCompanies: ({
    campaign,
    getMoreCompanies,
    updateShowInitialFiveCompanies,
    order,
  }: GetLabelingCompaniesType) => void
  loading?: boolean
  chatInit: LazyQueryExecFunction<
    ChatInitQuery,
    Exact<{
      data: ChatInitInput
    }>
  >
  chatInitRes: QueryResult<
    ChatInitQuery,
    Exact<{
      data: ChatInitInput
    }>
  >
  chatReply: LazyQueryExecFunction<
    ChatReplyQuery,
    Exact<{
      data: ChatReplyInput
    }>
  >
  chatReplyRes: QueryResult<
    ChatReplyQuery,
    Exact<{
      data: ChatReplyInput
    }>
  >
  chatRewriteDescr: LazyQueryExecFunction<
    ChatRewriteDescrQuery,
    Exact<{
      data: ChatRewriteDescriptionInput
    }>
  >
  chatRewriteDescrRes: QueryResult<
    ChatRewriteDescrQuery,
    Exact<{
      data: ChatRewriteDescriptionInput
    }>
  >
  acceptOrRejectTargetDescr: any
  acceptOrRejectTargetDescrRes: MutationResult<AcceptOrRejectTargetDescrMutation>
}

export const GraphQLContext = createContext<GraphQLContextType | undefined>(undefined)

interface GraphQLProviderProps {
  children: ReactNode
}

export const GraphQLProvider: FC<GraphQLProviderProps> = ({ children }) => {
  const [updatePersona, updatePersonaResult] = useMutation(UpdatePersonaDocument)
  const [createNewPersona, createPersonaResult] = useMutation(CreateNewPersonaDocument)
  const [createTargetingInfo, createTargetingInfoResult] = useMutation(CreateTargetingInfoDocument)
  const [acceptOrRejectTargetDescr, acceptOrRejectTargetDescrRes] = useMutation(AcceptOrRejectTargetDescrDocument)

  const [chatReply, chatReplyRes] = useLazyQuery(ChatReplyDocument)

  const [chatInit, chatInitRes] = useLazyQuery(ChatInitDocument)
  const [chatRewriteDescr, chatRewriteDescrRes] = useLazyQuery(ChatRewriteDescrDocument)

  const [getCompaniesForLabeling, getCompaniesForLabelingResult] = useLazyQuery(GetCompaniesForLabelingDocument, {
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const is_completed = data.getCompaniesForLabeling.is_completed
      if (is_completed) {
        getCompaniesForLabelingResult.stopPolling()
        localStorage.setItem('is_completed', 'true')
      }
    },
  })

  const [updateCampaign, updateCampaignResult] = useMutation(UpdateCampaignDocument, {
    onError: (error) => {
      console.log('%cError in UpdateCampaignDocument', 'color:red;font-size:50px', error)
    },
    onCompleted: (data) => {
      const updatedCampaign = data.updateCampaign
      if (updatedCampaign) {
        client.cache.writeQuery({
          query: GetCampaignByIdDocument,
          variables: {
            data: {
              id: updatedCampaign.id,
              isDemo: false,
            },
          },
          data: {
            getCampaignById: updatedCampaign,
          },
        })
      }
    },
  })

  const handleCreateTargetingInfo = async (campaign: Campaign, selectedPersona: Persona, onCompleted: () => void) => {
    const contactLocations =
      selectedPersona?.contactLocations?.map((location: any) => ({
        country: location?.country,
        ...(!!location?.region && { region: location?.region }),
        ...(!!location?.locality && { locality: location?.locality }),
      })) || []
    const hqLocations =
      selectedPersona?.hqLocations?.map((location: any) => ({
        country: location?.country,
        ...(!!location?.region && { region: location?.region }),
        ...(!!location?.locality && { locality: location?.locality }),
      })) || []

    const sizes =
      (selectedPersona?.numEmployeeRanges?.map(
        (r) => numEmployeeRangesMap[`${r?.[0].toUpperCase()}${r?.slice(1)}`],
      ) as NumEmployeeRange[]) || []

    createTargetingInfo({
      variables: {
        data: {
          campaign_id: campaign.id,
          user_id: campaign.creatorId,
          prompt: campaign?.targetingDescr as string,
          desired_num_leads: campaign?.desiredNumberOfLeads as number,
          lead_filter: {
            query: selectedPersona?.descr,
            level: selectedPersona?.managementLevels?.map(
              (level) => managementLevelsMap[level as string],
            ) as ManagementLevel[],
            title_keywords: selectedPersona?.exampleTitles,
            exclude_keywords: selectedPersona?.titleExcludeKeywords,
            location: contactLocations,
          },
          company_filter: {
            size: sizes,
            founded_year_after: selectedPersona?.foundedYearAfter ? Number(selectedPersona?.foundedYearAfter) : null,
            founded_year_before: selectedPersona?.foundedYearBefore ? Number(selectedPersona?.foundedYearBefore) : null,
            hq_locations: hqLocations,
          },
        },
      },
    }).then(onCompleted)
  }

  const handleGetLabelingCompanies = async ({
    campaign,
    getMoreCompanies,
    updateShowInitialFiveCompanies,
    order,
  }: GetLabelingCompaniesType) => {
    getCompaniesForLabeling({
      variables: {
        data: {
          campaignId: campaign.id,
          creatorId: campaign.creatorId,
          getMoreCompanies: getMoreCompanies || !campaign?.hasInitialGetCandidateCompaniesBeenFired,
          order: order,
        },
      },
    }).then(({ data }) => {
      if (data && data.getCompaniesForLabeling) {
        const { is_completed } = data.getCompaniesForLabeling
        if (campaign) {
          if (!campaign.hasInitialGetCandidateCompaniesBeenFired) {
            client.cache.writeQuery({
              query: GetCampaignByIdDocument,
              variables: {
                data: {
                  id: campaign.id,
                  isDemo: false,
                },
              },
              data: {
                getCampaignById: {
                  ...campaign,
                  hasInitialGetCandidateCompaniesBeenFired: true,
                  ...(updateShowInitialFiveCompanies && { showInitialFiveCompanies: updateShowInitialFiveCompanies }),
                } as Campaign,
              },
            })
          }

          if (is_completed) {
            getCompaniesForLabelingResult.stopPolling()
          }
        }
      }
    })
  }

  const handlePersonaCreateOrUpdate = async (user: User, selectedPersona: Persona | any, onCompleted: () => void) => {
    const variables = {
      data: {
        ...(!!selectedPersona?.name && { name: selectedPersona?.name }),
        companyId: user.companyId,
        creatorId: user.id,
        teamId: user.teamId as string,
        descr: selectedPersona?.descr as string,
        exampleTitles: selectedPersona?.exampleTitles as string[],
        titleExcludeKeywords: selectedPersona?.titleExcludeKeywords as string[],
        numEmployeeRanges: selectedPersona?.numEmployeeRanges as NumEmployeeRange[],
        ...(!!selectedPersona?.managementLevels &&
          !!selectedPersona?.managementLevels.length && { managementLevels: selectedPersona?.managementLevels }),
        ...(!!selectedPersona?.hqLocations &&
          !!selectedPersona?.hqLocations.length && {
            hqLocations: (selectedPersona.hqLocations as LocationInput[]).map((location) => {
              return {
                country: location?.country,
                ...(!!location?.region && { region: location?.region }),
                ...(!!location?.locality && { locality: location?.locality }),
              }
            }),
          }),
        // jobFunctions: addDialogJobFunctions,
        ...(!!selectedPersona?.contactLocations &&
          !!selectedPersona?.contactLocations.length && {
            contactLocations: (selectedPersona.contactLocations as LocationInput[]).map((location) => {
              return {
                country: location?.country,
                ...(!!location?.region && { region: location?.region }),
                ...(!!location?.locality && { locality: location?.locality }),
              }
            }),
          }),
        foundedYearAfter: Number(selectedPersona?.foundedYearAfter),
        foundedYearBefore: Number(selectedPersona?.foundedYearBefore),
      },
    }
    if (selectedPersona?.id) {
      updatePersona({
        variables: { ...variables, personaId: selectedPersona.id },
      }).then(() => {
        onCompleted && onCompleted()
      })
    } else {
      createNewPersona({
        variables,
      }).then(() => {
        onCompleted && onCompleted()
      })
    }
  }

  const contextValue: GraphQLContextType = {
    chatInit,
    chatInitRes,
    chatReply,
    chatReplyRes,
    chatRewriteDescr,
    chatRewriteDescrRes,
    createTargetingInfo,
    acceptOrRejectTargetDescr,
    acceptOrRejectTargetDescrRes,
    createTargetingInfoResult,
    getCompaniesForLabeling,
    handleGetLabelingCompanies,
    getCompaniesForLabelingResult,
    updatePersona,
    updatePersonaResult,
    handleCreateTargetingInfo,
    updateCampaign,
    updateCampaignResult,
    handlePersonaCreateOrUpdate,
    createPersonaResult,
    loading:
      createPersonaResult.loading ||
      updatePersonaResult.loading ||
      createTargetingInfoResult.loading ||
      getCompaniesForLabelingResult.loading ||
      updateCampaignResult.loading,
  }

  return <GraphQLContext.Provider value={contextValue}>{children}</GraphQLContext.Provider>
}

export const useGraphQL = (): GraphQLContextType => {
  const context = useContext(GraphQLContext)
  if (!context) {
    throw new Error('useGraphQL must be used within a GraphQLProvider')
  }
  return context
}
