import { Skeleton, Avatar, Typography, Grid } from '@mui/material'
import { FC, memo, useMemo } from 'react'

interface LabelListSkeletonProps {
  item?: number
}

const LabelListSkeleton: FC<LabelListSkeletonProps> = ({ item = 5 }) => {
  const getArray = useMemo((): number[] => {
    let result: number[] = []
    for (let i = 0; i < item; i++) {
      result.push(i)
    }
    return result
  }, [item])

  return (
    <div style={{ width: '100%' }}>
      {getArray?.map((_item, index) => (
        <Grid mb={2} key={index} container spacing={2} pr={2}>
          <Grid item xs={12} sm={3} md={3}>
            <div style={{ display: 'flex', gap: 5 }}>
              <Skeleton variant="circular" width={50} height={50}>
                <Avatar />
              </Skeleton>
              <div style={{ width: '100%' }}>
                <Skeleton variant="rectangular" width="100%" height={10}>
                  <Typography>.</Typography>
                </Skeleton>
                <br />
                <Skeleton variant="rectangular" width="100%" height={10}>
                  <Typography>.</Typography>
                </Skeleton>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Skeleton variant="rectangular" width="100%" height={60}>
              <Typography>.</Typography>
            </Skeleton>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Skeleton variant="rectangular" width="100%" height={60}>
              <Typography>.</Typography>
            </Skeleton>
          </Grid>
        </Grid>
      ))}
    </div>
  )
}

export default memo(LabelListSkeleton)
