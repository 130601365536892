import { StyleSheet } from 'aphrodite'
import { blue, mainGreen, note } from '../../shared/colors'

const styles = StyleSheet.create({
  salezillaLogo: {
    height: 49,
    width: 196,
    marginTop: 30,
    marginLeft: 20,
    opacity: 0.8,
    '@media (min-width: 600px)': {
      marginLeft: 20,
    },
    '@media (min-width: 900px)': {
      marginLeft: 30,
    },
    '@media (min-width: 1200px)': {
      marginLeft: 40,
    },
    '@media (min-width: 1536px)': {
      marginLeft: 50,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    overflow: 'hidden',
    // marginBottom: '100px',
    // marginTop: '117.84px',
    // marginLeft: '72px',
    // marginRight: '100px',
  },
  contentContainer: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',

    '@media (max-width: 765px)': {
      width: '100%',
    },
    '@media (min-width: 900px)': {
      width: 344,
    },
    '@media (min-width: 1200px)': {
      width: 444,
    },
    '@media (min-width: 1536px)': {
      width: 544,
    },
  },
  forgetPasswordText: {
    color: note,
  },
  textCenter: {
    textAlign: 'center',
    marginTop: '50px',
  },
  textLeft: {
    textAlign: 'left',
  },
  illustrationContentContainer: {
    flexGrow: 1,
  },
  illustrationContainer: {
    display: 'flex',
    flexDirection: 'column',
    // marginTop: '13vh',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90%',
    overflow: 'hidden',
  },
  textFont: {
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#FFF',
  },
  mobileHeading: {
    fontSize: '20px',
  },
  mobileAlign: {
    textAlign: 'left',
    margin: 0,
  },
  firstHeading: {
    width: '495px',
    color: '#FFF',
    textAlign: 'center',
    lineHeight: 'normal',
    fontSize: '14px',
    fontWeight: 400,
  },
  bookDemoButton: {
    marginTop: '14px',
    color: '#FFF',
    textTransform: 'none',
    borderRadius: '30px',
    border: '2px solid #FFF',
    width: 'max-content',
    padding: '15px 24px',
    fontWeight: 600,
    fontSize: '16px',
    ':hover': {
      color: '#4750F0',
      backgroundColor: '#FFF',
    },
  },
  illustrationText: {
    color: mainGreen,
    fontSize: 30,
    marginLeft: 35,
  },
  inputLabel: {
    color: '#31333A',
    fontSize: '14px',
    fontWeight: 600,
  },
  pageTitleText: {
    // display: 'flex',
    width: 'max-content',
    margin: 'auto',
    fontSize: 36,
    fontWeight: 600,
    color: '#4750F0',
    textAlign: 'center',
    fontFamily: 'Poppins',
    marginBottom: 48,
    '@media (max-width: 1199px)': {
      marginBottom: 24,
      fontSize: 36,
    },
  },
  resetPasswordButton: {
    color: blue,
    fontWeight: 600,
    ':hover': {
      cursor: 'pointer',
    },
    textDecoration: 'none',
  },
  signupContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  signupContainerText: {
    color: mainGreen,
  },
  signupORText: {
    padding: 20,
    fontSize: 24,
    fontWeight: 600,
    textAlign: 'center',
  },
  signupText: {
    fontWeight: 600,
    ':hover': {
      cursor: 'pointer',
    },
    textDecoration: 'underline',
  },
  signUpWithButtons: {
    paddingBottom: 10,
  },
  buttonStyle: {
    textTransform: 'none',
    color: 'black',
    width: '100%',
    fontSize: 18,
    padding: '8px 20px',
    fontWeight: 600,
    borderRadius: '8px',
    border: '1px solid #E5E5E5',
    background: '#FFF',
  },
  signupdescription: {
    color: '#1e7e73',
    fontSize: 12,
    display: 'flex',
  },
  signupdescriptiontext: {
    paddingLeft: 10,
  },
  termcheckbox: {
    paddingTop: 0,
  },
  loadingOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
})

export default styles
