import { useEffect, useState } from 'react'

import { ManagementLevel } from '../../../../graphql/generated'
import SelectFormControl from './SelectFormControl'
import { useCampaignAndUser } from '../../../NewCampaignLayout'
import { WidgetMessage } from '../..'
import { isEqual } from 'lodash'

export const managementLevelValues = [
  ManagementLevel.Owner,
  ManagementLevel.Founder,
  ManagementLevel.Csuite,
  ManagementLevel.Partner,
  ManagementLevel.Vp,
  ManagementLevel.Head,
  ManagementLevel.Director,
  ManagementLevel.Manager,
  ManagementLevel.Senior,
  ManagementLevel.Entry,
  ManagementLevel.Intern,
]

const ManagementLevelSelector = (props: { handleTranslate: (widgetMessage?: WidgetMessage) => Promise<void> }) => {
  const { campaign } = useCampaignAndUser()
  const selectedPersona = campaign?.personas?.[0]
  const { handleTranslate } = props

  const [managementLevels, setManagementLevels] = useState<ManagementLevel[]>([])

  useEffect(() => {
    if (selectedPersona?.managementLevels) {
      setManagementLevels((selectedPersona.managementLevels as ManagementLevel[]) ?? [])
    }
  }, [setManagementLevels, selectedPersona?.managementLevels])

  return (
    <div
      style={{
        width: '100%',
        padding: '12px 8px',
        borderRadius: '16px',
        background: '#FFF',
      }}
    >
      <SelectFormControl
        title="Management Levels*"
        labelId="multiple-management-level-label"
        selectId="multiple-management-level"
        values={managementLevels}
        errorCondition={false}
        errorMessage="Please select management levels"
        placeholder="Please select management levels"
        bg="#5F69C1"
        color="white"
        onChange={(value: any) => {
          setManagementLevels(value)
        }}
        onClose={() => {
          const selectedValues = managementLevels?.join(', ')
          if (selectedValues && !isEqual(selectedPersona?.managementLevels, managementLevels)) {
            handleTranslate({
              message: `Update Management Level: ${selectedValues}`,
              type: 'not_to_show',
              value: managementLevels,
              widget: 'management_level',
            })
          }
        }}
        valueMap={ManagementLevel}
        items={managementLevelValues}
      />
    </div>
  )
}

export default ManagementLevelSelector
