import React, { useEffect, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab, { TabProps } from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ProfileForm from './Profile/Profile'
import Subscription from './Subscription/Subscription'
import { useLocation, useNavigate } from 'react-router'
import { Link, LinkProps } from 'react-router-dom'
import CompanyAdminSetting from './AdminSetting/Admin'
import { useAuth } from '../../hooks/useAuth'
import MobileNavBar from '../../components/MobileNavBar'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

interface LinkTabProps extends Omit<TabProps, 'component'> {
  to: string
}

const LinkTab: React.FC<LinkTabProps> = (props) => {
  const { to, ...tabProps } = props

  const CustomLink = React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'innerRef' | 'to'>>((itemProps, ref) => (
    <Link to={to} ref={ref} {...itemProps} />
  ))

  return <Tab component={CustomLink as any} {...tabProps} />
}

export default function BasicTabs() {
  const [value, setValue] = useState(0)
  const location = useLocation()
  const navigate = useNavigate()
  const { pages, isMobile } = useAuth()

  const page = new URLSearchParams(location.search).get('page')

  useEffect(() => {
    if (location.pathname === '/settings' && location.search === '') {
      navigate('/settings?page=profile')
    }
  }, [location, navigate])

  useEffect(() => {
    if (page === 'profile') {
      setValue(0)
    } else if (page === 'admin-setting') {
      setValue(1)
    } else if (page === 'subscription') {
      setValue(2)
    }
  }, [page])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isMobile && <MobileNavBar pages={pages} />}
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          sx={{ '& .MuiTabs-scrollButtons': { display: 'flex !important' } }}
        >
          <LinkTab label="Profile" to="/settings?page=profile" />
          <LinkTab label="Admin Setting" to="/settings?page=admin-setting" />
          <LinkTab label="Subscription" to="/settings?page=subscription" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ProfileForm />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CompanyAdminSetting />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Subscription />
      </TabPanel>
      {/* <TabPanel value={value} index={3}>
        Payment
      </TabPanel> */}
    </Box>
  )
}
