import { FC } from 'react'
import { GetCompaniesForLabeling } from '../../../../graphql/generated'
import { css } from 'aphrodite'
import styles from '../style'
import { Button, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import ListItem from '../ListItem'
import InfoToolTip from '../../TargetingInput/InfoToolTip/InfoToolTip'

interface CompanyTableProps {
  companiesForLabeling: GetCompaniesForLabeling[]
  is_completed: boolean
  handleAccurateTooltip: () => void
  tableRef: React.RefObject<HTMLDivElement>
  open: boolean
  loading?: boolean
  handleTooltipClose: () => void
  handleTooltipOpen: () => void
  showActionButtons?: boolean
}

const CompanyTable: FC<CompanyTableProps> = ({
  companiesForLabeling,
  is_completed,
  handleAccurateTooltip,
  tableRef,
  open,
  handleTooltipClose,
  handleTooltipOpen,
  loading,
  showActionButtons = true,
}) => {
  return (
    <div className={css(styles.tableWrapper)}>
      <Grid container className={css(styles.LabelCompanyHeader)}>
        <Grid item sm={3}>
          <Typography className={css(styles.CompayFrame)} component="div">
            Company
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography className={css(styles.DescriptionHeading)}>Description</Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography className={css(styles.ButtonHeading)}>Validate & Refine Ai prediction</Typography>
        </Grid>
      </Grid>
      <Divider />
      <div
        ref={tableRef}
        className="hide-scrollbar"
        style={{
          maxHeight: window.innerHeight < 900 ? '430px' : '665px',
          overflowY: 'scroll',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        {showActionButtons && (
          <div
            style={{
              marginBottom: 10,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <InfoToolTip
              text={`Please finish labeling all ${companiesForLabeling?.length} companies before getting new company examples.`}
              open={open}
              handleTooltipClose={handleTooltipClose}
            >
              <Button
                disabled={loading || !is_completed}
                onClick={handleTooltipOpen}
                variant="outlined"
                className={css(styles.getMoreLabelsButton)}
                onBlur={handleTooltipClose}
              >
                {loading ? (
                  <>
                    <span>Loading... </span> <CircularProgress color="primary" size={20} />
                  </>
                ) : (
                  'Teach Rex with more examples'
                )}
              </Button>
            </InfoToolTip>
          </div>
        )}
        <ListItem
          companiesForLabeling={companiesForLabeling}
          is_completed={is_completed}
          handleAccurateTooltip={handleAccurateTooltip}
        />
        <br />
        <br />
      </div>
    </div>
  )
}

export default CompanyTable
