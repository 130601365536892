import { useLazyQuery, useMutation } from '@apollo/client'
import { FC, memo, useEffect, useMemo } from 'react'
import { Chip, Typography } from '@mui/material'
import { css } from 'aphrodite'
import moment from 'moment'

import {
  Campaign,
  CreateOrupdateCampaignTransactionDocument,
  GetCampaignByIdDocument,
  GetLeadsScoreAllProgressDocument,
  GetUserByIdDocument,
} from '../../../../../graphql/generated'
import styles from './styles'
import client from '../../../../../apolloClient'
import { useAuth } from '../../../../../hooks/useAuth'
import { ReactComponent as DraftSvgIcon } from '../../../../../assets/svg/draft.svg'
import { ReactComponent as SuccessSvgIcon } from '../../../../../assets/svg/success.svg'
import { ReactComponent as WaitingSvgIcon } from '../../../../../assets/svg/waiting_1.svg'

interface DownloadCSVButtonProps {
  campaign: Campaign
  showTime?: boolean
}

type LabelStatus = {
  label: string
  color: 'secondary' | 'primary' | 'default' | 'error' | 'info' | 'success' | 'warning' | undefined
}

const StatusLabel: FC<DownloadCSVButtonProps> = ({ campaign, showTime }) => {
  const { isInDraft, gcsLeadsCsvPath, id, creatorId } = campaign
  const { user } = useAuth()

  const [getCampaignById] = useLazyQuery(GetCampaignByIdDocument, {
    fetchPolicy: 'network-only',
  })

  const [createOrupdateCampaignTransaction] = useMutation(CreateOrupdateCampaignTransactionDocument, {
    onCompleted: (data) => {
      if (user && user.company && data.createOrupdateCampaignTransaction) {
        client.cache.writeQuery({
          query: GetUserByIdDocument,
          data: {
            getUserById: {
              ...user,
              company: {
                ...user?.company,
                creditsAvailable: data.createOrupdateCampaignTransaction.creditsAvailable,
              },
            },
          },
        })
      }
    },
  })

  const [getLeadsScoreAllProgress, { loading, data, stopPolling: stopPollingGetLeadsScoreAllProgress }] = useLazyQuery(
    GetLeadsScoreAllProgressDocument,
    {
      fetchPolicy: 'network-only',
      pollInterval: 5000,
      onCompleted: async (data) => {
        if (data?.getLeadsScoreAllProgress?.is_completed && campaign?.id && campaign?.gcsLeadsCsvPath) {
          createOrupdateCampaignTransaction({
            variables: {
              data: {
                campaignId: campaign?.id,
                companyId: campaign?.companyId,
                creditsSpent: data?.getLeadsScoreAllProgress?.num_leads_grabbed,
              },
            },
          })
          getCampaignById({
            variables: {
              data: {
                id: campaign?.id,
                isDemo: false,
              },
            },
          })

          stopPollingGetLeadsScoreAllProgress()
        }
      },
    },
  )

  useEffect(() => {
    if (!isInDraft && !gcsLeadsCsvPath) {
      getLeadsScoreAllProgress({
        variables: {
          data: {
            campaignId: id,
            creatorId: creatorId,
          },
        },
      })
    }
  }, [creatorId, id, getLeadsScoreAllProgress, gcsLeadsCsvPath, isInDraft])

  const formatRemainingTime = (seconds: number) => {
    const duration = moment.duration(seconds, 'seconds')
    const hours = Math.floor(duration.asHours())
    const minutes = Math.floor(duration.asMinutes()) % 60
    const remainingSeconds = seconds % 60

    let formattedTime = ''

    if (hours > 0) {
      formattedTime = `${hours} hour ${minutes} minute remain`
    } else if (minutes > 0) {
      formattedTime = `${minutes} minute remain`
    } else if (remainingSeconds > 0) {
      formattedTime = `${remainingSeconds} second remain`
    } else if (isInDraft) {
      formattedTime = ''
    }

    return formattedTime
  }

  const getStatus: LabelStatus = useMemo(() => {
    if (isInDraft && !gcsLeadsCsvPath) {
      return { label: 'Draft', color: 'error' }
    } else if (loading) {
      return { label: 'Processing', color: 'warning' }
    } else if (gcsLeadsCsvPath) {
      return { label: 'Completed', color: 'success' }
    }

    return { label: 'Processing', color: 'warning' }
  }, [isInDraft, gcsLeadsCsvPath, loading])

  return (
    <>
      <Chip
        className={css([
          styles.activeIndicator,
          isInDraft && styles.draftIndicator,
          !isInDraft && !gcsLeadsCsvPath && styles.processIndicator,
        ])}
        label={getStatus.label}
        icon={isInDraft ? <DraftSvgIcon /> : !isInDraft && !gcsLeadsCsvPath ? <WaitingSvgIcon /> : <SuccessSvgIcon />}
        // color={getStatus.color}
      />{' '}
      <Typography pl={2} variant="body2">
        {formatRemainingTime(data?.getLeadsScoreAllProgress?.time || 0)}
      </Typography>
    </>
  )
}

export default memo(StatusLabel)
