import { css } from 'aphrodite'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import styles from './styles'
import MessageBoxChat from './MessageBox'
import SendMessage from '../../assets/icons/SendMessage'
import {
  Campaign,
  ChatConversation,
  GetCampaignByIdDocument,
  UpdateTargetingInfoDocument,
  GetCampaignStatusDocument,
  GetCompaniesForLabeling,
  ChatHistory,
  ChatReplyInput,
  RewriteDescrMessage,
} from '../../graphql/generated'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import { useAuth } from '../../hooks/useAuth'
import { useCampaignAndUser } from '../NewCampaignLayout'
import client from '../../apolloClient'
import { CircularProgress, FormControl } from '@mui/material'
import { useGraphQL } from '../../context/GraphQLContext'
import { numEmployeeRangesMap } from '../../shared/constants'
import { ChatProvider } from './context/ChatContext'
import ChatActionButton from './ChatActionButton'
import WarningIcon from '../../assets/icons/WarningIcon'
import Text from '../Text'
import ChatRexLoader from './MessageBox/ChatRexLoader'

const style = {
  input: {
    borderRadius: '12px',
    padding: '16.5px 1px 16.5px 14px',
    '&.MuiInputBase-root': {
      minHeight: '56px !important',
      height: '100% !important',
    },
    '& .MuiOutlinedInput-input': {
      paddingRight: '3rem',
    },
    '& ::-webkit-scrollbar-thumb ': {
      background: 'lightgrey',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(55, 141, 250, 0.40)',
      boxShadow: '0px 0px 15px 0px rgba(55, 141, 250, 0.19)',
    },
    ':hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(55, 141, 250, 0.40)',
        boxShadow: '0px 0px 15px 0px rgba(55, 141, 250, 0.19)',
      },
    },
  },
}

interface ChatConversationWithElements extends ChatConversation {
  element?: JSX.Element
}

export type WidgetMessage = {
  type: string
  message: string
  widget?: string
  value?: any
}

interface Args {
  personaId?: string
  userId?: string
}

const Chat = ({ personaId, userId }: Args) => {
  const { user } = useAuth()
  const { campaign } = useCampaignAndUser()
  const {
    updateCampaign,
    handleGetLabelingCompanies,
    getCompaniesForLabelingResult,
    chatInit,
    chatInitRes,
    chatReply,
    chatReplyRes,
    chatRewriteDescr,
  } = useGraphQL()
  const navigate = useNavigate()
  // TODO: Implement this
  const [showNotEnoughCompaniesWarningModal, setShowNotEnoughCompaniesWarningModal] = useState(false)
  const [showBadPromptWarningModal, setShowBadPromptWarningModal] = useState(false)

  // TODO: Make - What their company does and offers, to whom dynamic and only ask when the user hasn't already answered this question

  // Input States
  const [inputCode, setInputCode] = useState<string>('')
  const [lastMessage, setLastMessage] = useState<string>('')

  // Loading state
  const [loading, setLoading] = useState<boolean>(false)

  // TODO: Consider switching this to campaign.chatMessages
  const [conversation, setConversation] = useState<ChatConversationWithElements[]>([])

  const [isInputActive, setInputActive] = useState(true)

  const [getCampaignStatus, { loading: _loading, error: _error, data: campaignStatus }] = useLazyQuery(
    GetCampaignStatusDocument,
    {
      pollInterval: 5000,
      fetchPolicy: 'network-only',
    },
  )

  const [
    updateTargetingInfo,
    { loading: updateTargetingInfoLoading, error: updateTargetingInfoError, data: updateTargetingInfoRes },
  ] = useMutation(UpdateTargetingInfoDocument)

  const chatContainerRef = useRef<HTMLDivElement | null>(null)

  const handleKeepEditing = useCallback(() => {
    updateCampaign({
      variables: {
        data: {
          campaignId: campaign?.id as string,
          hasAssistantGivenConvoSummary: false,
        },
      },
    })
  }, [campaign?.id, updateCampaign])

  useEffect(() => {
    if (campaign?.id && !campaign?.chat_init) {
      chatInit({
        variables: {
          data: {
            campaignId: campaign?.id,
            creatorId: campaign?.creatorId,
            company_description: user?.company?.companyDescr,
            company_name: user?.company?.name,
          },
        },
      }).then(() => {
        handleKeepEditing()
        client.cache.writeQuery({
          query: GetCampaignByIdDocument,
          variables: {
            data: {
              id: campaign.id,
              isDemo: false,
            },
          },
          data: {
            getCampaignById: { ...campaign, chat_init: true },
          },
        })
      })
    }
  }, [campaign?.id, chatInit, handleKeepEditing])

  const getRewriteDescrMessages = useCallback(
    async (message?: string) => {
      if (campaign?.id) {
        setLoading(true)
        const company_descr_and_user_labels = getCompaniesForLabelingResult?.data?.getCompaniesForLabeling?.companies
          ?.filter((company: GetCompaniesForLabeling) => company?.user_label !== '')
          ?.map((company: GetCompaniesForLabeling) => ({
            company_descr: company.company_info.description,
            label: company.user_label === 'y' ? true : false,
          }))

        await chatRewriteDescr({
          variables: {
            data: {
              current_target_descr: campaign?.latestRexEditedTargetDescr,
              company_descr_and_user_labels,
              message: message,
              campaignId: campaign?.id,
            },
          },
        }).then((res) => {
          setLoading(false)
          setLastMessage('')
          const rewriteDescrMessagesHistory = res?.data?.chatRewriteDescr?.history || []
          client.cache.writeQuery({
            query: GetCampaignByIdDocument,
            variables: {
              data: {
                id: campaign.id,
                isDemo: false,
              },
            },
            data: {
              getCampaignById: {
                ...campaign,
                enableRewriteDescr: true,
                rewriteDescrMessages: rewriteDescrMessagesHistory as RewriteDescrMessage[],
              },
            },
          })
        })
        setLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatRewriteDescr, getCompaniesForLabelingResult?.data?.getCompaniesForLabeling?.companies],
  )

  useEffect(() => {
    if (
      (campaign?.rewriteDescrMessages?.length === 0 || campaign?.showInitialNextFiveCompanies) &&
      campaign?.enableRewriteDescr
    ) {
      getRewriteDescrMessages()
    }
  }, [
    campaign?.enableRewriteDescr,
    campaign?.id,
    campaign?.rewriteDescrMessages,
    campaign?.rewriteDescrMessages?.length,
    campaign?.showInitialNextFiveCompanies,
    getRewriteDescrMessages,
  ])

  const handleRewriteDescr = () => {
    if (!inputCode) {
      alert('Please enter your message.')
      return
    }

    setLoading(true)
    getRewriteDescrMessages(inputCode)
    setInputCode('')
  }

  const handleTranslate = useCallback(
    async (widgetMessage?: WidgetMessage) => {
      if (!campaign) return
      const user_company_description = chatReplyRes?.data?.chatReply?.summary?.user_company_description

      const companySize =
        widgetMessage?.widget === 'company_size'
          ? widgetMessage?.value
          : campaign?.personas?.[0]?.numEmployeeRanges?.length
          ? campaign?.personas?.[0]?.numEmployeeRanges?.map(
              (value) => numEmployeeRangesMap[`${value?.[0].toUpperCase()}${value?.slice(1)}`],
            )
          : null
      const companyLocation =
        widgetMessage?.widget === 'company_location'
          ? widgetMessage?.value
          : campaign?.personas?.[0]?.hqLocations?.length
          ? campaign?.personas?.[0]?.hqLocations?.map((location) => ({
              country: location?.country as string,
              region: location?.region,
              locality: location?.locality,
            }))
          : null
      const titleKeyWords = campaign?.personas?.[0]?.exampleTitles?.length
        ? campaign?.personas?.[0]?.exampleTitles
        : null
      const excluded_services = campaign?.excluded_services ? campaign?.excluded_services : null
      const managementLevels =
        widgetMessage?.widget === 'management_level'
          ? widgetMessage?.value
          : campaign?.personas?.[0]?.managementLevels?.length
          ? campaign?.personas?.[0]?.managementLevels
          : null
      const desiredNumLeads = (campaign?.desiredNumberOfLeads as number) > 0 ? campaign.desiredNumberOfLeads : null
      const personaDescr = campaign?.personas?.[0]?.descr ? campaign?.personas?.[0]?.descr : null

      const payload: ChatReplyInput = {
        campaignId: campaign?.id as string,
        creatorId: user?.id as string,
        message: widgetMessage ? widgetMessage.message : inputCode,
        type: widgetMessage?.type,
        has_user_selected_values: widgetMessage && widgetMessage?.widget !== 'job_titles' ? true : false,
        summary: {
          target_company_description: campaign.targetingDescr,
          excluded_services: excluded_services,
          company_size: companySize,
          company_location: companyLocation,
          job_titles: titleKeyWords,
          management_level: managementLevels,
          desired_num_leads: desiredNumLeads,
          user_company_description: user_company_description || user?.company?.companyDescr,
        },
        targeting_info: {
          prompt: campaign.targetingDescr,
          company_filter: {
            size: companySize,
            hq_locations: companyLocation,
          },
          lead_filter: {
            query: personaDescr,
            title_keywords: titleKeyWords,
            level: managementLevels,
            location: companyLocation,
          },
          desired_num_leads: desiredNumLeads,
        },
      }
      if (!inputCode && !widgetMessage?.message) {
        alert('Please enter your message.')
        return
      }

      setLoading(true)

      chatReply({
        variables: {
          data: { ...payload },
        },
      }).then((res) => {
        setLoading(false)
        setLastMessage('')
        updateTargetingInfo({
          variables: {
            data: {
              campaignId: campaign.id,
            },
          },
        })
        if (res?.data?.chatReply?.is_completed) {
          getCampaignStatus({
            variables: {
              data: {
                campaignId: campaign.id,
                creatorId: campaign?.creatorId,
              },
            },
          })
        }
        if (res?.data?.chatReply && res?.data.chatReply.campaign) {
          const { campaign } = res.data.chatReply
          client.cache.writeQuery({
            query: GetCampaignByIdDocument,
            variables: {
              data: {
                id: campaign.id,
                isDemo: false,
              },
            },
            data: {
              getCampaignById: {
                ...res.data.chatReply.campaign,
              } as Campaign,
            },
          })
        }
      })

      // -------------- Fetch --------------

      if (!personaId || !userId) {
        setLoading(false)
        alert('Filters not setup')
        return
      }

      setInputCode('')
    },
    [campaign, chatReply, inputCode, personaId, user?.id, userId],
  )

  useEffect(() => {
    setInputActive(true) // Re-activate input when inputCode changes
  }, [inputCode])

  const handleChange = (Event: any) => {
    setInputCode(Event.target.value)
    setLastMessage(Event.target.value)
  }

  const onInputKeyPress = (Event: any) => {
    if (Event.key === 'Enter' || Event.keyCode === 13) {
      Event.preventDefault() // Prevent Enter key default behavior
      if (inputCode) {
        if (campaign?.enableRewriteDescr) {
          handleRewriteDescr()
          return
        } else {
          handleTranslate()
        }
      }
      setInputActive(false) // Deactivate input after sending
    }
  }

  const handleLabelCompanies = async () => {
    if (campaign) {
      await updateTargetingInfo({
        variables: {
          data: {
            campaignId: campaign.id,
            firstStepCompleted: true,
          },
        },
      })

      handleGetLabelingCompanies({ campaign, getMoreCompanies: true })
      localStorage.removeItem('is_completed')

      navigate({
        pathname: '/new-campaign/teaching-and-grading',
        search: `?campaignId=${campaign?.id}`,
      })
    }
  }

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const scrollHeight = chatContainerRef.current.scrollHeight
      const height = chatContainerRef.current.clientHeight
      const maxScrollTop = scrollHeight - height
      chatContainerRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
    }
  }

  useEffect(() => {
    if (campaign && campaign.hasInitialGetCandidateCompaniesBeenFired) {
      const isCompleted = localStorage.getItem('is_completed')
      handleGetLabelingCompanies({ campaign, getMoreCompanies: isCompleted !== 'true', order: 'asc' })
      getCampaignStatus({
        variables: {
          data: {
            campaignId: campaign.id,
            creatorId: campaign?.creatorId,
          },
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      campaign &&
      campaign.dbSize !== null &&
      campaign.dbSize !== undefined &&
      campaign.dbSize < 1000 &&
      !campaign.notEnoughCompaniesModalShown
    ) {
      setShowNotEnoughCompaniesWarningModal(true)
    }
  }, [campaign, campaign?.dbSize, campaign?.notEnoughCompaniesModalShown, conversation])

  useEffect(() => {
    if (
      getCompaniesForLabelingResult?.data?.getCompaniesForLabeling?.is_completed &&
      campaignStatus?.getCampaignStatus.is_prompt_bad &&
      !campaign?.badPromptModalShown
    ) {
      setShowBadPromptWarningModal(true)
    }
  }, [
    campaign?.badPromptModalShown,
    campaignStatus?.getCampaignStatus.is_prompt_bad,
    conversation,
    getCompaniesForLabelingResult?.data?.getCompaniesForLabeling?.is_completed,
  ])

  let warningNotice = null

  if (campaign && campaign.dbSize !== null && campaign.dbSize !== undefined && campaign.dbSize < 1000) {
    warningNotice = (
      <div className={css(styles.warningMsgContainer)}>
        <WarningIcon />
        <Text extraStyles={[styles.warningMsgTxt]}>
          <span className={css(styles.warningMsgTitleTxt)}>Not Enough Companies</span> There are limited companies that
          match your current filters. Please adjust company filters to enhance the number of potential matches.
        </Text>
      </div>
    )
  } else if (
    getCompaniesForLabelingResult.data?.getCompaniesForLabeling?.is_completed &&
    campaignStatus?.getCampaignStatus.is_prompt_bad
  ) {
    warningNotice = (
      <div className={css(styles.warningMsgContainer)}>
        <WarningIcon />
        <Text extraStyles={[styles.warningMsgTxt]}>
          <span className={css(styles.warningMsgTitleTxt)}>Please Change the Target Company Description</span> Target
          company description is ambiguous or too narrow, consider changing the target company description.
        </Text>
      </div>
    )
  }

  const conversations = useMemo(() => {
    const additionalMessage =
      !campaign?.enableRewriteDescr && lastMessage && !inputCode
        ? [{ role: 'user', content: lastMessage, type: '' }]
        : []
    const chatMessages = (campaign?.chatMessages as ChatHistory[]) || chatInitRes?.data?.chatInit?.history || []

    return [...chatMessages, ...additionalMessage]
  }, [
    campaign?.enableRewriteDescr,
    campaign?.chatMessages,
    lastMessage,
    inputCode,
    chatInitRes?.data?.chatInit?.history,
  ])

  const rewriteDescrMessages = useMemo(() => {
    const additionalMessage =
      campaign?.enableRewriteDescr && lastMessage && !inputCode
        ? [{ id: '', role: 'user', content: lastMessage, type: '' }]
        : []
    const rewriteMessages = (campaign?.rewriteDescrMessages as RewriteDescrMessage[]) || []

    return [...rewriteMessages, ...additionalMessage]
  }, [campaign?.enableRewriteDescr, campaign?.rewriteDescrMessages, inputCode, lastMessage])

  useEffect(() => {
    scrollToBottom()
  }, [conversations, rewriteDescrMessages, loading])

  return (
    <ChatProvider>
      <div className={css(styles.container)}>
        {/* Main Box */}
        <>
          <div ref={chatContainerRef} className={`hide-scrollbar ${css(styles.mainBoxContainer)}`}>
            <div className={css(styles.messagesContainer)}>
              {conversations?.map(
                (message, index) =>
                  message?.role !== 'system' &&
                  message?.type !== 'not_to_show' &&
                  message?.content && (
                    <MessageBoxChat
                      key={index}
                      handleTranslate={handleTranslate}
                      setInputCode={setInputCode}
                      desiredNumberOfLeads={campaign?.desiredNumberOfLeads || 0}
                      message={message}
                    />
                  ),
              )}

              {rewriteDescrMessages?.map(
                (message, index) =>
                  message?.role !== 'system' &&
                  message?.type !== 'not_to_show' &&
                  message?.content && (
                    <MessageBoxChat
                      key={index}
                      handleTranslate={handleTranslate}
                      setInputCode={setInputCode}
                      desiredNumberOfLeads={campaign?.desiredNumberOfLeads || 0}
                      message={message as ChatHistory}
                      messageId={message?.id}
                    />
                  ),
              )}
              {loading && <ChatRexLoader />}
            </div>
          </div>
        </>
        {!!warningNotice && warningNotice}
        {/* Chat Input */}
        <div className={css(styles.inputContainer)}>
          {(!campaign?.hasAssistantGivenConvoSummary || campaign?.enableRewriteDescr) && (
            <FormControl className={css(styles.formControl)}>
              <OutlinedInput
                maxRows={8}
                multiline
                id="chat-input"
                endAdornment={
                  <InputAdornment
                    position="end"
                    sx={{
                      position: 'absolute',
                      right: '15px',
                      bottom: '28px',
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <SendMessage
                        active={!!inputCode}
                        onClick={() => {
                          if (inputCode) {
                            if (campaign?.enableRewriteDescr) {
                              handleRewriteDescr()
                              return
                            }
                            handleTranslate()
                          }
                        }}
                      />
                    )}
                  </InputAdornment>
                }
                aria-describedby="chat-input-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                autoFocus
                placeholder="Type your message here..."
                value={inputCode}
                onChange={handleChange}
                onKeyDown={onInputKeyPress}
                sx={style.input}
              />
            </FormControl>
          )}
          {campaign?.hasAssistantGivenConvoSummary && !campaign?.enableRewriteDescr && (
            <ChatActionButton
              loading={updateTargetingInfoLoading}
              handleKeepEditing={handleKeepEditing}
              handleLabelCompanies={handleLabelCompanies}
              isPromptBad={campaignStatus?.getCampaignStatus.is_prompt_bad}
            />
          )}
        </div>
      </div>
    </ChatProvider>
  )
}

export default Chat
