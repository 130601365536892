import { StyleSheet } from 'aphrodite'
import {
  body,
  mainGreen,
  note,
  lightBlueGreen,
} from '../../../shared/colors'

const styles = StyleSheet.create({
  contentBulletText: {
    color: body,
    fontWeight: 400,
    marginBottom: 6,
  },
  contentContainer: {
    marginTop: 18,
    whiteSpace: 'normal',
  },
  contentSubtitleText: {
    color: lightBlueGreen,
    fontWeight: 400,
    marginBottom: 8,
  },
  contentTitleText: {
    color: mainGreen,
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 8,
  },
  dropdownContainer: {
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      cursor: 'pointer',
    },
  },
  mainText: {
    color: mainGreen,
  },
  mainTextSmall: {
    fontSize: 10.92,
  },
  noteText: {
    color: note,
    fontSize: 12,
  },
  noteTextSmall: {
    fontSize: 9.36,
  },
})

export default styles
