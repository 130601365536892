import { Button } from '@mui/material'
import InfoToolTip from '../TargetingInput/InfoToolTip/InfoToolTip'
import { useGraphQL } from '../../../context/GraphQLContext'
import { Campaign } from '../../../graphql/generated'
import { useState, useEffect } from 'react'
import styles from './style'
import { css } from 'aphrodite'
import { useTooltip } from './TooltipContext/TooltipContext'

const INITIAL_TOOLTIP =
  'The purple label is my prediction of whether this company matches your target description. Please label each company and correct my mistakes, so I can get you better results.'

type ButtonInput = {
  isActive?: boolean
  tooltip?: string
  icon: string
  campaign: Campaign
  onClick: () => void
  handleAccurateTooltip: () => void
  firstActiveTooltip?: boolean
  initialTooltip?: boolean
  companyIndex?: number
}

const ButtonRenderer: React.FC<ButtonInput> = ({
  isActive,
  tooltip,
  icon,
  onClick,
  campaign,
  firstActiveTooltip,
  handleAccurateTooltip,
  initialTooltip,
  companyIndex,
}) => {
  const { updateCampaign } = useGraphQL()
  const [open, setOpen] = useState(false)
  const { openTooltipId, setOpenTooltipId } = useTooltip()
  const [hideButton, setHideButton] = useState(false)
  let timeout: ReturnType<typeof setTimeout>

  useEffect(() => {
    if (!tooltip) {
      setOpen(false)
    }
  }, [tooltip])

  useEffect(() => {
    if (initialTooltip) {
      setOpen(true)
      setOpenTooltipId(0)
    }
  }, [companyIndex, initialTooltip, setOpenTooltipId])

  useEffect(() => {
    if (firstActiveTooltip && !campaign?.hasFirstRexDisagreeNoticeBeenShown && tooltip) {
      setOpen(true)
      localStorage.setItem('firstActiveItem', 'true')
      setOpenTooltipId(companyIndex)
      updateCampaign({
        variables: {
          data: {
            campaignId: campaign?.id as string,
            hasFirstRexDisagreeNoticeBeenShown: true,
          },
        },
      })
    }
  }, [
    campaign?.hasFirstRexDisagreeNoticeBeenShown,
    campaign?.id,
    companyIndex,
    firstActiveTooltip,
    setOpenTooltipId,
    tooltip,
    updateCampaign,
  ])

  const handleTooltipClose = () => {
    clearTimeout(timeout)
    setOpen(false)
    setOpenTooltipId(null)
    if (!campaign.hasFirstRexOnboardingNoticeBeenShown) {
      updateCampaign({
        variables: {
          data: {
            campaignId: campaign?.id as string,
            hasFirstRexOnboardingNoticeBeenShown: true,
          },
        },
      })
      handleAccurateTooltip()
    }
  }

  const handleTooltipOpen = () => {
    const firstActiveItem = localStorage.getItem('firstActiveItem')
    if (
      tooltip &&
      campaign.hasFirstRexDisagreeNoticeBeenShown &&
      campaign.hasFirstRexOnboardingNoticeBeenShown &&
      firstActiveItem !== 'true'
    ) {
      setHideButton(true)
      timeout = setTimeout(() => {
        setOpen(true)
        setOpenTooltipId(companyIndex)
      }, 1000)
    }
  }

  const handleMouseLeave = () => {
    const firstActiveItem = localStorage.getItem('firstActiveItem')
    if (firstActiveItem !== 'true') {
      clearTimeout(timeout)
      if (campaign.hasFirstRexDisagreeNoticeBeenShown && campaign.hasFirstRexOnboardingNoticeBeenShown) {
        setOpen(false)
      }
    }
  }

  const tooltipText = !campaign?.hasFirstRexOnboardingNoticeBeenShown ? INITIAL_TOOLTIP : (tooltip as string)
  return (
    // <InfoToolTip
    //   text={tooltipText}
    //   hideButton={hideButton}
    //   handleTooltipClose={handleTooltipClose}
    //   open={open && openTooltipId === companyIndex && !!tooltipText}
    // >
    <Button
      sx={{
        borderColor: '#E5E5E5',
        color: 'transparent !important',
        ':hover': {
          background: 'none',
        },
      }}
      // {...(isActive && { className: css(styles.active) })}
      onClick={onClick}
      onMouseEnter={handleTooltipOpen}
      onMouseLeave={handleMouseLeave}
    >
      {/* {tooltip && <span className={css(styles.toolTipSpan)} />} */}
      <img src={icon} alt={icon} />
    </Button>
    // </InfoToolTip>
  )
}

export default ButtonRenderer
