import { FunctionComponent } from 'react'
import styles from './style'
import { css } from 'aphrodite'
import Text from '../Text'
import WarningIcon from '../../assets/icons/WarningIcon'

interface WarningNoticeProps {
  heading?: string
  message: string
}

const WarningNotice: FunctionComponent<WarningNoticeProps> = ({ heading, message }) => {
  return (
    <div className={css(styles.warningMsgContainer)}>
      <WarningIcon />
      <Text extraStyles={[styles.warningMsgTxt]}>
        {heading && <span className={css(styles.warningMsgTitleTxt)}>{heading}</span>} {message}
      </Text>
    </div>
  )
}

export default WarningNotice
