import { FC, useState } from 'react'
import { ReactComponent as PencilSvgIcon } from '../../../assets/svg/edit_pencil.svg'
import { TextField, Typography } from '@mui/material'
import { css } from 'aphrodite'
import styles from './styles'
import { useGraphQL } from '../../../context/GraphQLContext'
import MobileNavBar from '../../../components/MobileNavBar'
import { useAuth } from '../../../hooks/useAuth'

interface CampaignNameProps {
  name: string
  campaignId: string
}

const CampaignName: FC<CampaignNameProps> = ({ name, campaignId }) => {
  const { updateCampaign } = useGraphQL()
  const { isMobile, pages } = useAuth()

  const [isEditing, setIsEditing] = useState(false)
  const [campaignName, setCampaignName] = useState(name || '')

  const handleEditCampaignName = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsEditing(true)
  }

  const handleUpdateCampaignName = () => {
    if (campaignId) {
      updateCampaign({
        variables: {
          data: {
            campaignId: campaignId,
            campaignName: campaignName,
          },
        },
      })
    }
    setIsEditing(false)
  }

  const handleUpdateCampaignNameOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleUpdateCampaignName()
    }
  }
  return (
    <div className={css(styles.campaignName)}>
      {isMobile && <MobileNavBar pages={pages} />}
      {isEditing ? (
        <>
          <TextField
            id="campaignName"
            name="campaignName"
            value={campaignName}
            onChange={(e) => {
              setCampaignName(e.target.value)
            }}
            onBlur={handleUpdateCampaignName}
            autoFocus
            onKeyDown={handleUpdateCampaignNameOnKeyDown}
            sx={{
              '& .MuiInputBase-root': {
                fontSize: 20,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          />
        </>
      ) : (
        <>
          <Typography
            my={2}
            variant="h6"
            onClick={handleEditCampaignName}
            style={{
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
            }}
          >
            {name || 'New leads'}
          </Typography>
          <span onClick={handleEditCampaignName} style={{ cursor: 'pointer', marginTop: '5px' }}>
            <PencilSvgIcon />
          </span>
        </>
      )}
    </div>
  )
}

export default CampaignName
