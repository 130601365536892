import React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import DialogTitle from '@mui/material/DialogTitle'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '.MuiDialog-paper': {
    display: 'flex',
    width: '100%',
    padding: '80px 48px 64px 48px',
    flexDirection: 'column',
    alignItems: 'center',
    // gap: '36px',
    borderRadius: '24px',
    background: '#FFF',
    boxShadow: '0px 4px 101px 0px rgba(131, 131, 131, 0.13)',
  },
  //  sx={{
  //       display: 'flex',
  //       width: '578px',
  //       padding: '80px 48px 64px 48px',
  //       flexDirection: 'column',
  //       alignItems: 'center',
  //       gap: '36px',
  //       borderRadius: '24px',
  //       background: '#FFF',
  //       boxShadow: '0px 4px 101px 0px rgba(131, 131, 131, 0.13)',
  //     }}
}))

interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2, '&.MuiDialogTitle-root': { padding: 0 } }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface DefaultContentInput {
  middleIcon?: any
  centerHeading?: string | JSX.Element
  subHeading?: string | JSX.Element
  subHeading2?: string | JSX.Element
  description?: string | JSX.Element
  button?: any
}

const DefaultContent: React.FC<DefaultContentInput> = ({
  middleIcon,
  centerHeading,
  subHeading,
  subHeading2,
  description,
  button,
}) => (
  <DialogContent dividers style={{ borderTop: 0, borderBottom: 'none' }}>
    {middleIcon && <div> {middleIcon}</div>}
    {centerHeading && <div>{centerHeading}</div>}
    {subHeading2 && <div>{subHeading2}</div>}
    {subHeading && <div>{subHeading}</div>}
    {description && <div>{description}</div>}
    {button && <div>{button}</div>}
  </DialogContent>
)

interface InfoModalInput {
  open: boolean
  handleClose: () => void
  title?: string | JSX.Element
  children?: React.ReactNode
  defaultContentInput?: DefaultContentInput
}

const InfoModal: React.FC<InfoModalInput> = ({ open, handleClose, title, children, defaultContentInput }) => {
  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </BootstrapDialogTitle>
      {children || <DefaultContent {...defaultContentInput} />}
    </BootstrapDialog>
  )
}

export default InfoModal
