import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  pageTitle: {
    fontSize: 30,
    marginBottom: 10,
  },
})

export default styles
