import { useLazyQuery, useMutation } from '@apollo/client'
import { Typography, CircularProgress, Button } from '@mui/material'
import { css } from 'aphrodite'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { useCampaignAndUser } from '../../../components/NewCampaignLayout'
import {
  GetCampaignStatusRes,
  GetLeadsReviewProgressDocument,
  Lead,
  StartLeadsReviewDocument,
} from '../../../graphql/generated'
import InfoToolTip from '../TargetingInput/InfoToolTip/InfoToolTip'
import styles from './style'
import LeadsWarningModal from '../TeachingAndGrading/LeadsWarningModal/LeadsWarningModal'
import LeadsResultPreview from '../TeachingAndGrading/LeadsResultPreview/LeadsResultPreview'

interface LabelListHeaderProps {
  campaignStatus?: GetCampaignStatusRes
  is_completed?: boolean
  openAccurateTooltip: boolean
  totalLabeledCompanies: number
  handleTooltipClose: () => void
}

const LabelListHeader: FC<LabelListHeaderProps> = ({
  campaignStatus,
  is_completed,
  totalLabeledCompanies,
  openAccurateTooltip,
  handleTooltipClose,
}) => {
  const { campaign } = useCampaignAndUser()
  const [showModal, setShowModal] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [openTooltip, setOpenTooltip] = useState(false)

  const handleButtonTooltip = useCallback(() => {
    setOpenTooltip(!openTooltip)
  }, [openTooltip])

  const [
    getLeadsReviewProgress,
    { loading: getLeadsReviewProgressLoading, data: getLeadsReviewProgressRes, stopPolling },
  ] = useLazyQuery(GetLeadsReviewProgressDocument, {
    fetchPolicy: 'network-only',
    pollInterval: 5000,
    onCompleted: (data) => {
      if (
        data?.getLeadsReviewProgress?.is_completed &&
        data?.getLeadsReviewProgress?.leads &&
        data?.getLeadsReviewProgress?.leads?.length >= 10
      ) {
        stopPolling()
        setShowModal(true)
      } else {
        setShowWarningModal(true)
      }
    },
  })

  const [startLeadsReview] = useMutation(StartLeadsReviewDocument, {
    onCompleted: (data) => {
      if (campaign) {
        getLeadsReviewProgress({
          variables: {
            data: {
              campaignId: campaign.id,
              creatorId: campaign.creatorId,
            },
          },
        })
      }
    },
  })

  const handleClick = useCallback(() => {
    if (campaign) {
      startLeadsReview({
        variables: {
          data: {
            campaignId: campaign.id,
            creatorId: campaign.creatorId,
          },
        },
      })
    }
  }, [campaign, startLeadsReview])

  const handleAccept = useCallback(() => {
    setShowModal((prevState) => !prevState)
  }, [])

  const handleWarningModal = useCallback(() => {
    setShowWarningModal((prevState) => !prevState)
  }, [])

  const handleButtonClick = useCallback(() => {
    if ((campaignStatus?.accurate_leads as number) < 100 && totalLabeledCompanies < 10) {
      handleButtonTooltip()
      return
    }
    handleClick()
  }, [campaignStatus?.accurate_leads, handleButtonTooltip, handleClick, totalLabeledCompanies])

  const shouldGetLeadsButtonBeDisabled =
    getLeadsReviewProgressLoading ||
    (campaign?.dbSize !== null && campaign?.dbSize !== undefined && campaign.dbSize < 1000) ||
    campaignStatus?.accurate_leads === 0

  const activateButton = useMemo(() => {
    if (
      campaignStatus?.evaluated_on_first_10_labels &&
      campaignStatus?.evaluated_on_new_label &&
      (campaign?.desiredNumberOfLeads as number) <= campaignStatus?.accurate_leads
    ) {
      return true
    }
    return false
  }, [
    campaign?.desiredNumberOfLeads,
    campaignStatus?.accurate_leads,
    campaignStatus?.evaluated_on_first_10_labels,
    campaignStatus?.evaluated_on_new_label,
  ])

  return (
    <div className={css(styles.tableHeader)}>
      <Typography className={css(styles.CompanyLabelHeading)}>Label company</Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 18,
          marginRight: '2px',
        }}
      >
        <InfoToolTip
          open={openAccurateTooltip}
          handleTooltipClose={handleTooltipClose}
          text="Accurate leads are the leads that our AI is confident that match your target segment based on the target description you’ve written and the companies you’ve labeled."
        >
          <div className={css(styles.leadsCardItem)}>
            <span className={css(styles.leadsCardItemTitle)}>Accurate leads number</span>
            <span className={css(styles.leadsCardItemValue)}>
              {campaignStatus?.accurate_leads !== undefined && campaignStatus?.accurate_leads > 0 ? (
                <>
                  {campaignStatus?.accurate_leads}{' '}
                  {(!campaignStatus?.evaluated_on_first_10_labels || !campaignStatus?.evaluated_on_new_label) && (
                    <CircularProgress style={{ marginLeft: 12 }} size="18px" />
                  )}
                </>
              ) : (
                <CircularProgress size="18px" />
              )}
            </span>
          </div>
        </InfoToolTip>
        <InfoToolTip
          open={openTooltip}
          handleTooltipClose={handleButtonTooltip}
          text="Your accurate leads number seems low. I would encourage you to label all 10 companies here to get this number higher.You can still see a preview of the leads if you’d like"
          ButtonComponent={
            <div className={css(styles.buttonComponentContanier)}>
              <Button className={css(styles.getLeadsBtn)} onClick={handleButtonTooltip}>
                Label more
              </Button>
              <Button
                className={css(styles.getLeadsBtn)}
                onClick={() => {
                  handleButtonTooltip()
                  handleClick()
                }}
              >
                Get leads
              </Button>
            </div>
          }
        >
          <Button
            disabled={shouldGetLeadsButtonBeDisabled || !campaignStatus?.evaluated_on_first_10_labels}
            onClick={handleButtonClick}
            variant="outlined"
            className={css([
              styles.outlinedButton,
              shouldGetLeadsButtonBeDisabled && styles.disabledButton,
              activateButton ? styles.activateLeadsButton : '',
            ])}
          >
            {getLeadsReviewProgressLoading ? (
              <>
                <span>Loading... </span> <CircularProgress color="primary" size={20} />
              </>
            ) : (
              'Get leads'
            )}
          </Button>
        </InfoToolTip>
      </div>
      <LeadsWarningModal
        title="Limited Results Found"
        message="There are limited leads that match your current filters.
        You can increase the lead number by relaxing role title filters or labeling more companies."
        open={showWarningModal}
        handleReject={handleWarningModal}
      />
      <LeadsResultPreview
        open={showModal}
        handleReject={handleAccept}
        campaignStatus={campaignStatus}
        loading={getLeadsReviewProgressLoading || !getLeadsReviewProgressRes?.getLeadsReviewProgress?.is_completed}
        leads={getLeadsReviewProgressRes?.getLeadsReviewProgress?.leads as Lead[]}
      />
    </div>
  )
}

export default memo(LabelListHeader)
