import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import client, { persistor } from '../apolloClient'
import {
  logInWithEmailAndPassword,
  logout as firebaseLogout,
  signInWithGoogle,
  signInWithMicrosoft,
  getRedirectResult,
  auth,
} from '../firebase'
import { useLazyQuery } from '@apollo/client'
import { GetUnonboardedUserByIdDocument, GetUserByIdDocument, User } from '../graphql/generated'
import { useLocalStorage } from './useLocalStorage'
import useNavigateToLogin from './useNavigateToLogin'
import { useMediaQuery } from '@mui/material'

interface Props {
  children: React.ReactNode
}

export interface Page {
  label: string
  path: string
  icon: React.ComponentType<any> // or the specific type of your icon component if available
  selectedIcon?: React.ComponentType<any> // Optional, based on your structure
}

export type Pages = Page[]
interface IAuth {
  isUserLoading: boolean
  user: User | null
  firebaseUser: any
  login: (email: string, password: string) => Promise<void>
  logout: () => void
  handleNavigate: (user: any) => void
  handleGoogleLogin: () => Promise<void>
  handleMicrosoftLogin: () => Promise<void>
  message: string
  setMessage: any
  pages: Pages
  setPages: React.Dispatch<React.SetStateAction<Pages>>
  isMobile: boolean
  // emailVerificationModal: boolean
  // setEmailVerificationModal: Function
}

export const AuthContext = createContext<IAuth>({} as IAuth)

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery('@media (max-width: 768px)')

  const [message, setMessage] = useState('')
  const [pages, setPages] = useState<Pages>([])
  const [firebaseUser, setFirebaseUser] = useLocalStorage('firebaseUser', null)
  const { navigateToLogin } = useNavigateToLogin()

  const [getUserById, { loading: getUserByIdLoading, error: getUserByIdError, data: getUserByIdRes }] = useLazyQuery(
    GetUserByIdDocument,
    {
      variables: {
        id: firebaseUser?.uid,
      },
      onCompleted(data) {
        if (!data.getUserById) {
          setFirebaseUser(null)
          setMessage('Account does not exist. Please create an account')
          navigate('/login')
        }
      },
      onError(error) {
        console.log('error in getUserById', error)
      },
    },
  )

  const [
    getUnonboardedUserById,
    { loading: getUnonboardedUserByIdLoading, error: getUnonboardedUserByIdError, data: getUnonboardedUserByIdRes },
  ] = useLazyQuery(GetUnonboardedUserByIdDocument, {
    variables: {
      id: firebaseUser?.uid,
    },
    onError(error) {
      console.log('error in getUnonboardedUserById', error)
    },
  })
  // const [emailVerificationModal, setEmailVerificationModal] = useState(false)

  useEffect(() => {
    if (firebaseUser) {
      getUserById()
      getUnonboardedUserById()
    }
  }, [firebaseUser, getUnonboardedUserById, getUserById])

  const login = async (email: string, password: string) => {
    try {
      const firebaseResUser = await logInWithEmailAndPassword(email, password)
      handleNavigate(firebaseResUser)
    } catch (e) {
      console.error(e)
      throw new Error(e as string)
    }
  }

  const handleNavigate = (firebaseResUser: any) => {
    if (firebaseResUser) {
      setFirebaseUser(firebaseResUser)
      getUserById()
      getUnonboardedUserById()
      navigate('/campaigns', { replace: true })
    }
  }

  const handleGoogleLogin = async () => {
    await signInWithGoogle()
  }

  const handleMicrosoftLogin = async () => {
    await signInWithMicrosoft()
  }

  const logout = async () => {
    await firebaseLogout()
    setFirebaseUser(null)
    // setUser(null)
    await client.clearStore()
    await persistor.purge()
    navigateToLogin()
  }

  return (
    <AuthContext.Provider
      value={{
        user: getUserByIdRes?.getUserById || getUnonboardedUserByIdRes?.getUnonboardedUserById || null,
        isUserLoading: getUserByIdLoading || getUnonboardedUserByIdLoading,
        login,
        logout,
        handleGoogleLogin,
        handleMicrosoftLogin,
        handleNavigate,
        // refreshUser,
        firebaseUser,
        message,
        setMessage,
        pages,
        setPages,
        isMobile,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
