import { css } from 'aphrodite'
import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import momentDurationFormatSetup from 'moment-duration-format'
import * as moment from 'moment'

import styles from './styles'
import { CreateNewPersonaDocument, UpdateCampaignDocument } from '../../../graphql/generated'
import { useCampaignAndUser } from '../../../components/NewCampaignLayout'
import Chat from '../../../components/Chat'
import { useAuth } from '../../../hooks/useAuth'
import CampaignName from '../TeachingAndGrading/CampaignName'

momentDurationFormatSetup(moment)

const TargetingInput = () => {
  const { campaign } = useCampaignAndUser()
  const { user } = useAuth()

  const [updateCampaign, { loading: updateCampaignLoading, error: updateCampaignError, data: updateCampaignRes }] =
    useMutation(UpdateCampaignDocument)

  const [createNewPersona, { loading: createPersonaLoading, error: createPersonaError, data: createPersonaData }] =
    useMutation(CreateNewPersonaDocument, {
      onCompleted: (data) => {
        updateCampaign({
          variables: {
            data: {
              campaignId: campaign?.id as string,
              personaIds: [{ id: data?.createNewPersona.id as string }],
            },
          },
        })
      },
    })

  useEffect(() => {
    if (
      !createPersonaLoading &&
      (!campaign?.personas || (campaign?.personas && campaign?.personas.length === 0)) &&
      user
    ) {
      // TODO: This is not linked to the campaign. Need to create a separate mutation for attaching a persona to a campaign
      createNewPersona({
        variables: {
          data: {
            companyId: user.companyId,
            creatorId: user.id,
            teamId: user.teamId as string,
          },
        },
      })
    }
  }, [])

  return (
    <div className={css(styles.container)}>
      <CampaignName name={campaign?.name as string} campaignId={campaign?.id as string} />
      {campaign?.personas && campaign.personas.length > 0 && !createPersonaLoading && (
        <Chat
          personaId={
            campaign?.personas && campaign.personas.length > 0 && !createPersonaLoading
              ? campaign?.personas[0]?.id
              : createPersonaData?.createNewPersona.id
          }
          userId={user?.id}
        />
      )}
    </div>
    // <TextField
    //   fullWidth
    //   margin="dense"
    //   size="small"
    //   name="campaignName"
    //   value={campaignName}
    //   disabled={isGettingCompanies}
    //   id="outlined-helperText"
    //   helperText={`Created ${campaign?.createdAt}`}
    //   placeholder="ex. Robo Advisors Enterprise Marketing Managers"
    //   variant="outlined"
    //   onChange={(e) => {
    //     setCampaignName(e.target.value)
    //     setErrorMessage('')
    //     handleCampaignUpdate(e)
    //   }}
    //   sx={{
    //     marginBottom: '20px',
    //     '& .MuiInputBase-root': {
    //       fontSize: 14,
    //     },
    //     '& .MuiFormHelperText-root': {
    //       marginLeft: 0,
    //     },
    //   }}
    // />
  )
}

export default TargetingInput
