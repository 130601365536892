import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid'
import { useEffect, useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useLazyQuery } from '@apollo/client'
import timezone from 'moment-timezone'
import { css } from 'aphrodite'
import moment from 'moment'

import { GetAllCampaignsByTeamIdDocument, Campaign as CampaignType } from '../../../../graphql/generated'
import SkeletonLoader from '../../../../components/SkeletonLoader/SkeletonLoader'
import DownloadCSVButton from './CampaignItem/DownloadCSVButton'
import CompaniesMatched from './CampaignItem/CompaniesMatched'
import MoreActionButton from './CampaignItem/MoreActionButton'
import ViewCampaign from './CampaignItem/ViewCampaign'
import CreateLeadsListBtn from './CreateLeadsListBtn'
import StatusLabel from './CampaignItem/StatusLabel'
import { useAuth } from '../../../../hooks/useAuth'
import { white } from '../../../../shared/colors'
import styles from './styles'

const style = {
  dataGridContainer: {
    '& .MuiDataGrid-row': { background: white },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-row.Mui-selected ': { background: 'rgba(105, 115, 134, 0.10)' },
    '& .MuiDataGrid-row.Mui-selected:hover ': { background: 'rgba(105, 115, 134, 0.10)' },
    '& .MuiDataGrid-columnHeaders': { background: white },
    '& .MuiDataGrid-columnSeparator': { display: 'none' },
    '& .MuiDataGrid-columnHeaderTitle': { fontSize: '12px', color: '#697386' },
    '& .MuiDataGrid-columnHeader:first-child': { padding: '0 30px 0 30px' },
    '& .MuiDataGrid-cell:first-child': { padding: '0 0 0 30px' },
    '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': { overflow: 'unset' },
    '@media (max-width:1280px)': {
      '& .MuiDataGrid-columnHeader:first-child': { padding: '0 15px 0 15px' },
      '& .MuiDataGrid-cell:first-child': { padding: '0 0 0 15px' },
      '& .MuiDataGrid-cell:last-child': { padding: '0 0 0 10px', minWidth: '190px !important' },
      '& .MuiDataGrid-row': { minWidth: '890px' },
    },
    '@media (width:1440px)': {
      '& .MuiDataGrid-cell:last-child': { padding: '0 0 0 10px', minWidth: '245px !important' },
    },
    '@media (width:1366px)': {
      '& .MuiDataGrid-cell:last-child': { padding: '0 0 0 10px', minWidth: '70px !important' },
      '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell': {
        overflow: 'unset !important',
      },
    },
  },
}

export const Campaign = () => {
  const { user } = useAuth()
  const [newCampaigns, setNewCampaigns] = useState([] as CampaignType[])

  const [getAllCampaigns, { loading, data }] = useLazyQuery(GetAllCampaignsByTeamIdDocument, {
    pollInterval: 5000,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (user) {
      getAllCampaigns({
        variables: {
          teamId: user.teamId as string,
        },
      })
    }
  }, [user, getAllCampaigns, newCampaigns])

  const campaignList = data?.getAllCampaignsByTeamId

  const getTimezone = useMemo(() => timezone.tz.guess(), [])

  const columns: GridColumns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'createdAt',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'List name',
      flex: 1.5,
      minWidth: 180,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div style={{ overflow: 'hidden' }}>
            <Typography variant={'subtitle2'} sx={{ display: 'flex', flexDirection: 'column' }}>
              {!!params.row.name
                ? params.row.name.length > 20
                  ? `${params.row.name.substring(0, 20)}...`
                  : params.row.name
                : 'New Campaign'}
              <span className={css(styles.createdAtWrapper)}>
                {moment.utc(params.row.createdAt).local().format('MM/DD/YY hh:mm A')}{' '}
                {timezone.tz(getTimezone).format('z')}
              </span>
            </Typography>
          </div>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 140,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <StatusLabel campaign={params.row} />
      },
    },
    {
      field: 'creator',
      headerName: 'Creator',
      flex: 1,
      minWidth: 132,
      renderCell: (params: GridRenderCellParams) => {
        const { creator } = params.row
        return (
          <Typography sx={{ overflow: 'hidden' }} variant={'subtitle2'}>
            {!!creator && `${creator.firstName} ${creator.lastName}`}
          </Typography>
        )
      },
    },
    {
      field: 'leadsGenerated',
      headerName: 'Leads generated',
      flex: 0.8,
      minWidth: 112,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <CompaniesMatched campaign={params.row} />
      },
    },
    {
      field: 'leadsRemain',
      headerName: 'Leads remain',
      flex: 0.7,
      minWidth: 112,
      headerClassName: css(styles.leadsRemain),
      cellClassName: css(styles.leadsRemain),
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <CompaniesMatched type="remain" campaign={params.row} />
      },
    },
    {
      field: 'manageCampaign',
      headerName: 'Manage campaign',
      flex: 1,
      minWidth: 117,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className={css(styles.manageCampaignContainer)}>
            <ViewCampaign campaign={params.row} />
            {/* <DownloadIcon /> */}
            <DownloadCSVButton campaign={params.row} />
            <MoreActionButton campaign={params.row} setNewCampaigns={setNewCampaigns} />
          </div>
        )
      },
    },
  ]

  return (
    <Grid item container sx={{ '& .MuiDataGrid-root': { border: 'none' } }}>
      <div className={css(styles.mainContainer)}>
        {loading ? (
          <SkeletonLoader />
        ) : (
          <DataGrid
            sx={style.dataGridContainer}
            rowHeight={65}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            columns={columns}
            rows={campaignList || []}
            components={{
              NoRowsOverlay: () => (
                <div className={css(styles.createLeadListBtnContainer)}>
                  <CreateLeadsListBtn />
                </div>
              ),
            }}
            initialState={{
              pagination: {
                pageSize: 10,
              },
              sorting: {
                sortModel: [{ field: 'createdAt', sort: 'desc' }],
              },
            }}
          />
        )}
      </div>
    </Grid>
  )
}
