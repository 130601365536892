import React, { memo, useEffect, useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import { Company, Team } from '../../../../graphql/generated'
import DataModal from '../../../../components/DataModal/DataModal'

interface Args {
  company: Company
}

const CompanyRow = ({ company }: Args) => {
  const [open, setOpen] = useState(false)
  const [campaignCount, setCampaignCount] = useState(0)
  const [userCount, setUserCount] = useState(0)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [infoModalType, setInfoModalType] = useState('')

  useEffect(() => {
    let newCampaignCount = 0
    let newUserCount = 0

    if (company.teams) {
      company.teams.forEach((team) => {
        if (team && team.campaigns && team.campaigns.length) {
          newCampaignCount += team.campaigns.length
        }

        if (team && team.users && team.users.length) {
          newUserCount += team.users.length
        }
      })
      setCampaignCount(newCampaignCount)
      setUserCount(newUserCount)
    }
  }, [company])

  return (
    <React.Fragment>
      <DataModal
        open={isInfoModalOpen}
        setModalOpen={setIsInfoModalOpen}
        setInfoModalType={setInfoModalType}
        infoModalType={infoModalType}
        company={company}
        team={company?.teams?.[0] as Team}
      />
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell colSpan={4}>{company.name}</TableCell>
        <TableCell
          colSpan={4}
          sx={{
            ...(company?.teams?.[0]?.campaigns &&
              company?.teams?.[0]?.campaigns.length && {
                color: '#3c52b2',
                cursor: 'pointer',
              }),
            paddingLeft: '50px',
          }}
          onClick={() => {
            if (company?.teams?.[0]?.campaigns && company?.teams?.[0]?.campaigns.length) {
              setIsInfoModalOpen(true)
              setInfoModalType('Campaigns')
            }
          }}
        >
          <h3 title="View Campaigns">{company?.teams?.[0]?.campaigns ? company?.teams?.[0]?.campaigns.length : 0}</h3>
        </TableCell>
        <TableCell
          colSpan={4}
          sx={{
            ...(company?.teams?.[0]?.users &&
              company?.teams?.[0]?.users.length && {
                color: '#3c52b2',
                cursor: 'pointer',
              }),
            paddingLeft: '40px',
          }}
          onClick={() => {
            if (company?.teams?.[0]?.users && company?.teams?.[0]?.users.length) {
              setIsInfoModalOpen(true)
              setInfoModalType('Users')
            }
          }}
        >
          <h3 title="View Users"> {company?.teams?.[0]?.users ? company?.teams?.[0]?.users.length : 0}</h3>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default memo(CompanyRow)
