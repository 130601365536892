import { StyleSheet } from 'aphrodite'
import { body, gptColor, grayishBlue, note, placeholder, successGreen } from '../../../shared/colors'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    background: 'linear-gradient(129deg, rgba(23, 205, 183, 0.15) 3.15%, rgba(95, 204, 28, 0.15) 110.17%), #FFFFFF',
    paddingTop: 30,
    paddingRight: 30,
    minHeight: 'fill-available',
    paddingBottom: 24,
    '@media (max-width: 899px)': {
      paddingTop: 26,
      paddingLeft: 21,
      paddingRight: 21,
    },
  },
  addOnSeparator: {
    background: grayishBlue,
    height: 1,
    width: '100%',
    marginBottom: 24,
  },
  button: {
    padding: '20px 66.5px',
    background: gptColor,
    filter: 'drop-shadow(0px 0px 36px rgba(34, 82, 76, 0.3))',
    borderRadius: '8px',
    height: 58,
    textTransform: 'none',
    fontSize: 18,
  },
  cardContainer: {
    display: 'flex',
    maxWidth: 593.13,
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '30px 36px',
    background: '#FCFCFD',
    border: '1px solid #E5E5E6',
    boxShadow: '0px 0px 53px rgba(66, 103, 67, 0.28)',
    borderRadius: '16px',
    marginBottom: 24,
    '@media (max-width: 899px)': {
      padding: 24,
    },
  },
  cardContainerTop: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  cardDescrOne: {
    color: body,
    lineHeight: '150%',
    marginBottom: 16,
  },
  cardDescrTwo: {
    color: body,
    lineHeight: '150%',
    marginBottom: 16,
  },
  cardTitle: {
    fontSize: 24,
    backgroundImage: gptColor,
    backgroundClip: 'text',
    color: 'transparent',
    '-webkit-background-clip': 'text',
    marginBottom: 16,
  },
  checkmark: {
    width: '15.33px',
    height: '11.5px',
    color: successGreen,
  },
  emailCreditsText: {
    fontWeight: 700,
    color: successGreen,
  },
  featureContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 8,
  },
  featuresContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24,
  },
  featureText: {
    color: body,
    marginLeft: 10,
  },
  gptColorText: {
    backgroundImage: gptColor,
    backgroundClip: 'text',
    color: 'transparent',
    '-webkit-background-clip': 'text',
    fontWeight: 600,
  },
  priceText: {
    color: note,
    fontSize: 24,
  },
  priceTextInterval: {
    color: placeholder,
    fontSize: 12,
  },
  salezillaLogoContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  salezillaLogo: {
    width: 196,
    height: 49,
    opacity: 0.05,
  },
  seatsIncluded: {
    fontWeight: 600,
    color: successGreen,
  },
  subtitle: {
    color: body,
    lineHeight: '150%',
    marginBottom: 24,
  },
  title: {
    fontWeight: 700,
    fontSize: 36,
    marginBottom: 20,
    '@media (max-width: 899px)': {
      marginBottom: 24,
    },
  },
})

export default styles
