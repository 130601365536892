import { FC, memo, useCallback, useEffect } from 'react'
import { css } from 'aphrodite'
import { Box, Grid, LinearProgress, Typography } from '@mui/material'
import { useLazyQuery, useMutation } from '@apollo/client'
import SuccessSvg from '../../../assets/svg/success.svg'
import WaitingSvg from '../../../assets/svg/waiting.svg'
import styles from './styles'
import LeadListActions from './LeadListActions'
import LeadsDataIndicator from './LeadsDataIndicator'
import LeadsListTable from './LeadsListTable'
import {
  CreateOrupdateCampaignTransactionDocument,
  GetCampaignByIdDocument,
  GetCampaignStatusDocument,
  GetLeadsReviewProgressDocument,
  GetLeadsScoreAllProgressDocument,
  GetUserByIdDocument,
  Lead,
} from '../../../graphql/generated'
import { useCampaignAndUser } from '../../../components/NewCampaignLayout'
import moment from 'moment'
import client from '../../../apolloClient'
import { useGraphQL } from '../../../context/GraphQLContext'
import CampaignName from '../TeachingAndGrading/CampaignName'

interface LeadListPreviewProps {}

const LeadListPreview: FC<LeadListPreviewProps> = () => {
  const { campaign, user } = useCampaignAndUser()
  const { updateCampaign } = useGraphQL()

  const [getCampaignById] = useLazyQuery(GetCampaignByIdDocument, {
    fetchPolicy: 'network-only',
  })

  const [createOrupdateCampaignTransaction] = useMutation(CreateOrupdateCampaignTransactionDocument, {
    onCompleted: (data) => {
      if (user && user.company && data.createOrupdateCampaignTransaction) {
        client.cache.writeQuery({
          query: GetUserByIdDocument,
          data: {
            getUserById: {
              ...user,
              company: {
                ...user?.company,
                creditsAvailable: data.createOrupdateCampaignTransaction.creditsAvailable,
              },
            },
          },
        })
      }
    },
  })

  const [
    getLeadsScoreAllProgress,
    { data: getLeadsScoreAllProgressRes, stopPolling: stopPollingGetLeadsScoreAllProgress },
  ] = useLazyQuery(GetLeadsScoreAllProgressDocument, {
    fetchPolicy: 'network-only',
    pollInterval: 5000,
    onCompleted: async (data) => {
      if (data?.getLeadsScoreAllProgress?.is_completed && campaign) {
        if (data?.getLeadsScoreAllProgress?.leadsGenerated)
          client.cache.writeQuery({
            query: GetCampaignByIdDocument,
            variables: {
              data: {
                id: campaign.id,
                isDemo: false,
              },
            },
            data: {
              getCampaignById: { ...campaign, leadsGenerated: data?.getLeadsScoreAllProgress?.leadsGenerated },
            },
          })
        createOrupdateCampaignTransaction({
          variables: {
            data: {
              campaignId: campaign?.id,
              companyId: campaign?.companyId,
              creditsSpent: data?.getLeadsScoreAllProgress?.num_leads_grabbed,
            },
          },
        })
        getCampaignById({
          variables: {
            data: {
              id: campaign?.id,
              isDemo: false,
            },
          },
        })

        stopPollingGetLeadsScoreAllProgress()
      }
    },
  })

  const [getCampaignStatus, { data: campaignStatus }] = useLazyQuery(GetCampaignStatusDocument, {
    pollInterval: 5000,
    fetchPolicy: 'network-only',
  })

  const [getLeadsReviewProgress, { data: getLeadsReviewProgressRes, stopPolling }] = useLazyQuery(
    GetLeadsReviewProgressDocument,
    {
      fetchPolicy: 'network-only',
      pollInterval: 5000,
      onCompleted: (data) => {
        if (data?.getLeadsReviewProgress?.is_completed) {
          stopPolling()
        }
      },
    },
  )

  useEffect(() => {
    if (campaign?.id && campaign?.creatorId) {
      getLeadsReviewProgress({
        variables: {
          data: {
            campaignId: campaign.id,
            creatorId: campaign.creatorId,
          },
        },
      })
    }
  }, [campaign?.creatorId, campaign?.id, getLeadsReviewProgress])

  const handleGetLeads = useCallback(() => {
    if (campaign?.id && campaign?.creatorId) {
      getLeadsScoreAllProgress({
        variables: {
          data: {
            campaignId: campaign.id,
            creatorId: campaign.creatorId,
          },
        },
      })
    }
  }, [campaign?.creatorId, campaign?.id, getLeadsScoreAllProgress])

  useEffect(() => {
    if (!campaign?.leadsFetched) {
      handleGetLeads()
    }
  }, [campaign?.leadsFetched, handleGetLeads])

  useEffect(() => {
    if (campaign?.id && campaign?.creatorId) {
      getCampaignStatus({
        variables: {
          data: {
            campaignId: campaign.id,
            creatorId: campaign.creatorId,
          },
        },
      })
    }
  }, [campaign?.id, campaign?.creatorId, getCampaignStatus])

  const formatRemainingTime = (seconds: number) => {
    const duration = moment.duration(seconds, 'seconds')
    const hours = Math.floor(duration.asHours())
    const minutes = Math.floor(duration.asMinutes()) % 60
    const formattedTime = `${hours} hour ${minutes} minute remain`

    return formattedTime
  }

  const startGettingLeads = useCallback(() => {
    updateCampaign({
      variables: {
        data: {
          campaignId: campaign?.id as string,
          leadsFetched: false,
        },
      },
    })
    handleGetLeads()
  }, [campaign?.id, handleGetLeads, updateCampaign])

  return (
    <Grid container className={css(styles.leadsListContainer)}>
      <CampaignName name={campaign?.name as string} campaignId={campaign?.id as string} />
      <Grid item xs={12}>
        <div className={css(styles.loadingBar)}>
          {!getLeadsScoreAllProgressRes?.getLeadsScoreAllProgress?.is_completed && !campaign?.leadsFetched ? (
            <>
              <Typography variant="body1" className={css(styles.loadingBarTitle)}>
                Grabbing leads contacts... Feel free to leave this site.
              </Typography>
              <Typography variant="body1" className={css(styles.loadingBarTime)}>
                <img src={WaitingSvg} alt="waiting" />
                {formatRemainingTime(
                  (getLeadsScoreAllProgressRes?.getLeadsScoreAllProgress?.time as number) || 900,
                )}{' '}
              </Typography>
              <Box sx={{ width: '100%' }}>
                <LinearProgress
                  variant="determinate"
                  color="success"
                  value={(getLeadsScoreAllProgressRes?.getLeadsScoreAllProgress?.percentage as number) || 0}
                />
              </Box>
            </>
          ) : (
            <Typography variant="body1" className={css(styles.loadingBarTitle)}>
              <img src={SuccessSvg} alt="waiting" />
              Your leads list is generated.
            </Typography>
          )}
        </div>
      </Grid>
      <LeadsDataIndicator
        campaignStatus={campaignStatus?.getCampaignStatus}
        leadsGenerated={campaign?.leadsGenerated as number}
      />
      {!!campaignStatus && (
        <LeadListActions
          handleGetLeads={startGettingLeads}
          campaignStatus={campaignStatus?.getCampaignStatus}
          loading={!getLeadsScoreAllProgressRes?.getLeadsScoreAllProgress?.is_completed}
          leadsRequested={campaign?.desiredNumberOfLeads as number}
        />
      )}
      <Grid item xs={12} className={css(styles.leadsTableHeader)}>
        <Typography variant="body1" className={css(styles.leadsTableHeaderTitle)}>
          Leads preview
        </Typography>
      </Grid>
      <Grid item xs={12} className={css(styles.leadsTableContainer)}>
        <LeadsListTable leads={(getLeadsReviewProgressRes?.getLeadsReviewProgress?.leads as Lead[]) || []} />
      </Grid>
    </Grid>
  )
}

export default memo(LeadListPreview)
