import { Box, Button, CircularProgress, Grid, TextField, Typography, useMediaQuery } from '@mui/material'
import { SendPasswordResetLinkDocument } from '../../graphql/generated'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { css } from 'aphrodite'

import { ReactComponent as ResetPasswordSvg } from '../../assets/svg/reset_password.svg'
import { ReactComponent as StepsSvg } from '../../assets/svg/login_hero_asset.svg'
import { ReactComponent as BackIconSvg } from '../../assets/svg/back.svg'
import { note, placeholder, dark, body } from '../../shared/colors'
import SalezillaLogo from '../../assets/images/salezilla_logo_white.png'
import styles from '../Authentication/style'
import Toast from '../Toast/Toast'
import style from './styles'
import Text from '../Text'

const ResetPassword = () => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery('@media (max-width: 899px)')
  const isMobileScreen = useMediaQuery('@media (max-width: 768px)')
  const [resetPassword, { loading: resetpasswordloading }] = useLazyQuery(SendPasswordResetLinkDocument, {
    fetchPolicy: 'no-cache',
  })
  const [severity, setSeverity] = useState<string>('error')
  const [resetEmail, setResetEmail] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const sendResetPassowrdLink = async () => {
    if (resetEmail) {
      try {
        await resetPassword({
          variables: {
            userEmail: resetEmail,
          },
        })

        setOpen(true)
        setResetEmail('')
        setSeverity('success')
        setMessage('Reset link sent please look at your email')
      } catch (error: any) {
        setOpen(true)
        setMessage(error.message as unknown as string)
      }
    }
  }

  useEffect(() => {
    if (open && severity === 'success') {
      const redirectTimer = setTimeout(() => {
        navigate('/login', { replace: true })
      }, 1000)

      return () => {
        clearTimeout(redirectTimer)
      }
    }
  }, [open, severity])

  return (
    <Grid container className={css(styles.container)}>
      <Toast open={open} setOpen={setOpen} message={message} severity={severity === 'error' ? 'error' : 'success'} />
      {!isMobile && (
        <Grid item md={7} style={{ height: '100vh' }}>
          <img alt="Salezilla Logo" src={SalezillaLogo} className={css(styles.salezillaLogo)} />
          <div className={css(styles.illustrationContainer)}>
            <Text semiBold extraStyles={[styles.firstHeading, styles.textFont]}>
              The Next Gen <br /> Cold Email Platform
            </Text>
            {/* {isScreen1250 ? (
              <Steps1250Svg style={{ marginTop: '44px', marginBottom: '44px', marginLeft: '-60px' }} />
            ) : isScreenLarge ? (
              <StepsLargeSvg style={{ marginTop: '44px', marginBottom: '44px', marginLeft: '-60px',  }} />
            ) : (
              )} */}
            <StepsSvg style={{ marginTop: '50px', marginBottom: '30px', marginLeft: '-60px' }} />

            <Text extraStyles={[styles.firstHeading]}>Want to sign up?</Text>
            <Button
              className={css(styles.bookDemoButton)}
              onClick={() => {
                window.open('https://calendly.com/dougtanner/salezilla-open-discussion?month=2023-11')
              }}
            >
              Book a Demo
            </Button>
          </div>
        </Grid>
      )}

      {isMobileScreen && (
        <Grid item sm={12} style={{ height: '20vh', width: '85%', margin: '30px' }}>
          <img
            alt="Salezilla Logo"
            src={SalezillaLogo}
            style={{
              margin: '60px 0px 20px 0px',
              height: 35,
              width: 107,
            }}
            // className={css(styles.salezillaLogo)}
          />
          <Text semiBold extraStyles={[styles.textFont, styles.mobileAlign, styles.mobileHeading]}>
            The Next Gen <br /> Cold Email Platform
          </Text>
        </Grid>
      )}

      <Grid
        sx={{
          width: '100%',
          padding: isMobileScreen ? '72px 30px' : '72px 48px',
          flexDirection: 'column',
          alignItems: isMobileScreen ? 'flex-start' : 'center',
          textAlign: isMobileScreen ? 'left' : 'center',
          justifyContent: isMobileScreen ? 'flex-start' : 'center',
          gap: '36px',
          borderTopLeftRadius: '24px',
          borderBottomLeftRadius: isMobileScreen ? 0 : '24px',
          borderTopRightRadius: isMobileScreen ? '24px' : 0,
          borderBottomRightRadius: 0,
          background: '#FFF',
          borderTopRight: '0px',
          boxShadow: '0px 4px 101px 0px rgba(131, 131, 131, 0.13)',
        }}
        item
        md={5}
        sm={12}
        className={css(style.mainContainer)}
      >
        <div className={css(style.contentContainer)}>
          <Button
            className={css(style.backBtn)}
            startIcon={<BackIconSvg />}
            onClick={() => navigate('/login', { replace: true })}
          >
            Back
          </Button>
          <div style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }}>
            <ResetPasswordSvg />
          </div>
          <Box className={css(style.inputContainer)}>
            <Typography sx={{ textAlign: 'center', fontSize: '14px', color: body }}>
              Enter your email below. We will send a link to reset the password.
            </Typography>
            <div className={css(style.inputField)}>
              <Typography sx={{ color: note, fontSize: '14px', fontWeight: 600 }}>Company Email</Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                variant="outlined"
                placeholder="Your company email"
                value={resetEmail}
                // width="100%"
                sx={{
                  input: {
                    paddingTop: '11px',
                    paddingBottom: '11px',
                    '::placeholder': {
                      color: placeholder,
                    },
                  },
                  marginTop: 0,
                  '.MuiOutlinedInput-notchedOutline ': {
                    borderRadius: '8px',
                    border: '1px solid #E5E5E5',
                  },
                  '.css-ubour0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    border: '2px solid #CED3F1',
                  },
                  '.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#CED3F1 !important',
                  },
                }}
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </div>
          </Box>
          <Button
            disabled={!resetEmail || resetpasswordloading}
            className={css(style.sendBtn)}
            variant="contained"
            onClick={sendResetPassowrdLink}
            fullWidth={isMobileScreen ? true : false}
          >
            {resetpasswordloading && <CircularProgress size={20} style={{ marginRight: 10 }} />}
            Send
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

export default ResetPassword
