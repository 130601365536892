import React from 'react'
import { darkGray, darkNavyBlue } from '../../shared/colors'

interface Args {
  isSelected: boolean
}

const DashboardIcon = ({ isSelected }: Args) => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.7972 26.9501H5.01135C2.63028 26.9501 1.4812 25.8484 1.4812 23.5739V4.85699C1.4812 2.58253 2.64212 1.48083 5.01135 1.48083H9.7972C12.1783 1.48083 13.3274 2.58253 13.3274 4.85699V23.5739C13.3274 25.8484 12.1664 26.9501 9.7972 26.9501ZM5.01135 3.25776C3.50689 3.25776 3.25812 3.66053 3.25812 4.85699V23.5739C3.25812 24.7704 3.50689 25.1731 5.01135 25.1731H9.7972C11.3017 25.1731 11.5504 24.7704 11.5504 23.5739V4.85699C11.5504 3.66053 11.3017 3.25776 9.7972 3.25776H5.01135Z"
        fill={isSelected ? darkNavyBlue : darkGray}
      />
      <path
        d="M23.4198 16.2885H18.6339C16.2528 16.2885 15.1038 15.1868 15.1038 12.9124V4.85699C15.1038 2.58253 16.2647 1.48083 18.6339 1.48083H23.4198C25.8008 1.48083 26.9499 2.58253 26.9499 4.85699V12.9124C26.9499 15.1868 25.789 16.2885 23.4198 16.2885ZM18.6339 3.25776C17.1295 3.25776 16.8807 3.66053 16.8807 4.85699V12.9124C16.8807 14.1088 17.1295 14.5116 18.6339 14.5116H23.4198C24.9242 14.5116 25.173 14.1088 25.173 12.9124V4.85699C25.173 3.66053 24.9242 3.25776 23.4198 3.25776H18.6339Z"
        fill={isSelected ? darkNavyBlue : darkGray}
      />
      <path
        d="M23.4198 26.9496H18.6339C16.2528 26.9496 15.1038 25.8479 15.1038 23.5734V21.4411C15.1038 19.1666 16.2647 18.0649 18.6339 18.0649H23.4198C25.8008 18.0649 26.9499 19.1666 26.9499 21.4411V23.5734C26.9499 25.8479 25.789 26.9496 23.4198 26.9496ZM18.6339 19.8419C17.1295 19.8419 16.8807 20.2446 16.8807 21.4411V23.5734C16.8807 24.7699 17.1295 25.1726 18.6339 25.1726H23.4198C24.9242 25.1726 25.173 24.7699 25.173 23.5734V21.4411C25.173 20.2446 24.9242 19.8419 23.4198 19.8419H18.6339Z"
        fill={isSelected ? darkNavyBlue : darkGray}
      />
    </svg>
  )
}

export default DashboardIcon
