import { StyleSheet } from 'aphrodite'
import { blue, dark, mainGreen, note } from '../../shared/colors'

const styles = StyleSheet.create({
  backBtn: {
    position: 'absolute',
    left: '12px',
    top: '32px',
    textTransform: 'none',
    fontSize: '18px',
    fontWeight: 600,
    color: dark,
  },
  mainContainer: {
    display: 'flex',
    position: 'relative',
    gap: '36px',
    background: '#FFF',
    height: '100%',
    boxShadow: '0px 4px 101px 0px rgba(131, 131, 131, 0.13)',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '36px',
    width: '100%',
    '@media (min-width: 900px)': {
      width: 344,
    },
    '@media (min-width: 1200px)': {
      width: 444,
    },
    '@media (min-width: 1536px)': {
      width: 544,
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
  },
  inputField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    width: '100%',
  },
  sendBtn: {
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    textTransform: 'none',
    borderRadius: '12px',
    border: '2px solid rgba(71, 80, 240, 0.50)',
    background: 'linear-gradient(0deg, rgba(105, 111, 134, 0.04) 0%, rgba(105, 111, 134, 0.04) 100%), #FFF',
    padding: '12px 36px',
    color: '#4750F0',
    fontSize: '18px',
    fontWeight: 600,
    height: 49,
    ':hover': {
      color: '#FFF',
      background: '#2932C9',
    },
  },
})

export default styles
