import { FC, memo, useEffect, useMemo } from 'react'
import { Campaign, GetCampaignStatusDocument } from '../../../../../graphql/generated'
import Text from '../../../../../components/Text'
import { useLazyQuery } from '@apollo/client'

interface CompaniesMatchedProps {
  campaign: Campaign
  type?: string
}

const CompaniesMatched: FC<CompaniesMatchedProps> = ({ campaign, type }) => {
  const { isInDraft, campaignTransactions = [] } = campaign

  const [getCampaignStatus, { data: campaignStatus }] = useLazyQuery(GetCampaignStatusDocument)

  const totalCreditsSpent = campaignTransactions?.reduce(
    (sum, transaction) => sum + (transaction?.creditsSpent ?? 0),
    0,
  )

  useEffect(() => {
    type === 'remain' &&
      getCampaignStatus({
        variables: {
          data: {
            campaignId: campaign.id,
            creatorId: campaign.creatorId,
          },
        },
      })
  }, [campaign.creatorId, campaign.id, getCampaignStatus, type])

  const getRemainingLeads = useMemo(() => {
    if (campaignStatus?.getCampaignStatus?.accurate_leads && totalCreditsSpent) {
      return campaignStatus?.getCampaignStatus?.accurate_leads - totalCreditsSpent
    }
  }, [campaignStatus?.getCampaignStatus?.accurate_leads, totalCreditsSpent])

  const getComponent = useMemo(() => {
    if (isInDraft) {
      return <Text>-</Text>
    }
    return <Text>{type === 'remain' ? getRemainingLeads : totalCreditsSpent}</Text>
  }, [isInDraft, type, getRemainingLeads, totalCreditsSpent])

  return <>{getComponent}</>
}

export default memo(CompaniesMatched)
