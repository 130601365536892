import { memo, useState } from 'react'
import Popover from '@mui/material/Popover'
import Button from '@mui/material/Button'
import { css } from 'aphrodite'
import { FormControlLabel, Grid, Input, InputLabel, Radio, RadioGroup, TextField } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'

import styles from './style'
import Text from '../../../components/Text'
import { Persona } from '../../../graphql/generated'

const theme = createTheme({
  palette: {
    primary: {
      main: '#378DFA',
    },
  },
})

const SelectYear = ({
  foundedYearAfter,
  foundedYearBefore,
  setSelectedPersona,
  handleUpdatePersona,
}: {
  foundedYearAfter: number
  foundedYearBefore: number
  handleUpdatePersona: () => void
  setSelectedPersona: React.Dispatch<React.SetStateAction<Persona | null>>
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [yearFrom, setYearFrom] = useState<number | ''>(foundedYearAfter)
  const [yearTo, setYearTo] = useState<number | ''>(foundedYearBefore)
  const [selectedOption, setSelectedOption] = useState('')
  const [isClicked, setIsClicked] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setIsClicked(!isClicked)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleApply = () => {
    handleUpdatePersona()
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClick}
        aria-describedby={id}
        className={css(styles.filterBtn)}
        sx={{
          background: isClicked ? '#FFF' : '#F4F5F6',
          border: isClicked ? '1px solid #378DFA' : '1px solid  #E5E5E5',
          boxShadow: isClicked ? '0px 0px 4px 0px rgba(55, 141, 250, 0.40)' : 'none',
        }}
      >
        <span className={css(styles.allFilterBtnText)}>
          {selectedOption ? selectedOption : yearFrom && yearTo ? `${yearFrom}- ${yearTo}` : 'Founded Year'}
        </span>
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          top: '4px',
          width: '200rem',
          '& .MuiPopover-paper ': {
            borderRadius: '12px',
            background: '#FFF',
            boxShadow: '0px 4px 20px 0px rgba(61, 61, 61, 0.38)',
          },
        }}
        onFocus={() => setIsClicked(true)}
        onBlur={() => setIsClicked(false)}
      >
        <Grid sx={{ width: '250px' }} container direction="column" alignItems="left" p={2}>
          <div>
            <CloseIcon
              style={{
                float: 'right',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            />
          </div>
          <div
            style={{
              marginBottom: '10px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            {/* first name last name */}
            <Text extraStyles={[styles.inputLabel]}>Founded year after</Text>
            <TextField
              size="small"
              type="number"
              variant="outlined"
              value={yearFrom}
              onChange={(e) => {
                setSelectedOption('')
                setYearFrom(parseInt(e.target.value, 10) || '')
                setSelectedPersona((prevPersona: any) => ({
                  ...prevPersona,
                  foundedYearAfter: parseInt(e.target.value, 10),
                }))
              }}
              placeholder="End a year"
              inputProps={{ min: 1900, max: new Date().getFullYear() }}
              sx={{ borderRadius: '8px', border: '1px solid #E5E5E5', background: '#FFF' }}
            />
          </div>
          <div
            style={{
              marginBottom: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text extraStyles={[styles.inputLabel]}>Founded year before</Text>
            <TextField
              size="small"
              type="number"
              variant="outlined"
              value={yearTo}
              onChange={(e) => {
                setSelectedOption('')
                setYearTo(parseInt(e.target.value, 10) || '')
                setSelectedPersona((prevPersona: any) => ({
                  ...prevPersona,
                  foundedYearAfter: parseInt(e.target.value, 10),
                }))
              }}
              placeholder="End a year"
              inputProps={{ min: 1900, max: new Date().getFullYear() }}
              sx={{ borderRadius: '8px', border: '1px solid #E5E5E5', background: '#FFF' }}
            />
          </div>

          <RadioGroup
            value={selectedOption}
            onChange={(e) => {
              setYearFrom('')
              setYearTo('')
              setSelectedOption(e.target.value)
            }}
          >
            <FormControlLabel value="2013-2019" control={<Radio />} label="2013 - 2019" />
            <FormControlLabel value="before-2009" control={<Radio />} label="Before 2009" />
          </RadioGroup>
          <Button variant="contained" onClick={handleApply} className={css(styles.filterApplyBtn)}>
            Apply
          </Button>
        </Grid>
      </Popover>
    </div>
  )
}
export default memo(SelectYear)
