import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useNavigateToLogin = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const loginState = useMemo(() => {
    return {
      from: `${pathname}${search}`,
    };
  }, [pathname, search]);

  const navigateToLogin = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  return {
    loginState,
    navigateToLogin,
  };
};

export default useNavigateToLogin;
