import { css } from 'aphrodite'
import PersonIcon from '@mui/icons-material/Person'

import styles from './styles'
import RexAvatar from '../../../assets/icons/RexAvatar'
import { ReactComponent as RexMblAvatar } from '../../../assets/svg/Rex-I.svg'
import Text from '../../Text'
import { white } from '../../../shared/colors'
import { ChatHistory, GetCompaniesForLabeling, GetUserByIdDocument } from '../../../graphql/generated'
import { Avatar } from '@mui/material'
import { useAuth } from '../../../hooks/useAuth'
import { useLazyQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import ChatSummaryForm from './ChatSummaryForm/ChatSummaryForm'
import CompanySizeSelector from './ChatSummaryForm/CompanySizeSelector'
import LocationSelector from './ChatSummaryForm/LocationSelector'
import ManagementLevelSelector from './ChatSummaryForm/ManagementLevelSelector'
import JobTitles from './JobTitles'
import { WidgetMessage } from '..'
import ChatLabelCompanies from '../ChatCompanyTable/ChatLabelCompanies'
import { useCampaignAndUser } from '../../NewCampaignLayout'
import { useGraphQL } from '../../../context/GraphQLContext'
import EditedTargetDescr from './ChatSummaryForm/EditedTargetDescr'

export default function MessageBox(props: {
  message: ChatHistory
  desiredNumberOfLeads: number
  messageId?: string
  handleTranslate: (widgetMessage?: WidgetMessage) => Promise<void>
  setInputCode: React.Dispatch<React.SetStateAction<string>>
}) {
  const { user, isMobile } = useAuth()
  const { campaign } = useCampaignAndUser()
  const { getCompaniesForLabelingResult } = useGraphQL()
  const { message, handleTranslate } = props
  const isUser = message.role === 'user'

  const [getUserPhoto, { data }] = useLazyQuery(GetUserByIdDocument)

  useEffect(() => {
    if (user?.id !== message.userId && message.userId) {
      getUserPhoto({
        variables: {
          id: message.userId,
        },
      })
    }
  }, [getUserPhoto, message.userId, user?.id])

  const getMessageUserPhoto = useMemo(() => {
    if (message.userId !== user?.id && data?.getUserById) {
      return data?.getUserById.photoUrl
    }
    return user?.photoUrl
  }, [data?.getUserById, message?.userId, user?.id, user?.photoUrl])

  const isMessageType = useMemo(
    () =>
      [
        'company_size',
        'management_level',
        'company_location',
        'job_titles',
        'last_message',
        'show_initial_five_companies',
        'show_next_five_companies',
        'edited_target_descr',
        'dismissed_edited_target_descr',
        'accepted_edited_target_descr',
        'accepted_edited_target_descr_show_companies',
        'dismissed_edited_target_descr_show_companies',
      ].includes(message?.type || ''),
    [message.type],
  )

  const isTypeCompaniesOrSummary =
    message.type === 'show_next_five_companies' ||
    message.type === 'show_initial_five_companies' ||
    message.type === 'accepted_edited_target_descr_show_companies' ||
    message.type === 'dismissed_edited_target_descr_show_companies'

  const getFirstFiveCompanies = useMemo(() => {
    if (getCompaniesForLabelingResult?.data?.getCompaniesForLabeling.companies) {
      return getCompaniesForLabelingResult?.data?.getCompaniesForLabeling.companies.slice(0, 5)
    }
    return []
  }, [getCompaniesForLabelingResult?.data?.getCompaniesForLabeling?.companies])

  const getNextFiveCompanies = useMemo(() => {
    if (getCompaniesForLabelingResult?.data?.getCompaniesForLabeling.companies) {
      return getCompaniesForLabelingResult?.data?.getCompaniesForLabeling.companies.slice(5, 10)
    }
    return []
  }, [getCompaniesForLabelingResult?.data?.getCompaniesForLabeling?.companies])

  return (
    <>
      <div className={css([styles.container, isUser ? styles.containerUser : styles.containerSystem])}>
        {!isUser && !(message.type === 'show_initial_five_companies' && !campaign?.showInitialFiveCompanies) && (
          <div className={css([styles.avatarContainer, styles.avatarContainerSystem])}>
            {isMobile ? <RexMblAvatar /> : <RexAvatar />}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <>
            {message.type !== 'edited_target_descr' &&
              message.type !== 'dismissed_edited_target_descr' &&
              message.type !== 'accepted_edited_target_descr' &&
              message.type !== 'show_initial_five_companies' &&
              message.type !== 'show_next_five_companies' && (
                <div className={css([isUser ? styles.userMessageContainer : styles.messageContainer])}>
                  {message.type !== 'last_message' ? (
                    <Text extraStyles={[styles.message]}>{message.content}</Text>
                  ) : (
                    <Text
                      inlineStyle={{
                        fontSize: '16px',
                      }}
                    >
                      Here's a summary of your targeting parameters, please make sure all fields are filled and to your
                      liking:
                    </Text>
                  )}
                </div>
              )}
            {message.role === 'assistant' && isMessageType && (
              <div
                style={{
                  width: isMobile || isTypeCompaniesOrSummary ? '100%' : '727px',
                }}
              >
                {message.type === 'last_message' && message.role === 'assistant' && (
                  <div className={css([styles.showSummaryFormContainer])}>
                    <ChatSummaryForm />
                  </div>
                )}
                {message.type === 'company_size' && <CompanySizeSelector handleTranslate={handleTranslate} />}
                {message.type === 'management_level' && <ManagementLevelSelector handleTranslate={handleTranslate} />}
                {message.type === 'company_location' && <LocationSelector handleTranslate={handleTranslate} />}
                {message.type === 'job_titles' && (
                  <JobTitles
                    label="Example titles"
                    handleTranslate={handleTranslate}
                    textStyles={styles.textStyle}
                    padding="12px 8px"
                  />
                )}
                {(message.type === 'edited_target_descr' ||
                  message.type === 'dismissed_edited_target_descr' ||
                  message.type === 'dismissed_edited_target_descr_show_companies' ||
                  message.type === 'accepted_edited_target_descr_show_companies' ||
                  message.type === 'accepted_edited_target_descr') && (
                  <div
                    style={{
                      width: isMobile ? '100%' : '727px',
                    }}
                  >
                    <EditedTargetDescr
                      messageType={message.type}
                      targetingDescr={message.content}
                      messageId={props.messageId}
                      handleCreateTargetingInfo={() => {}}
                    />
                  </div>
                )}
                {message.type === 'show_initial_five_companies' && campaign?.showInitialFiveCompanies && (
                  <ChatLabelCompanies companiesForLabeling={getFirstFiveCompanies as GetCompaniesForLabeling[]} />
                )}
                {(message.type === 'show_next_five_companies' ||
                  message.type === 'accepted_edited_target_descr_show_companies' ||
                  message.type === 'dismissed_edited_target_descr_show_companies') &&
                  campaign?.showInitialNextFiveCompanies && (
                    <ChatLabelCompanies
                      companiesForLabeling={getNextFiveCompanies as GetCompaniesForLabeling[]}
                      showResultPreview
                    />
                  )}
              </div>
            )}
          </>
        </div>

        {isUser && (
          // Render the user's profile picture component here
          // Replace the placeholder code below with the actual profile picture component
          <div className={css([styles.avatarContainer, styles.avatarContainerUser, styles.hideOnMobile])}>
            {getMessageUserPhoto ? (
              <Avatar alt="user profile photo" src={getMessageUserPhoto} />
            ) : (
              <PersonIcon width="20px" height="20px" sx={{ color: white }} />
            )}
          </div>
        )}
      </div>
    </>
  )
}
