import { FC, memo } from 'react'
import { Lead } from '../../../../graphql/generated'
import LeadsListTable from '../../LeadListPreview/LeadsListTable'
import { Grid, Typography } from '@mui/material'
import styles from './styles'
import { css } from 'aphrodite'

interface LeadsResultStep1Props {
  leads: Lead[]
}

const LeadsResultStep1: FC<LeadsResultStep1Props> = ({ leads }) => {
  return (
    <Grid container>
      <Grid item xs={12} py={2}>
        <div className={css(styles.dialogFormContainer)}>
          <Typography variant="body1" component="h2" sx={{ color: '#4CAF50', fontSize: '18px' }}>
            Step 1:
          </Typography>
          <Typography variant="body1" component="h2" sx={{ color: '#697386', fontSize: '14px' }}>
            To get an accurate leads list, please review the company descriptions and verify these are your targeting
            companies.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <LeadsListTable leads={leads} />
      </Grid>
    </Grid>
  )
}

export default memo(LeadsResultStep1)
