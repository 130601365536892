import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AuthProvider } from './hooks/useAuth'

import App from './App'
import client from './apolloClient'
import { mainGreen } from './shared/colors'
import { GraphQLProvider } from './context/GraphQLContext'

const theme = createTheme({
  palette: {
    primary: { main: mainGreen },
    warning: { main: '#E45A4F' },
  },
  typography: {
    fontFamily: 'Open Sans',
  },
})

const AppContainer = () => {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <GraphQLProvider>
              <App />
            </GraphQLProvider>
          </ThemeProvider>
        </AuthProvider>
      </ApolloProvider>
    </BrowserRouter>
  )
}

export default AppContainer
