import { FC } from 'react'
import { css } from 'aphrodite'
import { Divider, Grid, Typography } from '@mui/material'
import styles from './style'
import ListItem from '../../../pages/NewCampaign/LabellingListView/ListItem'
import { GetCompaniesForLabeling } from '../../../graphql/generated'

interface ChatCompanyTableProps {
  companiesForLabeling: GetCompaniesForLabeling[]
  is_completed: boolean
  handleAccurateTooltip: () => void
}

const ChatCompanyTable: FC<ChatCompanyTableProps> = ({ companiesForLabeling, is_completed, handleAccurateTooltip }) => {
  return (
    <div className={css(styles.tableWrapper)}>
      <Grid container className={css(styles.LabelCompanyHeader)}>
        <Grid item sm={3}>
          <Typography className={css(styles.CompayFrame)} component="div">
            Example Companies
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography className={css(styles.DescriptionHeading)}>Description</Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography className={css(styles.ButtonHeading)}>Correct Rex’s labels</Typography>
        </Grid>
      </Grid>
      <Divider />

      <ListItem
        companiesForLabeling={companiesForLabeling}
        is_completed={is_completed}
        handleAccurateTooltip={handleAccurateTooltip}
      />
    </div>
  )
}

export default ChatCompanyTable
