import { StyleSheet } from 'aphrodite'
import { white } from '../../../../../shared/colors'

const styles = StyleSheet.create({
  //
  statusIndicator: {
    display: 'flex',
    width: '100%',
    height: 29,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
  activeIndicator: {
    borderRadius: '6px',
    // padding: '4px 8px',
    padding: '4px 8px 4px 6px',
    fontSize: '12px',
    border: '1px solid #4CAF50',
    background: 'rgba(76, 175, 80, 0.10)',
  },
  draftIndicator: {
    background: 'rgba(55, 141, 250, 0.10)',
    border: '1px solid #378DFA',
  },
  processIndicator: {
    background: 'rgba(252, 176, 29, 0.10)',
    border: ' 1px solid #F2B949)',
  },
  activeIndicatorTxt: {
    color: white,
    fontSize: 16,
  },
  deleteCampaignBtn: {
    // margin: '0 auto',
    width: 'fit-content',
    textTransform: 'none',
    // fontSize: '12px',
    color: '#E45A4F',
  },
})

export default styles
