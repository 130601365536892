import React, { memo, useState } from 'react'
import { css } from 'aphrodite'
import { Popover, TextField, FormControlLabel, Checkbox, Button, Typography, Badge } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CloseIcon from '@mui/icons-material/Close'
import { uniqBy } from 'lodash'

import styles from './style'
import LocationAutocomplete from '../../../components/LocationAutocomplete'
import { Location, Persona } from '../../../graphql/generated'

const theme = createTheme({
  palette: {
    primary: {
      main: '#378DFA',
    },
  },
})

interface DropdownProps {
  locations: Location[]
  handleUpdatePersona: () => void
  setSelectedPersona: React.Dispatch<React.SetStateAction<Persona | null>>
}

const CompanyLocation: React.FC<DropdownProps> = ({ locations, setSelectedPersona, handleUpdatePersona }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [createPersonaLocationStr, setCreatePersonaLocationStr] = useState('')
  const [createPersonaLocationOptions, setCreatePersonaLocationOptions] = useState([] as Location[])
  const [selectedLocations, setSelectedLocations] = useState(locations)
  const [isCreatePersonaAutocompleteOpen, setIsCreatePersonaAutocompleteOpen] = useState(false)
  const [isClicked, setIsClicked] = useState(false)

  const handleOptionClick = (option: Location) => {
    const index = selectedLocations.findIndex((sl) => sl.country === option.country)
    if (index !== -1) {
      const filterLocations = selectedLocations.filter((item) => item.country !== option.country)
      setSelectedLocations([...filterLocations])
      setSelectedPersona((prevPersona: any) => ({
        ...prevPersona,
        hqLocations: [...filterLocations],
      }))
    } else {
      setSelectedPersona((prevPersona: any) => ({
        ...prevPersona,
        hqLocations: [...prevPersona?.hqLocations, option],
      }))
      setSelectedLocations([...selectedLocations, option])
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const recommendOptions: Location[] = [
    {
      __typename: 'Location',
      country: 'canada',
      region: null,
      locality: null,
    },
    {
      __typename: 'Location',
      country: 'united kingdom',
      region: null,
      locality: null,
    },
    {
      __typename: 'Location',
      country: 'germany',
      region: null,
      locality: null,
    },
    {
      __typename: 'Location',
      country: 'france',
      region: null,
      locality: null,
    },
    {
      __typename: 'Location',
      country: 'united states',
      region: null,
      locality: null,
    },
  ]

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setIsClicked(!isClicked)
  }

  const handleApply = () => {
    handleUpdatePersona()
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClick}
        aria-describedby={id}
        className={css(styles.filterBtn)}
        sx={{
          background: isClicked ? '#FFF' : '#F4F5F6',
          border: isClicked ? '1px solid #378DFA' : '1px solid  #E5E5E5',
          boxShadow: isClicked ? '0px 0px 4px 0px rgba(55, 141, 250, 0.40)' : 'none',
        }}
      >
        {selectedLocations?.length === 1 ? (
          <span className={css(styles.allFilterBtnText)}>{selectedLocations?.[0]?.country}</span>
        ) : (
          <>
            <span className={css(styles.allFilterBtnText)}>Company Location</span>
            {selectedLocations?.length > 1 && (
              <Badge
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#E5E5E5',
                    color: '#3C4257',
                  },
                }}
                badgeContent={selectedLocations?.length}
                color="primary"
              />
            )}
          </>
        )}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          top: '4px',
          '& .MuiPopover-paper ': {
            borderRadius: '12px',
            background: '#FFF',
            boxShadow: '0px 4px 20px 0px rgba(61, 61, 61, 0.38)',
          },
        }}
        onFocus={() => setIsClicked(true)}
        onBlur={() => setIsClicked(false)}
      >
        <CloseIcon
          style={{
            margin: '16px',
            float: 'right',
            cursor: 'pointer',
          }}
          onClick={handleClose}
        />
        <div style={{ padding: '16px', width: '250px' }}>
          <LocationAutocomplete
            limitTags={100}
            customStyle={{ '& .MuiChip-deletable': { display: 'none' } }}
            createPersonaLocationStr={createPersonaLocationStr}
            setCreatePersonaLocationStr={setCreatePersonaLocationStr}
            createPersonaLocationOptions={createPersonaLocationOptions}
            setCreatePersonaLocationOptions={setCreatePersonaLocationOptions}
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
            isCreatePersonaAutocompleteOpen={isCreatePersonaAutocompleteOpen}
            setIsCreatePersonaAutocompleteOpen={setIsCreatePersonaAutocompleteOpen}
          />
          {/* <TextField
            variant="outlined"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Enter a location"
            fullWidth
          /> */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {uniqBy([...selectedLocations, ...recommendOptions], 'country').map((option) => (
              <ThemeProvider theme={theme}>
                <FormControlLabel
                  control={
                    <Checkbox checked={selectedLocations.includes(option)} onChange={() => handleOptionClick(option)} />
                  }
                  label={option.country}
                />
              </ThemeProvider>
            ))}
          </div>
          <Button className={css(styles.filterApplyBtn)} fullWidth variant="contained" onClick={handleApply}>
            Apply
          </Button>
        </div>
      </Popover>
    </div>
  )
}

export default memo(CompanyLocation)
