import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  textStyle: {
    // marginTop: 12,
    marginBottom: 8,
    fontSize: 12,
    fontWeight: 600,
    color: '#A3AAB7',
  },
  targetDescriptionContainer: {
    position: 'relative',
    padding: '10px',
    width: '100%',
    borderRadius: '6px',
    border: '1px solid lightgray',
    background: '#FFF',
    ':hover': {
      border: '1px solid rgba(55, 141, 250, 0.40))',
      boxShadow: '0px 0px 15px 0px rgba(55, 141, 250, 0.19)',
    },
  },
  targetDescriptionArea: {
    textAlign: 'justify',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    minHeight: '26px',
    padding: '0 10px 0 10px',
    overflow: 'auto',
    fontSize: '14px',
    fontWeight: 600,
    gap: '10px',
    width: '100%',
    border: 'none',
    resize: 'none',
    outline: 'none',
    '-webkit-appearance': 'none',
    color: '#5F69C1',
    '::placeholder': {
      fontWeight: 400,
      color: '#a2a2a2',
    },
  },
  editTargetDescrHeading: {
    color: '#3C4257',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    margin: '10px 0',
  },
  editedTargetDescriptionContainer: {
    position: 'relative',
    padding: '10px',
    width: '100%',
    borderRadius: '14px',
    border: '1px solid #F0F3F5',
    background: '#FFF',
    ':hover': {
      border: '1px solid rgba(55, 141, 250, 0.40))',
      // boxShadow: '0px 0px 15px 0px rgba(55, 141, 250, 0.19)',
    },
  },
  editedTargetDescriptionArea: {
    textAlign: 'justify',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '0 10px 0 10px',
    overflow: 'auto',
    // padding: '13px 10px',
    height: '200px',
    // minHeight: '200px',
    fontSize: '14px',
    gap: '10px',
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '14px',
    resize: 'none',
    outline: 'none',
    '-webkit-appearance': 'none',
  },

  buttonContainer: {
    right: '19px',
    width: '97.5%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 5,
    background: 'white',
    marginTop: 10,
  },
  viewMoreButton: {
    textTransform: 'none',
    color: '#727578',
    height: '25px',
  },
  saveButton: {
    border: '1px solid #378DFA',
    background: '#378DFA',
    borderRadius: '8px',
    color: 'white',
    textTransform: 'none',
    padding: '2px 6px',
    height: '25px',
    ':hover': {
      color: 'white',
      background: '#0E5ADE',
      border: '1px solid #0E5ADE',
    },
    ':disabled': {
      border: '1px solid  #E5E5E5',
      background: 'linear-gradient(0deg, rgba(105, 111, 134, 0.04) 0%, rgba(105, 111, 134, 0.04) 100%), #FFF',
      transition: 'background 0.5s',
      color: '#C1C9D2',
    },
  },
  dismissedButton: {
    borderRadius: '8px',
    border: '1px solid  rgba(55, 141, 250, 0.40)',
    background: '#FFF',
    color: '#378DFA',
    textTransform: 'none',
    padding: '2px 6px',
    height: '25px',
    ':hover': {
      color: 'white',
      background: '#0E5ADE',
      border: '1px solid #0E5ADE',
    },
    ':disabled': {
      borderRadius: '16px',
      background: 'rgba(105, 115, 134, 0.10)',
      transition: 'background 0.5s',
      color: '#000',
      borderColor: 'transparent',
      padding: '2px 10px',
    },
  },
  savedBtn: {
    ':disabled': {
      borderRadius: '16px',
      background: 'rgba(55, 141, 250, 0.10)',
      transition: 'background 0.7s',
      transitionDelay: '0.2s',
      padding: '2px 8px',
      textTransform: 'none',
      color: '#378DFA',
      border: 'none',
      width: '119px',
      height: '32px',
    },
  },
})

export default styles
