import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useMutation } from '@apollo/client'
import { useCallback, useState } from 'react'

import { DeletePendingUserDocument, DeleteUserDocument, User } from '../../../graphql/generated'
import UserModal from './UserModal/UserModal'
import TableData from './TableData'

const AdminTable = ({ verifiedUsers = [], pendingUsers = [] }: { verifiedUsers: User[]; pendingUsers: User[] }) => {
  const [showEditUserModal, setShowEditUserModal] = useState(false)
  const [isPendingUser, setIsPendingUser] = useState<boolean | undefined>(false)

  const [editModalUser, setEditModalUser] = useState<User>()

  const handleShowEditUserModal = useCallback(
    (argUser: User, pending?: boolean) => {
      setEditModalUser(argUser)
      setIsPendingUser(pending)
      setShowEditUserModal(!showEditUserModal)
    },
    [showEditUserModal],
  )

  const [deleteUser] = useMutation(DeleteUserDocument, {
    update: (cache, { data }) => {
      const normalizedId = cache.identify({ id: data?.deleteUser.id, __typename: 'User' })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })

  const [deletePendingUser] = useMutation(DeletePendingUserDocument, {
    update: (cache, { data }) => {
      const normalizedId = cache.identify({ id: data?.deletePendingUser.id, __typename: 'User' })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })

  const handleDeleteUser = async (id: string) => {
    await deleteUser({
      variables: {
        userId: id,
      },
    })
  }

  const handleDeletePendingUser = async (id: string) => {
    await deletePendingUser({
      variables: {
        userId: id,
      },
    })
  }

  const getRole = useCallback((user: User) => {
    const { isSuperAdmin, isTeamAdmin } = user
    if (isTeamAdmin && isSuperAdmin) return 'Company Admin'
    if (isTeamAdmin && !isSuperAdmin) return 'Team Admin'
    return 'Member'
  }, [])

  return (
    <TableContainer component={Paper}>
      <UserModal
        header="Edit member"
        modalButton="Edit"
        user={editModalUser}
        companyId={editModalUser?.companyId}
        showUserModal={showEditUserModal}
        handleShowUserModal={() => {
          setEditModalUser(undefined)
          setShowEditUserModal(false)
          setIsPendingUser(false)
        }}
        isPendingUser={isPendingUser}
      />
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Date Added</TableCell>
            <TableCell>Team Role</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {verifiedUsers.map((user) => (
            <TableData
              key={user.id}
              user={user as User}
              handleDeleteUser={() => {
                handleDeleteUser(user.id)
              }}
              handleShowEditUserModal={() => {
                handleShowEditUserModal(user as User)
              }}
              getRole={getRole(user as User)}
            />
          ))}
          {pendingUsers?.map((user) => (
            <TableData
              key={user.id}
              user={user as User}
              handleDeleteUser={() => {
                handleDeletePendingUser(user.id)
              }}
              handleShowEditUserModal={() => {
                handleShowEditUserModal(user as User, true)
              }}
              getRole={getRole(user as User)}
              isPending
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AdminTable
