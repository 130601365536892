import { StyleSheet } from 'aphrodite'
import { darkNavyBlue, ghostWhite, white } from '../../../shared/colors'

const styles = StyleSheet.create({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // width: '100%',
    // maxWidth: 670,
    // background: 'linear-gradient(0deg, rgba(105, 111, 134, 0.04), rgba(105, 111, 134, 0.04)), #FFFFFF',
  },
  //
  campaignNameInputLabel: {
    marginBottom: 8,
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  filterGroupBtn: {
    background: '#378DFA',
    boxShadow: '0px 4px 12px rgba(99, 99, 99, 0.13)',
    borderRadius: '6px',
    color: white,
    textTransform: 'none',
    gap: 2,
  },
  cancelCampaignCreationButton: {
    color: '#C39090',
    fontSize: 14,
    textAlign: 'center',
    marginTop: 18,
    ':hover': {
      cursor: 'pointer',
    },
  },
  pageLabel: {
    marginBottom: 20,
  },
  errorMessage: {
    color: '#d93025',
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
  },
  getCompaniesTimeLeftStr: {
    color: darkNavyBlue,
  },
  gettingCompaniesContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  sectionTitleEmoji: {
    fontSize: 26,
    marginRight: 10,
  },
  personasContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    height: 176,
    background: '#FFFFFF',
    overflowY: 'scroll',
    overflowX: 'hidden',
    border: '1px solid #C1C9D2',
    borderRadius: 4,
    // width: 646,
    // border: '1px solid #FFFFFF',
    // boxShadow: '0px 32px 32px -24px rgba(0, 0, 0, 0.1)',
    // backdropFilter: 'blur(4px)',
    // borderRadius: 16,
  },
  personasInputLabelContainer: {
    marginBottom: 0,
  },
  smallSectionTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'flex-start',
    marginTop: '48px',
    marginBottom: 8,
  },
  smallSectionTitle: {
    fontSize: '12px',
    color: '#697386',
    marginTop: 8,
    marginBottom: 8,
  },
  seeExampleBtn: {
    width: 'fit-content',
    color: '#378DFA',
    background: '#F5FDFF',
    border: '0.5px solid #378DFA',
    boxShadow: '0px 4px 12px rgba(99, 99, 99, 0.13)',
    borderRadius: '4px',
    textTransform: 'none',
  },
  generateLabelBtn: {
    maxWidth: 320,
    color: white,
    alignSelf: 'center',
    marginBottom: '9px',
    padding: '12px 36px',
    background: '#0E5ADE',
    borderRadius: '8px',
    boxShadow: '0px 4px 12px rgba(99, 99, 99, 0.13)',
    textTransform: 'none',
  },
})

export default styles
