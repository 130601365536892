import { FC, memo, useEffect } from 'react'
import Text from '../../Text'
import ChatCompanyTable from './ChatCompanyTable'
import { css } from 'aphrodite'
import styles from '../MessageBox/styles'
import RexAvatar from '../../../assets/icons/RexAvatar'
import { useGraphQL } from '../../../context/GraphQLContext'
import { GetCompaniesForLabeling } from '../../../graphql/generated'
import { useCampaignAndUser } from '../../NewCampaignLayout'
import { Box, LinearProgress } from '@mui/material'

interface ChatLabelCompaniesProps {
  companiesForLabeling: GetCompaniesForLabeling[]
  showResultPreview?: boolean
}

const ChatLabelCompanies: FC<ChatLabelCompaniesProps> = ({ companiesForLabeling, showResultPreview }) => {
  const { getCompaniesForLabelingResult } = useGraphQL()
  const { campaign } = useCampaignAndUser()

  useEffect(() => {
    if (campaign?.showInitialFiveCompanies && !campaign?.showInitialNextFiveCompanies) {
      const elementToScrollTo = document.getElementById('company-labeling-first-part')
      if (elementToScrollTo) {
        elementToScrollTo.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        })
        elementToScrollTo.focus({ preventScroll: true })
      }
    } else if (campaign?.showInitialFiveCompanies && campaign?.showInitialNextFiveCompanies) {
      const elementToScrollTo = document.getElementById('company-labeling-second-part')
      if (elementToScrollTo) {
        elementToScrollTo.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        })
        elementToScrollTo.focus({ preventScroll: true })
      }
    }
  }, [])

  return (
    <>
      <div
        id={`company-labeling-${showResultPreview ? 'second-part' : 'first-part'}`}
        className={css([styles.container, styles.containerSystem])}
      >
        <div>
          {(!companiesForLabeling || companiesForLabeling.length === 0) &&
          !getCompaniesForLabelingResult?.data?.getCompaniesForLabeling?.is_completed ? (
            <>
              <div className={css(styles.messageContainer)}>
                <Text extraStyles={[styles.message]}>
                  Grabbing some company examples for you! I think these might be a good match. To help me nail down
                  exactly what you're looking for, could you check out their descriptions and let me know which ones
                  don’t quite fit? Just take a peek at the list below and give each company a quick 'Yes' or 'No'.
                </Text>
              </div>
              <br />
              <Box sx={{ width: '100%' }}>
                <LinearProgress
                  variant="determinate"
                  color="success"
                  value={(getCompaniesForLabelingResult?.data?.getCompaniesForLabeling?.percentage as number) || 0}
                />
              </Box>
              <br />
              <ChatCompanyTable companiesForLabeling={[]} is_completed={false} handleAccurateTooltip={() => {}} />
            </>
          ) : (
            <>
              {showResultPreview ? (
                <div style={{ margin: '10px 0px' }} className={css(styles.messageContainer)}>
                  <Text extraStyles={[styles.message]}>
                    Great! Please review these 5 as well. After labeling these 5 companies, you can see the result preview.
                  </Text>
                </div>
              ) : (
                <>
                  <div style={{ margin: '10px 0px' }} className={css(styles.messageContainer)}>
                    <Text extraStyles={[styles.message]}>
                      Take a quick look at the list below and let me know if you think each company is a 'Yes' or
                      'No'. Your input is super important because it helps train me to get even better at finding
                      accurate leads for you—lots of them! Thanks a bunch for teaming up with me to fine-tune the magic!
                    </Text>
                  </div>
                </>
              )}

              <ChatCompanyTable
                companiesForLabeling={companiesForLabeling as GetCompaniesForLabeling[]}
                is_completed={getCompaniesForLabelingResult?.data?.getCompaniesForLabeling?.is_completed as boolean}
                handleAccurateTooltip={() => {}}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default memo(ChatLabelCompanies)
