import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '48px 0px',
    gap: '48px',
  },
  mainTitle: {
    fontSize: '24px',
    fontWeight: 600,
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    width: '100%',
  },
  subTitle: {
    fontSize: '20px',
    fontWeight: 600,
  },
  description: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#697386',
  },
  dataIndicator: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '48px',
    '@media (max-width: 1039px)': {
      flexDirection: 'column',
    },
  },
  dataIndicatorCard: {
    width: '216px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px',
    gap: '19px',
    borderRadius: '8px',
    border: '1px solid var(--notes-seperator, #E5E5E5)',
    background: 'var(--background-background-grey, #FCFCFD)',
    boxShadow: '0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
  },
  dataIndicatorCardTitle: {
    color: 'var(--text-note, #697386)',
    // width: '100%',
    fontSize: '14px',
    width: '184px',
  },
  dataIndicatorCardValue: {
    color: 'var(--text-body, #3C4257)',
    width: '100%',
    height: '38px',
    fontWeight: 600,
  },
  planOverviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
  },
  growthPlanMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px',
    gap: '13px',
    background: '#FCFCFD',
    border: '1px solid #E5E5E6',
    boxShadow: '0px 4px 12px rgba(99, 99, 99, 0.13)',
    borderRadius: '8px',
  },
  growthPlanSubContainer: {
    display: 'flex',
    gap: '88px',
  },
  growthPlanDataIndicator: {
    display: 'flex',
    gap: '8px',
  },
  planManagmentBtn: {
    fontSize: '12px',
    fontWeight: 600,
    color: '#378DFA',
    textDecorationLine: 'underline',
    padding: 0,
    textTransform: 'none',
  },
})

export default styles
