import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import LinkIcon from '@mui/icons-material/Link'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { css } from 'aphrodite'
import { useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import styles from './styles'
import { white } from '../../../shared/colors'
import Text from '../../../components/Text'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CompanyFromStageCheck,
  StartGettingLeadsDocument,
  GetCampaignByIdDocument,
  GetModelGradingProgressDocument,
  GetCompaniesMoreTrainBatchDocument,
  GetCompaniesForGradingProgressDocument,
} from '../../../graphql/generated'
import { useCampaignAndUser } from '../../../components/NewCampaignLayout'
import client from '../../../apolloClient'
import TargetDescription from '../../../components/TargetDescription/TargetDescription'
import TableCellWithReadMore from './TableCellWithReadMore'
import { NewCampaignContext } from '../../../components/NewCampaignLayout/context/new_campaign_context'

// interface Size {
//   sm?: number
//   md?: number
//   lg?: number
//   xl?: number
// }

interface Column {
  id: 'name' | 'size' | 'locality' | 'industry' | 'description'
  label: string
  minWidth: any
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  {
    id: 'name',
    label: 'Company',
    minWidth: { sm: 170, md: 170, lg: 255, xl: 255 },
  },
  { id: 'description', label: 'Description', minWidth: 170 },
  {
    id: 'size',
    label: '# Employees',
    minWidth: 50,
    // align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  { id: 'locality', label: 'Company Location', minWidth: 100 },
  { id: 'industry', label: 'Industry', minWidth: 100 },
  // { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
  // {
  //   id: 'population',
  //   label: 'Population',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value: number) => value.toLocaleString('en-US'),
  // },
  // {
  //   id: 'size',
  //   label: 'Size\u00a0(km\u00b2)',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value: number) => value.toLocaleString('en-US'),
  // },
  // {
  //   id: 'density',
  //   label: 'Density',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value: number) => value.toFixed(2),
  // },
]

// interface Data {
//   companyName: string
//   code: string
//   population: number
//   size: number
//   density: number
// }

// const createData = (companyName: string, code: string, population: number, size: number): Data => {
//   const density = population / size
//   return { companyName, code, population, size, density }
// }

// const rows = [
//   createData('India', 'IN', 1324171354, 3287263),
//   createData('China', 'CN', 1403500365, 9596961),
//   createData('Italy', 'IT', 60483973, 301340),
//   createData('United States', 'US', 327167434, 9833520),
//   createData('Canada', 'CA', 37602103, 9984670),
//   createData('Australia', 'AU', 25475400, 7692024),
//   createData('Germany', 'DE', 83019200, 357578),
//   createData('Ireland', 'IE', 4857000, 70273),
//   createData('Mexico', 'MX', 126577691, 1972550),
//   createData('Japan', 'JP', 126317000, 377973),
//   createData('France', 'FR', 67022000, 640679),
//   createData('United Kingdom', 'GB', 67545757, 242495),
//   createData('Russia', 'RU', 146793744, 17098246),
//   createData('Nigeria', 'NG', 200962417, 923768),
//   createData('Brazil', 'BR', 210147125, 8515767),
// ]

const Review = () => {
  const { campaign, user } = useCampaignAndUser()
  const navigate = useNavigate()

  const { updateCampaign, getMoreCompaniesForTeachingProgress } = useContext(NewCampaignContext)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [companiesForReview, setCompaniesForReview] = useState([] as CompanyFromStageCheck[])
  const [companiesExpandMap, setCompaniesExpandMap] = useState({} as { [id: string]: boolean })
  const [onTargetPct, setOnTargetPct] = useState(0)
  const [segmentCoveragePct, setSegmentCoveragePct] = useState(0)
  const [isGettingLeadsDialogOpen, setIsGettingLeadsDialogOpen] = useState(false)
  const [
    startGettingLeads,
    { loading: startGettingLeadsLoading, error: startGettingLeadsError, data: startGettingLeadsRes },
  ] = useMutation(StartGettingLeadsDocument, {
    ...(user &&
      campaign && {
        variables: {
          data: {
            campaignId: campaign.id,
            creatorId: campaign.creatorId,
          },
        },
      }),
    onCompleted: (data) => {
      if (campaign) {
        const newCampaign = {
          ...campaign,
          ...data.startGettingLeads,
        }

        client.cache.writeQuery({
          query: GetCampaignByIdDocument,
          data: {
            getCampaignById: newCampaign,
          },
        })

        setIsGettingLeadsDialogOpen(true)
      }
    },
  })
  const [
    getCompaniesForGradingProgress,
    {
      loading: getCompaniesForGradingProgressLoading,
      error: getCompaniesForGradingProgressError,
      data: getCompaniesForGradingProgressRes,
      stopPolling: getCompaniesForGradingProgressStopPolling,
    },
  ] = useLazyQuery(GetCompaniesForGradingProgressDocument, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    ...(user &&
      campaign && {
        variables: {
          data: {
            campaignId: campaign.id,
            creatorId: campaign.creatorId,
          },
        },
      }),
    // TODO: Resolve race condition with useEffect
    onCompleted: (data) => {
      if (data.getCompaniesForGradingProgress) {
        const { companies, percentage, stage, time, precision, recall } = data.getCompaniesForGradingProgress
        // const { stage, precision, recall, companies } = data.getModelGradingProgress
        if (stage === 'completed' && campaign && companies) {
          console.log('campaign.secondStepCompleted: ', campaign.secondStepCompleted)
          if (campaign && !campaign.secondStepCompleted) {
            const newCampaign = {
              ...campaign,
              secondStepCompleted: true,
            }

            client.cache.writeQuery({
              query: GetCampaignByIdDocument,
              data: {
                getCampaignById: newCampaign,
              },
            })
          }

          const newCompaniesExpandMap = { ...companiesExpandMap }

          companies.forEach((company) => {
            newCompaniesExpandMap[(company as CompanyFromStageCheck).id] = false
          })

          setCompaniesForReview(companies as CompanyFromStageCheck[])
          setCompaniesExpandMap(newCompaniesExpandMap)

          precision && setOnTargetPct(precision)
          recall && setSegmentCoveragePct(recall)
        }
      }
    },
  })
  const [
    getModelGradingProgress,
    { loading: getModelGradingProgressLoading, error: getModelGradingProgressError, data: getModelGradingProgressRes },
  ] = useLazyQuery(GetModelGradingProgressDocument, {
    ...(user &&
      campaign && {
        variables: {
          data: {
            campaignId: campaign.id,
            creatorId: campaign.creatorId,
          },
        },
      }),
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log('data.getModelGradingProgress: ', data.getModelGradingProgress)
      if (data.getModelGradingProgress) {
        const { stage, precision, recall, companies } = data.getModelGradingProgress
        if (stage === 'completed' && campaign && companies) {
          console.log('campaign.secondStepCompleted: ', campaign.secondStepCompleted)
          if (campaign && !campaign.secondStepCompleted) {
            const newCampaign = {
              ...campaign,
              secondStepCompleted: true,
            }

            client.cache.writeQuery({
              query: GetCampaignByIdDocument,
              data: {
                getCampaignById: newCampaign,
              },
            })
          }

          const newCompaniesExpandMap = { ...companiesExpandMap }

          companies.forEach((company) => {
            newCompaniesExpandMap[(company as CompanyFromStageCheck).id] = false
          })

          setCompaniesForReview(companies as CompanyFromStageCheck[])
          setCompaniesExpandMap(newCompaniesExpandMap)

          setOnTargetPct(precision)
          setSegmentCoveragePct(recall)
        }
      }
    },
  })

  useEffect(() => {
    // TODO: Implement getting saved precision and/or recall here or set in the backend in
    if (campaign && !campaign.duplicatedFromCampaignId && !campaign.improveAccuracy) {
      getModelGradingProgress()
    }
    if (campaign && !campaign.duplicatedFromCampaignId && campaign.improveAccuracy) {
      getCompaniesForGradingProgress()
    } else if (campaign && campaign.duplicatedFromCampaignId && campaign.companies && campaign.companies.length > 0) {
      const newCompaniesExpandMap = { ...companiesExpandMap }

      campaign.companies.forEach((company) => {
        newCompaniesExpandMap[(company as CompanyFromStageCheck).id] = false
      })

      setCompaniesForReview(campaign.companies as CompanyFromStageCheck[])
      setCompaniesExpandMap(newCompaniesExpandMap)

      setOnTargetPct(campaign?.precision as number)
      setSegmentCoveragePct(campaign?.recall as number)
    }
  }, [])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const onComapnyDescEndClick = (companyIdx: number) => {
    setCompaniesExpandMap({
      ...companiesExpandMap,
      [companyIdx]: !companiesExpandMap[companyIdx],
    })
  }

  const [getCompaniesMoreTrainBatch, { loading, error, data }] = useLazyQuery(GetCompaniesMoreTrainBatchDocument, {
    ...(user &&
      campaign && {
        variables: {
          data: {
            campaignId: campaign.id,
            creatorId: campaign.creatorId,
          },
        },
      }),
    fetchPolicy: 'network-only',
    async onCompleted(data) {
      await getMoreCompaniesForTeachingProgress()
      await handleCampaignUpdate()
    },
  })

  const handleCampaignUpdate = useCallback(() => {
    if (campaign?.id) {
      updateCampaign({
        variables: {
          data: {
            improveAccuracy: true,
            isModelTaught: false,
            secondStepCompleted: false,
            campaignId: campaign.id,
          },
        },
      })
    }
  }, [campaign?.id, updateCampaign])

  const handleTrainBatch = useCallback(async () => {
    await getCompaniesMoreTrainBatch()
  }, [getCompaniesMoreTrainBatch])

  return (
    <div className={css(styles.container)}>
      <TargetDescription name={campaign?.name} targetDesc={campaign?.targetingDescr} />
      <div className={css(styles.scoresContainer)}>
        <div className={css(styles.scoreContainer, styles.onTargetPctContainer)}>
          <Text smallSectionTitle>On-Target Percentage</Text>
          <Text extraStyles={[styles.score]}>{onTargetPct * 100}%</Text>
        </div>
        <div className={css(styles.scoreContainer)}>
          <Text smallSectionTitle>Segment Coverage Percentage</Text>
          <Text extraStyles={[styles.score]}>{segmentCoveragePct * 100}%</Text>
        </div>
        <div className={css(styles.improveButton)}>
          <Button onClick={handleTrainBatch} variant="contained">
            Improve Accuracy
          </Button>
        </div>
      </div>
      <Text smallSectionTitle extraStyles={[styles.sampleCompaniesTitle]}>
        Sample Company Results
      </Text>
      <div className={css(styles.tableAndButtonContainer)}>
        <Paper
          sx={{
            // width: {
            //   sm: 650,
            //   md: 660,
            //   lg: 'calc(180% - 120px)',
            //   xl: 'calc(200% - 200px)',
            // },
            width: 'calc(100% - 20px)',
            overflow: 'hidden',
          }}
        >
          <TableContainer
            sx={{
              maxHeight: {
                sm: 1240,
                md: 1240,
                lg: 540,
                xl: 540,
              },
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      sx={{
                        fontSize: 12,
                        lineHeight: '1rem',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {companiesForReview
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((company, companyIdx) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={company.id}>
                        {columns.map((column) => {
                          const value = company[column.id]

                          if (column.id === 'name') {
                            return (
                              <TableCell key={column.id} align={column.align} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                <div className={css(styles.companyNameContainer)}>
                                  <div
                                    style={{
                                      backgroundImage: `url(${company.logo_url})`,
                                      mixBlendMode: 'darken',
                                      filter: 'drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25))',
                                      backgroundSize: 35,
                                      width: 35,
                                      height: 35,
                                      marginRight: 10,
                                    }}
                                  />
                                  <div>
                                    <Text>{value}</Text>
                                    <div className={css(styles.linksContainer)}>
                                      {company.website && (
                                        <LinkIcon
                                          sx={{
                                            cursor: 'pointer',
                                            marginRight: 0.5,
                                          }}
                                          onClick={() => {
                                            window.open(company.website, '_blank')
                                          }}
                                        />
                                      )}
                                      {company.linkedin_url && (
                                        <LinkedInIcon
                                          sx={{
                                            cursor: 'pointer',
                                            marginRight: 0.5,
                                          }}
                                          onClick={() => {
                                            window.open(company.linkedin_url as string, '_blank')
                                          }}
                                        />
                                      )}
                                      {/* {company.twitterUrl && (
                                        <TwitterIcon
                                          sx={{ cursor: 'pointer', marginRight: 0.5 }}
                                          onClick={() => {
                                            window.open(company.twitterUrl, '_blank')
                                          }}
                                        />
                                      )}
                                      {company.facebookUrl && (
                                        <FacebookIcon
                                          sx={{ cursor: 'pointer', marginRight: 0.5 }}
                                          onClick={() => {
                                            window.open(company.facebookUrl, '_blank')
                                          }}
                                        />
                                      )} */}
                                    </div>
                                  </div>
                                </div>
                              </TableCell>
                            )
                          } else if (typeof value === 'string' && column.id === 'description') {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <TableCellWithReadMore text={value} />
                              </TableCell>
                            )
                          } else {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number' ? column.format(value) : value}
                              </TableCell>
                            )
                          }
                        })}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={newCampaignCompaniesForReview.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
        <LoadingButton
          color="primary"
          loading={startGettingLeadsLoading}
          // loadingPosition="start"
          endIcon={<ArrowForwardRoundedIcon />}
          variant="contained"
          sx={{
            alignSelf: 'start',
            boxShadow: '0px 2px 4px rgba(136, 144, 194, 0.2), 0px 5px 15px rgba(37, 44, 97, 0.15)',
            color: white,
            marginTop: '25px',
            maxWidth: 220,
            padding: '14px 26px',
            textTransform: 'none',
            '& .MuiInputBase-input': {
              fontFamily: 'Lato',
              fontSize: 14,
            },
          }}
          onClick={() => {
            startGettingLeads()
          }}
        >
          Get Leads
        </LoadingButton>
        <Dialog
          open={isGettingLeadsDialogOpen}
          onClose={() => {
            setIsGettingLeadsDialogOpen(false)
          }}
          aria-labelledby="getting-leads-dialog-title"
          aria-describedby="getting-leads-dialog-description"
        >
          <DialogTitle id="getting-leads-dialog-title">{'Generating Leads'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="getting-leads-dialog-description">
              We're searching for targeted leads across our database of millions of companies and contacts. We will
              notify you via email when the list is ready. This process includes email verification and can take up to
              10 minutes. You'll be able to export a lead list CSV from there.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => {
                navigate('/campaigns', { replace: true })
              }}
            >
              Return to Dashboard
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}

export default Review
