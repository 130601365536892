import { useEffect, useState } from 'react'
import { css } from 'aphrodite'
import { Link, useNavigate } from 'react-router-dom'
import Vector from '../../assets/icons/Vector.svg'
import Elipse from '../../assets/icons/Ellipse.svg'
import Authentication from '../../components/Authentication/Authentication'
import styles from '../../components/Authentication/style'
import Text from '../../components/Text'
import CustomIcon from '../../components/CustomIcon/CustomIcon'
import { ReactComponent as ResetPasswordSvg } from '../../assets/svg/reset_password.svg'
import InfoModal from '../../components/InfoModal/InfoModal'
import { SendPasswordResetLinkDocument } from '../../graphql/generated'
import { Button, CircularProgress, Grid, TextField, Typography, useMediaQuery } from '@mui/material'
import { useLazyQuery } from '@apollo/client'
import { useAuth } from '../../hooks/useAuth'
import GoogleSvg from '../../assets/icons/signup/google.svg'
import MicrosoftSvg from '../../assets/icons/signup/microsoft.svg'
import { getRedirectResult } from 'firebase/auth'
import { auth } from '../../firebase'
import { note, placeholder } from '../../shared/colors'
// import { SendEmailVerificationLink } from '../Signup/EmailVerificationModal'
// import useSignup from '../Signup/useSignup'

const LoginPage = () => {
  const {
    login,
    handleGoogleLogin,
    handleNavigate,
    handleMicrosoftLogin,
    message: toastMessage,
    setMessage: setToastMessage,
  } = useAuth()

  const isMedium = useMediaQuery('@media (max-width: 1550px)')
  const isMobileScreen = useMediaQuery('@media (max-width: 768px)')
  const navigate = useNavigate()

  // const { login, emailVerificationModal, setEmailVerificationModal } = useAuth()
  // const { resendLinkEmail, setUserEmail, userEmail } = useSignup()
  const [resetPassword, { loading: resetpasswordloading }] = useLazyQuery(SendPasswordResetLinkDocument)
  const [severity, setSeverity] = useState<string>('error')
  const [resetEmail, setResetEmail] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [redirectLoading, setRedirectLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [modal, setModal] = useState<boolean>(false)

  useEffect(() => {
    const isRedirectLoading = localStorage.getItem('isRedirectLoading')
    if (isRedirectLoading === 'true') {
      setRedirectLoading(true)
    }
    getRedirectResult(auth)
      .then((result) => {
        if (result && result.user) {
          console.log('Signed in user:', result.user)
          handleNavigate(result.user)
        }
      })
      .catch((error) => {
        console.error('Error during Google sign-in:', error)
        setRedirectLoading(false)
      })
      .finally(() => {
        setRedirectLoading(false)
        localStorage.removeItem('isRedirectLoading')
      })
  }, [])

  const sendResetPassowrdLink = async () => {
    if (resetEmail) {
      try {
        const resp = await resetPassword({
          variables: {
            userEmail: resetEmail,
          },
        })
        if (resp?.error) {
          throw new Error(resp.error.message)
        }
        if (resp) {
          setModal(false)
          setOpen(true)
          setSeverity('success')
          setMessage('Reset link sent please look at your email')
        }
      } catch (error: any) {
        setOpen(true)
        setMessage(error.message as unknown as string)
      }
    }
  }

  useEffect(() => {
    if (toastMessage) {
      setOpen(true)
      setMessage(toastMessage)
    }
  }, [toastMessage])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    setLoading(true)

    try {
      const userEmail = data.get('email') as string
      const password = data.get('password') as string
      await login(userEmail, password)
      // setUserEmail(userEmail)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setOpen(true)
      setMessage('You have entered an invalid email or password')
    }
  }

  if (redirectLoading) {
    return (
      <div className={css(styles.loadingOverlay)}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <Authentication
      toast={open}
      setToast={() => {
        setMessage('')
        setOpen(false)
        setToastMessage('')
      }}
      message={message}
      submit={handleSubmit}
      showForgetPassword
      severity={severity}
      labels={{ submitLabel: 'Log in', heading: 'Log in' }}
      loading={resetpasswordloading || loading}
      resetClick={() => {
        setModal(true)
      }}
    >
      {/* <div className={css(styles.signupContainer)}>
        <Text extraStyles={[styles.signupContainerText]}>
          Don't have an account?{' '}
          <Link to="/signup">
            <span className={css(styles.signupText)}>Sign up</span>
          </Link>
        </Text>
      </div> */}
      <InfoModal
        open={modal}
        handleClose={() => setModal(false)}
        defaultContentInput={{
          middleIcon: (
            <div style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }}>
              {/* <div style={{ position: 'relative', height: 100, width: 100, margin: 'auto' }}> */}
              <ResetPasswordSvg />
              {/* <CustomIcon svg={Elipse} style={{ position: 'absolute', top: 21, left: 21 }} height={60} width={60} />
                <CustomIcon svg={Vector} style={{ position: 'absolute', top: 38, left: 38 }} height={30} width={30} /> */}
              {/* </div> */}
            </div>
          ),
          subHeading2: (
            <Typography
              variant="subtitle1"
              style={{ textAlign: 'center', fontSize: 14, color: '#3C4257', marginTop: '36px', marginBottom: '24px' }}
            >
              Enter your email below. We will send a link to reset the password.
            </Typography>
          ),
          description: (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '8px',
              }}
            >
              <Typography sx={{ color: note, fontSize: '14px', fontWeight: 600 }}>Company Email</Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                // label="Company Email"
                name="email"
                autoComplete="email"
                autoFocus
                variant="outlined"
                placeholder="Your company email"
                // width="100%"
                sx={{
                  input: { paddingTop: '11px', paddingBottom: '11px', color: placeholder },
                  marginTop: 0,
                  '.MuiOutlinedInput-notchedOutline ': {
                    borderRadius: '8px',
                    border: '1px solid #E5E5E5',
                  },
                  '.css-ubour0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    border: '2px solid #CED3F1',
                  },
                  '.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#CED3F1 !important',
                  },
                }}
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </div>
          ),
          button: (
            // <div style={{ margin: 'auto', textAlign: 'center', marginTop: 10, width: '50%' }}>
            <Button
              disabled={!resetEmail || resetpasswordloading}
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '36px auto auto',
                textTransform: 'none',
                borderRadius: '16px',
                border: '1px solid rgba(55, 141, 250, 0.10)',
                background: '#FFF',
                boxShadow: '0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
                padding: '12px 36px',
                color: '#4750F0',
                fontSize: '18px',
                fontWeight: 600,
                height: 49,
              }}
              variant="contained"
              // fullWidth
              onClick={sendResetPassowrdLink}
            >
              Send
            </Button>
            // </div>
          ),
        }}
      />

      <Text extraStyles={[styles.forgetPasswordText, styles.textCenter, isMobileScreen && styles.textLeft]}>
        Don't have an account yet?{' '}
        <span
          className={css(styles.resetPasswordButton)}
          onClick={() => window.open('https://calendly.com/dougtanner/salezilla-open-discussion?month=2023-11')}
        >
          Book a demo with us
        </span>
      </Text>

      {/* <div>
        <Text extraStyles={[styles.signupORText]}>OR</Text>
        <Grid container className={css(styles.signUpWithButtons)} spacing={1}>
          <Grid item xs={12} lg={isMedium ? 12 : 6}>
            <Button
              variant="outlined"
              size="small"
              className={css(styles.buttonStyle)}
              onClick={() => {
                setRedirectLoading(true)
                localStorage.setItem('isRedirectLoading', 'true')
                handleGoogleLogin()
              }}
            >
              <img
                src={GoogleSvg}
                style={{
                  marginRight: '5px',
                }}
                alt="google"
              />
              Log in with Google
            </Button>
          </Grid>{' '}
          <Grid item xs={12} lg={isMedium ? 12 : 6}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setRedirectLoading(true)
                localStorage.setItem('isRedirectLoading', 'true')
                handleMicrosoftLogin()
              }}
              className={css(styles.buttonStyle)}
            >
              <img
                src={MicrosoftSvg}
                style={{
                  marginRight: '5px',
                }}
                alt="Microsoft"
              />{' '}
              Log in with Microsoft
            </Button>
          </Grid>
        </Grid>
      </div> */}
      {/* <SendEmailVerificationLink
        setModal={setEmailVerificationModal}
        modal={emailVerificationModal}
        resendLinkEmail={resendLinkEmail}
        userEmail={userEmail}
      /> */}
    </Authentication>
  )
}

export default LoginPage
