import { Constants } from '../../constants/constants'
import { Campaign } from '../../graphql/generated'

type StatusArgs = {
  campaign?: Campaign | null
  isGradingModel?: boolean
  isTeachingModel?: boolean
  isGettingCompanies?: boolean
}
export const getStatus = (args: StatusArgs) => {
  const { campaign, isGradingModel, isTeachingModel, isGettingCompanies } = args

  if (campaign?.isInDraft) {
    return { title: Constants.DELETE_DRAFT, message: Constants.DELETE_DRAFT_MESSAGE }
  } else if (campaign?.isGettingLeads || campaign?.isGettingCompanies) {
    return { title: Constants.DELETE_PROCESSING, message: Constants.DELETE_PROCESSING_MESSAGE }
  } else if (campaign?.isCompaniesExportReady) {
    return { title: Constants.DELETE_READY, message: Constants.DELETE_READY_MESSAGE }
  }
  if (isGradingModel) {
    return { title: Constants.GRADING_INPUT_TITLE, message: Constants.GRADING_INPUT_MESSAGE }
  }
  if (isTeachingModel) {
    return { title: Constants.TEACHING_INPUT_TITLE, message: Constants.TEACHING_INPUT_MESSAGE }
  }
  if (isGettingCompanies) {
    return { title: Constants.TARGETING_INPUT_TITLE, message: Constants.TARGETING_INPUT_MESSAGE }
  }
}
