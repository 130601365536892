import { StyleSheet } from 'aphrodite'

import { body } from '../../../shared/colors'

const styles = StyleSheet.create({
  header: {
    fontSize: '24px',
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 500px)': {
      marginBottom: '2rem',
    },
  },
  subHeading: {
    fontSize: '16px',
    color: body,
    '@media (max-width: 500px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  seatUsed: {
    color: '#697386',
    fontSize: 12,
    marginLeft: '10px',
    '@media (max-width: 768px)': {
      marginLeft: 0,
    },
  },
  addMemberButton: {
    textTransform: 'capitalize',
    fontSize: '12px',
    color: body,
    '@media (max-width: 936px)': {
      padding: '5px 6px',
    },
  },
  userInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '1.5rem',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
  },
  deleteButton: {
    color: '#E45A4F',
  },
  editButton: {
    color: '#727578',
  },
})

export default styles
