import { useEffect, useMemo, useState } from 'react'
import { NumEmployeeRange } from '../../../../graphql/generated'
import { numEmployeeRangesMap } from '../../../../shared/constants'
import { useCampaignAndUser } from '../../../NewCampaignLayout'
import SelectFormControl from './SelectFormControl'
import { WidgetMessage } from '../..'
import { isEqual } from 'lodash'

const CompanySizeSelector = (props: { handleTranslate: (widgetMessage?: WidgetMessage) => Promise<void> }) => {
  const { campaign } = useCampaignAndUser()
  const selectedPersona = campaign?.personas?.[0]
  const { handleTranslate } = props
  const [numEmployeeRanges, setNumEmployeeRanges] = useState<NumEmployeeRange[]>([])

  const numEmployeeRangesString = useMemo(
    () => numEmployeeRanges.map((r) => r.charAt(0).toUpperCase() + r.slice(1)),
    [numEmployeeRanges],
  )

  useEffect(() => {
    if (selectedPersona?.numEmployeeRanges) {
      setNumEmployeeRanges(selectedPersona.numEmployeeRanges as NumEmployeeRange[])
    }
  }, [setNumEmployeeRanges, selectedPersona?.numEmployeeRanges])

  return (
    <>
      <div
        style={{
          width: '100%',
          padding: '12px 8px',
          borderRadius: '16px',
          background: '#FFF',
        }}
      >
        <SelectFormControl
          title="Company Size"
          labelId="multiple-numEmployeeRanges-label"
          selectId="multiple-numEmployeeRanges"
          values={numEmployeeRangesString}
          errorCondition={false}
          errorMessage="Please select # of employees"
          placeholder="Select target company employee range"
          bg="none"
          color="#5F69C1"
          onChange={(value: string[]) => {
            setNumEmployeeRanges(value as NumEmployeeRange[])
          }}
          onClose={() => {
            const selectedValues = numEmployeeRanges?.map(
              (r) => numEmployeeRangesMap[`${r?.[0].toUpperCase()}${r?.slice(1)}`],
            )

            if (selectedValues && !isEqual(selectedPersona?.numEmployeeRanges, numEmployeeRanges)) {
              handleTranslate({
                message: `Update Company Size: ${selectedValues.join(', ')}`,
                type: 'not_to_show',
                widget: 'company_size',
                value: selectedValues,
              })
            }
          }}
          valueMap={numEmployeeRangesMap}
          items={Object.keys(NumEmployeeRange)}
        />
      </div>
    </>
  )
}

export default CompanySizeSelector
