import { Button, Box, Typography, Grid, CircularProgress, LinearProgress, Divider } from '@mui/material'
import { css } from 'aphrodite'
// import { KeyboardArrowDown } from '@mui/icons-material'
import WaitingSvg from '../../../assets/svg/waiting.svg'
import CompanyList from './ListItem'
import styles from './style'
import { GetCampaignStatusRes, GetCompaniesForLabeling } from '../../../graphql/generated'
import moment from 'moment'
import LabelListHeader from './LabelListHeader'
import { useEffect, useMemo, useState } from 'react'
import InfoToolTip from '../TargetingInput/InfoToolTip/InfoToolTip'
import { useGraphQL } from '../../../context/GraphQLContext'
import { useCampaignAndUser } from '../../../components/NewCampaignLayout'
import CompanyTable from './CompanyTable/CompanyTable'
import ShowNotice from '../../../components/ShowNotice/ShowNotice'

const LabellingListView = ({
  loading,
  is_completed,
  handleMoreCompanies,
  companiesForLabeling,
  time,
  percentage,
  campaignStatus,
  tableRef,
  warningNotice,
}: {
  companiesForLabeling: GetCompaniesForLabeling[] | []
  is_completed: boolean
  loading?: boolean
  handleMoreCompanies: () => void
  time: number
  percentage: number
  campaignStatus?: GetCampaignStatusRes
  warningNotice?: any
  tableRef: React.RefObject<HTMLDivElement>
}) => {
  const formatRemainingTime = (seconds: number) => {
    const duration = moment.duration(seconds, 'seconds')
    const hours = Math.floor(duration.asHours())
    const minutes = Math.floor(duration.asMinutes()) % 60
    const formattedTime = `${hours} hour ${minutes} minute remain`

    return formattedTime
  }

  const [open, setOpen] = useState(false)
  const [notice, setNotice] = useState<string>('')

  const [openAccurateTooltip, setOpenAccurateTooltip] = useState(false)
  const { updateCampaign } = useGraphQL()
  const { campaign } = useCampaignAndUser()

  const handleAccurateTooltip = () => {
    setOpenAccurateTooltip(true)
    updateCampaign({
      variables: {
        data: {
          campaignId: campaign?.id as string,
          hasSecondRexOnboardingNoticeBeenShown: true,
        },
      },
    })
  }

  useEffect(() => {
    if (campaign?.hasFirstRexOnboardingNoticeBeenShown && !campaign?.hasSecondRexOnboardingNoticeBeenShown) {
      handleAccurateTooltip()
    }
  }, [])

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const totalLabeledCompanies = companiesForLabeling.filter((company) => company.user_label !== '')

  const handleTooltipOpen = () => {
    const missingCompanyLabel = companiesForLabeling.find((company) => company.user_label === '')
    if (missingCompanyLabel) {
      setOpen(true)
      return
    }
    handleMoreCompanies()
  }

  const getNotice = useMemo(() => {
    if (!campaignStatus?.evaluated_on_first_10_labels) {
      return 'It takes 2 minutes for the model to estimate the result. You could label more to get more accurate results.'
    } else if (!campaignStatus?.evaluated_on_new_label) {
      return 'Model is processing the companies you just labeled. You could always label more to keep improving accurate leads number.'
    } else if (
      campaignStatus?.evaluated_on_first_10_labels &&
      campaignStatus?.evaluated_on_new_label &&
      (campaign?.desiredNumberOfLeads as number) > campaignStatus?.accurate_leads
    ) {
      return `Let’s label more to get ${campaign?.desiredNumberOfLeads} leads! On average, labeling 20 companies can teach Rex to achieve greater accurate results.`
    }
    const first10Labels = localStorage.getItem('first10Labels')
    if (!first10Labels) {
      return `The first 10 companies you’ve labeled have already been incorporated by the model. The accurate leads number will keep improving while you are labeling.`
    }
    return `We now have more accurate leads than you requested! Hit “Get leads” to check the result quality at anytime. You could always label more companies to get more leads.`
  }, [
    campaign?.desiredNumberOfLeads,
    campaignStatus?.accurate_leads,
    campaignStatus?.evaluated_on_first_10_labels,
    campaignStatus?.evaluated_on_new_label,
  ])

  useEffect(() => {
    setNotice(getNotice)

    if (
      getNotice ===
      `The first 10 companies you’ve labeled have already been incorporated by the model. The accurate leads number will keep improving while you are labeling.`
    ) {
      localStorage.setItem('first10Labels', 'true')
      const timeout = setTimeout(() => {
        setNotice(
          `We now have more accurate leads than you requested! Hit “Get leads” to check the result quality at anytime. You could always label more companies to get more leads.`,
        )
      }, 15000)

      return () => clearTimeout(timeout)
    }
  }, [getNotice])

  return (
    <Box className={css(styles.LabelCompanyContainer)} component="div">
      {/* label list header */}
      <LabelListHeader
        campaignStatus={campaignStatus}
        is_completed={is_completed}
        openAccurateTooltip={openAccurateTooltip}
        totalLabeledCompanies={totalLabeledCompanies?.length}
        handleTooltipClose={() => {
          setOpenAccurateTooltip(false)
        }}
      />
      {time !== 0 && (
        <Box sx={{ width: '100%' }}>
          <Typography my={1} variant="body1" className={css(styles.loadingBarTime)}>
            <img src={WaitingSvg} alt="waiting" />
            {formatRemainingTime((time as number) || 0)}{' '}
          </Typography>
          <LinearProgress variant="determinate" color="primary" value={percentage} />
        </Box>
      )}
      {warningNotice && warningNotice}
      <ShowNotice
        notice={notice}
        showInfoIcon={
          !campaignStatus?.evaluated_on_first_10_labels ||
          !campaignStatus?.evaluated_on_new_label ||
          (campaign?.desiredNumberOfLeads as number) > campaignStatus?.accurate_leads
        }
      />
      {/* <Button
        endIcon={<KeyboardArrowDown />}
        className={css(styles.ViewInstructionButton, styles.ViewDetailButtonLabel)}
      >
        View Instruction
      </Button> */}
      {/* <Typography className={css(styles.Notice)} component="p">
        The AI gives you its opinions on whether a company fits or doesn’t fit your criteria, please express whether you
        think the company fits or doesn’t fit. Try to find patterns in the AI’s predictions, and correct the target
        description accordingly.
      </Typography> */}

      <CompanyTable
        open={open}
        loading={loading}
        tableRef={tableRef}
        is_completed={is_completed}
        handleTooltipOpen={handleTooltipOpen}
        handleTooltipClose={handleTooltipClose}
        companiesForLabeling={companiesForLabeling}
        handleAccurateTooltip={handleAccurateTooltip}
      />
    </Box>
  )
}

export default LabellingListView
