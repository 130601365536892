import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { useState } from 'react'
import { css } from 'aphrodite'

import AddLeadsListIcon from './../../../../assets/icons/next_page'
import styles from './styles'

const CreateLeadsListBtn = () => {
  const [isHovered, setIsHovered] = useState(false)
  const navigate = useNavigate()

  const handleCreateLeadlist = () => {
    navigate('/new-campaign/targeting-input')
  }

  return (
    <Button
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleCreateLeadlist}
      className={css(styles.createLeadListBtn)}
      variant="contained"
      startIcon={<AddLeadsListIcon isHovered={isHovered} />}
    >
      Create your first leads list
    </Button>
  )
}

export default CreateLeadsListBtn
