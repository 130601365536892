import { Grid, Typography, TextField } from '@mui/material'
import { FC, memo } from 'react'
import { css } from 'aphrodite'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import styles from './styles'
import { useCampaignAndUser } from '../../../../components/NewCampaignLayout'
import { GetCampaignStatusRes } from '../../../../graphql/generated'

interface LeadsResultStep2Props {
  campaignStatus?: GetCampaignStatusRes
  formik: any
}

const LeadsResultStep2: FC<LeadsResultStep2Props> = ({ campaignStatus, formik }) => {
  const { campaign, user } = useCampaignAndUser()

  const selectedFilters = campaign?.personas?.[0]

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setValues({ ...formik.values, [event.target.name]: parseInt(event.target.value) })
  }

  const { values, errors } = formik

  return (
    <Grid p={1} container>
      <Grid item xs={12}>
        <div className={css(styles.dialogFormContainer)}>
          <Typography variant="body1" component="h2" sx={{ color: '#4CAF50', fontSize: '18px' }}>
            Step 2:
          </Typography>
          <Typography variant="body1" component="h2" sx={{ color: '#697386', fontSize: '14px' }}>
            Confirm desired number of accurate leads and max leads per company
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} className={css(styles.dialogFormCardContainer)}>
        <div className={css(styles.dialogFormCard)}>
          <Typography variant="body1" component="h2" className={css(styles.dialogFormCardTitle)}>
            Accurate leads number
          </Typography>
          <Typography variant="body1" component="h2" sx={{ fontSize: '30px' }}>
            {campaignStatus?.accurate_leads}
          </Typography>
        </div>
        <div className={css(styles.dialogFormCard)}>
          <Typography variant="body1" component="h2" className={css(styles.dialogFormCardTitle)}>
            Potential leads available
          </Typography>
          <Typography variant="body1" component="h2" sx={{ fontSize: '30px' }}>
            {campaignStatus?.db_leads_estimate}
          </Typography>
        </div>
        <div className={css(styles.dialogFormThirdCard)}>
          <Typography variant="body1" component="h2" className={css(styles.dialogFormCardTitle)}>
            Your credit available
          </Typography>
          <Typography variant="body1" component="h2" sx={{ fontSize: '30px' }}>
            {user?.company?.creditsAvailable}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} className={css(styles.dialogFormContainer)}>
        <div className={css(styles.dialogFormFieldContainer)}>
          <Typography variant="body1" className={css(styles.dialogFormFieldTitle)}>
            Desired number of accurate leads
          </Typography>
          <TextField
            type="number"
            fullWidth
            label=""
            value={values.max_leads_to_grab}
            id="max_leads_to_grab"
            name="max_leads_to_grab"
            size="small"
            onChange={handleChange}
          />
        </div>
        <div className={css(styles.dialogFormFieldContainer)}>
          <Typography variant="body1" className={css(styles.dialogFormFieldTitle)}>
            Max leads per company
          </Typography>
          <TextField
            type="number"
            fullWidth
            label=""
            value={values.limit_contacts_per_company}
            id="limit_contacts_per_company"
            name="limit_contacts_per_company"
            size="small"
            onChange={handleChange}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        {errors?.max_leads_to_grab && (
          <div className={css(styles.dialogFormTextContainer)}>
            <ErrorOutlineIcon sx={{ color: '#E45A4F' }} />
            <Typography variant="body2" sx={{ color: '#697386' }}>
              {errors?.max_leads_to_grab}
            </Typography>
          </div>
        )}
        <div className={css(styles.dialogFormTextWrapper)}>
          <Typography variant="body1" sx={{ fontSize: '16px' }}>
            People filters
          </Typography>
          <div className={css(styles.dialogFormTextSubWrapper)}>
            <Typography variant="body1" className={css(styles.textTitles)}>
              Example titles
            </Typography>
            <Typography variant="body1" className={css(styles.textSubTitles)}>
              {selectedFilters?.exampleTitles?.join(', ')}
            </Typography>
          </div>
          <div className={css(styles.dialogFormTextSubWrapper)}>
            <Typography variant="body1" className={css(styles.textTitles)}>
              Title description
            </Typography>
            <Typography variant="body1" className={css(styles.textSubTitles)}>
              {selectedFilters?.descr}
            </Typography>
          </div>
          <div className={css(styles.textWrapper)}>
            <div className={css(styles.dialogFormTextSubWrapper)}>
              <Typography variant="body1" className={css(styles.textTitles)}>
                Management Levels
              </Typography>
              <Typography variant="body1" className={css(styles.textSubTitles)}>
                {selectedFilters?.managementLevels?.join(', ')}
              </Typography>
            </div>
            <div style={{ borderBottom: '1px solid #E5E5E6', width: '100%' }}>
              <Typography variant="body1" className={css(styles.textTitles)}>
                Exclude title keywords
              </Typography>
              <Typography variant="body1" className={css(styles.textSubTitles)}>
                {selectedFilters?.titleExcludeKeywords?.join(', ')}
              </Typography>
            </div>
          </div>
          <div className={css(styles.dialogFormTextSubWrapper_I)}>
            <Typography variant="body1" className={css(styles.textTitles)}>
              Contact Location(s)
            </Typography>
            <Typography variant="body1" className={css(styles.textSubTitles)}>
              {selectedFilters?.contactLocations?.map((item) => item?.country)?.join(', ')}
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default memo(LeadsResultStep2)
