import { memo, useEffect, useState } from 'react'
import { ButtonGroup, Divider } from '@mui/material'
import TickIcon from '../../../assets/images/tick.svg'
import LabelNoHintSvg from '../../../assets/images/label_no_hint.svg'
import UserLabelNoSvg from '../../../assets/images/user_label_no.svg'
import UserLabelYesSvg from '../../../assets/images/user_label_yes.svg'
import UserLabeledYesSvg from '../../../assets/svg/user_labeled_yes.svg'
import UserLabeledNoSvg from '../../../assets/svg/user_labeled_no.svg'
import UserLabeledUnclearSvg from '../../../assets/svg/user_labeled_unclear.svg'
import Question from '../../../assets/images/question.svg'
import {
  Campaign,
  GetCompaniesForLabelingDocument,
  LabelCompanyDocument,
  LabelingCompany,
} from '../../../graphql/generated'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useCampaignAndUser } from '../../../components/NewCampaignLayout'
import ButtonRenderer from './ButtonRenderer'
import { useTooltip } from './TooltipContext/TooltipContext'
import client from '../../../apolloClient'
import { useGraphQL } from '../../../context/GraphQLContext'

type StatusRendererInput = {
  recommendedOption: { _class: string; toolTip: string }
  company: LabelingCompany
  machineLabel: string
  userLabel: string
  handleAccurateTooltip: () => void
  companyIndex?: number
  totalLabled: number
}

export const BUTTONS_CLASSES = {
  TICK: 'y',
  CROSS: 'n',
  QUESTION: '?',
}

const StatusRenderer: React.FC<StatusRendererInput> = ({
  recommendedOption,
  company,
  machineLabel,
  userLabel,
  handleAccurateTooltip,
  companyIndex,
}) => {
  const { campaign } = useCampaignAndUser()
  const { setOpenTooltipId } = useTooltip()
  const { updateCampaign } = useGraphQL()

  const [getCompaniesForLabeling] = useLazyQuery(GetCompaniesForLabelingDocument, {
    fetchPolicy: 'cache-and-network',
  })
  const [labelCompany] = useMutation(LabelCompanyDocument)

  const [activeButton, setActiveButton] = useState(userLabel)

  useEffect(() => {
    if (!campaign?.hasFirstRexOnboardingNoticeBeenShown) {
      setOpenTooltipId(0)
    }
  }, [campaign?.hasFirstRexOnboardingNoticeBeenShown, setOpenTooltipId])

  const changeButtonStatus = (status: string) => () => {
    if (campaign) {
      labelCompany({
        variables: {
          data: {
            campaignId: campaign.id,
            creatorId: campaign.creatorId,
            company: { id: company.id as number, label: status },
          },
        },
      }).then(async () => {
        const queryData = await getCompaniesForLabeling({
          variables: {
            data: {
              campaignId: campaign?.id as string,
              creatorId: campaign?.creatorId as string,
              getMoreCompanies: false,
              order: 'asc',
            },
          },
        })

        const queryDataCompanies = queryData?.data?.getCompaniesForLabeling?.companies

        if (queryDataCompanies && queryData?.data?.getCompaniesForLabeling) {
          const updatedCompanies = queryDataCompanies?.map((element) => {
            const updatedElement = { ...element }

            if (updatedElement.company_info_id === company.id) {
              updatedElement.user_label = status
            }

            return updatedElement
          })
          const labeledInitialFiveCompanies = updatedCompanies
            ?.slice(0, 5)
            ?.filter((company) => company.user_label !== '')
          const labeledFiveCompanyAgainstMachine = labeledInitialFiveCompanies?.find(
            (company) => company.user_label !== '' && company.user_label !== company.machine_label,
          )

          if (
            campaign?.id &&
            !campaign?.showInitialNextFiveCompanies &&
            labeledInitialFiveCompanies?.length === 5 &&
            labeledFiveCompanyAgainstMachine
          ) {
            updateCampaign({
              variables: {
                data: {
                  campaignId: campaign.id,
                  enableRewriteDescr: true,
                },
              },
            })
          }
          if (
            campaign &&
            !campaign?.showInitialNextFiveCompanies &&
            labeledInitialFiveCompanies?.length === 5 &&
            !labeledFiveCompanyAgainstMachine
          ) {
            updateCampaign({
              variables: {
                data: {
                  campaignId: campaign.id,
                  showInitialNextFiveCompanies: true,
                },
              },
            })
          }

          const labeledNextFiveCompanies = updatedCompanies
            ?.slice(5, 10)
            ?.filter((company) => company.user_label !== '')
          const labeledNextFiveCompanyAgainstMachine = labeledNextFiveCompanies?.find(
            (company) => company.user_label !== '' && company.user_label !== company.machine_label,
          )
          if (
            campaign &&
            campaign?.showInitialFiveCompanies &&
            campaign?.showInitialNextFiveCompanies &&
            labeledNextFiveCompanies?.length === 5 &&
            labeledNextFiveCompanyAgainstMachine
          ) {
            updateCampaign({
              variables: {
                data: {
                  campaignId: campaign.id,
                  enableRewriteDescr: true,
                },
              },
            })
          }
          client.cache.writeQuery({
            query: GetCompaniesForLabelingDocument,
            variables: {
              data: {
                campaignId: campaign?.id as string,
                creatorId: campaign?.creatorId as string,
              },
            },
            data: {
              getCompaniesForLabeling: {
                ...queryData?.data?.getCompaniesForLabeling,
                companies: updatedCompanies,
              },
            },
          })
        }
      })
      setActiveButton(() => (status === activeButton ? '' : status))
    }

    setOpenTooltipId(companyIndex)
    localStorage.removeItem('firstActiveItem')
  }

  return (
    <ButtonGroup
      variant="text"
      sx={{
        gap: '6px',
        '& .MuiButtonGroup-grouped:not(:last-of-type)': {
          borderRight: 'none',
        },
      }}
    >
      <ButtonRenderer
        {...([BUTTONS_CLASSES.CROSS, BUTTONS_CLASSES.QUESTION].includes(activeButton) &&
          BUTTONS_CLASSES.TICK === recommendedOption._class && { tooltip: recommendedOption.toolTip })}
        icon={activeButton === 'y' ? UserLabeledYesSvg : machineLabel === 'y' ? TickIcon : UserLabelYesSvg}
        onClick={changeButtonStatus(activeButton === BUTTONS_CLASSES.TICK ? '' : BUTTONS_CLASSES.TICK)}
        {...(activeButton === BUTTONS_CLASSES.TICK && { isActive: true })}
        firstActiveTooltip={activeButton === 'n' ? activeButton !== machineLabel : false}
        campaign={campaign as Campaign}
        handleAccurateTooltip={handleAccurateTooltip}
        initialTooltip={
          companyIndex === 0 && machineLabel === 'y' && (!campaign?.hasFirstRexOnboardingNoticeBeenShown as boolean)
        }
        companyIndex={companyIndex}
      />
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ background: '#E5E5E5', marginTop: '5px', marginBottom: '5px', borderWidth: 0.7 }}
      />
      <ButtonRenderer
        {...([BUTTONS_CLASSES.TICK, BUTTONS_CLASSES.QUESTION].includes(activeButton) &&
          BUTTONS_CLASSES.CROSS === recommendedOption._class && { tooltip: recommendedOption.toolTip })}
        icon={activeButton === 'n' ? UserLabeledNoSvg : machineLabel === 'n' ? LabelNoHintSvg : UserLabelNoSvg}
        onClick={changeButtonStatus(activeButton === BUTTONS_CLASSES.CROSS ? '' : BUTTONS_CLASSES.CROSS)}
        {...(activeButton === BUTTONS_CLASSES.CROSS && { isActive: true })}
        campaign={campaign as Campaign}
        firstActiveTooltip={activeButton === 'y' ? activeButton !== machineLabel : false}
        handleAccurateTooltip={handleAccurateTooltip}
        initialTooltip={
          companyIndex === 0 && machineLabel === 'n' && (!campaign?.hasFirstRexOnboardingNoticeBeenShown as boolean)
        }
        companyIndex={companyIndex}
      />
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ background: '#E5E5E5', marginTop: '5px', marginBottom: '5px', borderWidth: 0.7 }}
      />
      <ButtonRenderer
        {...([BUTTONS_CLASSES.TICK, BUTTONS_CLASSES.CROSS].includes(activeButton) &&
          BUTTONS_CLASSES.QUESTION === recommendedOption._class && { tooltip: recommendedOption.toolTip })}
        icon={activeButton === '?' ? UserLabeledUnclearSvg : Question}
        onClick={changeButtonStatus(activeButton === BUTTONS_CLASSES.QUESTION ? '' : BUTTONS_CLASSES.QUESTION)}
        {...(activeButton === BUTTONS_CLASSES.QUESTION && {
          isActive: true,
        })}
        campaign={campaign as Campaign}
        handleAccurateTooltip={handleAccurateTooltip}
        companyIndex={companyIndex}
      />
    </ButtonGroup>
  )
}

export default memo(StatusRenderer)
