import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  mainTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  dialogFormContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '24px',
  },
  dialogFormCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '48px',
    width: '100%',
    marginBottom: '28px',
    marginTop: '28px',
  },
  dialogFormCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    borderRight: '2px solid #E5E5E6',
    paddingRight: '48px',
  },
  dialogFormCardTitle: {
    fontSize: '14px',
    color: '#A3AAB7',
  },
  dialogFormThirdCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
  dialogFormFieldContainer: {
    width: '100%',
  },
  dialogFormFieldTitle: {
    fontSize: '16px',
    marginBottom: '13px',
  },
  dialogFormTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'rgba(193, 201, 210, 0.1)',
    border: '0.5px solid rgba(105, 115, 134, 0.2)',
    borderRadius: '4px',
    padding: '4px 16px',
    marginBottom: '24px',
    marginTop: '12px',
    gap: '8px',
  },
  dialogFormTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px',
    gap: '12px',
    background: '#FCFCFD',
    border: '1px solid #E5E5E6',
    borderRadius: '8px',
  },
  dialogFormTextSubWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    borderBottom: '1px solid #E5E5E6',
    paddingBottom: '2px',
    width: '100%',
  },
  dialogFormTextSubWrapper_I: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    borderBottom: '1px solid #E5E5E6',
    paddingBottom: '2px',
    width: '25%',
  },
  textWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
  },
  textTitles: {
    fontSize: '14px',
    color: '#3C4257',
  },
  textSubTitles: {
    fontSize: '12px',
    color: '#697386',
  },
  dialogFormButtonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 10px 24px',
    gap: '20px',
  },
  containedButton: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 700,
    fontSize: '18px',
    background: '#378DFA',
    boxShadow: '0px 0px 36px rgba(34, 82, 76, 0.3)',
    borderRadius: '8px',
    textTransform: 'none',
    textAlign: 'center',
    padding: '10px 56px',
    gap: '2px',
  },
  outlinedButton: {
    border: '1px solid #378DFA',
    boxShadow: '0px 4px 12px rgba(99, 99, 99, 0.13)',
    borderRadius: '6px',
    color: '#378DFA',
    textTransform: 'none',
  },
})

export default styles
