import { Grid, Typography } from '@mui/material'
import { FC, memo } from 'react'
import { css } from 'aphrodite'

import styles from './styles'

interface LeadsDataIndicatorProps {
  title: string
  value: string | number
  font: string
}

const LeadsDataIndicator: FC<LeadsDataIndicatorProps> = ({ title, value, font }) => {
  return (
    <div className={css(styles.dataIndicatorCard)}>
      <Typography variant="body1" className={css(styles.dataIndicatorCardTitle)}>
        {title}
      </Typography>
      <Typography variant="body1" className={css(styles.dataIndicatorCardValue)} fontSize={font}>
        {value}
      </Typography>
    </div>
  )
}

export default memo(LeadsDataIndicator)
