import { createContext, useContext, FC, ReactNode, useState, useCallback } from 'react'
import { Location, ManagementLevel, NumEmployeeRange } from '../../../graphql/generated'

export interface State {
  descr: string
  exampleTitles: string[]
  numEmployeeRanges: NumEmployeeRange[]
  managementLevels: ManagementLevel[]
  hqLocations: Location[]
}

const initialState: State = {
  descr: '',
  exampleTitles: [],
  numEmployeeRanges: [],
  managementLevels: [],
  hqLocations: [],
}

interface ChatContextType {
  state: State
  setState: React.Dispatch<React.SetStateAction<State>>
  validateForm: () => boolean
  showError: boolean
  setShowError: React.Dispatch<React.SetStateAction<boolean>>
}

export const ChatContext = createContext<ChatContextType | undefined>(undefined)

interface ChatProviderProps {
  children: ReactNode
}

export const ChatProvider: FC<ChatProviderProps> = ({ children }) => {
  const [state, setState] = useState<State>(initialState)
  const [showError, setShowError] = useState<boolean>(false)

  const { descr, exampleTitles, numEmployeeRanges, managementLevels, hqLocations } = state

  const validateForm = useCallback(() => {
    if (
      !descr ||
      exampleTitles.length === 0 ||
      hqLocations.length === 0 ||
      numEmployeeRanges.length === 0 ||
      managementLevels.length === 0
    ) {
      setShowError(true)
      return false
    }
    setShowError(false)
    return true
  }, [descr, exampleTitles.length, hqLocations.length, managementLevels.length, numEmployeeRanges.length])

  const contextValue: ChatContextType = {
    state,
    setState,
    validateForm,
    showError,
    setShowError,
  }

  return <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
}

export const useChatContext = (): ChatContextType => {
  const context = useContext(ChatContext)
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider')
  }
  return context
}
