import { FC, useCallback, useState } from 'react'
import { TextareaAutosize } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { css } from 'aphrodite'

import TargetDescription from '../../../../pages/NewCampaign/TargetDescription/TargetDescription'
import { Location, ManagementLevel, NumEmployeeRange, UpdateTargetingInfoDocument } from '../../../../graphql/generated'
import { numEmployeeRangesMap } from '../../../../shared/constants'
import { State, useChatContext } from '../../context/ChatContext'
import LocationAutocomplete from '../../../LocationAutocomplete'
import { useCampaignAndUser } from '../../../NewCampaignLayout'
import { useGraphQL } from '../../../../context/GraphQLContext'
import SelectFormControl from './SelectFormControl'
import Text from '../../../Text'
import styles from './style'
import { useMutation } from '@apollo/client'
import DesiredLeadsNumber from './DesiredLeadsNumber'
import JobTitles from '../JobTitles'
import Toast from '../../../Toast/Toast'
import { managementLevelValues } from './ManagementLevelSelector'

interface ChatSummaryFormProps {}

const TOAST_MESSAGE = 'Change saved. Rex will get you new sets of companies on the next page based on your new changes.'

const ChatSummaryForm: FC<ChatSummaryFormProps> = () => {
  const { campaign, user } = useCampaignAndUser()
  const [open, setOpen] = useState(false)
  const { state, setState, showError } = useChatContext()
  const selectedPersona = campaign?.personas?.[0]
  const { handlePersonaCreateOrUpdate } = useGraphQL()
  const isRexPage = true

  const [updateTargetingInfo] = useMutation(UpdateTargetingInfoDocument)

  const [createContactLocationStr, setContactLocationStr] = useState('')
  const [createContactLocationOptions, setCreateContactLocationOptions] = useState([] as Location[])
  const [isCreateContactAutocompleteOpen, setIsCreateContactAutocompleteOpen] = useState(false)
  const [initialRender, setInitialRender] = useState(true)

  const { descr, exampleTitles, numEmployeeRanges, managementLevels, hqLocations } = state

  const setDescription = useCallback(
    (value: string) => setState((prevState: State) => ({ ...prevState, descr: value })),
    [setState],
  )
  const setPersonaTitle = useCallback(
    (value: string[]) => setState((prevState: State) => ({ ...prevState, exampleTitles: value })),
    [setState],
  )
  const setNumEmployeeRanges = useCallback(
    (value: NumEmployeeRange[]) => setState((prevState: State) => ({ ...prevState, numEmployeeRanges: value })),
    [setState],
  )
  const setManagementLevels = useCallback(
    (value: ManagementLevel[]) => setState((prevState: State) => ({ ...prevState, managementLevels: value })),
    [setState],
  )
  const setSelectedLocations = useCallback(
    (value: Location[]) => setState((prevState: State) => ({ ...prevState, hqLocations: value })),
    [setState],
  )

  const numEmployeeRangesString = useMemo(
    () => numEmployeeRanges.map((r) => r.charAt(0).toUpperCase() + r.slice(1)),
    [numEmployeeRanges],
  )

  useEffect(() => {
    if (selectedPersona) {
      setNumEmployeeRanges(selectedPersona.numEmployeeRanges as NumEmployeeRange[])
      setDescription(selectedPersona.descr ?? '')
      setPersonaTitle((selectedPersona.exampleTitles as string[]) ?? '')
      setSelectedLocations(selectedPersona.hqLocations as Location[])
      setManagementLevels((selectedPersona.managementLevels as ManagementLevel[]) ?? [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDescription, setManagementLevels, setNumEmployeeRanges, setPersonaTitle, setSelectedLocations])

  const handleUpdateTargetingInfo = useCallback(() => {
    if (campaign?.id) {
      updateTargetingInfo({
        variables: {
          data: { campaignId: campaign?.id },
        },
      }).then(() => {
        if (campaign?.showInitialFiveCompanies) {
          setOpen(true)
        }
      })
    }
  }, [campaign?.id, campaign?.showInitialFiveCompanies, updateTargetingInfo])

  const handlePersonaUpdate = () => {
    if (user) {
      handlePersonaCreateOrUpdate(
        user,
        {
          id: selectedPersona?.id as string,
          ...state,
          numEmployeeRanges: state?.numEmployeeRanges.map((item) => item.toLowerCase()) as NumEmployeeRange[],
        } as any,
        () => {
          handleUpdateTargetingInfo()
        },
      )
    }
  }

  useEffect(() => {
    if (!initialRender) {
      handlePersonaUpdate()
    }
  }, [state, initialRender])

  return (
    <div id="chat-summary-form" style={{ width: '100%' }}>
      <Toast message={TOAST_MESSAGE} open={open} setOpen={setOpen} severity="success" />
      <Text
        inlineStyle={{
          marginBottom: 12,
        }}
        smallSectionTitle
      >
        Target Company Description
      </Text>
      <TargetDescription
        handleCreateTargetingInfo={() => {
          handleUpdateTargetingInfo()
          setInitialRender(false)
        }}
        targetingDescr={campaign?.targetingDescr as string}
        showEditButton={false}
        showError={showError}
        isRexPage={isRexPage}
      />
      <div style={{ marginTop: '36px' }}>
        <Text smallSectionTitle>Desired Leads Number</Text>
        <DesiredLeadsNumber showError={showError} />
      </div>

      <div style={{ marginTop: '36px' }}>
        <Text smallSectionTitle inlineStyle={{ marginBottom: '12px' }}>
          About Company
        </Text>
        <SelectFormControl
          title="Company Size"
          labelId="multiple-numEmployeeRanges-label"
          selectId="multiple-numEmployeeRanges"
          values={numEmployeeRangesString}
          errorCondition={showError && numEmployeeRanges.length === 0}
          errorMessage="Please select # of employees"
          placeholder="Select target company employee range"
          bg="none"
          color="#5F69C1"
          onChange={(value: string[]) => {
            setNumEmployeeRanges(value as NumEmployeeRange[])
            setInitialRender(false)
          }}
          valueMap={numEmployeeRangesMap}
          items={Object.keys(NumEmployeeRange)}
        />

        <Text extraStyles={[styles.textStyle]}>HQ Locations</Text>
        <LocationAutocomplete
          placeholder={hqLocations && hqLocations?.length > 0 ? '' : 'Enter a City/ State/ Country'}
          label="HQ Locations"
          showError={showError && hqLocations.length === 0}
          createPersonaLocationStr={createContactLocationStr}
          setCreatePersonaLocationStr={setContactLocationStr}
          createPersonaLocationOptions={createContactLocationOptions}
          setCreatePersonaLocationOptions={setCreateContactLocationOptions}
          selectedLocations={hqLocations}
          setSelectedLocations={setSelectedLocations}
          isCreatePersonaAutocompleteOpen={isCreateContactAutocompleteOpen}
          setIsCreatePersonaAutocompleteOpen={setIsCreateContactAutocompleteOpen}
          handleUpdateLocation={() => {
            setInitialRender(false)
          }}
        />
      </div>

      <div style={{ marginTop: '36px' }}>
        <Text smallSectionTitle inlineStyle={{ marginBottom: '12px' }}>
          About Leads
        </Text>
        <JobTitles
          label="Example titles"
          textStyles={styles.textStyle}
          exampleTitles={exampleTitles}
          showError={showError}
          handleChange={(titles) => {
            setPersonaTitle(titles)
            setInitialRender(false)
          }}
        />

        <div style={{ marginBottom: '12px' }}>
          <Text extraStyles={[styles.textStyle]}>Title description</Text>
          <div className={css(styles.targetDescriptionContainer)}>
            <TextareaAutosize
              required
              maxRows={8}
              className={css(styles.targetDescriptionArea)}
              value={descr as string}
              placeholder={
                'I am looking for sales and marketing people who would be decision makers of a potential lead generation software product at an enterprise company'
              }
              onChange={(e) => {
                setDescription(e.target.value)
                setInitialRender(false)
              }}
            />
          </div>
          <small style={{ color: '#d32f2f' }}>{showError && !descr ? 'Title description is required!' : ''}</small>
        </div>
        <SelectFormControl
          title="Management Levels*"
          labelId="multiple-management-level-label"
          selectId="multiple-management-level"
          values={managementLevels}
          errorCondition={showError && managementLevels.length === 0}
          errorMessage="Please select management levels"
          placeholder="Please select management levels"
          bg="#5F69C1"
          color="white"
          onChange={(value: any) => {
            setManagementLevels(value)
            setInitialRender(false)
          }}
          valueMap={ManagementLevel}
          items={managementLevelValues}
        />
      </div>
    </div>
  )
}

export default ChatSummaryForm
