import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  toolTipContainer: {
    display: 'flex',
    width: '335px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
    flexShrink: 0,
    alignSelf: 'stretch',
  },
  toolTipText: {
    display: 'flex',
    padding: '10px',
    alignItems: 'flex-start',
    gap: '10px',
    alignSelf: 'stretch',
    borderRadius: '16px',
    textAlign: 'justify',
  },
  toolTipBtn: {
    display: 'flex',
    width: '174px',
    fontWeight: 600,
    color: '#378DFA',
    padding: '6px 16px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '4px',
    borderRadius: '8px',
    border: '1px solid rgba(55, 141, 250, 0.40)',
    background: '#FFF',
    boxShadow: '0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
  },
})

export default styles
