import { memo, useCallback, useState } from 'react'
import { Button, TextareaAutosize } from '@mui/material'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import { debounce } from 'lodash'
import { css } from 'aphrodite'

import styles from './style'
import { useCampaignAndUser } from '../../../components/NewCampaignLayout'
import { ReactComponent as SuccessSvgIcon } from '../../../assets/svg/success.svg'

import { useGraphQL } from '../../../context/GraphQLContext'
import { useChatContext } from '../../../components/Chat/context/ChatContext'

const TargetDescription = ({
  targetingDescr: descr,
  handleCreateTargetingInfo,
  loading,
  showEditButton = true,
  showError,
  isRexPage,
}: {
  targetingDescr?: string
  loading?: boolean
  handleCreateTargetingInfo: () => void
  showEditButton?: boolean
  showError?: boolean
  isRexPage?: boolean
}) => {
  const { campaign } = useCampaignAndUser()
  const { updateCampaign } = useGraphQL()
  const [targetingDescr, setTargetingDescr] = useState(descr || '')
  // const { showError } = useChatContext()
  const [expanded, setExpanded] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  const handleSaveClick = async () => {
    if (campaign?.id) {
      await updateCampaign({
        variables: {
          data: {
            targetingDescr: targetingDescr,
            campaignId: campaign.id,
          },
        },
      }).then(() => {
        handleCreateTargetingInfo()
      })
    }
    setIsSaved(true)

    // Reset button state after 2 seconds
    setTimeout(() => {
      setIsSaved(false)
    }, 2000)
  }

  const handleCampaignUpdate = useCallback(
    debounce((name, value) => {
      if (campaign?.id) {
        updateCampaign({
          variables: {
            data: {
              [name]: value,
              campaignId: campaign.id,
            },
          },
        }).then(() => {
          handleCreateTargetingInfo()
        })
      }
    }, 1000),
    [campaign, updateCampaign, handleCreateTargetingInfo],
  )

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      <div
        className={css([
          styles.targetDescriptionContainer,
          isRexPage ? styles.rexHover : styles.defaultHover,
          isClicked ? (isRexPage ? styles.rexClick : styles.Click) : isRexPage ? styles.defaultRex : styles.default,
        ])}
        onClick={() => setIsClicked(!isClicked)}
        style={{
          border: isClicked ? '1px solid rgba(55, 141, 250, 0.40))' : '1px solid #F0F3F5',
          boxShadow: isClicked ? '0px 0px 15px 0px rgba(55, 141, 250, 0.19)' : 'none',
        }}
        // style={{
        //   height: expanded ? '220px' : '90px',
        // }}
      >
        <TextareaAutosize
          // minRows={4}
          // minLength={5}
          maxRows={8}
          name="targetingDescr"
          placeholder="The company provides smartwatches with advanced health and fitness features"
          className={css(
            styles.targetDescriptionArea,
            // expanded ? styles.expandedTargetDescription : styles.collapseTargetDescription,
          )}
          value={
            expanded
              ? targetingDescr
              : targetingDescr.length > 490
              ? `${targetingDescr.substring(0, 490)}...`
              : targetingDescr
          }
          onChange={(e) => {
            const { name, value } = e.target
            setTargetingDescr(value)
            if (!showEditButton) {
              handleCampaignUpdate(name, value)
            }
          }}
          onClick={() => {
            setExpanded(true)
            // handleCampaignUpdate()
          }}
        />
        <div className={css(styles.buttonContainer)}>
          {targetingDescr.length >= 490 && (
            <Button
              className={css(styles.viewMoreButton)}
              size="small"
              onClick={handleExpandClick}
              endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
            >
              {expanded ? 'View Less' : 'View More'}
            </Button>
          )}
          {showEditButton && (
            <Button
              disabled={!targetingDescr || campaign?.targetingDescr === targetingDescr}
              onClick={handleSaveClick}
              className={css([styles.saveButton, isSaved ? styles.savedBtn : ''])}
            >
              {isSaved ? (
                <>
                  <SuccessSvgIcon /> Saved
                </>
              ) : (
                'Save'
              )}
            </Button>
          )}
        </div>
      </div>{' '}
      <small style={{ color: '#d32f2f' }}>
        {showError && !targetingDescr ? 'Company Target description is required!' : ''}
      </small>
    </>
  )
}

export default memo(TargetDescription)
