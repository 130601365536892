import { ApolloCache, ApolloError, DefaultContext, LazyQueryHookOptions, MutationFunctionOptions } from '@apollo/client'
import { createContext } from 'react'
import {
  Exact,
  GetCompaniesMoreTrainBatchProgressQuery,
  UpdateCampaignInput,
  UpdateCampaignMutation,
} from '../../../graphql/generated'

export const NewCampaignContext = createContext<{
  error?: ApolloError
  loading?: boolean
  data?: any
  updateCampaign: (
    options?:
      | MutationFunctionOptions<
          UpdateCampaignMutation,
          Exact<{
            data: UpdateCampaignInput
          }>,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => void
  getMoreCompaniesForTeachingProgress: (
    options?:
      | Partial<
          LazyQueryHookOptions<
            GetCompaniesMoreTrainBatchProgressQuery,
            {
              data: {
                campaignId: string
                creatorId: string
              }
            }
          >
        >
      | undefined,
  ) => void
}>({
  loading: false,
  data: undefined,
  updateCampaign: () => {},
  getMoreCompaniesForTeachingProgress: () => {},
})
