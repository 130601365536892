import { memo, useState } from 'react'
import { Button, Collapse, Typography } from '@mui/material'
import { ExpandMore, ExpandLess } from '@mui/icons-material'

const TableCellWithReadMore = ({ text }: { text: string }) => {
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Typography variant="subtitle2">
      {!expanded ? (
        `${text.substring(0, 130)}...`
      ) : (
        <Collapse in={expanded}>
          <Typography variant="subtitle2">{text}</Typography>
        </Collapse>
      )}
      <Button
        sx={{
          textTransform: 'none',
        }}
        size="small"
        onClick={handleExpandClick}
        endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
      >
        {expanded ? 'Read Less' : 'Read More'}
      </Button>
    </Typography>
  )
}

export default memo(TableCellWithReadMore)
