import EditIcon from '@mui/icons-material/Edit'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import { FC, memo, useCallback, useState } from 'react'

import { ReactComponent as PencilSvgIcon } from '../../../../../assets/svg/edit_pencil.svg'
import { Campaign } from '../../../../../graphql/generated'
import { useAuth } from '../../../../../hooks/useAuth'
import RenameCampaignModal from '../RenameCampaignModal'

interface RenameCampaignButtonProps {
  campaign: Campaign
}

const RenameCampaignButton: FC<RenameCampaignButtonProps> = ({ campaign }) => {
  const { user } = useAuth()
  const [showRenameCampaignModal, setShowRenameCampaignModal] = useState(false)

  const handleShowRenameCampaignModal = useCallback(() => {
    setShowRenameCampaignModal(!showRenameCampaignModal)
  }, [showRenameCampaignModal])

  const handleRenameCampaignClick = () => {
    handleShowRenameCampaignModal()
  }

  return (
    // <Link
    //   to={{
    //     pathname: '/new-campaign',
    //     search: `?campaignId=${campaign.id}`,
    //   }}
    // >
    <>
      <RenameCampaignModal
        showUserModal={showRenameCampaignModal}
        handleShowUserModal={handleShowRenameCampaignModal}
        name={campaign?.name as string}
        campaignId={campaign?.id as string}
      />
      <Button
        startIcon={<PencilSvgIcon fontSize="medium" />}
        sx={{
          textTransform: 'capitalize',
          color: '#202223',
          width: '180 !important',
          padding: '10px 59px',
          ':hover': {
            background: 'none',
          },
        }}
        onClick={handleRenameCampaignClick}
      >
        Rename
      </Button>
    </>
    // </Link>
  )
}

export default memo(RenameCampaignButton)
