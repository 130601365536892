import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  warningMsgContainer: {
    alignSelf: 'center',
    display: 'flex',
    width: '100%',
    maxWidth: '830px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '6px 16px',
    borderRadius: '8px',
    border: '1px solid #E4827A',
    background: 'rgba(228, 90, 79, 0.08)',
    marginTop: '16px',
  },
  warningMsgTitleTxt: {
    fontWeight: 700,
  },
  warningMsgTxt: {
    marginLeft: '11.5px',
  },
})

export default styles
