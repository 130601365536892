import { StyleSheet } from 'aphrodite'
import { darkNavyBlue, white } from '../../shared/colors'

const styles = StyleSheet.create({
  container: {
    background: white,
    boxShadow: '0px 0px 8px rgba(99, 99, 99, 0.2)',
    position: 'relative',
    padding: '9px 8px 36px 8px',
  },
  // paddingContainer: {
  //   padding: '9px 8px 36px 8px',
  //   // padding: '0px 8px 36px 24px',
  // },
  // expandPaddingContainer: {
  //   padding: '9px 8px 36px 8px',
  //   // padding: '36px 8px 36px 24px',
  // },
  divider: {
    margin: '10px 0px 0px 10px',
  },
  campaignsLink: {
    all: 'unset',
  },
  campaignRocket: {
    fontSize: 25,
  },
  navBarContainer: {
    display: 'flex',
    // width: 231,
    // '@media screen and (min-width: 900px)': {
    //   width: 170,
    // },
  },
  navItemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 62,
    padding: '8px',
    marginBottom: 10,
    gap: '12px',
    ':hover': {
      cursor: 'pointer',
      // opacity: 0.4,
      borderRadius: '14px',
      background: '#F5F6F9',
    },
  },
  expandedNavItemWidth: {
    width: 230,
  },
  collapseNavItemWidth: {
    width: '100%',
    justifyContent: 'center',
  },
  navItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navList: {
    listStyle: 'none',
    paddingLeft: 5,
    // '@media (min-width: 600px)': {
    //   paddingLeft: 5,
    // },
    // '@media (min-width: 900px)': {
    //   paddingLeft: 10,
    // },
    // '@media (min-width: 1200px)': {
    //   paddingLeft: 20,
    // },
    // '@media (min-width: 1536px)': {
    //   // paddingLeft: 40,
    // },
  },
  pageLabel: {
    // marginLeft: 12,
  },
  salezillaLogo: {
    height: 49,
    width: 196,
    marginLeft: 24,
    // '@media (min-width: 600px)': {
    //   marginLeft: 20,
    // },
    // '@media (min-width: 900px)': {
    //   marginLeft: 30,
    // },
    // '@media (min-width: 1200px)': {
    //   marginLeft: 40,
    // },
    // '@media (min-width: 1536px)': {
    //   marginLeft: 50,
    // },
  },
  selectedNavItem: {
    borderRadius: '14px',
    background: '#F5F6F9',
    // boxShadow: '0px 0px 3px 0px rgba(99, 99, 99, 0.20)',
    padding: '8px',
    // backgroundColor: white,
    // border: `1.36px solid ${romance}`,
    // boxShadow: '0px 4px 12px rgba(99, 99, 99, 0.13)',
    // borderRadius: '8px',
    // padding: '8px 16px',
  },
  selectedNavText: {
    color: darkNavyBlue,
  },
  userInfoContainer: {
    position: 'absolute',
    bottom: 0,
    width: '-webkit-fill-available',
  },
  userInfoDivider: {
    marginLeft: 10,
    marginRight: 8,
  },
  userInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  userAvatar: {
    paddingLeft: '16px',
    gap: 16,
  },
  moreIcon: {
    marginLeft: 3,
    // marginRight: 12,
  },
  expandedNavbar: {
    // width: '260px',
    transition: 'width 0.3s ease-in-out',
  },
  collapsedNavbar: {
    width: '60px',
    transition: 'width 0.3s ease-in-out',
  },
  selectedNavIcons: {
    width: '44px',
    height: '44px',
    padding: '8px',
    borderRadius: '14px',
    background: 'var(--background-select-grey, #F4F5F6)',
    // boxShadow: '0px 0px 3px 0px rgba(99, 99, 99, 0.20)',
  },
})

export default styles
