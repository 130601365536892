import { useLocation, useNavigate, useOutlet } from 'react-router-dom'
// import { AppBar } from './NavBar'
import { css } from 'aphrodite'
import { memo, useEffect } from 'react'

import styles from './styles'
import SalezillaLogo from '../../assets/images/salezilla_logo.png'
import { useAuth } from '../../hooks/useAuth'
import useNavigateToLogin from '../../hooks/useNavigateToLogin'

const HomeLayout = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { navigateToLogin } = useNavigateToLogin()
  const { user, isUserLoading } = useAuth()
  const outlet = useOutlet()
  useEffect(() => {
    if (
      !isUserLoading &&
      !user &&
      !location.pathname.includes('signup') &&
      !location.pathname.includes('reset-password')
    ) {
      navigateToLogin()
    } else if (!isUserLoading && user && user.company) {
      navigate('/campaigns', { replace: true })
    }
  }, [user, location.pathname, navigateToLogin, navigate, isUserLoading])

  return (
    <div className="home-container">
      {location.pathname !== '/login' && location.pathname !== '/signup' && location.pathname !== '/reset-password' && (
        <img alt="Salezilla Logo" src={SalezillaLogo} className={css(styles.salezillaLogo)} />
      )}
      {outlet}
    </div>
  )
}

export default memo(HomeLayout)
