import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  mainContainer: {
    height: '80vh',
    padding: '20px 0px',
    width: '100%',
  },
  manageCampaignContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    alignItems: 'center',
    '@media (max-width:1380px)': {
      gap: 10,
    },
  },
  editBtn: {
    color: '#3C4257',
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'none',
    padding: '4px 8px',
    borderRadius: '16px',
    border: '0.5px solid #A3AAB7',
    background: '#EFF0F2',
    width: '66px',
    height: '28px',
    ':disabled': {
      border: '0.5px solid #C1C9D2',
      background: '#F0F3F5',
      color: '#C1C9D2',
    },
  },
  reviewBtn: {
    color: '#378DFA',
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'none',
    padding: '4px 8px',
    borderRadius: '16px',
    border: '0.5px solid #378DFA',
    background: '#F5FDFF',
    width: '66px',
    height: '28px',
    ':disabled': {
      border: '0.5px solid #C1C9D2',
      background: '#F0F3F5',
      color: '#C1C9D2',
    },
  },
  createdAtWrapper: {
    fontSize: '12px',
    marginTop: '1px',
    color: '#A3AAB7',
  },
  leadsRemain: {
    '@media (max-width:1280px)': {
      width: '102px',
      minWidth: '102px',
      maxWidth: '102px',
    },
    '@media (width:1366px)': {
      width: '123px',
      minWidth: '123px',
      maxWidth: '123px',
    },
  },
  dialogTitle: {
    fontWeight: 600,
    color: '#31333A',
    textAlign: 'center',
  },
  dialogBtnContainer: {
    display: 'flex',
    padding: '0px 10px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '48px',
  },
  cancelBtn: {
    color: '#378DFA',
    borderRadius: '10px',
    border: '1px solid rgba(55, 141, 250, 0.10)',
    background: '#FDFDFD',
    boxShadow: '0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
    textTransform: 'none',
    padding: '10px 24px',
    ':hover': {
      background: '#FDFDFD',
    },
  },
  createLeadListBtnContainer: {
    margin: '31px 24px',
    position: 'relative',
    zIndex: 999,
  },
  createLeadListBtn: {
    width: '100%',
    height: '82px',
    padding: '20px 30px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '24px',
    borderRadius: '16px',
    background: '#FFF',
    boxShadow: 'none',
    color: '#3C4257',
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: 600,
    ':hover': {
      border: '3px solid #CED3F1',
      background: '#FFF',
      WebkitTransition: 'none',
      boxShadow: 'none',
      color: '#7865ED',
    },
  },
})

export default styles
