import { useLazyQuery } from '@apollo/client'
import { FC, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { VerifyLinkDocument } from '../../graphql/generated'
import Toast from '../../components/Toast/Toast'
import { Box, CircularProgress, Typography } from '@mui/material'

interface UserVerificationProps {}

const getParams = (queryString: string) => {
  const params = new URLSearchParams(queryString)
  const verify = params.get('verify')
  const email = params.get('email')
  return { verify, email }
}

const UserVerification: FC<UserVerificationProps> = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')

  const [verifyEmailLink, { loading }] = useLazyQuery(VerifyLinkDocument)

  const verifyLink = useCallback(
    async ({ verify, email }: { verify: string; email: string }) => {
      try {
        if (verify && email) {
          const response = await verifyEmailLink({
            variables: {
              verifyInput: {
                link: verify,
                email,
              },
            },
          })
          if (response.data?.verifyLink) {
            navigate('/campaigns')
          }
        }
      } catch (error) {
        setOpen(true)
        setMessage('Error occured while verifing link')
      }
    },
    [navigate, verifyEmailLink],
  )

  useEffect(() => {
    const { search } = location
    const { verify, email } = getParams(search)
    if (verify && email) {
      verifyLink({ verify, email })
    }
  }, [location, verifyLink])

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100vw"
        position="fixed"
        top={0}
        left={0}
        bgcolor="rgba(0, 0, 0, 0.7)"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100vw"
      position="fixed"
      top={0}
      left={0}
      bgcolor="rgba(0, 0, 0, 0.7)"
    >
      <Toast message={message} open={open} setOpen={setOpen} severity="error" />
      <Typography variant="h2" color="white">
        Verification Successful
      </Typography>
    </Box>
  )
}

export default UserVerification
