import { Snackbar, Alert, AlertColor } from '@mui/material'
import { Dispatch, FC, SetStateAction, memo, useCallback } from 'react'
import Slide, { SlideProps } from '@mui/material/Slide'

interface ToastProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  message: string
  severity: AlertColor
}

type TransitionProps = Omit<SlideProps, 'direction'>

function TransitionRight(props: TransitionProps) {
  return <Slide {...props} direction="left" />
}

const Toast: FC<ToastProps> = ({ open, setOpen, message, severity }) => {
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      TransitionComponent={TransitionRight}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default memo(Toast)
