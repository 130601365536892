import { StyleSheet } from 'aphrodite'
import { ghostWhite } from '../../../shared/colors'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // width: '100%',
    // maxWidth: 670,
  },
  //
  companyDescEnd: {
    color: '#659FCA',
    ':hover': {
      cursor: 'pointer',
    },
  },
  companyLogo: {
    height: 35,
    width: 35,
  },
  companyLogoContainer: {
    boxShadow: '3px 0 3px rgb(0 0 0 / 7%)',
    borderRight: '1px solid #d3dae3',
  },
  companyNameContainer: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
  },
  onTargetPctContainer: {
    marginRight: 80,
  },
  linksContainer: {
    display: 'flex',
  },
  sampleCompaniesTitle: {
    marginBottom: 8,
  },
  scoreContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  improveButton: {
    marginLeft: 110,
  },
  score: {
    color: '#00AF12',
    fontSize: 22,
  },
  scoresContainer: {
    display: 'flex',
    // justifyContent: 'space-between',
    // width: '100%',
    marginLeft: 110,
    marginRight: 30,
    marginBottom: 15,
  },
  tableAndButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
  },
})

export default styles
