// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

import {
  GoogleAuthProvider,
  getAuth,
  signInWithRedirect,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  OAuthProvider,
  getRedirectResult,
} from 'firebase/auth'
import { getFirestore, collection, addDoc } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const auth = getAuth(app)
const db = getFirestore(app)

const googleProvider = new GoogleAuthProvider()
const microsoftProvider = new OAuthProvider('microsoft.com')

// googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email')
// googleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile')

const signInWithGoogle = async () => {
  try {
    await signInWithRedirect(auth, googleProvider)
  } catch (err: any) {
    console.error(err)
    console.error(err.message)
  }
}

const signInWithMicrosoft = async () => {
  try {
    await signInWithRedirect(auth, microsoftProvider)
  } catch (err: any) {
    console.log('microsoft error', err.message)
  }
}

const logInWithEmailAndPassword = async (email: string, password: string) => {
  const { user: userResult } = await signInWithEmailAndPassword(auth, email, password)

  return userResult
}

const registerWithEmailAndPassword = async (name: string, email: string, password: string) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      authProvider: 'local',
      email,
    })
  } catch (err: any) {
    console.error(err)
    console.error(err.message)
  }
}

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
    console.error('Password reset link sent!')
  } catch (err: any) {
    console.error(err)
    console.error(err.message)
  }
}

const logout = async () => {
  await signOut(auth)
}

export {
  analytics,
  auth,
  db,
  getRedirectResult,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  signInWithMicrosoft,
}
