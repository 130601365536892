import { FC, memo } from 'react'
import { Button } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'

import { Campaign } from '../../../../../graphql/generated'
import DeleteCampaignButton from './DeleteCampaignButton'
import { useAuth } from '../../../../../hooks/useAuth'
import { ReactComponent as MoreHorizIcon } from '../../../../../assets/svg/more.svg'
import AccessDemo from './AccessDemo'
import RenameCampaignButton from './RenameCampaignButton'

interface MoreActionButtonProps {
  campaign: Campaign
  setNewCampaigns: React.Dispatch<React.SetStateAction<Campaign[]>>
}

const MoreActionButton: FC<MoreActionButtonProps> = ({ campaign, setNewCampaigns }) => {
  const { user } = useAuth()
  const { gcsLeadsCsvPath } = campaign

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Button
        onClick={handleClick}
        startIcon={<MoreHorizIcon fontSize="large" />}
        sx={{
          padding: '6px 0px',
          minWidth: '0px',
          color: '#727578',
          '& .MuiButton-startIcon': { marginRight: '4px', marginLeft: '4px' },
        }}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '& .MuiMenu-paper': {
            borderRadius: '8px',
          },
        }}
      >
        {/* {gcsLeadsCsvPath && (
          <MenuItem disabled={!gcsLeadsCsvPath}>
            <DownloadCSVButton campaign={campaign} />
          </MenuItem>
        )} */}
        {!gcsLeadsCsvPath && !campaign.duplicatedFromCampaignId && (
          <MenuItem
            disabled={!!gcsLeadsCsvPath}
            sx={{
              width: '146px',
              margin: '0px 8px',
              padding: '0px',
              ':hover': {
                borderRadius: '4px',
                background: ' #F5F6F9',
              },
            }}
          >
            <RenameCampaignButton campaign={campaign} />
          </MenuItem>
        )}
        {/* <MenuItem>
          <ViewCampaign campaign={campaign} />
        </MenuItem> */}
        {/* {user?.email.endsWith('@salezilla.io') && gcsLeadsCsvPath && (
          <MenuItem disabled={!gcsLeadsCsvPath}>
            <SalezillaDemoDuplicateButton campaign={campaign} setNewCampaigns={setNewCampaigns} />
          </MenuItem>
        )} */}
        {user?.email.endsWith('@salezilla.io') && !!campaign.duplicatedFromCampaignId && (
          <MenuItem disabled={!campaign.duplicatedFromCampaignId}>
            <AccessDemo campaign={campaign} setNewCampaigns={setNewCampaigns} />
          </MenuItem>
        )}
        <MenuItem
          sx={{
            width: '146px',
            margin: '0px 8px',
            padding: '0px',
            ':hover': {
              borderRadius: '4px',
              background: 'rgba(228, 90, 79, 0.08)',
            },
          }}
        >
          <DeleteCampaignButton campaign={campaign} />
        </MenuItem>
      </Menu>
    </>
  )
}

export default memo(MoreActionButton)
