import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { css } from 'aphrodite'
import { FC, useMemo } from 'react'
import styles from './styles'
import { useChatContext } from './context/ChatContext'
import { useGraphQL } from '../../context/GraphQLContext'
import { useCampaignAndUser } from '../NewCampaignLayout'
import { GetCompaniesForLabeling } from '../../graphql/generated'

interface ChatActionButtonProps {
  loading: boolean
  isPromptBad?: boolean
  handleKeepEditing: () => void
  handleLabelCompanies: () => void
}

const ChatActionButton: FC<ChatActionButtonProps> = ({
  loading,
  isPromptBad,
  handleKeepEditing,
  handleLabelCompanies,
}) => {
  const { validateForm, setShowError } = useChatContext()
  const { campaign } = useCampaignAndUser()
  const { updateCampaign, getCompaniesForLabelingResult, handleGetLabelingCompanies } = useGraphQL()
  const companies = useMemo(
    () => getCompaniesForLabelingResult?.data?.getCompaniesForLabeling?.companies || [],
    [getCompaniesForLabelingResult?.data?.getCompaniesForLabeling?.companies],
  )

  const handleReadyClick = () => {
    if (validateForm() && campaign?.targetingDescr && (campaign?.desiredNumberOfLeads as number) > 0) {
      if (campaign) {
        updateCampaign({
          variables: {
            data: {
              campaignId: campaign.id,
              showInitialFiveCompanies: true,
            },
          },
        })
        handleGetLabelingCompanies({ campaign, updateShowInitialFiveCompanies: true, order: 'asc' })
      }
      return
    }
    setShowError(true)
    const elementToScrollTo = document.getElementById('chat-summary-form')
    if (elementToScrollTo) {
      elementToScrollTo.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })
      elementToScrollTo.focus({ preventScroll: true })
    }
  }

  const labelTenCompanies = useMemo(
    () => companies?.filter((company: GetCompaniesForLabeling) => company.user_label !== '').length === 10,
    [companies],
  )

  return (
    <div className={css(styles.buttonsContainer)}>
      {/* <Button disabled={loading} variant="contained" className={css(styles.editButton)} onClick={handleKeepEditing}>
        Keep Editing
      </Button> */}
      {campaign?.showInitialFiveCompanies ? (
        <LoadingButton
          loading={loading}
          disabled={campaign?.showInitialFiveCompanies && !labelTenCompanies ? true : false}
          variant="contained"
          className={css(styles.readyButton)}
          onClick={handleLabelCompanies}
        >
          Go to the label page
        </LoadingButton>
      ) : (
        <LoadingButton
          loading={loading}
          disabled={isPromptBad || (campaign?.dbSize as number) < 1000}
          variant="contained"
          className={css(styles.readyButton)}
          onClick={handleReadyClick}
        >
          Looks good, start label companies
        </LoadingButton>
      )}
    </div>
  )
}

export default ChatActionButton
