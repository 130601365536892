import { FC } from 'react'
import Text from '../../../components/Text'
import styles from '../AddObjectDialog/styles'
import { TextField, useMediaQuery } from '@mui/material'
import { white } from '../../../shared/colors'
import { MuiChipsInput } from 'mui-chips-input'

interface ChipTextFieldProps {
  label: string
  placeholder: string
  value: string[]
  error?: boolean
  helperText?: string
  handleChange: (value: string[]) => void
  textStyles?: object
}

const ChipTextField: FC<ChipTextFieldProps> = ({
  label,
  value,
  placeholder,
  handleChange,
  error,
  helperText,
  textStyles,
}) => {
  const isMobile = useMediaQuery('@media (max-width: 1023px)')

  return (
    <div>
      <Text extraStyles={[textStyles || styles.dialogFormContentSubTitle]}>{label}</Text>
      <TextField
        required
        fullWidth
        size="small"
        error={error}
        helperText={helperText}
        // placeholder="ex. Social Media Strategist, CRM Manager, Marketing Analyst..."
        sx={{
          background: white,
          '.MuiInputBase-adornedEnd': {
            paddingLeft: 0,
            marginTop: 0,
          },
          '.MuiInputBase-input': {
            width: 0,
          },
          '& .MuiInput-root:before': {
            borderBottom: 'none !important',
          },
          '& .MuiInput-root:after': {
            borderBottom: 'none !important',
          },
          ':hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: '2px solid #CED3F1',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
          },
        }}
        InputProps={{
          endAdornment: (
            <MuiChipsInput
              size="small"
              variant="standard"
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              sx={{
                borderWidth: 0,
                marginTop: '0.5rem',
                width: '100%',
                '& .MuiChip-root': { background: '#5F69C1', color: 'white' },
                '& .MuiChip-deleteIcon ': { color: 'white !important' },
              }}
            />
          ),
        }}
        InputLabelProps={{
          ...(!isMobile && { shrink: true }),
        }}
      />
      {/* <TextField
          fullWidth
          size="small"
          sx={{ background: white }}
          placeholder="Exclude some keywords in the title"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        /> */}
    </div>
  )
}

export default ChipTextField
