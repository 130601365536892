import exp from 'constants';

const activeD =
  'M26.4302 5.56971C26.6515 5.79101 26.725 6.1203 26.6187 6.41467L19.6227 25.7882C19.6151 25.8092 19.6066 25.8299 19.5973 25.8503C19.4857 26.0939 19.3065 26.3003 19.081 26.445C18.8555 26.5897 18.5932 26.6666 18.3253 26.6666C18.0574 26.6666 17.7951 26.5897 17.5696 26.445C17.3488 26.3032 17.1723 26.1024 17.0603 25.8654L13.4184 18.5815L6.13459 14.9396C5.89757 14.8276 5.69668 14.6512 5.55497 14.4303C5.41027 14.2049 5.33334 13.9426 5.33334 13.6746C5.33334 13.4067 5.41027 13.1444 5.55497 12.9189C5.69967 12.6935 5.90607 12.5143 6.14964 12.4026C6.16998 12.3933 6.19071 12.3848 6.21176 12.3772L25.5853 5.38126C25.8796 5.27496 26.2089 5.3484 26.4302 5.56971ZM15.0023 18.1393L18.2922 24.7192L23.8708 9.27069L15.0023 18.1393ZM22.7292 8.1291L7.28078 13.7077L13.8607 16.9977L22.7292 8.1291Z';

const inactiveD =
  'M26.4303 5.56971C26.6516 5.79101 26.725 6.1203 26.6187 6.41467L19.6227 25.7882C19.6151 25.8092 19.6066 25.8299 19.5973 25.8503C19.4857 26.0939 19.3065 26.3003 19.081 26.445C18.8555 26.5897 18.5932 26.6666 18.3253 26.6666C18.0574 26.6666 17.7951 26.5897 17.5696 26.445C17.3488 26.3032 17.1724 26.1024 17.0603 25.8654L13.4184 18.5815L6.13462 14.9396C5.8976 14.8276 5.69671 14.6512 5.555 14.4303C5.4103 14.2049 5.33337 13.9426 5.33337 13.6746C5.33337 13.4067 5.4103 13.1444 5.555 12.9189C5.6997 12.6935 5.9061 12.5143 6.14967 12.4026C6.17001 12.3933 6.19074 12.3848 6.21179 12.3772L25.5853 5.38126C25.8797 5.27496 26.2089 5.3484 26.4303 5.56971ZM15.0023 18.1393L18.2923 24.7192L23.8709 9.27069L15.0023 18.1393ZM22.7293 8.1291L7.28081 13.7077L13.8607 16.9977L22.7293 8.1291Z';

const activeFill = 'white';
const inactiveFill = '#D6D6D6';

interface Args {
  active: boolean;
  onClick: () => void;
}

const SendMessage = ({ active, onClick }: Args) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    onClick={onClick}
  >
    {active && (
      <rect width="32" height="32" rx="6" fill="#378DFA" />
    )}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={active ? activeD : inactiveD}
      fill={active ? activeFill : inactiveFill}
    />
  </svg>
);

export default SendMessage;