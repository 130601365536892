import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import { css } from 'aphrodite'
import styles from './style'
import SelectYear from './SelectYear'
import CompanyLocationDropdown from './CompanyLocationDropdown'
import CompanyEmployeesDropdown from './CompanyEmployeesDropdown'
import CreationModal, { DialogEnum } from '../../UniverseController/CreationModal/CreationModal'
import { useCampaignAndUser } from '../../../components/NewCampaignLayout'
import { useCallback, useState } from 'react'
import { Location, NumEmployeeRange, Persona } from '../../../graphql/generated'

export const LeadsCompanyFilter = ({
  persona,
  setSelectedPersona,
  handleUpdatePersona,
  handleCreateTargetingInfo,
}: {
  persona: Persona
  handleUpdatePersona: () => void
  handleCreateTargetingInfo: () => void
  setSelectedPersona: React.Dispatch<React.SetStateAction<Persona | null>>
}) => {
  const { user } = useCampaignAndUser()
  const [openModal, setOpenModal] = useState(false)

  const handleModal = useCallback(() => {
    setOpenModal(!openModal)
  }, [openModal])

  console.log('persona from LeadsCompanyFilter', persona)

  return (
    <Box className={css(styles.leadsFilterContainer)} component="div">
      {user && (
        <CreationModal
          openModal={openModal}
          user={user}
          dialogType={DialogEnum.PERSONA}
          onClose={handleModal}
          selectedPersona={{ ...persona }}
          setSelectedPersona={setSelectedPersona}
          handleCreateTargetingInfo={handleCreateTargetingInfo}
          // getPersonasByTeamId={getPersonasByTeamId}
        />
      )}
      {/* <Typography className={css(styles.leadCompanyFilterLabel)} variant="body2">
        Leads & company filters
      </Typography> */}
      <Grid gap={1} className={css(styles.filterSection)}>
        <Grid item>
          <Button onClick={handleModal} variant="outlined" className={css(styles.allFilterBtn)}>
            <span className={css(styles.allFilterBtnText)}>All filters</span>
          </Button>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item>
          <CompanyEmployeesDropdown
            numEmployee={persona?.numEmployeeRanges as NumEmployeeRange[]}
            setSelectedPersona={setSelectedPersona}
            handleUpdatePersona={handleUpdatePersona}
          />
        </Grid>
        <Grid item>
          <CompanyLocationDropdown
            locations={persona?.hqLocations as Location[]}
            setSelectedPersona={setSelectedPersona}
            handleUpdatePersona={handleUpdatePersona}
          />
        </Grid>
        <Grid item>
          <SelectYear
            foundedYearAfter={persona?.foundedYearAfter as number}
            foundedYearBefore={persona?.foundedYearBefore as number}
            setSelectedPersona={setSelectedPersona}
            handleUpdatePersona={handleUpdatePersona}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
