import { PropsWithChildren } from 'react'
import { NewCampaignContext } from './new_campaign_context'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  Campaign,
  GetCompaniesMoreTrainBatchProgressDocument,
  UpdateCampaignDocument,
  User,
} from '../../../graphql/generated'
import { useNavigate } from 'react-router'

export default function NewCampaignContextProvider(
  props: PropsWithChildren<{ user: User | null; campaign?: Campaign | null; getCampaignByIdLoading: boolean }>,
) {
  const { user, campaign } = props
  const navigate = useNavigate()
  const [getMoreCompaniesForTeachingProgress, { data, loading, error }] = useLazyQuery(
    GetCompaniesMoreTrainBatchProgressDocument,
    {
      ...(user &&
        campaign && {
          variables: {
            data: {
              campaignId: campaign.id,
              creatorId: campaign.creatorId,
            },
          },
        }),
      fetchPolicy: 'network-only',
      onCompleted() {
        if (campaign) {
          navigate({
            pathname: '/new-campaign/teaching-and-grading',
            search: `?campaignId=${campaign.id}`,
          })
        }
      },
    },
  )

  const [updateCampaign, { loading: updateCampaignLoading, error: updateCampaignError, data: updateCampaignRes }] =
    useMutation(UpdateCampaignDocument, {
      onError: (error) => {
        console.log('%cerror', 'color:red;font-size:50px', error)
      },
      onCompleted: (data) => {},
    })

  return (
    <NewCampaignContext.Provider
      value={{
        data: data,
        loading: loading || updateCampaignLoading,
        error: error || updateCampaignError,
        updateCampaign,
        getMoreCompaniesForTeachingProgress,
      }}
    >
      {props.children}
    </NewCampaignContext.Provider>
  )
}
