import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  header: {
    position: 'relative',
    backgroundColor: '#FFFFFF',
    color: '#31333A',
    boxShadow: 'none',
  },
  personalTitle: {
    width: '50%',
    paddingRight: 10,
  },
  excludeTitle: {
    padding: 0,
    width: '50%',
    paddingLeft: 2,
  },
  dialogWarningContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '10px',
  },
  dialogButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '57px',
  },
  dialogConfirmBotton: {
    display: 'flex',
    padding: '12px 36px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '17px',
    color: '#378DFA',
    fontSize: '18px',
    fontWeight: 600,
    borderRadius: '16px',
    border: '0.5px solid #378DFA',
    background: '#F5FDFF',
    boxShadow: '0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
  },
  dialogErrorMessage: {
    color: '#d93025',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '21px 24px 0 24px',
  },
  dialogFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    // padding: '20px 24px 24px 24px',
    background: 'linear-gradient(0deg, rgba(105, 111, 134, 0.04), rgba(105, 111, 134, 0.04)), #FFFFFF',
  },
  dialogFormContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 24px 0 24px',
  },
  dialogFormContentSubContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FCFCFD',
    padding: '24px 36px',
    border: '1px solid #E5E5E6',
    borderRadius: '8px',
    marginTop: '16px',
    gap: '20px',
  },
  dialogFormContentSubTitle: {
    marginBottom: '8px',
  },
  dialogFormInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 1024,
    '@media (max-width: 1023px)': {
      width: '100%',
    },
    marginBottom: 24,
  },
  dialogFormInputRowContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  dialogFormOutterContainer: {},
  dialogFormSubsectionTitle: {
    marginTop: 32,
    marginBottom: 10,
    fontSize: 16,
    color: '#424242',
    fontWeight: 500,
  },
  dialogFormTopContainer: {
    background: '#fafafa',
    border: '1px solid #e0e0e0',
    boxSizing: 'border-box',
    height: 48,
    margin: '-1px -1px 1px -1px',
    padding: '0 24px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  dialogFormTitle: {
    color: '#424242',
    fontSize: 18,
  },
  yearDialogFormContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '36px',
  },
  yearDialogFormSubContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: 8,
  },
})

export default styles
