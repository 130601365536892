import { memo, useState } from 'react'
import { css } from 'aphrodite'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import LinkedinSvg from '../../../assets/svg/linkedin.svg'
import WebsiteLinkSvg from '../../../assets/svg/website_link.svg'
import Description from '../LabellingListView/Description'
import styles from './styles'
import LeadsTablePagination from './LeadsTablePagination'
import LeadsModal from './LeadsModal/LeadsModal'
import { Lead } from '../../../graphql/generated'

const LeadsListTable = ({ leads }: { leads: Lead[] }) => {
  const [page, setPage] = useState(0) // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10) // Rows per page
  return (
    <>
      <LeadsModal open={false} handleAccept={() => {}} handleReject={() => {}} loading={false} />
      <TableContainer className={css(styles.leadsTable)} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            sx={{
              background: 'linear-gradient(0deg, rgba(105, 111, 134, 0.04), rgba(105, 111, 134, 0.04)), #FFFFFF',
            }}
          >
            <TableRow>
              <TableCell className={css(styles.leadsTableHeadTitle)}>Contacts</TableCell>
              <TableCell className={css(styles.leadsTableHeadTitle)}>Company</TableCell>
              <TableCell className={css(styles.leadsTableHeadTitle)}>Company description</TableCell>
              <TableCell className={css(styles.leadsTableHeadTitle)}>Title</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={css(styles.leadsTableBody)}>
            {leads?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row: Lead) => {
              return (
                <TableRow key={row.linkedin_url} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell
                    sx={{
                      width: '15%',
                    }}
                    component="th"
                    scope="row"
                  >
                    <Typography variant="body1" className={css(styles.leadsTableCellContact)}>
                      {row.name}
                      <a href={row.linkedin_url as string} target="_blank" rel="noreferrer">
                        <img src={LinkedinSvg} alt="linkedin" />
                      </a>
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '15%',
                    }}
                  >
                    <Typography variant="body1" className={css(styles.leadsTableCellContact)}>
                      {row.organization_name}
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <a href={row.organization_website_url as string} target="_blank" rel="noreferrer">
                          <img src={WebsiteLinkSvg} alt="website-link" />
                        </a>
                        <a href={row.linkedin_url as string} target="_blank" rel="noreferrer">
                          <img src={LinkedinSvg} alt="linkedin" />
                        </a>
                      </div>
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '50%',
                    }}
                  >
                    <Description
                      className={css(styles.leadsTableCellDescription)}
                      text={(row?.company_description as string) || ''}
                      maxCharacterCount={150}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '20%',
                    }}
                  >
                    <Typography variant="body1" className={css(styles.leadsTableCellTitle)}>
                      {row.title}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <LeadsTablePagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        totalRecords={leads.length}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  )
}

export default memo(LeadsListTable)
