import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  dialogHeader: {
    borderBottom: '1px solid #E5E5E5',
    background: '#FFF',
    padding: '24px 36px 16px 36px',
    fontSize: '20px',
    fontWeight: 600,
  },
  dialogContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 34px',
    gap: '24px',
  },
  dialogFieldWrapper: {
    width: 500,
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  dialogBtnWrapper: {
    display: 'flex',
    padding: '0px 36px 24px',
    justifyContent: 'center',
    gap: '48px',
  },
  dialogCancelBtn: {
    color: '#697386',
    fontSize: '18px',
    fontWeight: 600,
    textTransform: 'none',
    padding: '10px 36px',
    borderRadius: '8px',
    boxShadow: ' 0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
    background: 'var(--background-select-grey-transparent, rgba(105, 115, 134, 0.10))',
  },
  dialogAddBtn: {
    fontSize: '18px',
    fontWeight: 600,
    textTransform: 'none',
    padding: '10px 36px',
    borderRadius: '8px',
    boxShadow: ' 0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
    background: 'var(--colors-blue, #378DFA)',
  },
})

export default styles
