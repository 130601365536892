import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { css } from 'aphrodite'
import { Skeleton, Stack, useMediaQuery } from '@mui/material'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import LoadingButton from '@mui/lab/LoadingButton'

import styles from './styles'
import Text from '../../../components/Text'
import {
  GetPricingPlanDocument,
  CompleteInitialPaymentStepDocument,
  User,
  GetUserByIdDocument,
} from '../../../graphql/generated'
import InfraSetupDropdown from '../InfraSetupDropdown'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useAuth } from '../../../hooks/useAuth'
import SalezillaLogo from '../../../assets/images/salezilla_logo.png'

const ConfirmationPage = () => {
  const { user } = useAuth()
  // const { user, refreshUser } = useAuth()
  const navigate = useNavigate()
  const isMobile = useMediaQuery('@media (max-width: 899px)')

  const [showInfraSetupDropdown, setShowInfraSetupDropdown] = useState(false)

  const [getPricingPlan, { loading: getPricingPlanLoading, error, data: getPricingPlanData }] =
    useLazyQuery(GetPricingPlanDocument)

  const [
    completeInitialPaymentStep,
    {
      loading: completeInitialPaymentStepLoading,
      error: completeInitialPaymentStepError,
      data: completeInitialPaymentStepRes,
    },
  ] = useMutation(CompleteInitialPaymentStepDocument, {
    update: (cache, { data }) => {
      if (user && data && data.completeInitialPaymentStep) {
        const newUser = data.completeInitialPaymentStep as User

        cache.writeQuery({
          query: GetUserByIdDocument,
          data: {
            getUserById: newUser,
          },
        })

        // refreshUser(newUser)

        navigate('/campaigns', { replace: true })
      }
    },
  })

  useEffect(() => {
    if (user && user.company) {
      getPricingPlan({
        variables: {
          stripeMainPlanSubscriptionId: user.company.stripeMainPlanSubscriptionId as string,
        },
      })
    }
  }, [getPricingPlan, user])

  const handleStartClick = () => {
    if (user) {
      completeInitialPaymentStep({
        variables: {
          data: {
            companyId: user.companyId,
            userId: user.id,
          },
        },
      })
    }
  }

  const content = getPricingPlanLoading ? (
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton variant="rounded" width={210} height={60} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton variant="rounded" width={210} height={60} />
    </Stack>
  ) : (
    getPricingPlanData &&
    getPricingPlanData.getPricingPlan && (
      <>
        <Text extraStyles={[styles.title]}>Welcome!</Text>
        <Text extraStyles={[styles.subtitle]}>
          You’ve successfully subscribed to the{' '}
          <span className={css(styles.gptColorText)}>{getPricingPlanData.getPricingPlan.productName}</span>! Thank you
          for joining Salezilla.
        </Text>
        <div className={css(styles.cardContainer)}>
          <div className={css(styles.cardContainerTop)}>
            <Text semiBold extraStyles={[styles.cardTitle]}>
              {getPricingPlanData.getPricingPlan.productName}
            </Text>
            <Text semiBold extraStyles={[styles.priceText]}>
              $
              {getPricingPlanData.getPricingPlan.recurringInterval === 'year'
                ? (Math.round((getPricingPlanData.getPricingPlan.price / 12) * 100) / 100).toFixed(0)
                : getPricingPlanData.getPricingPlan.price}
              {getPricingPlanData.getPricingPlan.price > 0 && (
                <span className={css(styles.priceTextInterval)}>
                  {getPricingPlanData.getPricingPlan.recurringInterval === 'month' ? '/mo' : '/yr'}
                </span>
              )}
            </Text>
          </div>
          <Text extraStyles={[styles.cardDescrOne]}>
            This package offers you full access to our Self Serve Tool with{' '}
            <span className={css(styles.emailCreditsText)}>
              {getPricingPlanData.getPricingPlan.creditsIncluded} email credits
            </span>
            {getPricingPlanData.getPricingPlan.price > 0
              ? getPricingPlanData.getPricingPlan.recurringInterval === 'month'
                ? ' per month'
                : ' per year'
              : ''}
            .
          </Text>
          <Text extraStyles={[styles.cardDescrTwo]}>
            <span className={css(styles.seatsIncluded)}>
              {getPricingPlanData.getPricingPlan.seatsIncluded} seat
              {getPricingPlanData.getPricingPlan.seatsIncluded > 1 ? 's' : ''}
            </span>{' '}
            is allocated to your company.
          </Text>
          <div className={css(styles.featuresContainer)}>
            {getPricingPlanData.getPricingPlan.featuresIncluded.map((feature, j) => (
              <div key={j} className={css(styles.featureContainer)}>
                <CheckRoundedIcon className={css(styles.checkmark)} />
                <Text extraStyles={[styles.featureText]}>{feature}</Text>
              </div>
            ))}
          </div>
          {isMobile && <div className={css(styles.addOnSeparator)} />}
          <InfraSetupDropdown
            isOpen={showInfraSetupDropdown}
            onClick={() => {
              setShowInfraSetupDropdown(!showInfraSetupDropdown)
            }}
          />
        </div>
        <LoadingButton
          loading={completeInitialPaymentStepLoading}
          variant="contained"
          onClick={(_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            handleStartClick()
          }}
          className={css(styles.button)}
        >
          Let’s get started!
        </LoadingButton>
      </>
    )
  )

  return (
    <div className={css(styles.container)}>
      {!isMobile && (
        <div className={css(styles.salezillaLogoContainer)}>
          <img alt="Salezilla Logo" src={SalezillaLogo} className={css(styles.salezillaLogo)} />
        </div>
      )}
      {content}
    </div>
  )
}

export default ConfirmationPage
