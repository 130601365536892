import { memo } from 'react'
import Text from '../Text'
import { css } from 'aphrodite'
import styles from '../../pages/NewCampaign/TeachingAndGrading/styles'
import { Maybe } from 'graphql/jsutils/Maybe'

const TargetDescription = ({
  targetDesc,
  name,
}: {
  targetDesc: Maybe<string> | undefined
  name?: Maybe<string> | undefined
}) => {
  return (
    <>
      <div className={css(styles.smallSectionTitleContainer)}>
        <p className={css(styles.sectionTitleEmoji)}>🎯</p>
        <Text smallSectionTitle>
          {name}
        </Text>
      </div>
      <Text>{targetDesc}</Text>
    </>
  )
}

export default memo(TargetDescription)
