import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { css } from 'aphrodite'

import { ReactComponent as RexIcon } from '../../../../assets/svg/Rex.svg'
import styles from './styles'
import { FC, ReactElement, memo } from 'react'
import { Button } from '@mui/material'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    placement="top"
    arrow
    disableFocusListener
    disableHoverListener
    disableTouchListener
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: '#697386',
    display: 'flex',
    padding: '16px 8px',
    alignItems: 'flex-start',
    gap: '16px',
    borderRadius: '16px',
    background: '#FFF',
    boxShadow: '0px 8px 18px 0px rgba(89, 82, 98, 0.26)',
    maxWidth: 351,
    marginBottom: '50px',
    '& .MuiTypography-root': {
      fontSize: theme.typography.pxToRem(12),
    },
    '.MuiTooltip-arrow': {
      width: '39.909px',
      height: '30.5px',
      color: 'white',
      marginBottom: 0,
      bottom: '-27px',
    },
  },
}))

interface ToolTipProps {
  text: string
  open?: boolean
  hideButton?: boolean
  handleTooltipClose: () => void
  className?: string
  children?: ReactElement
  ButtonComponent?: ReactElement
}

const InfoToolTip: FC<ToolTipProps> = ({
  text,
  children,
  open,
  className,
  handleTooltipClose,
  ButtonComponent,
  hideButton = false,
}) => {
  return (
    <HtmlTooltip
      className={className}
      title={
        <div className={css(styles.toolTipContainer)}>
          <div style={{ display: 'flex', padding: '0px 10px', alignItems: 'center', gap: '8px', alignSelf: 'stretch' }}>
            <RexIcon width="30px" height="30px" />
            <Typography color="#7865ED" sx={{ fontWeight: 700 }}>
              Rex:{' '}
            </Typography>
          </div>
          <Typography color="inherit" className={css(styles.toolTipText)}>
            {text}
          </Typography>
          {!hideButton && (
            <>
              {ButtonComponent ? (
                ButtonComponent
              ) : (
                <Button className={css(styles.toolTipBtn)} onClick={handleTooltipClose}>
                  Got it!
                </Button>
              )}
            </>
          )}
        </div>
      }
      open={open}
    >
      {children ? children : <Button>Tool Tip Button</Button>}
    </HtmlTooltip>
  )
}

export default memo(InfoToolTip)
