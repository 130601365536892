import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  LabelCompanyHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px 4px',
    gap: 10,
    height: '36px',
    flex: 'none',
    color: '#697386',
  },
  DescriptionHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '8px 10px',
    // width: '651px',
    height: '36px',
    fontSize: '12px',
  },
  CompayFrame: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '8px 10px',
    gap: '10px',
    width: '100%',
    height: '36px',
    fontSize: '12px',
  },
  ButtonHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '8px 25px',
    // padding: '8px 10px',
    gap: '19px',
    width: '248px',
    height: '36px',
    fontSize: '12px',
  },

  tableHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableWrapper: {
    borderRadius: '16px',
    padding: '8px 18px',
    background: '#FFFFFF',
    width: '100%',
    // padding: '0px 10px',
    // border: '1px solid #E5E5E5',
  },
})

export default styles
