import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  leadsListContainer: {
    // overflowY: 'auto',
    // overflowX: 'hidden',
    // height: '100vh',
  },
  leadsListHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '32px 16px 16px',
    gap: '10px',
    height: '81px',
    background: '#FCFCFD',
    boxShadow: '0px 4px 14px rgba(99, 99, 99, 0.1)',
  },
  leadsListHeaderTitle: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '33px',
    color: '#000000',
  },
  loadingBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px',
    gap: '8px',
    maxHeight: '98px',
    background: 'linear-gradient(129deg, rgba(23, 205, 183, 0.05) 3.15%, rgba(95, 204, 28, 0.05) 110.17%), #FFFFFF',
    border: '2px solid #E5E5E6',
  },
  loadingBarTitle: {
    display: 'flex',
    gap: 5,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#3C4257',
  },
  loadingBarTime: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    color: '#697386',
    display: 'flex',
    alignItems: 'center',
  },
  dataIndicator: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '24px 16px',
    gap: '48px',
    width: '773px',
    height: '150px',
  },
  dataIndicatorCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '12px 20px',
    gap: '23px',
    width: '215px',
    height: '102px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 24px rgba(66, 103, 67, 0.28)',
    borderRadius: '8px',
  },
  dataIndicatorCardTitle: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#A3AAB7',
    display: 'flex',
    alignItems: 'center',
  },
  dataIndicatorCardValue: {
    width: '42px',
    height: '33px',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '33px',
    color: '#697386',
  },
  leadsListActions: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '16px',
    gap: '38px',
    height: '108px',
  },
  leadsListActionsBtn: {
    textTransform: 'none',
    background: '#F5FDFF',
    border: '0.5px solid #378DFA',
    boxShadow: '0px 4px 12px rgba(99, 99, 99, 0.13)',
    borderRadius: '8px',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '25px',
    letterSpacing: '0.01em',
    color: '#378DFA',
    padding: '12px 36px',
    gap: '17px',
    ':hover': {
      background: '#378DFA',
      color: '#FFFFFF',
    },
    ':disabled': {
      borderRadius: '8px',
      border: '0.5px solid var(--text-placeholder, #C1C9D2)',
      background: 'var(--notes-unselected-grey, #F0F3F5)',
      color: 'var(--text-placeholder, #C1C9D2)',

      /* Button shadow */
      boxShadow: '0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
    },
  },
  leadsTableHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '24px 16px',
    gap: '8px',
    height: '81px',
    borderRadius: '8px',
  },
  leadsTableHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '33px',
    color: '#3C4257',
  },
  //   Table styling ................................................
  leadsTableContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px 16px',
    height: '505px',
  },
  leadsTable: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '10px',
    height: '448px',
    overflowY: 'auto',
    overflowX: 'hidden',
    background: '#FFFFFF',
    border: '1px solid #E5E5E6',
    borderRadius: '8px',
  },
  leadsTableBody: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  leadsTableHeadTitle: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '165%',
    color: '#697386',
  },
  leadsTableCellContact: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '165%',
    color: '#0E5ADE',
  },
  leadsTableCellTitle: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '165%',
    color: '#3C4257',
  },
  leadsTableCellDescription: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%',
    color: '#31333A',
  },
  userInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '1.5rem',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
  },
  deleteButton: {
    color: '#E45A4F',
  },
  editButton: {
    color: '#0E5ADE',
  },
})

export default styles
