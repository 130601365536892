import React from 'react'
import { DashboardContextType } from '../@types/context_types'
const DashboardContext = React.createContext<DashboardContextType>({
  startDate: null,
  setStartDate: () => {},
  endDate: null,
  setEndDate: () => {},
})
export const DashboardProvider = DashboardContext.Provider
export default DashboardContext
