import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist'
import { onError } from '@apollo/client/link/error'
import checkTokenExpirationInLocalStorage from './shared/utils/checkTokenExpiration'
import refreshUserToken from './shared/utils/refreshUserToken'

const nonDevHttpLink = `${process.env.NONDEV_HTTP_LINK}/graphql`
const devHttpLink = 'http://localhost:4000/graphql'

const httpLink = new HttpLink({
  uri: process.env.NODE_ENV === 'development' ? devHttpLink : nonDevHttpLink,
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    )

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const authLink = setContext(async (_, { headers }) => {
  try {
    if (window?.location?.pathname === '/login' || window?.location?.pathname === '/signup') return
    const user = JSON.parse(localStorage.getItem('firebaseUser') as string)
    let token = user?.stsTokenManager?.accessToken
    // const isTokenExpired = checkTokenExpirationInLocalStorage()
    // if (isTokenExpired) {
    //   const refreshedUser = await refreshUserToken()
    //   localStorage.setItem('firebaseUser', JSON.stringify(refreshedUser))
    //   token = refreshedUser?.stsTokenManager?.accessToken
    // }

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  } catch (error) {
    console.log('error', error)
  }
})

const cache = new InMemoryCache()

// Await before instantiating ApolloClient, else queries might run before the cache is persisted
export const persistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
})

const client = new ApolloClient({
  cache,
  link: from([errorLink, authLink, httpLink]),
})

export default client
