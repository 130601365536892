import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

export default function SkeletonLoader() {
  return (
    <Box>
      <Skeleton />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation={false} height={60} />
      <Skeleton />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation={false} height={60} />
      <Skeleton />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation={false} height={60} />
    </Box>
  )
}
