import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { FC, memo, useEffect, useState } from 'react'
import { css } from 'aphrodite'

import styles from './styles'
import LeadsResultStep2 from './LeadsResultStep2'
import LeadsResultStep1 from './LeadsResultStep1'
import {
  Lead,
  GetCampaignStatusRes,
  StartLeadsScoreAllDocument,
  CreateOrupdateCampaignTransactionDocument,
  GetCampaignByIdDocument,
  GetUserByIdDocument,
} from '../../../../graphql/generated'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useCampaignAndUser } from '../../../../components/NewCampaignLayout'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router'
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded'
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined'
import LabelListSkeleton from '../../LabellingListView/LabelListSkeleton'
import client from '../../../../apolloClient'

interface WarningModalProps {
  open: boolean
  loading: boolean
  leads?: Lead[]
  handleReject: () => void
  handleGetLeads?: () => void
  campaignStatus?: GetCampaignStatusRes
}

const LeadsResultPreview: FC<WarningModalProps> = ({
  open,
  handleReject,
  leads,
  campaignStatus,
  loading,
  handleGetLeads,
}) => {
  const { campaign, user } = useCampaignAndUser()
  const navigate = useNavigate()
  const [currentSlide, setCurrentSlide] = useState(0)
  const accurateLeads = campaignStatus?.accurate_leads || 0
  const creditsAvailable = user?.company?.creditsAvailable || 0

  useEffect(() => {
    if (open) {
      setCurrentSlide(0)
    }
  }, [open])

  const validationSchema = Yup.object().shape({
    max_leads_to_grab: Yup.number()
      .min(1, 'Desired number of accurate leads must be at least 1')
      .test(
        'leadsValidation',
        'Desired number of accurate leads must be at or below the accurate leads number and credit available',
        function (value: number | undefined) {
          return value !== undefined && value <= accurateLeads && value <= creditsAvailable
        },
      )
      .required('Desired number of accurate leads is required'),
  })

  const [createOrupdateCampaignTransaction] = useMutation(CreateOrupdateCampaignTransactionDocument, {
    onCompleted: (data) => {
      if (user && user.company && data.createOrupdateCampaignTransaction) {
        client.cache.writeQuery({
          query: GetUserByIdDocument,
          data: {
            getUserById: {
              ...user,
              company: {
                ...user?.company,
                creditsAvailable: data.createOrupdateCampaignTransaction.creditsAvailable,
              },
            },
          },
        })
      }
    },
  })

  const [startLeadsScoreAll, { loading: startGettingLeadsLoading }] = useMutation(StartLeadsScoreAllDocument, {
    onCompleted: (data) => {
      if (data.startLeadsScoreAll && campaign) {
        client.cache.writeQuery({
          query: GetCampaignByIdDocument,
          data: {
            getCampaignById: {
              ...data.startLeadsScoreAll,
            },
          },
        })
        navigate({
          pathname: '/new-campaign/leads-preview',
          search: `?campaignId=${campaign?.id}`,
        })
        handleGetLeads && handleGetLeads()
        handleReject()
      }
    },
  })

  const formik = useFormik({
    initialValues: {
      max_leads_to_grab: campaign?.desiredNumberOfLeads || 0,
      limit_contacts_per_company: 1,
    },
    validationSchema,
    onSubmit: (data) => {
      if (campaign) {
        createOrupdateCampaignTransaction({
          variables: {
            data: {
              campaignId: campaign?.id,
              companyId: campaign?.companyId,
              desiredNumberOfLeads: data.max_leads_to_grab,
              limitContactsPerCompany: data.limit_contacts_per_company,
            },
          },
        })
        startLeadsScoreAll({
          variables: {
            data: {
              campaign_id: campaign?.id,
              user_id: campaign?.creatorId,
              max_leads_to_grab: data.max_leads_to_grab,
              limit_contacts_per_company: data.limit_contacts_per_company,
            },
          },
        })
      }
    },
  })

  const { values } = formik

  const handleSlideChange = (index: number) => {
    setCurrentSlide(index)
  }

  return (
    <Dialog
      maxWidth="lg"
      sx={{ '& .MuiDialog-paper': { width: '100%', height: '100%' } }}
      open={open}
      onClose={handleReject}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="dialog-title">
        <div className={css(styles.closeIcon)} onClick={handleReject}>
          <CloseIcon />
        </div>
      </DialogTitle>
      <DialogContent style={{ overflowX: 'hidden' }}>
        {loading ? (
          <LabelListSkeleton item={10} />
        ) : (
          <>
            {currentSlide === 0 && !!leads?.length ? (
              <>
                <Typography variant="h5" component="h2" className={css(styles.mainTitle)}>
                  Result Preview
                </Typography>
                <LeadsResultStep1 leads={leads} />
              </>
            ) : (
              <>
                <Typography variant="h5" component="h2" className={css(styles.mainTitle)}>
                  Get Leads
                </Typography>
                <LeadsResultStep2 campaignStatus={campaignStatus} formik={formik} />
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <div className={css(styles.dialogFormButtonsContainer)}>
          {!!leads?.length && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span onClick={() => handleSlideChange(0)}>
                {currentSlide === 0 ? (
                  <FiberManualRecordRoundedIcon sx={{ cursor: 'pointer' }} />
                ) : (
                  <FiberManualRecordOutlinedIcon sx={{ cursor: 'pointer' }} />
                )}
              </span>
              {!loading && (
                <span onClick={() => handleSlideChange(1)}>
                  {currentSlide === 1 ? (
                    <FiberManualRecordRoundedIcon sx={{ cursor: 'pointer' }} />
                  ) : (
                    <FiberManualRecordOutlinedIcon sx={{ cursor: 'pointer' }} />
                  )}
                </span>
              )}
            </div>
          )}
          {currentSlide === 0 && !!leads?.length ? (
            <Button disabled={loading} onClick={() => handleSlideChange(1)} variant="text">
              Get leads
            </Button>
          ) : (
            <Button
              disabled={loading}
              onClick={() => formik.handleSubmit()}
              variant="contained"
              className={css(styles.containedButton)}
            >
              {startGettingLeadsLoading ? (
                <>
                  <span>Loading... </span> <CircularProgress color="primary" size={20} />
                </>
              ) : (
                <>
                  Generate {values.max_leads_to_grab} leads
                  <span style={{ fontWeight: 400, fontSize: '12px' }}>spend {values.max_leads_to_grab} credits</span>
                </>
              )}
            </Button>
          )}
          <Button onClick={handleReject} variant="outlined" className={css(styles.outlinedButton)}>
            Back to label page
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default memo(LeadsResultPreview)
