import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  leadsModalContainer: {},
  leadsModalTitle: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '33px',
    textAlign: 'center',
    color: '#31333A',
  },
  leadsModalContent: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    color: '#3C4257',
    textAlign: 'left',
  },
  downloadBtn: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '25px',
    letterSpacing: '0.01em',
    border: '1px solid #378DFA',
    borderRadius: '8px',
    color: '#378DFA',
    boxShadow: '0px 4px 12px rgba(99, 99, 99, 0.13)',
    textTransform: 'none',
  },
})

export default styles
