import { FC, memo, useMemo } from 'react'
import { saveAs } from 'file-saver'
import { Campaign, GetLeadsCsvPathDocument } from '../../../../../graphql/generated'
import { useLazyQuery } from '@apollo/client'
import { Button } from '@mui/material'
import { ReactComponent as DownloadIcon } from '../../../../../assets/svg/download.svg'
import { ReactComponent as DownloadActiveIcon } from '../../../../../assets/svg/download_active.svg'

interface DownloadCSVButtonProps {
  campaign: Campaign
}

const DownloadCSVButton: FC<DownloadCSVButtonProps> = ({ campaign }) => {
  const [getCompaniesCsvSignedUrl] = useLazyQuery(GetLeadsCsvPathDocument, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        campaignId: campaign.id,
        creatorId: campaign.creatorId,
      },
    },
    onCompleted: (data) => {
      if (data.getLeadsCsvPath) {
        const signedUrl = data.getLeadsCsvPath
        if (!!signedUrl) {
          saveAs(signedUrl as string)
        }
      }
    },
  })

  const isDisabled = useMemo(
    () => campaign?.isInDraft || !campaign?.gcsLeadsCsvPath,
    [campaign?.isInDraft, campaign?.gcsLeadsCsvPath],
  )

  return (
    <Button
      onClick={() => {
        getCompaniesCsvSignedUrl()
      }}
      disabled={isDisabled}
      startIcon={isDisabled ? <DownloadIcon /> : <DownloadActiveIcon />}
      sx={{ padding: '6px 0px', minWidth: '0px', '& .MuiButton-startIcon': { marginRight: '4px', marginLeft: '4px' } }}
    ></Button>
  )
}

export default memo(DownloadCSVButton)
