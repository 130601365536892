const UniverseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29px" height="29px" viewBox="0 0 29 29" version="1.1">
      <g id="surface1">
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(14.901961%,22.745098%,47.843137%)"
          fillOpacity="1"
          d="M 29 14.5 C 29 22.507812 22.507812 29 14.5 29 C 6.492188 29 0 22.507812 0 14.5 C 0 6.492188 6.492188 0 14.5 0 C 22.507812 0 29 6.492188 29 14.5 Z M 29 14.5 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(7.058824%,6.666667%,28.627451%)"
          fillOpacity="1"
          d="M 22.144531 8.589844 L 20 10.804688 L 15.925781 6.730469 L 14.785156 7.867188 L 22.601562 15.6875 C 22.507812 15.738281 22.414062 15.789062 22.316406 15.835938 L 19.335938 12.855469 L 13.152344 17.316406 L 9.21875 13.382812 L 6.984375 15.699219 L 12.546875 21.261719 L 9.449219 23.433594 L 15.007812 28.988281 C 22.476562 28.730469 28.507812 22.828125 28.96875 15.414062 Z M 22.144531 8.589844 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,67.843137%,61.960784%)"
          fillOpacity="1"
          d="M 22.554688 9.667969 C 22.554688 10.554688 21.835938 11.277344 20.945312 11.277344 C 20.054688 11.277344 19.332031 10.554688 19.332031 9.667969 C 19.332031 8.777344 20.054688 8.054688 20.945312 8.054688 C 21.835938 8.054688 22.554688 8.777344 22.554688 9.667969 Z M 22.554688 9.667969 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,38.431373%,38.431373%)"
          fillOpacity="1"
          d="M 20.945312 8.054688 C 21.835938 8.054688 22.554688 8.777344 22.554688 9.667969 C 22.554688 10.554688 21.835938 11.277344 20.945312 11.277344 Z M 20.945312 8.054688 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,67.843137%,61.960784%)"
          fillOpacity="1"
          d="M 9.667969 14.5 C 9.667969 15.390625 8.945312 16.109375 8.054688 16.109375 C 7.164062 16.109375 6.445312 15.390625 6.445312 14.5 C 6.445312 13.609375 7.164062 12.890625 8.054688 12.890625 C 8.945312 12.890625 9.667969 13.609375 9.667969 14.5 Z M 9.667969 14.5 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,38.431373%,38.431373%)"
          fillOpacity="1"
          d="M 8.054688 12.890625 C 8.945312 12.890625 9.667969 13.609375 9.667969 14.5 C 9.667969 15.390625 8.945312 16.109375 8.054688 16.109375 Z M 8.054688 12.890625 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,67.843137%,61.960784%)"
          fillOpacity="1"
          d="M 16.109375 7.25 C 16.109375 7.695312 15.75 8.054688 15.304688 8.054688 C 14.859375 8.054688 14.5 7.695312 14.5 7.25 C 14.5 6.804688 14.859375 6.445312 15.304688 6.445312 C 15.75 6.445312 16.109375 6.804688 16.109375 7.25 Z M 16.109375 7.25 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,67.843137%,61.960784%)"
          fillOpacity="1"
          d="M 19.332031 23.359375 C 19.332031 23.804688 18.972656 24.167969 18.527344 24.167969 C 18.082031 24.167969 17.722656 23.804688 17.722656 23.359375 C 17.722656 22.917969 18.082031 22.554688 18.527344 22.554688 C 18.972656 22.554688 19.332031 22.917969 19.332031 23.359375 Z M 19.332031 23.359375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,78.823529%,10.588235%)"
          fillOpacity="1"
          d="M 15.195312 9.855469 L 16.304688 12.105469 C 16.355469 12.203125 16.449219 12.273438 16.558594 12.289062 L 19.042969 12.652344 C 19.320312 12.691406 19.433594 13.035156 19.230469 13.230469 L 17.433594 14.980469 C 17.355469 15.058594 17.316406 15.171875 17.335938 15.28125 L 17.761719 17.753906 C 17.808594 18.03125 17.515625 18.242188 17.269531 18.113281 L 15.046875 16.945312 C 14.949219 16.894531 14.832031 16.894531 14.730469 16.945312 L 12.511719 18.113281 C 12.261719 18.242188 11.972656 18.03125 12.019531 17.753906 L 12.441406 15.28125 C 12.460938 15.171875 12.425781 15.058594 12.34375 14.980469 L 10.550781 13.230469 C 10.347656 13.035156 10.460938 12.691406 10.738281 12.652344 L 13.21875 12.289062 C 13.332031 12.273438 13.425781 12.203125 13.476562 12.105469 L 14.585938 9.855469 C 14.710938 9.601562 15.070312 9.601562 15.195312 9.855469 Z M 15.195312 9.855469 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(83.137255%,54.509804%,2.745098%)"
          fillOpacity="1"
          d="M 19.230469 13.230469 C 19.433594 13.035156 19.320312 12.691406 19.042969 12.652344 L 16.5625 12.289062 C 16.449219 12.273438 16.355469 12.203125 16.304688 12.105469 L 15.195312 9.855469 C 15.132812 9.726562 15.007812 9.664062 14.882812 9.667969 L 14.882812 16.90625 C 14.9375 16.90625 14.996094 16.917969 15.046875 16.945312 L 17.269531 18.113281 C 17.515625 18.242188 17.808594 18.03125 17.761719 17.753906 L 17.335938 15.28125 C 17.316406 15.171875 17.355469 15.058594 17.433594 14.980469 Z M 19.230469 13.230469 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,67.843137%,61.960784%)"
          fillOpacity="1"
          d="M 24.167969 20.140625 C 24.167969 20.582031 23.804688 20.945312 23.359375 20.945312 C 22.917969 20.945312 22.554688 20.582031 22.554688 20.140625 C 22.554688 19.695312 22.917969 19.332031 23.359375 19.332031 C 23.804688 19.332031 24.167969 19.695312 24.167969 20.140625 Z M 24.167969 20.140625 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,92.941176%,70.980392%)"
          fillOpacity="1"
          d="M 21.582031 14.292969 L 21.960938 15.058594 C 21.980469 15.09375 22.011719 15.117188 22.050781 15.121094 L 22.894531 15.246094 C 22.988281 15.257812 23.027344 15.375 22.957031 15.441406 L 22.347656 16.039062 C 22.320312 16.0625 22.308594 16.101562 22.3125 16.140625 L 22.457031 16.980469 C 22.472656 17.078125 22.375 17.148438 22.289062 17.105469 L 21.535156 16.707031 C 21.5 16.6875 21.460938 16.6875 21.425781 16.707031 L 20.671875 17.105469 C 20.585938 17.148438 20.484375 17.078125 20.503906 16.980469 L 20.648438 16.140625 C 20.652344 16.101562 20.640625 16.0625 20.613281 16.039062 L 20 15.441406 C 19.933594 15.375 19.972656 15.257812 20.066406 15.246094 L 20.910156 15.121094 C 20.949219 15.117188 20.980469 15.09375 21 15.058594 L 21.375 14.292969 C 21.417969 14.207031 21.542969 14.207031 21.582031 14.292969 Z M 21.582031 14.292969 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(99.215686%,87.843137%,52.156863%)"
          fillOpacity="1"
          d="M 22.957031 15.441406 C 23.027344 15.375 22.988281 15.257812 22.894531 15.246094 L 22.050781 15.121094 C 22.011719 15.117188 21.980469 15.09375 21.960938 15.058594 L 21.582031 14.292969 C 21.558594 14.242188 21.507812 14.222656 21.460938 14.230469 L 21.460938 16.695312 C 21.484375 16.691406 21.511719 16.695312 21.535156 16.707031 L 22.289062 17.105469 C 22.375 17.148438 22.472656 17.078125 22.457031 16.980469 L 22.3125 16.140625 C 22.308594 16.101562 22.320312 16.0625 22.347656 16.039062 Z M 22.957031 15.441406 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,92.941176%,70.980392%)"
          fillOpacity="1"
          d="M 10.890625 19.425781 L 11.425781 20.511719 C 11.449219 20.558594 11.496094 20.59375 11.550781 20.601562 L 12.75 20.773438 C 12.882812 20.792969 12.9375 20.960938 12.839844 21.054688 L 11.972656 21.902344 C 11.933594 21.9375 11.914062 21.992188 11.925781 22.046875 L 12.128906 23.238281 C 12.152344 23.375 12.011719 23.476562 11.890625 23.414062 L 10.820312 22.847656 C 10.769531 22.824219 10.714844 22.824219 10.664062 22.847656 L 9.59375 23.414062 C 9.472656 23.476562 9.332031 23.375 9.355469 23.238281 L 9.558594 22.046875 C 9.570312 21.992188 9.550781 21.9375 9.511719 21.902344 L 8.644531 21.054688 C 8.546875 20.960938 8.601562 20.792969 8.734375 20.773438 L 9.933594 20.601562 C 9.988281 20.59375 10.035156 20.558594 10.058594 20.511719 L 10.59375 19.425781 C 10.65625 19.304688 10.828125 19.304688 10.890625 19.425781 Z M 10.890625 19.425781 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(99.215686%,87.843137%,52.156863%)"
          fillOpacity="1"
          d="M 12.839844 21.054688 C 12.9375 20.960938 12.882812 20.792969 12.75 20.773438 L 11.550781 20.601562 C 11.496094 20.59375 11.449219 20.558594 11.425781 20.511719 L 10.890625 19.425781 C 10.863281 19.367188 10.808594 19.339844 10.757812 19.335938 L 10.757812 22.832031 C 10.777344 22.835938 10.800781 22.839844 10.820312 22.851562 L 11.890625 23.414062 C 12.011719 23.476562 12.152344 23.375 12.128906 23.242188 L 11.925781 22.046875 C 11.914062 21.992188 11.933594 21.9375 11.972656 21.902344 Z M 12.839844 21.054688 "
        />
      </g>
    </svg>
  )
}

export default UniverseIcon
