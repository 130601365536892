import { Button, Grid, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useMutation } from '@apollo/client'
import { css } from 'aphrodite'
import * as Yup from 'yup'

import { UpdateUserDocument } from '../../../graphql/generated'
import styles from '../../../components/Authentication/style'
import UpdatePasswordModal from '../UpdatePasswordModal'
import { useAuth } from '../../../hooks/useAuth'
import Text from '../../../components/Text'
import style from './styles'

const ProfileForm = () => {
  const { user, isMobile } = useAuth()
  const isMediumScreen = useMediaQuery('@media (max-width: 1200px)')
  const [updateUser, { loading, error, data }] = useMutation(UpdateUserDocument, {
    onError: (error) => {
      console.log('%cerror', 'color:red;font-size:50px', error)
    },
    onCompleted: (data) => {},
  })

  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const initialValues = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    company: user?.company?.name,
  }

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    // contactNumber: Yup.string().required('Contact number is required'),
    company: Yup.string().required('Company is required'),
    // password: Yup.string()
    //   .required('Password is required')
    //   .min(8, 'Password must be at least 8 characters')
    //   .matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]+$/,
    //     'Password must include at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character',
    //   ),
  })

  const handleSubmit = (values: any) => {
    updateUser({
      variables: {
        data: {
          userId: user?.id,
          ...values,
        },
      },
    })
  }

  const sxStyle = {
    width: isMobile || isMediumScreen ? '100%' : '50%',
    input: { paddingTop: '11px', paddingBottom: '11px' },
  }

  useEffect(() => {
    if (user) {
      initialValues.firstName = user.firstName || ''
      initialValues.lastName = user.lastName || ''
      initialValues.email = user.email || ''
      initialValues.company = user.company?.name || ''
    }
  }, [user])

  const handleShowPasswordModal = useCallback(() => {
    setShowPasswordModal(!showPasswordModal)
  }, [showPasswordModal])

  return (
    <div className={css(style.profileContainer)}>
      <Typography variant="h6" align="left">
        Profile
      </Typography>
      {user?.photoUrl && (
        <img
          src={user?.photoUrl}
          alt="User Avatar"
          className={css(style.userAvatar)}
          style={{ width: isMobile ? '100%' : '115px' }}
        />
      )}
      <br />
      <br />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values }) => {
          return (
            <Form>
              <div className={css(style.title)}>
                {/* first name last name */}
                <Text semiBold extraStyles={[styles.inputLabel]}>
                  First Name
                </Text>
                <Field
                  type="text"
                  id="firstName"
                  name="firstName"
                  as={TextField}
                  sx={sxStyle}
                  value={values.firstName}
                />
                <ErrorMessage name="firstName" component="small" />
              </div>
              <div className={css(style.title)}>
                {/* first name last name */}
                <Text semiBold extraStyles={[styles.inputLabel]}>
                  Last Name
                </Text>
                <Field type="text" id="lastName" name="lastName" as={TextField} sx={sxStyle} value={values.lastName} />
                <ErrorMessage name="lastName" component="small" />
              </div>

              <div className={css(style.title)}>
                <Text semiBold extraStyles={[styles.inputLabel]}>
                  Email
                </Text>
                <Field type="email" id="email" name="email" as={TextField} sx={sxStyle} />
                <ErrorMessage name="email" component="small" />
              </div>

              {/* <div
            style={{
              marginBottom: '30px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text semiBold extraStyles={[styles.inputLabel]}>
              Contact Number
            </Text>
            <Field type="text" id="contactNumber" name="contactNumber" as={TextField} sx={sxStyle} />
            <ErrorMessage name="contactNumber" component="small" />
          </div> */}

              <div className={css(style.title)}>
                <Text semiBold extraStyles={[styles.inputLabel]}>
                  Company
                </Text>
                <Field type="text" id="company" name="company" as={TextField} sx={sxStyle} />
                <ErrorMessage name="company" component="small" />
              </div>

              <div className={css(style.title)}>
                <Text semiBold extraStyles={[styles.inputLabel]}>
                  Password
                </Text>
                {/* <div
                  style={{
                    display: 'flex',
                    columnGap: '5px',
                  }}
                > */}
                <Grid container spacing={2}>
                  <Grid item lg={6} sm={12}>
                    <Field
                      type="password"
                      id="password"
                      name="password"
                      placeholder="********"
                      as={TextField}
                      sx={{ ...sxStyle, width: '100%' }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item lg={6} sm={12}>
                    <Button variant="outlined" onClick={handleShowPasswordModal}>
                      Update Password
                    </Button>
                  </Grid>
                </Grid>
                <ErrorMessage name="password" component="small" />
              </div>

              {/* Submit Button */}
              <Button type="submit" variant="contained">
                Save
              </Button>
            </Form>
          )
        }}
      </Formik>
      <UpdatePasswordModal showPasswordModal={showPasswordModal} handleShowPasswordModal={handleShowPasswordModal} />
    </div>
  )
}

export default ProfileForm
