import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import { Avatar, Chip, IconButton, TableCell, TableRow } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { css } from 'aphrodite'
import moment from 'moment'

import styles from './style'
import Text from '../../../components/Text'
import { User } from '../../../graphql/generated'
import { useAuth } from '../../../hooks/useAuth'

interface TableDataProps {
  user: User
  handleShowEditUserModal: () => void
  handleDeleteUser: () => void
  getRole: React.ReactNode
  isPending?: boolean
}
const TableData = ({ user, handleDeleteUser, handleShowEditUserModal, getRole, isPending }: TableDataProps) => {
  const { user: authUser } = useAuth()
  const isUserAdmin = authUser?.isSuperAdmin && authUser?.isTeamAdmin

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        <div className={css(styles.userInfoContainer)}>
          <Avatar alt={user.firstName as string} src={user.photoUrl ? user.photoUrl : '/broken-image.jpg'} />
          <div className={css(styles.userInfo)}>
            <div>
              {user.firstName} {user.lastName}
              {isPending && (
                <Chip
                  label="Invitation sent"
                  size="small"
                  style={{
                    fontSize: '12px',
                    borderRadius: '4px',
                    background: 'var(--green-light-green, #39A894)',
                    padding: '2px 8px',
                    marginLeft: '16px',
                  }}
                />
              )}
            </div>
            {/* {user.firstName} {user.lastName} */}
            <Text>{user.email}</Text>
          </div>
        </div>
      </TableCell>
      <TableCell>{moment(user.createdAt).format('MMM DD YYYY')}</TableCell>
      <TableCell>{getRole}</TableCell>
      <TableCell>
        {(!isUserAdmin || authUser?.id !== user.id) && (
          <>
            <IconButton className={css(styles.editButton)} onClick={() => handleShowEditUserModal()}>
              <BorderColorOutlinedIcon />
            </IconButton>
            <IconButton className={css(styles.deleteButton)} onClick={() => handleDeleteUser()}>
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  )
}

export default TableData
