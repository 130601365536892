import { FC, memo } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import { css } from 'aphrodite'
import { Maybe } from 'graphql/jsutils/Maybe'
import { Constants } from '../../../../constants/constants'
import styles from './styles'

interface LeadsWarningModalProps {
  open: boolean
  title?: string
  message?: string
  acceptLabel?: string
  rejectLabel?: string
  handleReject: () => void
  name?: Maybe<string> | undefined
}

const LeadsWarningModal: FC<LeadsWarningModalProps> = ({
  open,
  handleReject,
  name,
  title = '',
  message = ``,
  acceptLabel = 'Back to label page',
}) => {
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={handleReject}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle align="center" id="dialog-title">
        <Typography className={css(styles.leadsModalTitle)} variant="subtitle2">
          {title || Constants.WARNING_TITLE}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description" className="">
          <Typography
            className={css(styles.leadsModalContent)}
            variant="button"
            display="block"
            color="ThreeDDarkShadow"
            gutterBottom
          >
            {name}
          </Typography>
          <Typography className={css(styles.leadsModalContent)} variant="subtitle2" display="block" gutterBottom>
            {message || Constants.WARNING_MESSAGE}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', margin: '1rem 0rem' }}>
        <Button className={css(styles.downloadBtn)} variant="outlined" onClick={handleReject}>
          {acceptLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default memo(LeadsWarningModal)
