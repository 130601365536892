import { StyleSheet } from 'aphrodite'
import { textLightBody, white } from '../../shared/colors'
// import { textLightBody, white } from '../../../shared/colors'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    height: 'calc(100vh - 77px)',
    // background: 'linear-gradient(0deg, rgba(105, 111, 134, 0.04) 0%, rgba(105, 111, 134, 0.04) 100%), #FFF',
    background: '#F1F2F4',
    overflow: 'hidden',
    flex: '1 1',
    /* medium screen */
    // '@media (max-width: 768px)': {
    //   width: '90%',
    // },
    /* Small screen */
    // '@media (max-width: 576px)': {
    //   width: '100%',
    // },
  },
  mainBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '100%',
    maxWidth: 1000,
    height: 0,
    flex: '1 1',
    overflow: 'auto',
    padding: '24px',
    '@media (max-width: 768px)': {
      padding: '0 10px',
    },
  },
  messagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  message: {
    color: textLightBody,
    lineHeight: '150%',
  },
  messageContainer: {
    padding: 16,
    // display: 'flex',
    background: white,
    width: '100%',
    // width: 'fit-content',
    borderRadius: 8,
    boxShadow: '8px 8px 12px 0px rgba(138, 177, 137, 0.06)',
  },
  inputContainer: {
    display: 'flex',
    width: '100%',
    // marginLeft: '4px',
    padding: '10px 0px 30px 0px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    // background: 'linear-gradient(180deg, rgba(253, 253, 253, 0.00) 0%, rgba(252, 252, 252, 0.94) 24.23%, #EEE 100%)',
    background: 'linear-gradient(180deg, rgba(241, 242, 244, 0.00) 0%, rgba(241, 242, 244, 0.85) 24.23%, #F1F2F4 100%)',
    /* medium screen */
    // '@media (max-width: 970px)': {
    //   width: '90%',
    //   marginLeft: '45px',
    // },
    // /* Small screen */
    '@media (max-width: 768px)': {
      padding: '10px 0px 70px 0px',
    },
  },
  inputField: {
    width: '100%',
    height: '60px',
    display: 'flex',
    maxWidth: '880px',
    minHeight: '56px',
    padding: '12px 16px',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '12px',
    border: '1px solid #F0F3F5',
    background: '#FFF',
    boxShadow: '0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
    marginRight: '0px',
    '@media (max-width: 768px)': {
      width: '90%',
    },
    /* Small screen */
    // '@media (max-width: 576px)': {
    //   width: '100%',
    // },
  },
  formControl: {
    width: '100%',
    maxWidth: '845px',
    borderRadius: '12px',
    border: '1px solid #F0F3F5',
    background: '#FFF',
    boxShadow: '0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
    '@media (max-width: 970px)': {
      width: '90%',
    },
  },
  buttonsContainer: {
    display: 'flex',
    // maxWidth: '890px',
    // width: '100%',
    height: '56px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '200px',
    borderRadius: '12px',
    '@media (max-width: 1024px)': {
      flexDirection: 'column',
      gap: '20px',
      marginBottom: '20px',
    },
  },
  editButton: {
    display: 'flex',
    padding: '12px 36px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignStems: 'center',
    color: '#378DFA',
    fontSize: '18px',
    fontWeight: 600,
    gap: '17px',
    marginLeft: '10px',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius: '12px',
    border: '1px solid #F4F5F6',
    background: '#FDFDFD',
    boxShadow: '0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
    textTransform: 'none',
    '@media (max-width: 1024px)': {
      marginRight: '150px',
      marginLeft: '150px',
    },
  },
  readyButton: {
    display: 'flex',
    padding: '12px 36px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignStems: 'center',
    fontSize: '18px',
    // marginRight: '45px',
    fontWeight: 600,
    gap: '17px',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius: '12px',
    border: '1px solid #F4F5F6',
    // background: '#4CAF50',
    boxShadow: '0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
    background: '#378DFA',
    textTransform: 'none',
    '@media (max-width: 600px)': {
      padding: '6px 16px',
    },
    ':disabled': {
      borderRadius: '8px',
      border: '0.5px solid var(--text-placeholder, #C1C9D2)',
      background: 'var(--notes-unselected-grey, #F0F3F5)',
      color: 'var(--text-placeholder, #C1C9D2)',

      /* Button shadow */
      boxShadow: '0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
    },
    // '@media (max-width: 1024px)': {
    //   marginLeft: '150px',
    //   marginRight: '150px',
    // },
  },
  warningMsgContainer: {
    alignSelf: 'center',
    display: 'flex',
    width: '100%',
    maxWidth: '830px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px 16px',
    borderRadius: '8px',
    border: '1px solid #E4827A',
    background: 'rgba(228, 90, 79, 0.08)',
  },
  warningMsgTitleTxt: {
    fontWeight: 700,
  },
  warningMsgTxt: {
    marginLeft: '11.5px',
  },
})

export default styles
