import { StyleSheet } from 'aphrodite'
import { textLightBody, white } from '../../../shared/colors'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    // marginBottom: 24,
  },
  containerUser: {
    maxWidth: '890px',
    marginLeft: 'auto',
    marginRight: 0,
    display: 'flex',
    // width: '100%',
    '@media (max-width: 768px)': {
      marginLeft: '55px',
      justifyContent: 'flex-end',
    },
  },
  containerSystem: {
    maxWidth: '890px',
    marginLeft: 0,
    marginRight: 'auto',
    display: 'flex',
    // width: '100%',
    '@media (max-width: 768px)': {
      marginRight: '55px',
    },
  },
  //
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'full',
    height: 40,
    minHeight: 40,
    minWidth: 40,
  },
  avatarContainerSystem: {
    marginRight: 20,
    '@media (max-width: 768px)': {
      marginRight: 8,
    },
  },
  avatarContainerUser: {
    marginLeft: 20,
    // background: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%)',
    borderRadius: '40px',
    background: 'lightgray 50% / cover no-repeat',
  },
  hideOnMobile: {
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  message: {
    color: textLightBody,
    lineHeight: '150%',
  },
  textStyle: {
    // marginTop: 12,
    marginBottom: 8,
    fontSize: 12,
    fontWeight: 600,
    color: '#A3AAB7',
  },
  messageContainer: {
    // display: 'flex',
    // width: 'fit-content',

    padding: 16,
    width: 'auto',
    // background: white,
    // borderRadius: 8,
    // boxShadow: '8px 8px 12px 0px rgba(138, 177, 137, 0.06)',

    borderRadius: ' 0px 16px 16px 16px',
    background: '#FAFAFA',
    boxShadow: '8px 8px 12px 0px rgba(187, 188, 187, 0.06)',
  },
  showSummaryFormContainer: {
    padding: 16,
    width: '100%',
    background: white,
    borderRadius: 16,
    // marginTop: '20px',
    boxShadow: '8px 8px 12px 0px rgba(138, 177, 137, 0.06)',
  },
  userMessageContainer: {
    padding: 16,
    background: '#DCE1ED',
    width: '100%',
    borderRadius: '16px 0px 16px 16px',
    boxShadow: '8px 8px 12px 0px rgba(187, 188, 187, 0.06)',
  },
})

export default styles
