import { FC, memo } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { css } from 'aphrodite'
import { Maybe } from 'graphql/jsutils/Maybe'
import CloseIcon from '@mui/icons-material/Close'
import { Constants } from '../../../../constants/constants'
import styles from './styles'

interface LeadsModalProps {
  open: boolean
  loading: boolean
  handleReject: () => void
  handleAccept: () => void
  name?: Maybe<string> | undefined
  title?: string
  message?: string
  acceptLabel?: string
  rejectLabel?: string
}

const LeadsModal: FC<LeadsModalProps> = ({
  open,
  loading,
  handleAccept,
  handleReject,
  //   title = 'Download csv.',
  title = 'Email me csv.',
  name,
  message = 'Please provide your email below. We’ll send the leads csv once we finish generating leads result.',
  //   message = 'Click to download the csv. of your 200 leads list.',
  acceptLabel = 'Confirm',
  //   acceptLabel='Download',
  rejectLabel,
}) => {
  return (
    <Dialog
      sx={{ textAlign: 'center', boxShadow: '0px 4px 30px rgba(99, 99, 99, 0.35)', borderRadius: '16px' }}
      open={open}
      onClose={handleReject}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="dialog-title">
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <CloseIcon />
        </div>
        <Typography className={css(styles.leadsModalTitle)} variant="subtitle2">
          {title || Constants.WARNING_TITLE}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description" className="">
          <Typography
            className={css(styles.leadsModalContent)}
            variant="button"
            display="block"
            color="ThreeDDarkShadow"
            gutterBottom
          >
            {name}
          </Typography>
          <Typography className={css(styles.leadsModalContent)} variant="subtitle2" display="block" gutterBottom>
            {message || Constants.WARNING_MESSAGE}
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            // label="Company Email"
            name="email"
            autoComplete="email"
            autoFocus
            variant="outlined"
            placeholder="Input your email"
            // width="100%"
            sx={{
              marginTop: '13px',
              marginBottom: '13px',
              input: { paddingTop: '11px', paddingBottom: '11px' },
            }}
          />
        </DialogContentText>
        <br />
        <Button className={css(styles.downloadBtn)} variant="outlined" disabled={loading} onClick={handleReject}>
          {acceptLabel}
        </Button>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}></DialogActions>
    </Dialog>
  )
}

export default memo(LeadsModal)
