import { Container } from '@mui/material'

interface Args {
  children: any
}

export const BasicPage = ({ children }: Args) => {
  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{ '@media (min-width: 600px)': { paddingLeft: '2px', paddingRight: '2px' }, minWidth: '100%' }}
    >
      {children}
    </Container>
  )
}
