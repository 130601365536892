interface Args {
  isHovered: boolean
}

const NextPageIcon = ({ isHovered }: Args) => {
  return (
    <svg width="53" height="54" viewBox="0 0 53 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="26.5" cy="27" r="26.5" fill={isHovered ? '#7865ED' : '#E7E9F6'} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.0001 19.5C26.4478 19.5 26.0001 19.9477 26.0001 20.5V26.5001H20C19.4477 26.5001 19 26.9478 19 27.5001C19 28.0524 19.4477 28.5001 20 28.5001H26.0001V34.5004C26.0001 35.0527 26.4478 35.5004 27.0001 35.5004C27.5524 35.5004 28.0001 35.0527 28.0001 34.5004V28.5001H34.0004C34.5527 28.5001 35.0004 28.0524 35.0004 27.5001C35.0004 26.9478 34.5527 26.5001 34.0004 26.5001H28.0001V20.5C28.0001 19.9477 27.5524 19.5 27.0001 19.5Z"
        fill={isHovered ? 'white' : '#7865ED'}
      />
    </svg>
  )
}

export default NextPageIcon
