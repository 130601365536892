import { FC, memo, useCallback, useState } from 'react'
import { css } from 'aphrodite'
import { Button, Grid } from '@mui/material'
import styles from './styles'
import { GetCampaignStatusRes, GetLeadsCsvPathDocument } from '../../../graphql/generated'
import { useLazyQuery } from '@apollo/client'
import { useCampaignAndUser } from '../../../components/NewCampaignLayout'
import { saveAs } from 'file-saver'
import LeadsResultPreview from '../TeachingAndGrading/LeadsResultPreview/LeadsResultPreview'

interface LeadListActionsProps {
  leadsRequested: number
  campaignStatus: GetCampaignStatusRes
  loading?: boolean
  handleGetLeads: () => void
}

const LeadListActions: FC<LeadListActionsProps> = ({ campaignStatus, leadsRequested, handleGetLeads }) => {
  const { campaign } = useCampaignAndUser()
  const [showModal, setShowModal] = useState(false)

  const [getCompaniesCsvSignedUrl, { loading }] = useLazyQuery(GetLeadsCsvPathDocument, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        campaignId: campaign?.id as string,
        creatorId: campaign?.creatorId as string,
      },
    },
    onCompleted: (data) => {
      if (data.getLeadsCsvPath) {
        const signedUrl = data.getLeadsCsvPath
        if (!!signedUrl) {
          saveAs(signedUrl as string)
        }
      }
    },
  })

  const handleAccept = useCallback(() => {
    setShowModal((prevState) => !prevState)
  }, [])

  return (
    <Grid item xs={12} className={css(styles.leadsListActions)}>
      <LeadsResultPreview
        open={showModal}
        handleReject={handleAccept}
        handleGetLeads={handleGetLeads}
        campaignStatus={campaignStatus}
        loading={loading}
      />
      <Button
        disabled={loading || !campaign?.leadsFetched}
        onClick={() => getCompaniesCsvSignedUrl()}
        variant="outlined"
        color="primary"
        className={css(styles.leadsListActionsBtn)}
      >
        Download CSV
      </Button>
      <Button
        variant="outlined"
        onClick={handleAccept}
        className={css(styles.leadsListActionsBtn)}
        disabled={!campaign?.leadsFetched || leadsRequested >= campaignStatus?.accurate_leads}
      >
        Get more leads
      </Button>
      {/* <Button variant="outlined" className={css(styles.leadsListActionsBtn)}>
        Email me csv.
      </Button>
      <Button variant="outlined" className={css(styles.leadsListActionsBtn)}>
        Build email campaign
      </Button> */}
    </Grid>
  )
}

export default memo(LeadListActions)
