import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { Company } from '../../../graphql/generated'
import CompanyRow from './CompanyRow'

interface Args {
  companies: Company[]
}

const CompanyTable = ({ companies }: Args) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>Client</TableCell>
            <TableCell colSpan={4}># Campaigns</TableCell>
            <TableCell colSpan={4}># Users</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies?.map((company) => (
            <CompanyRow key={company.id} company={company} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CompanyTable
