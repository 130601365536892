import { createContext, useContext, useState } from 'react'

const TooltipContext = createContext({
  openTooltipId: null,
  setOpenTooltipId: (id: any) => {},
})

export const useTooltip = () => useContext(TooltipContext)

export const TooltipProvider = ({ children }: any) => {
  const [openTooltipId, setOpenTooltipId] = useState(null)

  return <TooltipContext.Provider value={{ openTooltipId, setOpenTooltipId }}>{children}</TooltipContext.Provider>
}
