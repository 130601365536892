import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import { Avatar } from '@mui/material'
import Box from '@mui/material/Box'
import { css } from 'aphrodite'

import { ReactComponent as LogoutSvgIcon } from '../../assets/svg/logout.svg'
import { ReactComponent as MenuSvgIcon } from '../../assets/svg/Move.svg'
import SalezillaLogo from '../../assets/images/salezilla_logo.png'
import { useAuth } from '../../hooks/useAuth'
import styles from './../NavBar/styles'
import WarningModal from '../WarningModal/WarningModal'

const pathnameMap = {
  '/campaigns': 'campaigns',
  '/new-campaign/targeting-input': 'new-campaign/targeting-input',
  '/new-campaign/teaching-and-grading': 'new-campaign/targeting-input',
  '/new-campaign/review': 'new-campaign',
  '/universe-controller': 'universe-controller',
  '/settings': 'settings',
} as any

interface Args {
  pages: any[]
}

const MobileNavBar = ({ pages }: Args) => {
  const navigate = useNavigate()
  const { user, firebaseUser, logout } = useAuth()
  const [selectedPage, setSelectedPage] = useState('campaigns')
  const location = useLocation()
  const [showWarnModal, setShowWarnModal] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setSelectedPage(pathnameMap[location.pathname as string] as string)
  }, [setSelectedPage, location.pathname])

  const handlePageClick = async (path: string) => {
    if (path === 'home') {
      window.open('https://www.salezilla.io/', '_blank')
    }
    if (path.includes('new-campaign') && location.pathname.includes('new-campaign')) {
      setSelectedPage(path)
      handleWarnModal()
    } else {
      setSelectedPage(path)
      navigate(`/${path}`)
    }
  }

  const handleLogout = useCallback(() => {
    logout()
  }, [logout])

  const onUserInfoClick = () => {
    navigate('/settings')
  }

  const handleWarnModal = () => {
    setShowWarnModal(!showWarnModal)
  }

  const handleAccept = () => {
    navigate(`/${selectedPage}`)
    handleWarnModal()
  }

  const list = () => (
    <Box role="presentation">
      <WarningModal
        loading={false}
        open={showWarnModal}
        handleAccept={handleAccept}
        title="Create new lead list?"
        handleReject={handleWarnModal}
        acceptLabel="Create new lead list"
        rejectLabel="Stay on current list building"
        message="You’re already working on a lead list. You progress will be saved. Are you sure you want to move on to creating another lead list?"
      />

      <img alt="Salezilla Logo" src={SalezillaLogo} className={css(styles.salezillaLogo)} />
      <Divider variant="middle" className={css(styles.divider)} />
      <div className={css(styles.navBarContainer)}>
        <ul className={css(styles.navList)}>
          {pages?.map((page) => (
            <li
              className={css([
                styles.navItemContainer,
                styles.expandedNavItemWidth,
                selectedPage === page.path && styles.selectedNavItem,
              ])}
              key={page.label}
              onClick={async () => {
                await handlePageClick(page.path)
              }}
            >
              {page.icon && (
                <>
                  {selectedPage !== page.path ? (
                    <page.icon
                      // className={css([selectedPage === page.path && [isExpanded ? '' : styles.selectedNavIcons]])}
                      isSelected={selectedPage === page.path}
                    />
                  ) : (
                    <page.selectedIcon
                      // className={css([selectedPage === page.path && [isExpanded ? '' : styles.selectedNavIcons]])}
                      isSelected={selectedPage === page.path}
                    />
                  )}
                </>
              )}
              {page.path === 'new-campaign' && <p className={css(styles.campaignRocket)}>🚀</p>}
              <p className={css([styles.pageLabel, selectedPage === page.path && styles.selectedNavText])}>
                {page.label}
              </p>
            </li>
          ))}
          {!!user && firebaseUser && (
            <li className={css(styles.navItemContainer)} key="logout" onClick={handleLogout}>
              <LogoutSvgIcon className={css(styles.moreIcon)} />
              <p>Logout</p>
            </li>
          )}
        </ul>
      </div>
      <Divider variant="middle" className={css(styles.divider)} />
      <div className={css(styles.userInfoContainer)}>
        <Divider variant="middle" className={css(styles.userInfoDivider)} />
        <div className={css(styles.userInfo)}>
          <div className={css(styles.navItemContainer, styles.userAvatar)} onClick={onUserInfoClick}>
            <Avatar alt={user?.firstName as string} src={user?.photoUrl ? user.photoUrl : '/broken-image.jpg'} />
            <p>
              {user?.firstName} {user?.lastName}
            </p>
          </div>
        </div>
      </div>
    </Box>
  )

  return (
    <div>
      <IconButton>
        <MenuSvgIcon onClick={() => setOpen(true)} />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        sx={{ '& .MuiDrawer-paper': { padding: '36px 12px 36px 16px' } }}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  )
}

export default MobileNavBar
