import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { TextField, Box, useMediaQuery, Button, Grid } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { css } from 'aphrodite'

import { ReactComponent as StepsSvg } from '../../assets/svg/login_hero_asset.svg'
import { ReactComponent as Steps1250Svg } from '../../assets/svg/login_hero_asset_1250.svg'
import { ReactComponent as StepsLargeSvg } from '../../assets/svg/login_hero_asset_large.svg'
import SalezillaLogo from '../../assets/images/salezilla_logo_white.png'
import { placeholder } from '../../shared/colors'
import Toast from '../../components/Toast/Toast'
import Text from '../../components/Text'
import styles from './style'
import WarningNotice from '../WarningNotice'

interface Labels {
  heading: string
  submitLabel: string
}

interface AuthenticationInput {
  showForgetPassword?: boolean
  toast: boolean
  loading?: boolean
  disabled?: boolean
  message: string
  labels: Labels
  severity?: string
  children?: React.ReactNode
  agreement?: React.ReactNode
  CustomForm?: React.ReactNode
  setToast: Dispatch<SetStateAction<boolean>>
  submit?: React.FormEventHandler<HTMLFormElement>
  resetClick?: () => void
}

const Authentication: React.FC<AuthenticationInput> = ({
  showForgetPassword,
  toast,
  message,
  CustomForm,
  setToast,
  submit,
  children,
  severity = 'error',
  labels: { heading, submitLabel },
  resetClick,
  agreement,
  loading = false,
}) => {
  const navigate = useNavigate()

  const isMobile = useMediaQuery('@media (max-width: 899px)')
  const isSmallScreen = useMediaQuery('@media (min-width: 900px)')
  const isMediumScreen = useMediaQuery('@media (min-width: 1200px)')
  const isLargeScreen = useMediaQuery('@media (min-width: 1536px)')
  const isScreen1185 = useMediaQuery('@media (max-width: 1185px)')
  const isScreen1250 = useMediaQuery('@media (max-width: 1250px)')
  const isScreenLarge = useMediaQuery('@media (min-width: 1251px)')
  const isMobileScreen = useMediaQuery('@media (max-width: 768px)')

  let illustrationWidth = 0
  let illustrationHeight = '73.2vh'
  let topMarginForOuterBox = '5vh'
  let textFieldBottomMargin = '20px'
  let ctaButtonTopMargin = '48px'
  let ctaButtonWidth = '100%'

  if (isLargeScreen) {
    illustrationWidth = 631
    topMarginForOuterBox = '5vh'
    textFieldBottomMargin = '74px'
    ctaButtonTopMargin = '38px'
    ctaButtonWidth = '284px'
  } else if (isMediumScreen) {
    illustrationWidth = 570
    topMarginForOuterBox = '4vh'
    ctaButtonWidth = '244px'
  } else if (isSmallScreen) {
    illustrationWidth = 460
    topMarginForOuterBox = '0'
    textFieldBottomMargin = '24px'
    ctaButtonWidth = '224px'
  }

  return (
    <Grid container className={css(styles.container)}>
      {/* {!isMobile && (
        <div className={css(styles.illustrationContainer)}>
          <Text semiBold extraStyles={[styles.illustrationText]}>
            Sales Prospecting Automated
          </Text>
          <OnboardingIllustration width={illustrationWidth} height={illustrationHeight} />
          {/* <div className={css(styles.illustrationContentContainer)}>
            </div> */}
      {/* </div>
      )} */}
      {!isMobile && (
        <Grid item md={7} style={{ height: '100vh' }}>
          <img alt="Salezilla Logo" src={SalezillaLogo} className={css(styles.salezillaLogo)} />
          <div className={css(styles.illustrationContainer)}>
            <Text semiBold extraStyles={[styles.firstHeading, styles.textFont]}>
              The Next Gen <br /> Cold Email Platform
            </Text>
            {/* {isScreen1250 ? (
              <Steps1250Svg style={{ marginTop: '44px', marginBottom: '44px', marginLeft: '-60px' }} />
            ) : isScreenLarge ? (
              <StepsLargeSvg style={{ marginTop: '44px', marginBottom: '44px', marginLeft: '-60px',  }} />
            ) : (
              )} */}
            <StepsSvg style={{ marginTop: '50px', marginBottom: '30px', marginLeft: '-60px' }} />

            <Text extraStyles={[styles.firstHeading]}>Want to sign up?</Text>
            <Button
              className={css(styles.bookDemoButton)}
              onClick={() => {
                window.open('https://calendly.com/dougtanner/salezilla-open-discussion?month=2023-11')
              }}
            >
              Book a Demo
            </Button>
          </div>
        </Grid>
      )}

      {isMobileScreen && (
        <Grid item sm={12} style={{ height: '20vh', width: '85%', margin: '30px' }}>
          <img
            alt="Salezilla Logo"
            src={SalezillaLogo}
            style={{
              margin: '60px 0px 20px 0px',
              height: 35,
              width: 107,
            }}
            // className={css(styles.salezillaLogo)}
          />
          <Text semiBold extraStyles={[styles.textFont, styles.mobileAlign, styles.mobileHeading]}>
            The Next Gen <br /> Cold Email Platform
          </Text>
        </Grid>
      )}

      <Grid
        item
        md={5}
        sm={12}
        sx={{
          // marginTop: topMarginForOuterBox,
          // display: 'flex',
          // flexDirection: 'column',
          // alignItems: 'center',
          width: '100%',
          height: '100%',
          display: 'flex',
          padding: isMobileScreen ? '72px 30px' : '72px 48px',
          flexDirection: 'column',
          alignItems: isMobileScreen ? 'flex-start' : 'center',
          textAlign: isMobileScreen ? 'left' : 'center',
          justifyContent: isMobileScreen ? 'flex-start' : 'center',
          gap: '36px',
          borderTopLeftRadius: '24px',
          borderBottomLeftRadius: isMobileScreen ? 0 : '24px',
          borderTopRightRadius: isMobileScreen ? '24px' : 0,
          borderBottomRightRadius: 0,
          background: '#FFF',
          borderTopRight: '0px',
          boxShadow: '0px 4px 101px 0px rgba(131, 131, 131, 0.13)',
        }}
      >
        <div className={css(styles.contentContainer)}>
          <Toast
            open={toast}
            setOpen={setToast}
            message={message}
            severity={severity === 'error' ? 'error' : 'success'}
          />
          {CustomForm ? (
            <>{CustomForm}</>
          ) : (
            <>
              <Text bold extraStyles={[styles.pageTitleText, isMobile && styles.mobileAlign]}>
                {heading}
              </Text>
              <Box component="form" {...(submit && { onSubmit: submit })} noValidate sx={{ mt: 1 }}>
                {!isMobileScreen && (
                  <Text semiBold extraStyles={[styles.inputLabel, styles.mobileAlign]}>
                    Company Email
                  </Text>
                )}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  // label="Company Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  variant="outlined"
                  placeholder="Your company email"
                  // width="100%"
                  sx={{
                    marginTop: '8px',
                    marginBottom: textFieldBottomMargin,
                    fontSize: '14px',
                    fontWeight: 600,
                    input: {
                      paddingTop: '11px',
                      paddingBottom: '11px',
                      backgroundColor: '#F9F9FA',
                      '::placeholder': {
                        color: '#697386',
                        fontSize: '14px',
                        fontFamily: 'Poppins',
                        opacity: 1,
                      },
                    },
                    '.MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '8px',
                      border: '1px solid #E5E5E5',
                    },
                    '.css-ubour0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      border: '2px solid #CED3F1',
                    },
                    '.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CED3F1 !important',
                    },
                  }}
                />
                {!isMobileScreen && (
                  <Text semiBold extraStyles={[styles.inputLabel, styles.mobileAlign]}>
                    Password
                  </Text>
                )}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  // label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  placeholder="Your password"
                  sx={{
                    marginTop: '8px',
                    marginBottom: '20px',
                    height: '45px',
                    fontSize: '14px',
                    fontWeight: 600,
                    input: {
                      paddingTop: '11px',
                      paddingBottom: '11px',
                      backgroundColor: '#F9F9FA',
                      '::placeholder': {
                        color: '#697386',
                        fontSize: '14px',
                        fontFamily: 'Poppins',
                        opacity: 1,
                      },
                    },
                    '.MuiOutlinedInput-notchedOutline ': {
                      borderRadius: '8px',
                      border: '1px solid #E5E5E5',
                    },
                    '.css-ubour0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      border: '2px solid #CED3F1',
                    },
                    '.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CED3F1 !important',
                    },
                  }}
                />
                {showForgetPassword && (
                  <Text extraStyles={[styles.forgetPasswordText, styles.mobileAlign]}>
                    Forget your password?{' '}
                    <span
                      className={css(styles.resetPasswordButton)}
                      onClick={() => navigate('/reset-password', { replace: true })}
                    >
                      Reset password
                    </span>
                  </Text>
                )}
                {agreement && agreement}
                {message && <WarningNotice message={message} />}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <LoadingButton
                    type="submit"
                    fullWidth={isMobileScreen ? true : false}
                    variant="contained"
                    sx={{
                      display: 'flex',
                      alignSelf: 'center',
                      marginTop: '96px',
                      marginBottom: isMobileScreen ? '16px' : '96px',
                      mb: 2,
                      width: ctaButtonWidth,
                      height: '52px',
                      textTransform: 'none',
                      padding: '16px 40px',
                      gap: '10px',
                      borderRadius: '12px',
                      border: '2px solid #CED3F1',
                      background:
                        'linear-gradient(0deg, rgba(105, 111, 134, 0.04) 0%, rgba(105, 111, 134, 0.04) 100%), #FFF',
                      boxShadow: 'none',
                      color: '#4750F0',
                      fontSize: '18px',
                      fontWeight: 600,
                      ':hover': {
                        boxShadow: 'none',
                        color: '#FFF',
                        background: '#2932C9',
                      },
                    }}
                    loading={loading}
                  >
                    {submitLabel}
                  </LoadingButton>
                </div>
                {children}
              </Box>
            </>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

export default Authentication
