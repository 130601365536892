import { FC, memo, useCallback, useState } from 'react'
import { useAuth } from '../../../../../hooks/useAuth'
import { useNavigate } from 'react-router'
import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { css } from 'aphrodite'

import WarningModal from '../../../../../components/WarningModal/WarningModal'
import { Campaign, DeleteCampaignDocument } from '../../../../../graphql/generated'
import { getStatus } from '../../../../../shared/utils/getCampaignStatus'
import { Constants } from '../../../../../constants/constants'
import styles from './styles'

interface DeleteCampaignButtonProps {
  campaign: Campaign
  label?: string
  redirect?: string
}

const DeleteCampaignButton: FC<DeleteCampaignButtonProps> = ({ campaign, label, redirect }) => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)

  const [deleteCampaign, { loading, error, data }] = useMutation(DeleteCampaignDocument, {
    update: (cache, { data }) => {
      const normalizedId = cache.identify({ id: campaign.id, __typename: 'Campaign' })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
    onCompleted: () => {
      setShowModal(false)
      if (redirect) {
        navigate(redirect, { replace: true })
      }
    },
  })

  const onCancelCampaignClick = () => {
    deleteCampaign({
      ...(campaign && {
        variables: {
          data: { campaignId: campaign.id, creatorId: campaign.creatorId },
        },
      }),
    })
  }

  const handleShowModal = useCallback(() => {
    setShowModal((show) => !show)
  }, [])

  return (
    <>
      <WarningModal
        name={campaign.name}
        loading={loading}
        open={showModal}
        handleAccept={onCancelCampaignClick}
        handleReject={handleShowModal}
        {...getStatus({ campaign })}
      />
      <Button
        className={css([styles.deleteCampaignBtn])}
        startIcon={<DeleteIcon fontSize="medium" sx={{ width: 24 }} />}
        onClick={handleShowModal}
        sx={{
          width: '180 !important',
          padding: '10px 59px',
          ':hover': {
            background: 'none',
          },
        }}
      >
        {label || Constants.DELETE_CAMPAIGN}&nbsp; &nbsp;
      </Button>
    </>
  )
}

export default memo(DeleteCampaignButton)
