export const black = '#000000'
export const mainGreen = '#1F7E73'
export const lightGreen = '#39A894'
export const body = '#3C4257'
export const dark = '#31333A'
export const gray = '#7d7d7d'
export const greenNavy = '#0C2E39'
export const darkGray = '#7B7B7B'
export const gray35 = '#595959'
export const white = '#ffffff'
export const ghostWhite = '#F8FAFB'
export const primaryColor = '#F16722'
export const romance = '#EFEEEB'
export const darkNavyBlue = '#1A2B88'
export const note = '#697386'
export const placeholder = '#C1C9D2'
export const whiteWithGreen = '#F5FDFF'
export const lightBlueGreen = '#A3AAB7'
export const grayishBlue = '#E5E5E5'
export const successGreen = '#4CAF50'
export const gptColor = 'linear-gradient(99.45deg, #1F7E73 -8.68%, #8AD252 108.71%, rgba(31, 126, 115, 0) 108.72%)'
export const leaf = '#319635'
export const tree = '#2A862E'
export const buttonShadow = '0px 4px 12px rgba(99, 99, 99, 0.13)'
export const blue = '#378DFA'
export const textLightBody = '#5E657B'