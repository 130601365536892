import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Text from '../../components/Text'
import styles from '../../components/Authentication/style'
import { UpdateUserPasswordDocument } from '../../graphql/generated'
import { useMutation } from '@apollo/client'
import { useAuth } from '../../hooks/useAuth'

interface PasswordDialogProps {
  showPasswordModal: boolean
  handleShowPasswordModal: () => void
}

const PasswordDialog = ({ showPasswordModal, handleShowPasswordModal }: PasswordDialogProps) => {
  const { user } = useAuth()
  const [updateUserPassword, { loading: updateUserPasswordLoading, error, data }] = useMutation(UpdateUserPasswordDocument, {
    onError: (error) => {
      console.log('%cerror', 'color:red;font-size:50px', error)
    },
    onCompleted: (data) => {},
  })

  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .required('New Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]+$/,
        'Password must include at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character',
      ),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match')
      .required('Confirm New Password is required'),
  })

  const handleSubmit = (values: any, { resetForm }: any) => {
    console.log(values)
    updateUserPassword({
      variables: {
        data: {
          userId: user?.id,
          ...values,
        },
      },
    })
    resetForm()
    handleShowPasswordModal()
  }
  const sxStyle = {
    input: { paddingTop: '11px', paddingBottom: '11px' },
  }
  return (
    <Dialog open={showPasswordModal} onClose={handleShowPasswordModal} maxWidth="lg">
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent style={{ width: '500px' }}>
        <Formik
          initialValues={{
            newPassword: '',
            confirmNewPassword: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              columnGap: '2rem',
            }}
          >
            <div
              style={{
                marginBottom: '30px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text semiBold extraStyles={[styles.inputLabel]}>
                New Password
              </Text>
              <div
                style={{
                  display: 'flex',
                  columnGap: '5px',
                }}
              >
                <Field
                  type={showPassword ? 'text' : 'password'}
                  name="newPassword"
                  as={TextField}
                  sx={sxStyle}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
              </div>
              <ErrorMessage name="newPassword" component="small" />
            </div>
            <div
              style={{
                marginBottom: '30px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text semiBold extraStyles={[styles.inputLabel]}>
                Confirm New Password
              </Text>
              <div
                style={{
                  display: 'flex',
                  columnGap: '5px',
                }}
              >
                <Field
                  type={showPassword ? 'text' : 'password'}
                  name="confirmNewPassword"
                  as={TextField}
                  sx={sxStyle}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
              </div>
              <ErrorMessage name="confirmNewPassword" component="small" />
            </div>

            <DialogActions>
              <Button disabled={updateUserPasswordLoading} type="submit" color="primary">
                Save
              </Button>
              <Button onClick={handleShowPasswordModal} color="secondary">
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default PasswordDialog
