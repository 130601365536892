import { FC, memo } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Maybe } from 'graphql/jsutils/Maybe'
import { Constants } from '../../constants/constants'

interface WarningModalProps {
  open: boolean
  loading: boolean
  handleReject: () => void
  handleAccept: () => void
  name?: Maybe<string> | undefined
  title?: string
  message?: string
  acceptLabel?: string
  rejectLabel?: string
  width?: string
}

const WarningModal: FC<WarningModalProps> = ({
  open,
  loading,
  handleAccept,
  handleReject,
  title,
  name,
  message,
  acceptLabel,
  rejectLabel,
  width,
}) => {
  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          borderRadius: '16px',
          background: ' #FFF',
          boxShadow: '0px 4px 30px 0px rgba(99, 99, 99, 0.35)',
          width: width || '467px',
          maxHeight: '650px',
          padding: '36px',
        },
      }}
      open={open}
      onClose={handleReject}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle
        style={{ display: 'flex', justifyContent: 'center', fontSize: '20px', fontWeight: 600 }}
        id="dialog-title"
      >
        {title || Constants.WARNING_TITLE}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          <Typography variant="button" display="block" color="ThreeDDarkShadow" gutterBottom>
            {name}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              color: '#3C4257',
            }}
            variant="subtitle2"
            display="block"
            gutterBottom
          >
            {message || Constants.WARNING_MESSAGE}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '24px',
        }}
      >
        <Button
          sx={{
            background: '#FFF',
            color: '#378DFA',
            textTransform: 'none',
            borderRadius: '10px',
          }}
          variant="contained"
          disabled={loading}
          onClick={handleReject}
        >
          {rejectLabel || Constants.NEVER_MIND}
        </Button>
        <LoadingButton
          style={{
            textTransform: 'none',
            borderRadius: '10px',
          }}
          variant="contained"
          loading={loading}
          onClick={handleAccept}
          color="warning"
        >
          {acceptLabel || Constants.DELETE}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default memo(WarningModal)
