import { css } from 'aphrodite'
import {
  useMediaQuery,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

import styles from './styles'
import Text from '../../../components/Text'
import { mainGreen } from '../../../shared/colors'

interface Args {
  hasArrow?: boolean
  isOpen: boolean
  planTypeSelected?: string
  planIndex?: number
  onClick?: () => void
}

const InfraSetupDropdown = ({
  hasArrow = true,
  isOpen,
  planTypeSelected,
  planIndex,
  onClick,
}: Args) => {
  return (
    <div>
      <Text
        extraStyles={[
          styles.noteText,
          ...(planTypeSelected === 'self-serve' && typeof planIndex === 'number' && planIndex > 2
            ? [styles.noteTextSmall]
            : []),
        ]}
      >
        Optional add on:
      </Text>
      <div className={css(styles.dropdownContainer)} onClick={onClick}>
        <Text
          semiBold
          extraStyles={[
            styles.mainText,
            ...(planTypeSelected === 'self-serve' && typeof planIndex === 'number' && planIndex > 2
              ? [styles.mainTextSmall]
              : []),
          ]}
        >
          Advanced Cold Email Infrastructure Setup*
        </Text>
        {hasArrow &&
          (isOpen ? (
            <ArrowDropUpIcon style={{ color: mainGreen }} />
          ) : (
            <ArrowDropDownIcon style={{ color: mainGreen }} />
          ))}
      </div>
      {isOpen && (
        <div className={css([styles.contentContainer])}>
          <Text extraStyles={[styles.contentTitleText]}>$3,499 one-time setup</Text>
          <Text extraStyles={[styles.contentSubtitleText]}>
            Protect your email domain, land in the inbox, and achieve higher email volumes safely with our tried and
            true setup
          </Text>
          <Text extraStyles={[styles.contentBulletText]}>
            · 6 new domains will be purchased as part of the setup package
          </Text>
          <Text extraStyles={[styles.contentBulletText]}>· DKIM & SPF setup for 6 domains</Text>
          <Text extraStyles={[styles.contentBulletText]}>· 6 email addresses setup</Text>
          <Text extraStyles={[styles.contentBulletText]}>· 6 email address warmups</Text>
          <Text extraStyles={[styles.contentBulletText]}>· Connect emails to campaign management tool</Text>
        </div>
      )}
    </div>
  )
}

export default InfraSetupDropdown
