import { useState } from 'react'
import { Typography, Button } from '@mui/material'
import { css } from 'aphrodite'
import styles from './style'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'

const Description = ({
  text,
  maxCharacterCount,
  className,
}: {
  text: string
  maxCharacterCount: number
  className?: string
}) => {
  const [expanded, setExpanded] = useState(false)

  const handleToggle = () => {
    setExpanded(!expanded)
  }

  const truncatedText = text.substring(0, maxCharacterCount)
  const displayText = expanded ? text : truncatedText + '...'

  return (
    <div className={css(styles.DescriptionContainer)}>
      <Typography className={className || css(styles.DescriptionBox)} variant="body1">
        {displayText}
      </Typography>
      {text.length > maxCharacterCount && (
        <Button
          className={css(styles.ExpandBtn)}
          onClick={handleToggle}
          endIcon={expanded ? <ArrowDropUp /> : <ArrowDropDown />}
        />
      )}
    </div>
  )
}

export default Description
