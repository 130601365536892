const RexAvatar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <circle cx="20" cy="20" r="20" fill="url(#paint0_linear_4462_1676)" />
    <g filter="url(#filter0_d_4462_1676)">
      <path
        d="M12.5069 15.2497C11.9856 15.5592 11.363 16.5723 10.7693 18.0357L10.3639 19.0629L9.78464 19.0207C9.21991 18.9926 9.20543 19.0066 9.03167 19.5554C9.01434 20.1295 9.01436 20.6257 9.00273 20.7911C8.98503 21.0459 9.06034 21.6118 9.03138 23.2722L9.04615 26L11.725 25.9156C16.1994 25.7889 22.6431 24.5788 28.0877 22.8481C32.4608 21.455 32.678 21.2017 31.1141 19.2177C29.8398 17.6136 28.7683 16.5583 28.2904 16.4457C27.6099 16.2768 26.8569 16.6286 25.9446 17.5151L25.0758 18.3594L23.5264 16.7412C22.6576 15.8406 21.8033 15.0808 21.615 15.0245C20.7607 14.8275 19.7326 15.8125 18.357 18.1764L17.7343 19.2318L17.1551 18.5423C16.0691 17.2618 14.1432 15.4185 13.6798 15.2074C13.0861 14.926 13.0572 14.926 12.5069 15.2497ZM24.3228 19.7946C24.3228 19.8509 23.1499 19.9072 21.7164 19.9072C20.2828 19.9072 19.1099 19.8509 19.1099 19.7946C19.1099 19.6117 20.6738 17.2196 21.0213 16.8538L21.3109 16.5583L22.8169 18.1202C23.6423 18.9785 24.3228 19.7243 24.3228 19.7946ZM14.4618 17.7402C15.0265 18.3172 15.6926 19.0489 15.9388 19.3584L16.4021 19.9213L15.3306 19.8368C14.7369 19.7946 13.7812 19.6821 13.202 19.6117C12.6228 19.5413 12.0725 19.4851 11.9856 19.4851C11.7684 19.4851 11.9567 18.8941 12.5214 17.698C12.7965 17.107 13.1006 16.6708 13.2165 16.6708C13.3323 16.6708 13.897 17.1493 14.4618 17.7402ZM28.0298 19.668C25.8867 19.8368 25.7419 19.6258 27.161 18.3594L27.8416 17.7402L28.7249 18.6549L29.5937 19.5554L28.0298 19.668Z"
        fill="url(#paint1_linear_4462_1676)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_4462_1676"
        x="8"
        y="14"
        width="29"
        height="17"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.285326 0 0 0 0 0.111753 0 0 0 0 0.654167 0 0 0 0.54 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4462_1676" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4462_1676" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_4462_1676" x1="-25" y1="-26.5" x2="35.5" y2="45" gradientUnits="userSpaceOnUse">
        <stop offset="0.0969882" stopColor="#95B5F2" />
        <stop offset="1" stopColor="#614BE2" />
      </linearGradient>
      <linearGradient id="paint1_linear_4462_1676" x1="27.5" y1="15" x2="10.5" y2="28.5" gradientUnits="userSpaceOnUse">
        <stop offset="0.195995" stopColor="white" />
        <stop offset="1" stopColor="#E2E2E2" />
      </linearGradient>
    </defs>
  </svg>
)

export default RexAvatar
