import { TextField } from '@mui/material'
import { debounce } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useGraphQL } from '../../../../context/GraphQLContext'
import { useCampaignAndUser } from '../../../NewCampaignLayout'

const DesiredLeadsNumber = ({ showError }: { showError?: boolean }) => {
  const { updateCampaign } = useGraphQL()
  const { campaign, user } = useCampaignAndUser()
  const [errorMessage, setErrorMessage] = useState('')
  const [isGettingCompanies, setIsGettingCompanies] = useState(false)
  const [desiredNumberOfLeads, setDesiredNumberOfLeads] = useState<number>()

  const isValidNumberOfLeads = useMemo(() => {
    if (desiredNumberOfLeads && user?.company?.creditsAvailable) {
      return desiredNumberOfLeads <= user?.company?.creditsAvailable
    }
    return true
  }, [desiredNumberOfLeads, user?.company?.creditsAvailable])

  const handleCampaignUpdate = debounce((e) => {
    const { name, value } = e.target
    if (campaign?.id) {
      updateCampaign({
        variables: {
          data: {
            [name]: value ? parseInt(value) : 0,
            campaignId: campaign.id,
          },
        },
      })
    }
  }, 1000)

  useEffect(() => {
    setDesiredNumberOfLeads(campaign?.desiredNumberOfLeads as number)
  }, [campaign?.desiredNumberOfLeads])

  return (
    <TextField
      type="number"
      required
      fullWidth
      size="medium"
      margin="dense"
      name="desiredNumberOfLeads"
      value={desiredNumberOfLeads}
      disabled={isGettingCompanies}
      id="outlined-helperText"
      helperText={
        (showError && !desiredNumberOfLeads ? 'Please enter desired number of leads' : '') ||
        (isValidNumberOfLeads
          ? `Credits available ${user?.company?.creditsAvailable}`
          : 'Desired number of leads must be less than or equal Credits available')
      }
      error={(showError && !desiredNumberOfLeads) || !isValidNumberOfLeads}
      placeholder="ex. 300"
      variant="outlined"
      onChange={(e) => {
        setDesiredNumberOfLeads(parseInt(e.target.value))
        setErrorMessage('')
        handleCampaignUpdate(e)
      }}
      sx={{
        '& .MuiInputBase-root': {
          fontSize: 14,
        },
        '& .MuiInputBase-input': {
          height: '13px !important',
        },
        '& .MuiFormHelperText-root': {
          marginLeft: 0,
        },
        ':hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #CED3F1 !important',
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: '8px',
        },
        '.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#CED3F1 !important',
        },
      }}
    />
  )
}

export default DesiredLeadsNumber
