import { StyleSheet } from 'aphrodite'
import { body } from '../../../shared/colors'

const styles = StyleSheet.create({
  leadsFilterContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '12px',
    width: '805px',
    height: '35px',
    left: '16px',
    top: '16px',
  },
  leadCompanyFilterLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '8px',
    height: '22px',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    fontFamily: "'Open Sans'",
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#A3AAB7',
  },
  filterSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '8px',
    width: '805px',
    height: '31px',
    flex: 'none',
    order: 1,
    flexGrow: 0,
  },
  allFilterBtn: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 14px',
    gap: '17px',
    width: 'auto',
    height: '31px',
    textTransform: 'capitalize',
    borderRadius: '24px',
    border: '1px solid var(--notes-inactive-icon, #D6D6D6)',
    background: 'var(--main-white, #FFF)',
    ':hover': {
      /* Styles to apply on hover */
      boxShadow: '0px 0px 4px 0px rgba(55, 141, 250, 0.40)',
      border: '1px solid #378DFA',
      background: '#F5FDFF',
      borderRadius: '24px',
    },
  },
  filterBtn: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 14px',
    gap: '17px',
    width: 'auto',
    height: '31px',
    textTransform: 'capitalize',
    borderRadius: ' 8px',
    // border: '1px solid  #E5E5E5',
    // background: '#F4F5F6',
    ':hover': {
      /* Styles to apply on hover */
      boxShadow: '0px 0px 4px 0px rgba(55, 141, 250, 0.40)',
      border: '1px solid #378DFA',
      background: '#FFF',
    },
  },
  allFilterBtnText: {
    height: '23px',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '165%',
    color: '#3C4257',
  },
  inputLabel: {
    color: body,
  },
  filterApplyBtn: {
    borderRadius: '6px',
    border: '0.5px solid #FFF',
    background: '#378DFA',
    boxShadow: '0px 4px 12px 0px rgba(99, 99, 99, 0.13)',
  },
})

export default styles
